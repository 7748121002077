import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

import {CustomNotifierService} from '../../../core/services/custom.notifier.service';

import Column from '../../../core/list/column';
import {PageList} from '../../../core/list/page-list';
import {QuotationModalComponent} from '../../../core/quotation-modal/quotation-modal.component';
import {QuotationRefuseModalComponent} from '../../../core/quotation-refuse-modal/quotation-refuse-modal.component';
import {ListComponent} from '../../../core/list/list.component';

import {QuotationsRequestService} from '../../../core/services/quotations/quotations.request.service';
import {PreviewService} from '../../../core/services/helper/preview.service';

import ExamplesResponse from '../../../core/interfaces/response/examples.response';
import QuotationsResponse from '../../../core/interfaces/response/quotation.response';
import QuotationAllResponse from '../../../core/interfaces/quotation/response/all';
import Quotation from '../../../core/interfaces/quotation/quotation';
import QuotationForm from '../../../core/interfaces/quotation/form';
import QuotationModalAcceptResponse, {QuotationTrainingModalAcceptResponse} from '../../../core/interfaces/quotation/response/modal_accept';
import PreviewMenu from '../../../core/interfaces/preview_menu';
import {NoResultService} from '../../../core/no-result/no-result.service';
import {QuotationRedirectDashboard} from '../../../core/interfaces/dashboard/quotation-dashboard';

@Component({
  selector: 'app-all-quotations',
  templateUrl: './all-quotations.component.html',
  styleUrls: ['./all-quotations.component.scss']
})
export class AllQuotationsComponent extends PageList implements OnInit, AfterViewInit {
  private _modal: QuotationModalComponent;
  private _modalRefuse: QuotationRefuseModalComponent;
  public columns: Column[] = [];
  public quotations: Quotation[] = [];
  private list: ListComponent;
  public menu: PreviewMenu[] = [];
  public emptyQuotations: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _quotationService: QuotationsRequestService,
    private _previewService: PreviewService,
    private _notifier: CustomNotifierService,
    private _noResultService: NoResultService,
    public sanitizer: DomSanitizer
  ) {
    super();
  }

  /**
   * Called before view init
   */
  public ngOnInit() {
    this.menu = [
      {
        callback: 'download',
        name: 'Télécharger',
        icon: 'get_app',
        color: 'default'
      },
      {
        callback: 'accept',
        name: 'Accepter le devis',
        icon: 'done',
        color: 'green',
        cases_display: ['À transmettre'],
      },
      {
        callback: 'refuse',
        name: 'Refuser le devis',
        icon: 'close',
        color: 'red',
        cases_display: ['À transmettre'],
      },
      {
        callback: 'update-supporting-document',
        name: 'Modifier le justificatif',
        icon: 'create',
        color: 'default',
        cases_display: ['Attente commercial'],
      }
    ];

    this._activatedRoute.data.subscribe((response: QuotationAllResponse) => {
      this.quotations = response.data.datas;
      this.total = response.data.total;
      this.pages = response.data.pages;
      this.emptyQuotations = response.data.total === 0;

      response.data.forms.forEach((item: QuotationForm) => {
        this.columns.push({
          label: item.label,
          showLabel: true,
          sort: '',
          property: item.name,
          checked: true,
          type: item.type
        });
      });
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      // this.loader = this.requestCount > 0;
    });
  }

  /**
   * Called after view init
   */
  public ngAfterViewInit() {
    this._activatedRoute.queryParams.subscribe((params: QuotationRedirectDashboard) => {
      if (params.source !== undefined && params.source === 'dashboard') {
        this.updateList({
          action: 'select',
          value: {id: params.id}
        });
      }
    });

    this._modal.eventOnClose.subscribe(event => {
      this.menu = [
        {
          callback: 'download',
          name: 'Télécharger',
          icon: 'get_app',
          color: 'default'
        },
        {
          callback: 'update-supporting-document',
          name: 'Modifier le justificatif',
          icon: 'create',
          color: 'default',
        }
      ];
      if (event !== null) {
        /**
         * The current quote in the quote list
         */
        const quotation = this.quotations.findIndex((item: Quotation) => {
          return item.id === event.id;
        });

        if (quotation !== -1) {
          if (event.validateAction && event.validateAction === 'update-supporting-document') {
            this._previewService.toggleLoader('preview-menu');
            this._quotationService.updateSupportingDocument(event.id, event)
              .subscribe((response: any) => {
                this._previewService.toggleLoader('preview-menu');
                if (response.success) {
                  this._notifier.successUpdate();
                } else {
                  this._notifier.errorRequest();
                }
              });
          } else {
            // If a training is set, accept the quote with a specific route
            if (this.quotations[quotation].training !== null) {
              this._previewService.toggleLoader('preview-menu');
              this._quotationService.acceptTraining(event.id, event)
                .subscribe((response: QuotationTrainingModalAcceptResponse) => {
                  this._previewService.toggleLoader('preview-menu');
                  if (response.success) {
                    this.quotations[quotation] = response.quotation;
                    this._notifier.successCustom('Le devis a bien été signé, un commercial validera prochainement le devis');
                  } else {
                    this._notifier.errors(response.error);
                  }
                });
            } else {
              this._previewService.toggleLoader('preview-menu');
              this._quotationService.accept(event.id, event)
                .subscribe((response: QuotationModalAcceptResponse) => {
                  this._previewService.toggleLoader('preview-menu');
                  if (response.success) {
                    this.quotations[quotation] = response.datas;
                  } else {
                    this._notifier.errors(response.error);
                  }
                });
            }
          }
        }
      }
    });

    this._modalRefuse.eventOnClose
      .subscribe(event => {
        if (event !== null) {
          this._previewService.toggleLoader('preview-menu');
          this._quotationService
            .refuse(event.id, event).subscribe(response => {
            this._previewService.toggleLoader('preview-menu');
            const search = this.quotations.findIndex(item => {
              return item.id === event.id;
            });

            if (search !== -1) {
              this.quotations[search] = response[`datas`];
            }
          });
        }
      });
  }

  /**
   * Update list of quotations
   * @param data
   */
  public updateList(data) {
    if (data.action === 'pageSize') {
      this.changePageSize(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    } else if (data.action === 'sort') {
      this.sort = data.value;
      this.order = data.order;
      this.getData();
    } else if (data.action === 'search') {
      this.search = data.value;
      this.page = 1;
      this.getData();
    } else if (data.action === 'delete') {
      this.deleteData(data.value);
    } else if (data.action === 'download') {
      this._previewService.toggleLoader('preview-menu');
      this._quotationService.getQuotation(data.value.id).subscribe(response => {
        const link = document.createElement('a');
        link.download = data.value.customField.number + '.pdf';
        link.href = 'data:application/octet-stream;base64,' + response['datas'];
        document.body.append(link);
        link.setAttribute('style', 'display: none');
        link.click();
        link.remove();
        this._previewService.toggleLoader('preview-menu');
      });
    } else if (data.action === 'select') {
      this._previewService.toggleLoader('preview');
      this._quotationService.getQuotation(data.value.id)
        .subscribe(response => {
          this._previewService.toggleLoader('preview');
          this.list.id = data.value.id;
          this.list.objectPreview = response[`quotation`];
          this.list.showPreview(this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response[`datas`]));
        });
    } else if (data.action === 'accept') {
      this._modal.open(data.value.id);
    } else if (data.action === 'refuse') {
      this._modalRefuse.open(data.value.id);
    } else if (data.action === 'update-supporting-document') {
      this._modal.open(data.value.id, true, true);
    }
  }

  /**
   * Get quotations data from API
   */
  public getData() {
    this.requestInProgress.next(true);

    this._quotationService.getQuotations(
      this.search,
      this.sort,
      this.order,
      this.limit,
      this.page
    ).subscribe((response: QuotationsResponse) => {
      if (response.success) {
        this.quotations = response.datas;
        this.columns = [];

        response.forms.forEach(item => {
          this.columns.push({
            label: item.label,
            showLabel: true,
            sort: 'Users.email',
            property: item.name,
            checked: true,
            type: item.type
          });
        });
        this.total = response.total;
        this.pages = response.pages;
        if (this.total === 0) {
          this._noResultService.subTitle = 'Aucun devis n\'a été trouvé';
        }
      }
      this.requestInProgress.next(false);
    });
  }

  public deleteData(exampleId) {
    this._quotationService.deleteExample(exampleId).subscribe((response: ExamplesResponse) => {
      if (response.success) {
        this._notifier.successDelete();
        this.changePage(1);
      } else {
        this._notifier.errors(response.errors);
      }
    });
  }

  public bindModal(deleteModal: QuotationModalComponent) {
    this._modal = deleteModal;
  }

  bindModalRefuse(deleteModal: QuotationRefuseModalComponent) {
    this._modalRefuse = deleteModal;
  }

  bindList(list: ListComponent) {
    if (this.list === undefined) {
      this.list = list;
    }
  }

  /**
   * Check the loader value for preview from service
   */
  get isLoaderPreview(): boolean {
    return this._previewService.loaderPreview;
  }

  /**
   * Check the loader value for preview menu from service
   */
  get isLoaderPreviewMenu(): boolean {
    return this._previewService.loaderPreviewMenu;
  }
}
