import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuotationRefuseModalComponent } from './quotation-refuse-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  declarations: [QuotationRefuseModalComponent],
  exports: [QuotationRefuseModalComponent]
})

export class QuotationRefuseModalModule {}
