<div class="card card-scroll">
  <div class="card-header">
    <div class="d-flex w-100 justify-content-between align-items-center">
      <div class="d-flex align-items-center justify-content-center">
        <mat-icon aria-hidden="false" aria-label="" [routerLink]="['/tickets/']">arrow_back</mat-icon>
        <div class="d-flex col flex-column justify-content-center">
          <h3 class="header-title mb-2">Ticket n°{{ ticket.id }} - {{ ticket.content }}</h3>

          <p class="m-0" *ngIf="!ticket.status">État : <span class="label-status label-open">Ouvert</span></p>
          <p class="m-0" *ngIf="ticket.status">État : <span class="label-status label-close">Fermé</span></p>
        </div>
      </div>

      <div class="d-flex mt-sm-0">
        <div class="dl">
          <button mat-raised-button id="button-close-ticket" class="mat-accent float-right btn btn-primary" (click)="closeTicket(ticketId)" [disabled]="loader">Clôturer le ticket</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <!-- Chat Box -->
      <div class="chat-box col-12 d-md-flex flex-column justify-content-between">
        <div class="messages" #scrollMe>

          <div *ngFor="let comment of comments; let last = last;">
            <!-- Client Message-->
            <div class="media w-75 ml-auto right" *ngIf="!comment.from_support">

              <div class="media-body ml-2" [ngClass]="{ 'mb-2' : !last }">
                <div class="bg-primary rounded py-2 px-3">
                  <p class="text-small mb-0 text-white pre-line">
                    {{ comment.comment }}
                  </p>
                  <div class="mb-2" *ngIf="comment.files && comment.files.length">
                    <div class="d-flex justify-content-end" *ngFor="let file of comment.files">
                      <div class="card-file">
                        <img src="../assets/img/file_icon.png" class="tooltipHtml" [title]="file.name" alt=""/>
                        <span>{{ file.name }}</span>
                        <mat-icon aria-hidden="false" aria-label="" *ngIf="!loader" (click)="downloadFile(file)">download</mat-icon>
                        <div [ngClass]="{ 'is-loading is-loading-sm': loader }"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="small text-right text-muted m-0 comment-right">
                  {{ comment.user_name + ' - ' + comment.date }}
                </p>
              </div>
            </div>

            <!-- BATISAFE Message-->
            <div class="media w-75 left" *ngIf="comment.from_support">
              <div class="media-body mr-2" [ngClass]="{ 'mb-2' : !last }">
                <div class="bg-light rounded py-2 px-3">
                  <p class="text-small mb-0 pre-line">
                    {{ comment.comment }}
                  </p>
                  <div class="mb-2" *ngIf="comment.files && comment.files.length">
                    <div class="d-flex justify-content-start" *ngFor="let file of comment.files">
                      <div class="card-file">
                        <img src="../assets/img/file_icon.png" class="tooltipHtml" [title]="file.name" alt=""/>
                        <span>{{ file.name }}</span>
                        <mat-icon aria-hidden="false" aria-label="" *ngIf="!loader" (click)="downloadFile(file)">download</mat-icon>
                        <div [ngClass]="{ 'is-loading is-loading-sm': loader }"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="small text-muted m-0 comment-left">
                  {{ comment.date + ' - ' + comment.user_name }}
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="card-footer" *ngIf="!ticket.status">

    <input id="fileUpload" multiple="multiple" name="fileUpload" style="display: none" type="file"/>

    <div class="row mb-2" *ngIf="sendFiles.length">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex" *ngFor="let file of sendFiles">
        <div class="card-file flex-fill">
          <img src="../assets/img/file_icon.png" [title]="file.name" alt=""/>
          <span>{{ file.name }}</span>
          <mat-icon aria-hidden="false" aria-label="" (click)="removeFileFromArray(file)">close</mat-icon>
        </div>
      </div>
    </div>

    <form [formGroup]="messageForm" class="row">
      <mat-form-field class="col-lg-9 col-12">
        <!-- LABEL -->
        <mat-label required>Message</mat-label>
        <!-- INPUT -->
        <textarea id="textarea" (keyup)="autogrowTextHeight()" formControlName="comment" matInput placeholder="" type="text"></textarea>
        <!-- ERROR -->
        <mat-error *ngIf="messageForm.controls['comment'].hasError('required')">
          <span>Ce champ ne peux pas être vide</span>
        </mat-error>
        <mat-error *ngIf="messageForm.controls['comment'].hasError('key')">
          {{ messageForm.controls['comment'].errors['key'] }}
        </mat-error>
      </mat-form-field>
      <div class="col-lg-3 col-12 d-flex justify-content-end align-items-end mb-4">
        <button mat-raised-button id="button-file" class="mat-accent float-right btn btn-primary" (click)="joinFile()" [disabled]="loader || messageForm.invalid">Joindre un fichier</button>
        <button mat-raised-button type="submit" class="mat-accent float-right btn btn-primary ml-2" (click)="sendMessage()" [disabled]="loader || messageForm.invalid">Envoyer</button>
      </div>
    </form>

  </div>

</div>
