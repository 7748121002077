import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalTrainingAgreementComponent } from './training-agreement.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilsModule } from '../../utils/utils.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  bootstrap: [ModalTrainingAgreementComponent],
  declarations: [ModalTrainingAgreementComponent],
  exports: [ModalTrainingAgreementComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MatIconModule,
    NgbModule,
    CommonModule,
    UtilsModule,
    MatButtonModule
  ]
})
export class ModalTrainingAgreementModule { }
