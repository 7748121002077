<div class="layout-container">
  <div id="header-container">
    <div class="topbar"></div>
    <nav class="navbar navbar-expand-md navbar-light" id="toolbar">
      <div class="container-fluid dropdown">
        <div class="flex-grow-1 d-flex justify-content-center align-items-center">
          <div style="margin: -70px;">
            <img width="70px" src="../../../assets/img/logo.png" alt="Logo entreprise">
          </div>
        </div>
        <app-notification-widget></app-notification-widget>
      </div>
    </nav>

    <div class="d-flex p-0 h-100 content-block">
      <div class="sidebar_menu">
        <nav class="navbar navbar-expand-md navbar-dark align-items-start" id="sidebar">

          <!-- FOR MOBILE VIEW -->
          <div class="container-fluid flex-wrap mobile">
            <div class="toggler">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="navbar-user">
                <div ngbDropdown container="body" placement="top-right" #myDrop="ngbDropdown">
                  <a ngbDropdownToggle>
                    <div class="avatar avatar-sm">
                      <img class="avatar-img rounded-circle" alt="Image général utilisateur" height="40" src="../assets/img/user.png" />
                    </div>
                  </a>
                  <div class="dropdown-menu menu-head" ngbDropdownMenu>
                    <a [routerLink]="['/account']" class="href dropdown-item no-before">Mon compte</a>
                    <hr class="dropdown-divider">
                    <a (click)="authService.logout()" class="href dropdown-item no-before">Déconnexion</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="collapse w-100" id="sidebarCollapse">
              <div class="w-100">
                <div class="d-flex flex-column w-100">
                  <div class="sidebar-header d-flex align-items-center">
                    <div class="user-pic">
                      <img class="img-responsive img-rounded" src="../../../assets/img/avatar.png" alt="Image général utilisateur">
                    </div>
                    <div class="user-info">
                      <span class="user-name">{{this.authService.user.firstname}}
                        <strong>{{this.authService.user.lastname}}</strong>
                      </span>
                      <span class="user-role">{{this.authService.user.job}}</span>
                    </div>
                  </div>
                  <hr class="mobile">
                  <!-- sidebar-header  -->
                  <div class="sidebar-menu">
                    <ul>
                      <li class="header-menu">
                        <span class="header-title">Général</span>
                      </li>
                      <ng-container *ngFor="let menu of menus">
                        <ng-container *ngIf="!currentUserIsTrainingParticipant || (currentUserIsTrainingParticipant && menu.accessTrainingParticipant)">
                          <li class="sidebar-dropdown">
                            <a [routerLink]="[menu.url]" class="button-arrow d-flex align-items-center justify-content-start" role="button" (click)="setActiveNav($event); hideCollapse()">
                              <i [ngClass]="menu.icon"></i>
                              <span>{{ menu.name }}</span>

                              <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                                <g class="arrow-head">
                                  <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                                </g>
                                <g class="arrow-body">
                                  <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                                </g>
                              </svg>

                            </a>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="!currentUserIsTrainingParticipant">
                <hr class="mobile">
                <div class="w-100">
                  <div class="d-flex flex-column w-100">
                    <div class="sidebar-menu">
                      <ul>
                        <li class="header-menu">
                          <span class="header-title">Batisafe</span>
                        </li>
                        <ng-container *ngFor="let menu of batisafe_menus">
                          <ng-container *ngIf="!currentUserIsTrainingParticipant || (currentUserIsTrainingParticipant && menu.accessTrainingParticipant)">
                            <li class="sidebar-dropdown">
                              <a [routerLink]="[menu.url]" class="button-arrow d-flex align-items-center justify-content-start" role="button" (click)="setActiveNav($event); hideCollapse()">
                                <i [ngClass]="menu.icon"></i>
                                <span>{{ menu.name }}</span>

                                <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                                  <g class="arrow-head">
                                    <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                                  </g>
                                  <g class="arrow-body">
                                    <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                                  </g>
                                </svg>
                              </a>
                            </li>
                          </ng-container>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!currentUserIsTrainingParticipant">
                <hr class="mobile">
                <div class="w-100">
                  <div class="d-flex flex-column w-100">
                    <div class="sidebar-menu">
                      <ul>
                        <li class="header-menu">
                          <span class="header-title">Théonorme</span>
                        </li>
                        <ng-container *ngFor="let menu of theonorme_menus">
                          <ng-container *ngIf="!currentUserIsTrainingParticipant || (currentUserIsTrainingParticipant && menu.accessTrainingParticipant)">
                            <li class="sidebar-dropdown">
                              <a [routerLink]="[menu.url]" class="button-arrow d-flex align-items-center justify-content-start" role="button" (click)="setActiveNav($event); hideCollapse()">
                                <i [ngClass]="menu.icon"></i>
                                <span>{{ menu.name }}</span>

                                <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                                  <g class="arrow-head">
                                    <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                                  </g>
                                  <g class="arrow-body">
                                    <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                                  </g>
                                </svg>

                              </a>
                            </li>
                          </ng-container>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- FOR DESKTOP VIEW -->
          <div class="container-fluid flex-wrap desktop">
            <div class="d-flex flex-column w-100">
              <div class="sidebar-header d-flex align-items-center">
                <div class="user-pic">
                  <img class="img-responsive img-rounded" src="../../../assets/img/avatar.png" alt="Image général utilisateur">
                </div>
                <div class="user-info">
                    <span class="user-name">{{this.authService.user.firstname}}
                      <strong>{{this.authService.user.lastname}}</strong>
                    </span>
                  <span class="user-role">{{this.authService.user.job}}</span>
                </div>
              </div>
              <!-- sidebar-header  -->
              <div class="sidebar-menu">
                <ul>
                  <li class="header-menu">
                    <span class="header-title">Général</span>
                  </li>
                  <ng-container *ngFor="let menu of menus">
                    <ng-container *ngIf="!currentUserIsTrainingParticipant || (currentUserIsTrainingParticipant && menu.accessTrainingParticipant)">
                      <li class="sidebar-dropdown">
                        <a [routerLink]="[menu.url]" class="button-arrow d-flex align-items-center justify-content-start" role="button" (click)="setActiveNav($event); hideCollapse()">
                          <i [ngClass]="menu.icon"></i>
                          <span>{{ menu.name }}</span>

                          <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                            <g class="arrow-head">
                              <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                            </g>
                            <g class="arrow-body">
                              <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                            </g>
                          </svg>

                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </div>
            </div>
            <ng-container *ngIf="!currentUserIsTrainingParticipant">
              <div class="d-flex flex-column w-100">
                <div class="sidebar-menu">
                  <ul>
                    <li class="header-menu">
                      <span class="header-title">Batisafe</span>
                    </li>
                    <ng-container *ngFor="let menu of batisafe_menus">
                      <ng-container *ngIf="!currentUserIsTrainingParticipant || (currentUserIsTrainingParticipant && menu.accessTrainingParticipant)">
                        <li class="sidebar-dropdown">
                          <a [routerLink]="[menu.url]" class="button-arrow d-flex align-items-center justify-content-start" role="button" (click)="setActiveNav($event); hideCollapse()">
                            <i [ngClass]="menu.icon"></i>
                            <span>{{ menu.name }}</span>

                            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                              <g class="arrow-head">
                                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                              </g>
                              <g class="arrow-body">
                                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                              </g>
                            </svg>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!currentUserIsTrainingParticipant">
              <div class="d-flex flex-column w-100">
                <div class="sidebar-menu">
                  <ul>
                    <li class="header-menu">
                      <span class="header-title">Théonorme</span>
                    </li>
                    <ng-container *ngFor="let menu of theonorme_menus">
                      <ng-container *ngIf="!currentUserIsTrainingParticipant || (currentUserIsTrainingParticipant && menu.accessTrainingParticipant)">
                        <li class="sidebar-dropdown">
                          <a [routerLink]="[menu.url]" class="button-arrow d-flex align-items-center justify-content-start" role="button" (click)="setActiveNav($event); hideCollapse()">
                            <i [ngClass]="menu.icon"></i>
                            <span>{{ menu.name }}</span>

                            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                              <g class="arrow-head">
                                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                              </g>
                              <g class="arrow-body">
                                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                              </g>
                            </svg>

                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="sidebar-footer">
            <a class="href" [routerLink]="['/account']" style="border-right: 1px solid rgba(0, 0, 0, 0.1)">
              <i class="fa fa-user"></i>
            </a>
            <a class="href" (click)="authService.logout()">
              <i class="fa fa-power-off"></i>
            </a>
          </div>

        </nav>
      </div>
      <div class="content-container container-fluid flex-grow-1">
        <div class="row d-flex flex-column" style="min-height:100%;max-height:100%;">
          <div class="col flex-grow-1 justify-content-center py-3 pt-4 pt-lg-3" style="max-height:100%;overflow:hidden">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
