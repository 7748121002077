import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit {
  @Input() total: any;
  @Input() limit: any;
  @Input() page: any;
  @Input() pages: any;
  @Input() pageSize: any;
  @Input() dropdownItemsByPage: any[] = [12, 24, 48, 96];
  @Input() classes: any;
  @Output() returnEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (!this.dropdownItemsByPage.length) {
      this.dropdownItemsByPage = [12, 24, 48, 96];
    }
  }

  returnAction(action: string, value: number) {
    this.returnEvent.emit({ action, value });
  }
}
