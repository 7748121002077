import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { FormModule } from '../../../core/form/form.module';

import { EditExampleComponent } from './edit-example.component';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    MatIconModule,
    ConfirmModalModule
  ],
  declarations: [EditExampleComponent]
})

export class EditExampleModule {}
