<div class="container" style="max-width:100%;display:flex;flex-direction: column;height:100%;">
  <div class="row justify-content-center">
    <div class="col-12 justify-content-center d-flex pt-4 pb-4" style="box-shadow: 0 1px 3px rgba(7,7,7,0.3);border-top:solid 10px #3e79a0;">
      <div (click)="goLogin()" class="link-cursor" style="background:url('../../../../assets/img/logo.png');width:160px;height:118px;"></div>
    </div>
  </div>
  <div class="flex-grow-1 row d-block" style="background:url('../../../../assets/img/pattern.jpg');">
    <div class="row justify-content-center p-3 d-flex" >
      <div class="card" id="mentions-card">
        <div class="card-header d-flex">
          <div class="flex-grow-1">
            <b>Mentions légales</b>
          </div>
          <div class="button-arrow" (click)="goLogin()">
            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
              <g class="arrow-head">
                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
              </g>
              <g class="arrow-body">
                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
              </g>
            </svg>
          </div>
        </div>
        <div class="card-body">
          <div class="col-12 justify mentions">
            <h1>&Eacute;diteur du site</h1>
            <h2>BatiSafe</h2>
            <p>Soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de 100 000 euros <br/>Immatricul&eacute;e
              au RCS de Chamb&eacute;ry sous le num&eacute;ro RCS 509 666 616. <br/>Dont le si&egrave;ge social est situ&eacute;
              Savoie Hexapole &ndash; L&rsquo;Agrion &ndash; 101, rue Maurice Herzog &ndash; 73420 M&eacute;ry.</p>
            <p><strong>N&deg; TVA intracommunautaire :</strong> FR15509666616</p>
            <p><strong>SIRET :</strong> 509 666 616 00040</p>
            <p><strong>Tel :</strong> +33 4 79 61 29 81</p>
            <p><strong>Adresse email :</strong> contact@batisafe.fr</p>
            <h1>Directeur de la publication</h1>
            <p>J&eacute;r&ocirc;me Pauchard</p>
            <h1>H&eacute;bergeur</h1>
            <h2>Leviatan</h2>
            <p><strong>Coordonn&eacute;es :</strong> 725 Boulevard Robert Barrier 73100 Aix-les-Bains</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 m-O">
      <div class="card d-flex" style="display:flex;padding:0 20px;height:auto;line-height:80px;flex-direction:row;">
        <div class="d-block d-lg-none"><a href="https://batisafe.fr" target="_blank">&copy; Batisafe.fr</a></div>
        <div class="w-50 d-lg-block d-none"><a href="https://batisafe.fr" target="_blank">Batisafe.fr</a> - Tous droits réservés</div>
        <div class="text-right flex-grow-1 d-block d-lg-none">
          <a href="/mentions">Légales</a> | <a href="/cgu">CGU</a>
        </div>
        <div class="text-right flex-grow-1 d-none d-lg-block" style="margin-right: 2.8rem;">
          <a href="/mentions">Mentions légales</a> | <a href="/cgu">Conditions générales d'utilisation</a>
        </div>
      </div>
    </div>
  </div>
</div>
