import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlanModalComponent } from './plan-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatButtonModule, FormsModule],
  declarations: [PlanModalComponent],
  exports: [PlanModalComponent]
})

export class PlanModalModule {}
