import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * The preview service is used to pass values between different components for previews iframe
 */
@Injectable()
export class PreviewService {
  loader: boolean;
  loaderPreviewMenu: boolean;
  loaderPreview: boolean;
  loaderChange: Subject<any> = new Subject<any>();

  constructor() {
    this.loaderChange.subscribe((loaderToggle: any) => {
      if (loaderToggle.loader_name === 'preview') {
        this.loaderPreview = loaderToggle.loader_toggle;
      } else if (loaderToggle.loader_name === 'preview-menu') {
        this.loaderPreviewMenu = loaderToggle.loader_toggle;
      } else if (loaderToggle.loader_name === 'loader') {
        this.loader = loaderToggle.loader_toggle;
      }
    });
  }

  /**
   * Change the loader value passing through Subject
   *
   * @param loaderName  Name of loader to toggle
   * @param toggle  If toggle is passed, it will override the current value for the given
   *                loader instead of toggle it from previous state.
   */
  toggleLoader(loaderName: 'preview' | 'preview-menu' | 'loader' = 'loader', toggle: boolean = null) {
    const toggleLoaderState = {
      loader_name: loaderName,
      loader_toggle: toggle
    };

    if (toggle === null) {
      if (loaderName === 'loader') {
        toggleLoaderState.loader_toggle = !this.loader;
      } else if (loaderName === 'preview') {
        toggleLoaderState.loader_toggle = !this.loaderPreview;
      } else if (loaderName === 'preview-menu') {
        toggleLoaderState.loader_toggle = !this.loaderPreviewMenu;
      }
    }
    this.loaderChange.next(toggleLoaderState);
  }
}
