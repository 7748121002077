<div class="row ml-0 mr-0 mt-3">
  <div class="col-12 d-flex">
    <div class="flex-grow-1 pl-2">
      <div class="title-header mb-0"> Poser une question </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > <a href="#" routerLink="/theonorme/questions" class="breadcrum-link">Assistance BatiSafe</a> > Poser une question </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mr-2">
    </div>
  </div>
</div>
<div class="row ml-2 mr-2">
  <div class="col-12">
    <div class="alert-ticket">
      <i class="fa fa-exclamation d-inline-block mr-2"></i> Vous souhaitez nous poser une question sur un changement législatif, une nouvelle loi ou simplement obtenir des informations.
    </div>
  </div>
</div>

<div class="card card-scroll ml-4 mr-4">
  <div class="card-body">
    <form [formGroup]="messageForm">
      <div class="row">
        <div class="col-12 mb-3">
          <!-- LABEL -->
          <label required>Question</label>
          <!-- INPUT -->
          <textarea id="textarea" class="form-control" (keyup)="autogrowTextHeight()" formControlName="question" matInput placeholder="" type="text"></textarea>
          <!-- ERROR -->
          <mat-error *ngIf="messageForm.controls['question'].hasError('required') && !messageForm.controls['question'].valid && messageForm.controls['question'].touched">
            <span>Ce champ ne peux pas être vide</span>
          </mat-error>
          <mat-error *ngIf="messageForm.controls['question'].hasError('key')">
            {{ messageForm.controls['question'].errors['key'] }}
          </mat-error>
        </div>

        <input id="fileUpload" multiple="multiple" name="fileUpload" style="display: none" type="file"/>

        <div class="col-12 d-flex justify-content-end">
          <button mat-raised-button type="submit" class="mat-accent float-right btn btn-primary ml-2" (click)="sendMessage()" [disabled]="loader || messageForm.invalid">Envoyer</button>
        </div>
      </div>

    </form>

  </div>
</div>
<app-confirm-modal [type]="'verification'" #pendingModal>{{ bindModal(pendingModal) }}</app-confirm-modal>
