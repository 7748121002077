import { Routes } from '@angular/router';
import { AllInvoicesComponent } from './all-invoices/all-invoices.component';
import { InvoicesResolver } from '../../core/services/invoices/invoices.resolver';

export const invoicesRoutes: Routes = [
  {
    path: 'invoices',
    component: AllInvoicesComponent,
    resolve: {
      data: InvoicesResolver,
    }
  }
];
