<div id="modal-agreement-training" class="modal modal-agreement-training fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">Envoyer la convention signée</h1>
          </div>
        </div>

        <button type="button" class="close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <ng-container *ngIf="loader">
        <div class="modal-body" style="text-align: center;">
          <i class="fa fa-spinner fa-spin"></i>
          <br/>Récupération de la convention déjà envoyée
        </div>
      </ng-container>

      <ng-container *ngIf="!loader">
        <div class="modal-body">
          <div class="form-group mb-3">
            <!-- WITHOUT FILES -->
            <ng-container *ngIf="trainingAgreementService.trainingAgreements.length === 0">
              <div class="form-group">
                <label class="mb-1" for="file_agreements">Convention signée</label>

                <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event, 'agreements')">
                  <div class="dz-default dz-message" (click)="chooseFile('agreements')">
                    <span>Déposer votre fichier ici pour télécharger</span>
                  </div>
                  <input type="file" [hidden]="true" [multiple]="false" id="file_agreements"
                         (change)="onFileChange($event, 'agreements')">
                </div>
              </div>
            </ng-container>

            <!-- WITH FILES -->
            <ng-container *ngIf="trainingAgreementService.trainingAgreements.length > 0">
              <div class="form-group">
                <label class="mb-1" for="file_agreements">Convention signée</label>
                <div class="card" [style]="loaderButton ? 'opacity: 0.5; pointer-events: none;' : ''">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between"
                         *ngFor="let addedFile of trainingAgreementService.trainingAgreements">
                      <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: calc(100% - 90px);" [title]="addedFile.label">
                        {{ addedFile.label }}
                      </div>
                      <div>
                        <a class="href" data-placement="bottom" style="cursor: pointer;"
                           [title]="'Télécharger ' + addedFile.label" title="Télécharger"
                           (click)="downloadFile(addedFile)">
                          <mat-icon aria-hidden="false" aria-label="Télécharger">download</mat-icon>
                        </a>
                        <a class="href" data-placement="bottom" style="cursor: pointer;"
                           [title]="'Supprimer ' + addedFile.label"
                           (click)="removeAddedFile(addedFile)">
                          <mat-icon aria-hidden="false" aria-label="Supprimer">delete</mat-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="modal-footer">
          <button mat-raised-button [disabled]="loaderButton" class="mat-accent btn btn-primary col w-100 mr-2" (click)="cancel()">
            <ng-container *ngIf="loaderButton">
              <i class="fa fa-spinner fa-spin"></i>
            </ng-container>
            <ng-container *ngIf="!loaderButton">
              Annuler
            </ng-container>
          </button>
          <button mat-raised-button [disabled]="loaderButton" class="mat-accent btn btn-primary col w-100 ml-2" (click)="validate()">
            <ng-container *ngIf="loaderButton">
              <i class="fa fa-spinner fa-spin"></i>
            </ng-container>
            <ng-container *ngIf="!loaderButton">
              Confirmer
            </ng-container>
          </button>
        </div>
      </ng-container>

    </div>
  </div>
</div>
