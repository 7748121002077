import { Component, EventEmitter, Injectable, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  NativeDateAdapter
} from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';

import { CustomNotifierService } from '../services/custom.notifier.service';
import { FormChecker } from '../utils/formChecker';

import Field from './field';

/**
 * Override default error ONLY for material inputs
 */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek() {
    return 1;
  }
  getMonthNames(style) {
    if (style === 'long') {
      return ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    } else if (style === 'short') {
      return ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
    } else if (style === 'narrow') {
      return ['J', 'F', 'M', 'A', '', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
    }
  }
  getDayOfWeekNames(style) {
    if (style === 'long') {
      return ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'jeudi', 'Vendredi', 'Samedi'];
    } else if (style === 'short') {
      return ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    } else if (style === 'narrow') {
      return ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    }
  }
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class FormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fields: Field[];
  @Input() entity: any;
  @Input() errors: { general?: { code: string; message: string } };
  @Input() loader: boolean;
  @Input() initialForm = true;
  @Output() returnEvent = new EventEmitter<any>();
  @Output() intactEvent = new EventEmitter<any>();

  _ = _;
  rand = 0;

  formChecker: FormChecker;
  form: FormGroup;
  matcher = new CustomErrorStateMatcher();
  optionTinyMce: {
    height: 800, menubar: false, plugins: ['link preview table code lists'],
    toolbar: 'undo redo | formatselect fontselect fontsizeselect | \
    bold italic underline strikethrough backcolor forecolor | preview | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent link tabl'
  };

  constructor(
    private formBuilder: FormBuilder,
    private notifier: CustomNotifierService,
  ) {}

  ngOnInit() {
    this.rand = Math.random() * 100;
    const rs = new ReplaySubject();
    rs.subscribe((isIntact: boolean) => {
      this.intactEvent.emit(isIntact);
    });

    this.form = this.formBuilder.group({}, { validators: (form: FormGroup) => {
        if (
          form.controls.password && form.controls.confirm_password &&
          form.controls.password.value !== form.controls.confirm_password.value
        ) {
          form.controls.confirm_password.setErrors({ notSame: true });
          return { notSame: true };
        }
      }});

    this.fields.forEach(field => {
      this.form.addControl(field.property, field.formControl);
    });

    if (this.entity) {
      const findField = this.fields.find(field => {
        return field.type === 'form' && field.multiple;
      });
      if (findField !== undefined) {
        for (const key in this.entity[findField.property]) {
          if (this.entity[findField.property].hasOwnProperty(key)) {
            this.addNewField(findField);
          }
        }
      }

      this.form.patchValue(this.entity);
    }

    this.formChecker = new FormChecker(this.form, rs);

    if (!this.initialForm) {
      this.form.valueChanges.subscribe(() => {
        this.returnEvent.emit(this.form.value);
      });
    }
  }

  ngOnChanges() {
    if (this.errors) {
      this.notifier.errorsForm(this.errors, this.form);
    }
  }

  ngOnDestroy() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    // @ts-ignore
    for (const tooltip of tooltips) {
      tooltip.remove();
    }
  }

  chooseFile(field: Field) {
    const element: HTMLElement = document.getElementById('file' + field.property) as HTMLElement;
    element.click();
  }

  onFileChange($event, field: Field) {
    this.form.controls[field.property].setValue('');
    const reader = new FileReader();

    if ($event.target && $event.target.files && $event.target.files.length) {
      const [file] = $event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.form.controls[field.property].setValue(reader.result);
      };
    } else {
      this.form.controls[field.property].setValue($event.file);
    }
    const element: HTMLInputElement = document.getElementById('file' + field.property) as HTMLInputElement;
    element.value = '';
  }

  checkPicture(field: Field) {
    if (this.form.controls[field.property].value.indexOf(';base64,') !== -1) {
      return this.form.controls[field.property].value;
    } else {
      return environment.SERVER_HOST + field.fileUrl + this.form.controls[field.property].value;
    }
  }

  removeFile(field: Field) {
    this.form.controls[field.property].setValue(null);
  }

  saveForm() {
    if (this.form.valid) {
      this.fields.forEach(field => {
        if (field.type === 'date') {
          this.form.controls[field.property].setValue(moment(this.form.value[field.property]).format('YYYY-MM-DD'));
        }
      });
      this.returnEvent.emit(this.form.value);
    }
  }

  defaultCompareWith(c1, c2) {
    return c1 === c2;
  }

  hasRequiredField(field) {
    if (field.validator !== null) {
      const validator = field.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return null;
  }

  /**
   * Add new field inside form array
   * @param field inside form
   */
  addNewField(field: Field) {
    const control = this.form.controls[field.property] as FormArray;
    const newGroup = this.formBuilder.group({}, { validators: (form: FormGroup) => {}});
    field.subFields.forEach(subField => {
      newGroup.addControl(subField.property, _.clone(subField.formControl));
    });
    control.push(newGroup);
  }
  /**
   * Delete array inside fom array
   * @param field inside form
   * @param i index of form array
   */
  deleteField(field: Field, i: number) {
    const control = this.form.controls[field.property] as FormArray;
    control.removeAt(i);
  }

  validateForm($event: any, form: FormGroup) {
    form.patchValue($event);
  }
}
