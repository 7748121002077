import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoResultComponent } from './no-result.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NoResultComponent],
  exports: [NoResultComponent]
})

export class NoResultModule {}
