import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { AccountRequestService } from '../services/account/account.request.service';
import UpdateUserResponse from '../interfaces/response/updateUser.response';
import { CustomNotifierService } from '../services/custom.notifier.service';
import { QuotationsRequestService } from '../services/quotations/quotations.request.service';
import Quotation from '../interfaces/quotation/quotation';
import { QuotationsService } from '../services/quotations/quotations.service';

declare var $: any;

@Component({
  selector: 'app-quotation-modal',
  templateUrl: './quotation-modal.component.html',
  styleUrls: ['./quotation-modal.component.scss']
})
export class QuotationModalComponent implements AfterViewInit, OnInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();
  @ViewChild('receiptFile') receiptFile: ElementRef;

  modalForm: FormGroup;
  email = new FormControl('', [Validators.email]);
  address = new FormControl('', []);
  address1 = new FormControl('', []);
  address2 = new FormControl('', []);
  zipcode = new FormControl('', []);
  city = new FormControl('', []);

  modalElConfirm = null;
  id: number;
  isTraining = false; // Define if the quotation is a training or not
  updateSupportingDocument = false; // Define if the modal need to update a supporting document for the quotation
  quotation: Quotation;

  loader: boolean;

  view = {
    delete_data: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> une donnée. Voulez-vous continuer ?'
    },
    delete_file: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> un fichier. Voulez-vous continuer ?'
    },
    verification: {
      title: 'Attention',
      content: 'Les modifications que vous avez apportées <span class="strong">ne seront pas enregistrées</span> ' +
        'si vous quittez la page sans sauvegarder. Voulez-vous continuer ?'
    }
  };
  file: any;
  filename: string;

  constructor(
    private rootNode: ElementRef,
    private formBuilder: FormBuilder,
    private notifier: CustomNotifierService,
    private quotationService: QuotationsService,
    public authService: AuthService,
    public accountService: AccountRequestService,
    public quotationRequestService: QuotationsRequestService
  ) {}

  ngOnInit() {
    this.loader = true;
    this.modalForm = this.formBuilder.group({
      email: this.email,
      address: this.address,
      address1: this.address1,
      address2: this.address2,
      zipcode: this.zipcode,
      city: this.city,
    });
  }

  /**
   * Open the modal and set some data
   *
   * @param {number} quotationId The quote id
   * @param {boolean} isTraining Is the quote is a training
   * @param updateSupportingDocument
   */
  open(quotationId: number, isTraining: boolean = false, updateSupportingDocument: boolean = false) {
    this.id = quotationId;
    this.isTraining = isTraining;
    this.updateSupportingDocument = updateSupportingDocument;

    if (updateSupportingDocument) {
      this._retrieveQuotation(quotationId);
    } else {
      this.email.setValidators(Validators.required);
      this.email.setValue(this.authService.user.email);
      this.address.setValidators(Validators.required);
      this.zipcode.setValidators(Validators.required);
      this.city.setValidators(Validators.required);

      this.accountService.getAccountInfo()
        .subscribe((response: UpdateUserResponse) => {
          this.loader = false;
          if (response.success) {
            this.address.setValue(response.user.address);
            this.address1.setValue(response.user.address_1);
            this.address2.setValue(response.user.address_2);
            this.zipcode.setValue(response.user.zipcode);
            this.city.setValue(response.user.city);
          } else {
            this.notifier.errorCustom(response.message);
          }
        });
    }
    this.modalElConfirm.modal('show');
  }

  cancel() {
    this.eventOnClose.next(null);
    this.modalForm.reset();
    this.clearFile();
    this.modalElConfirm.modal('hide');
  }

  validate() {
    let action = null;
    if (this.updateSupportingDocument) {
      action = 'update-supporting-document';
    }
    this.eventOnClose.next({
      id : this.id,
      file : this.file,
      filename: this.filename,
      email : this.email.value,
      address : this.address.value,
      address_1 : this.address1.value,
      address_2 : this.address2.value,
      zip_code : this.zipcode.value,
      city : this.city.value,
      validateAction: action
    });
    this.modalForm.reset();
    this.clearFile();
    this.modalElConfirm.modal('hide');
  }

  handleUpload(event) {
    const file = event.target.files[0];
    this.filename = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.file = reader.result;
    };
  }

  ngAfterViewInit() {
    this.modalElConfirm = $(this.rootNode.nativeElement).find('div.modal-confirm');

    const self = this;
    $('#confirm-modal').on('hidden.bs.modal', () => {
      this.modalForm.reset();
      this.clearFile();
      self.eventOnClose.next(null);
    });
  }

  clearFile() {
    this.receiptFile.nativeElement.value = null;
    this.file = null;
  }

  getQuotation(): Quotation | null {
    return this.quotationService.quotation;
  }

  /**
   * Retrieve a quotation
   *
   * @param quotationId
   * @private
   */
  private _retrieveQuotation(quotationId: number) {
    this.quotationRequestService.getQuotation(quotationId)
      .subscribe((response: any) => {
        this.loader = false;
        if (response.success) {
          this.quotationService.quotation = response.quotation;
        } else {
          this.notifier.errorCustom('Le devis n\'a pas pu être récupéré');
          this.cancel();
        }
      });
  }
}
