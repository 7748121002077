import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../../core/auth/auth.service';
import { AuthRequestService } from '../../../core/services/auth/auth.request.service';
import { CustomNotifierService } from "../../../core/services/custom.notifier.service";
import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import AuthResponse from '../../../core/interfaces/response/auth.response';
import ValidatorPassword from '../../../core/custom-validators/password.directive';

@Component({
  selector: 'app-password-lost',
  templateUrl: './password-lost.component.html',
  styleUrls: ['./password-lost.component.scss'],
  animations: [
    trigger('routeTransition', [
      state('void', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      state('*', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      transition(':enter', [
        style({
          opacity: '0',
          overflow: 'hidden'
        }),
        animate('.5s linear', style({
          opacity: '1',
          overflow: 'hidden'
        }))
      ]),
      transition(':leave', [
        style({
        })
      ])
    ])
  ]
})

export class PasswordLostComponent implements OnInit {
  public showPassword = false;
  public isSigningIn = false;
  public isReset = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public token;
  public errors = {};

  /*public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email])
  });*/
  public newPasswordForm: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, ValidatorPassword()]),
    token: new FormControl('', [])
  });

  @HostBinding('@routeTransition') get routeTransition() {
    return;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private authRequestService: AuthRequestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: CustomNotifierService
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      if (params.get('token') !== undefined && params.get('token') !== null ) {
        this.isReset = true;
        this.newPasswordForm.get('token').setValue(params.get('token'));
      } else {
        this.authService.logout()
      }
    });
  }

  resetPassword() {
    this.errors = {};
    this.isSigningIn = true;

    if (this.isReset) {
      this.authRequestService.resetPasswordToken(this.newPasswordForm.value).subscribe((response: AuthResponse) => {
        if (response.success) {
          this.notifier.successCustom('Le mot de passe a correctement été réinitialisé');
          this.router.navigateByUrl('/auth/login');
        } else if (response.errors && !response.errors.general) {
          this.errors = response.errors;
        }
      });
    }/* else {
      this.authRequestService.login(this.loginForm.value).subscribe((response: AuthResponse) => {
        if (response.success) {
          localStorage.setItem('accessToken', response.access_token);
          localStorage.setItem('refreshToken', response.refresh_token);
          localStorage.setItem('user', JSON.stringify(response.user));
          localStorage.setItem('enterprises', JSON.stringify(response.enterprises));
          localStorage.setItem('enterprise', JSON.stringify(response.enterprises[0]));
          this.authService.user = response.user;
          this.authService.login();
        } else if (response.errors && !response.errors.general) {
          this.errors = response.errors;
        }
        this.isSigningIn = false;
      });
    }*/
  }

  clearError() {
    if (this.newPasswordForm.get('password').value !== undefined) {
      this.newPasswordForm.get('password').updateValueAndValidity();
    }
  }
}
