import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class TheonormeRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  // QUESTION ----------------------------------------------------------------------------------------------------------
  public getQuestions(search = '', sort = '', order = '', limit = 0, page = 1, token = null) {
    return this.get('api/theonorme-questions?search=' + search + '&sort=' + sort + '&order=' + order +
      '&limit=' + limit + '&page=' + page, {}, token);
  }

  public getQuestion(questionId, token = null) {
    return this.get('api/theonorme-get-question/' + questionId, {}, token);
  }

  public createQuestion(question) {
    return this.post('api/theonorme-create-question', { question });
  }

  public deleteQuestion(questionId) {
    return this.delete('api/theonorme-delete-question/' + questionId);
  }

  public createComment(questionId, comment) {
    return this.post('api/theonorme-create-answer/' + questionId, { comment });
  }

  public markAsRead(questionId) {
    return this.put('api/theonorme-answer-viewed/' + questionId, {});
  }

  // VEILLES -----------------------------------------------------------------------------------------------------------
  public getVeilles(alert: boolean, search = '', sort = '', order = '', limit = 0, page = 1,
                    beginDate = '', endDate = '', token = null) {
    let alertSearch = alert === true ? 'true' : 'false';

    return this.get('api/theonorme-veilles?search=' + search + '&sort=' + sort + '&order=' + order +
      '&limit=' + limit + '&page=' + page + '&beginDate=' + beginDate + '&endDate=' + endDate + '&alert=' + alertSearch,
      {}, token);
  }
}
