import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../../request.service';

@Injectable()
export class EstablishmentTypeRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public getEstablishmentType() {
    return this.get('api/get-establishment-type', {});
  }
}
