import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionModalComponent } from './question-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NoResultModule } from '../../no-result/no-result.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule, NgSelectModule, NoResultModule],
  declarations: [QuestionModalComponent],
  exports: [QuestionModalComponent]
})

export class QuestionModalModule {}
