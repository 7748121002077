import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomNotifierService } from '../../../core/services/custom.notifier.service';

import { ExamplesRequestService } from '../../../core/services/examples/examples.request.service';
import ExamplesResponse from '../../../core/interfaces/response/examples.response';

import Column from '../../../core/list/column';
import { PageList } from '../../../core/list/page-list';

@Component({
  selector: 'app-all-examples',
  templateUrl: './all-examples.component.html',
  styleUrls: ['./all-examples.component.scss'],
})
export class AllExamplesComponent extends PageList implements OnInit {
  columns: Column[] = [
    { label: 'Photo', showLabel: false, sort: 'Users.picture', property: 'picture', checked: true, type: 'img', linkImg: 'img/users/' },
    { label: 'Email', showLabel: true, sort: 'Users.email', property: 'email', checked: true, type: 'text' },
    { label: 'Prénom', showLabel: true, sort: 'Users.first_name', property: 'first_name', checked: true, type: 'text' },
    { label: 'Nom', showLabel: true, sort: 'Users.last_name', property: 'last_name', checked: true, type: 'text' },
  ];

  examples: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private examplesService: ExamplesRequestService,
    private notifier: CustomNotifierService,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(response => {
      this.examples = response.data.examples;
      this.total = response.data.total;
      this.pages = response.data.pages;
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      // this.loader = this.requestCount > 0;
    });
  }

  getData() {
    this.requestInProgress.next(true);

    this.examplesService.getExamples(this.search, this.sort, this.order, this.limit, this.page).subscribe((response: ExamplesResponse) => {
      if (response.success) {
        this.examples = response.examples;
        this.total = response.total;
        this.pages = response.pages;
      }
      this.requestInProgress.next(false);
    });
  }

  deleteData(exampleId) {
    this.examplesService.deleteExample(exampleId).subscribe((response: ExamplesResponse) => {
      if (response.success) {
        this.notifier.successDelete();
        this.changePage(1);
      } else {
        this.notifier.errors(response.errors);
      }
    });
  }
}
