import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public login(user) {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', environment.API_KEY);
    formData.append('client_secret', environment.API_SECRET);
    formData.append('username', user.email);
    formData.append('password', user.password);

    return this.post('oauth/v2/token', formData);
  }

  public getAnonymousToken() {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'client_credentials');
    formData.append('client_id', environment.API_KEY);
    formData.append('client_secret', environment.API_SECRET);

    return this.post('oauth/v2/token', formData);
  }

  public checkEmail(data) {
    return this.post('auth/portalRegisterCheck', data);
  }

  public activateAccount(data) {
    return this.get('auth/portalActivate/' + data);
  }

  public createAccount(type, data) {
    return this.post('auth/portalRegister?type=' + type, data);
  }

  public logout() {
    return this.get('auth/logout');
  }

  public me() {
    return this.get('auth/me');
  }

  /**
   * Reset the password with the token provided
   *
   * @param data
   */
  public resetPasswordToken(data) {
    return this.post('api/tokenpassword', data);
  }

  /**
   * Forgot password and receive an email
   *
   * @param data
   */
  public forgotPassword(data) {
    return this.post('api/lostpassword', data);
  }
}
