/**
 * NOTE :
 * Feel free to move this request service with your other request services.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import Notification from '../interfaces/notification/notification';

import { RequestService } from '../services/request.service';

@Injectable()
export class NotificationRequestService extends RequestService {
  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  /**
   * Get all notifications
   *
   * @param {string} search
   * @param {string} sort
   * @param {string} order
   * @param {number} limit
   * @param {number} page
   * @param {string} token
   */
  getAllNotifications(search: string, sort: string, order: string, limit: number, page: number, token: string = null) {
    return this.get(
      'api/notifications-portal?search=' + search
      + '&sort=' + sort + '.' + order
      + '&limit=' + limit
      + '&page=' + page,
      {},
      token
    );
  }

  /**
   * Update one notification to mark as seen
   *
   * @param {Notification} notification
   */
  updateNotification(notification: Notification) {
    return this.put('api/notifications-portal/' + notification.id, notification);
  }

  /**
   * Mark all notifications as seen
   */
  markAllNotificationsAsSeen() {
    return this.put('api/notifications-portal/0', null);
  }
}
