import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-quotation-refuse-modal',
  templateUrl: './quotation-refuse-modal.component.html',
  styleUrls: ['./quotation-refuse-modal.component.scss']
})
export class QuotationRefuseModalComponent implements AfterViewInit, OnInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalForm: FormGroup;
  reason = new FormControl('', [Validators.required]);
  comment = new FormControl('', []);

  modalElConfirm = null;
  id: number;
  file: any;

  constructor(
    private rootNode: ElementRef,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.modalForm = this.formBuilder.group({
      reason: this.reason,
      comment: this.comment,
    });
  }

  open(id) {
    this.id = id;
    this.modalElConfirm.modal('show');
  }

  cancel() {
    this.eventOnClose.next(null);
    this.clearForm();
    this.modalElConfirm.modal('hide');
  }

  validate() {
    this.eventOnClose.next({
      id : this.id,
      reason : this.reason.value,
      comment : this.comment.value,
    });
    this.clearForm();
    this.modalElConfirm.modal('hide');
  }

  ngAfterViewInit() {
    this.modalElConfirm = $(this.rootNode.nativeElement).find('div.modal-confirm');

    const self = this;
    $('#confirm-modal').on('hidden.bs.modal', () => {
      this.clearForm();
      self.eventOnClose.next(null);
    });
  }

  clearForm() {
    this.reason.setValue('');
    this.comment.setValue('');
  }
}
