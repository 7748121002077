import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class AccountRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public getAccountInfo() {
    return this.get('api/myaccount', {});
  }

  public updateAccountInfo(obj) {
    return this.put('api/myaccount', obj);
  }

  public updateAccountPassword(obj) {
    return this.put('api/myaccountpassword', obj);
  }
}
