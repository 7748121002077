import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoxplanDirModalComponent } from './boxplan-dir-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  declarations: [BoxplanDirModalComponent],
  exports: [BoxplanDirModalComponent]
})

export class BoxplanDirModalModule {}
