import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { FormModule } from '../../../../core/form/form.module';

import { CreateQuestionComponent } from './create-question.component';
import { ConfirmModalModule } from '../../../../core/confirm-modal/confirm-modal.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    MatIconModule,
    ConfirmModalModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [CreateQuestionComponent]
})

export class CreateQuestionModule {}
