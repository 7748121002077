import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';
import { ExamplesRequestService } from './examples.request.service';

import * as moment from 'moment';

import ExamplesResponse from '../../interfaces/response/examples.response';

@Injectable()
export class ExamplesResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private examplesService: ExamplesRequestService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      // this.requestInProgress.next(true);
      // this.examplesService.getExamples(this.token).subscribe((response: ExamplesResponse) => {
      //   data.examples = response.examples;
      //   this.requestInProgress.next(false);
      // });

      // this.requestInProgress.next(true);
      // this.examplesService.getExample(this.token).subscribe((response: ExamplesResponse) => {
      //   data.example = response.example;
      //   this.requestInProgress.next(false);
      // });

      if (route.routeConfig.path === 'examples') {
        resolve({
          examples: [],
          pages: 1,
          total: 0
        });
      } else if (route.routeConfig.path === 'examples/:exampleId') {
        resolve({
          example: null,
          subExamples: [
            { id: 1, name: 'Cat 1' },
            { id: 2, name: 'Cat 2' },
            { id: 3, name: 'Cat 3' },
            { id: 4, name: 'Cat 4' },
            { id: 5, name: 'Cat 5' }
          ]
        });
      } else if (route.routeConfig.path === 'examples/create') {
        resolve({
          subExamples: [
            { id: 1, name: 'Cat 1' },
            { id: 2, name: 'Cat 2' },
            { id: 3, name: 'Cat 3' },
            { id: 4, name: 'Cat 4' },
            { id: 5, name: 'Cat 5' }
          ]
        });
      }
    });
  }
}
