import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ExamplesModule } from './examples/examples.module';
import { QuotationsModule } from './quotations/quotations.module';
import { InvoicesModule } from './invoices/invoices.module';
import { TrainingsModule } from './trainings/trainings.module';
import { FilesModule } from './files/files.module';
import { AllAccountModule } from './account/all-account.module';
import { LegalsModule } from './legals/legals.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SurveyModule } from '../core/survey/survey.module';
import { TicketsModule } from './tickets/tickets.module';
import { TheonormeModule } from './theonorme/theonorme.module';

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    DashboardModule,
    ExamplesModule,
    QuotationsModule,
    InvoicesModule,
    TrainingsModule,
    SurveyModule,
    FilesModule,
    AllAccountModule,
    LegalsModule,
    NotificationsModule,
    TicketsModule,
    TheonormeModule,
  ],
  declarations: []
})

export class PagesModule {}
