import { Routes } from '@angular/router';
import { AllQuestionsComponent } from './questions/all-questions/all-questions.component';
import { CreateQuestionComponent } from './questions/create-question/create-question.component';
import { AllVeillesComponent } from './veilles/all-veilles.component';
import { PendingChangesGuard } from '../../core/utils/pending.changes.guard';
import { AllAnswersComponent } from './questions/all-answer/all-answers.component';
import { TheonormeResolver } from '../../core/services/theonorme/theonorme.resolver';

export const theonormeRoutes: Routes = [
  {
    path: 'theonorme/questions',
    component: AllQuestionsComponent,
    resolve: {
      data: TheonormeResolver,
    }
  },
  {
    path: 'theonorme/questions/create',
    component: CreateQuestionComponent,
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'theonorme/question/:questionId',
    component: AllAnswersComponent,
    resolve: {
      data: TheonormeResolver,
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'theonorme/veilles',
    component: AllVeillesComponent,
    resolve: {
      data: TheonormeResolver,
    }
  },
  {
    path: 'theonorme/alertes-reglementaires',
    component: AllVeillesComponent,
    resolve: {
      data: TheonormeResolver
    }
  }
];
