import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { ConfirmModalComponent } from '../../../../core/confirm-modal/confirm-modal.component';
import { ComponentCanDeactivate } from '../../../../core/utils/component-can-deactivate';

import { FormBuilder } from '@angular/forms';
import { CustomNotifierService } from '../../../../core/services/custom.notifier.service';
import { TheonormeRequestService } from '../../../../core/services/theonorme/theonorme.request.service';

@Component({
  selector: 'app-all-answers',
  templateUrl: './all-answers.component.html',
  styleUrls: ['./all-answers.component.scss'],
})
export class AllAnswersComponent implements OnInit, ComponentCanDeactivate, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isIntact = true;
  errors: { general?: { code: string; message: string } };
  question: any;
  user_name: any;
  answer: any;
  fileToUpload: File | null = null;
  sendFiles: any[] = [];
  questionId: number;

  requestInProgress = new Subject();
  requestCount = 0;
  loader: boolean;

  modal: ConfirmModalComponent;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private notifier: CustomNotifierService,
    private activatedRoute: ActivatedRoute,
    private answerService: TheonormeRequestService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(response => {
      if (response.data && response.data.question) {
        this.question = response.data.question;
      }
      if (response.data && response.data.answer) {
        this.answer = response.data.answer;
        if (this.question && !this.question.is_viewed) {
          this.markAsReadAnswer();
        }
      }
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.questionId = this.activatedRoute.snapshot.params.questionId;
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.isIntact) {
      return true;
    } else {
      const observable = new Observable<boolean>(observer => {
        this.modal.eventOnClose.subscribe(event => {
          if (event) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      });
      this.modal.open(1);
      return observable;
    }
  }
  bindModal(pendingModal: ConfirmModalComponent) {
    this.modal = pendingModal;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }


  markAsReadAnswer() {
    this.requestInProgress.next(true);
    this.answerService.markAsRead(this.activatedRoute.snapshot.params.questionId).subscribe((response: any) => {
      this.requestInProgress.next(false);
    });
  }
}
