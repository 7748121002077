import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SurveyRequestService } from './services/survey.request.service';
import { CustomNotifierService } from '../services/custom.notifier.service';
import { AuthService } from '../auth/auth.service';
import { TrainingsRequestService } from '../services/trainings/trainings.request.service';

import { ResponseSurveyParticipantResolver } from './interfaces/response/survey';
import SurveyQuestion, { QuestionChoice } from './interfaces/survey_question';
import Survey from './interfaces/survey';
import { SurveyService } from './services/survey.service';
import { TrainingsService } from '../services/trainings/trainings.service';
import SurveyCategory from './interfaces/survey_category';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements AfterViewInit, OnInit {
  @Input() displayGPDR = true;

  @Output() returnEvent: EventEmitter<any> = new EventEmitter();

  surveyForm: FormGroup;
  isParticipant: boolean; // The current user seeing this page
  thanks: boolean; // When user answer to all questions, display a thank message

  /**
   * Survey component constructor
   *
   * @param _activatedRoute
   * @param _router
   * @param _notifier
   * @param _authService
   * @param _surveyRequestService
   * @param _trainingRequestService
   * @param _trainingService
   * @param _surveyService
   * @param _formBuilder
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notifier: CustomNotifierService,
    private _authService: AuthService,
    private _surveyRequestService: SurveyRequestService,
    private _trainingRequestService: TrainingsRequestService,
    private _trainingService: TrainingsService,
    private _surveyService: SurveyService,
    private _formBuilder: FormBuilder
  ) {
    this.isParticipant = this._authService.user.training_participant_access;
    this.thanks = false;

    this.surveyForm = this._formBuilder.group({
      questions: this._formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe((response: ResponseSurveyParticipantResolver) => {
      if (response.data.success) {
        this._surveyService.surveyQuestions = response.data.questions;
        this._surveyService.survey = response.data.survey;
        if (response.data.training_date_id) {
          this._retrieveTrainingDate(response.data.training_date_id);
        }
        // Add the type for questions
        this._surveyService.surveyQuestions.forEach((surveyQuestion, index) => {
          const newData = surveyQuestion;
          newData.type = this._getTextType(surveyQuestion.question_type);
          this._surveyService.updateSurveyQuestion(index, newData);
        });

        if (this.displayGPDR) {
          // Add gdpr question at the beginning of array
          const gdprSurveyQuestion: SurveyQuestion = {
            training_survey: this.getSurvey(),
            id: 0,
            slug: 'gdpr',
            question: 'En cochant cette case, vous donnez l\'autorisation à Batisafe de ' +
              'stocker et traiter les données saisies dans ce formulaire.',
            question_type: 4,
            question_choices: [],
            required: true,
            prefill: false,
            type: 'checkbox'
          };
          this._surveyService.addSurveyQuestionBeginning(gdprSurveyQuestion, true);
        }

        // For each questions, create a form control and add it to the form array `questions`
        for (const surveyQuestion of this._surveyService.surveyQuestions) {
          let newQuestionFormControl: FormControl;
          let checkboxQuestionChoices: FormArray;
          if (this.displayGPDR && surveyQuestion.slug && surveyQuestion.slug === 'gdpr') {
            newQuestionFormControl = this._formBuilder.control(false, [Validators.requiredTrue]);
          } else {
            // In case of multiple responses available for checkboxes
            if (surveyQuestion.type === 'checkbox' && surveyQuestion.question_choices.length > 1) {
              // Add controls in array
              checkboxQuestionChoices = this._formBuilder.array([]);
              surveyQuestion.question_choices.forEach((choice, index) => {
                checkboxQuestionChoices.push(this._formBuilder.control(''));
              });

            } else {
              newQuestionFormControl = this._formBuilder.control('', [Validators.required]);
              // if (surveyQuestion.required) {
              //   newQuestionFormControl.setValidators(Validators.required);
              // }
              if (surveyQuestion.question_type === 2) {
                newQuestionFormControl.setValidators(Validators.email);
              }
            }
          }

          if (newQuestionFormControl === undefined) {
            this.questions.push(checkboxQuestionChoices);
          } else {
            this.questions.push(newQuestionFormControl);
          }
        }
      } else {
        this._notifier.errorCustom('Erreur');
        this._router.navigate(['trainings']).then(() => {
        });
      }
    });
  }

  ngAfterViewInit(): void {
  }

  /**
   * Return all questions returned from the API
   */
  getQuestions(): SurveyQuestion[] | null {
    return this._surveyService.surveyQuestions;
  }

  getSurvey(): Survey | null {
    return this._surveyService.survey;
  }

  getSurveyCategory(): SurveyCategory | null {
    return this._surveyService.surveyCategory;
  }

  /**
   * Return all questions from the form array (Angular Reactive Form)
   */
  get questions() {
    return this.surveyForm.get('questions') as FormArray;
  }

  /**
   * Retrieve a question of API from the form control place in form array (retrieve some data like `label` for example)
   * @param index Current index to search
   * @param multipleSelect If is like a checkbox with multiple value selectable
   * @param multipleSelectIndex Sub-index in form
   */
  matchReactiveFormQuestionAPIQuestion(
    index: number,
    multipleSelect: boolean = false,
    multipleSelectIndex: number = 0
  ): SurveyQuestion|QuestionChoice {
    if (multipleSelect) {
      return this.getQuestions()[index].question_choices[multipleSelectIndex];
    }
    return this.getQuestions()[index];
  }

  /**
   * Retrieve the training date id if is set on params
   *
   * @param trainingDateId
   */
  _retrieveTrainingDate(trainingDateId: number) {
    this._trainingRequestService.getTrainingDate(trainingDateId)
      .subscribe((response: any) => {
        if (response.success) {
          this._trainingService.trainingDate = response.training_date;
        } else {
          this._notifier.errorCustom(response.message);
        }
      });
  }

  /**
   * Return the string value of the type id
   *
   * @param typeId Type id to return string equivalent
   */
  _getTextType(typeId: number): string {
    const typeArray: string[] = [
      'text',
      'email',
      'select',
      'checkbox',
      'radio',
      'number',
      'date',
      'phone',
      'textarea'
    ];
    return typeArray[typeId - 1];
  }

  validSurvey() {
    const answersQuestions: any = [];
    // In case of GDPR log in API
    const gdpr = {
      asked: this.displayGPDR,
      accept: false
    };

    this.surveyForm.value[`questions`].forEach((value, key) => {
      const searchQuestion: SurveyQuestion = this.matchReactiveFormQuestionAPIQuestion(parseInt(key, 10)) as SurveyQuestion;
      if (searchQuestion.slug !== 'gdpr') { // Only retrieve responses not for GDPR
        // Clean the response if is an array by keeping the checked options on array
        let responseClean: any = value;
        if (Array.isArray(value)) {
          responseClean = [];
          value.forEach((response, index) => {
            if (response.checked) {
              responseClean.push(response.name);
            } else { // In case of multiple checkboxes
              if (response) { // Only retrieve responses selected
                const apiQuestion = this.matchReactiveFormQuestionAPIQuestion(key, true, index) as QuestionChoice;
                responseClean.push(apiQuestion.question_option_name);
              }
            }
          });
        }

        // Retrieve the question linked to the answer
        const question: SurveyQuestion = this._surveyService.surveyQuestions.find(element => element.id === searchQuestion.id);

        answersQuestions.push({
          id: searchQuestion.id,
          question: question.question,
          response: responseClean
        });
      } else { // In this case, set if the GDPR as been accepted
        gdpr.accept = value;
      }
    });

    this._surveyRequestService.answerQuestions({
        answers: answersQuestions,
        is_participant: this.isParticipant,
        training_date: this._trainingService.trainingDate.id,
        gdpr_question: gdpr
      },
      this.getSurvey().id)
      .subscribe((response: any) => {
        if (response.success) {
          this._notifier.successCustom('Vos réponses ont été soumises, merci de votre participation.');
          this.thanks = true;
          setTimeout(() => {
            this._router.navigate(['trainings']).then(() => {
            });
          }, 3000);
        } else {
          this._notifier.errorCustom(response.message);
        }
      });
  }
}
