import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate();
  }
}
