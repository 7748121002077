import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguComponent } from './cgu.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [CguComponent],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class CguModule { }
