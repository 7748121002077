import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';

import * as moment from 'moment';

import { TrainingsRequestService } from './trainings.request.service';

import { TrainingDateAllResponse } from '../../interfaces/training/response/training_date';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class TrainingsResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private trainingsService: TrainingsRequestService,
    private authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });


      this.requestInProgress.next(true);

      // If the user is a participant training
      if (this.authService.user.training_participant_access) {
        this.trainingsService.getTrainingsDatesParticipant('', '', '', 12, 1, this.token)
          .subscribe((response: any) => {
            if (response.success) {
              data.trainings_dates = response.trainings_dates;
              data.forms = response.forms;
              data.total = response.total;
              data.pages = response.pages;
            }
            this.requestInProgress.next(false);
          });
      } else {
        this.trainingsService.getTrainingsDates('', '', '', 12, 1, this.token)
          .subscribe((response: TrainingDateAllResponse) => {
            data.trainings_dates = response.trainings_dates;
            data.forms = response.forms;
            data.total = response.total;
            data.pages = response.pages;
            this.requestInProgress.next(false);
        });
      }
    });
  }
}
