import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { NoResultModule } from '../../../core/no-result/no-result.module';
import { SurveyModule } from '../../../core/survey/survey.module';

import { DetailTrainingComponent } from './detail-training.component';
import { ModalTrainingModule } from '../../../core/modals/training/training.module';
import { ModalTrainingAgreementModule } from '../../../core/modals/training-agreement/training-agreement.module';

@NgModule({
  declarations: [DetailTrainingComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MatIconModule,
    NoResultModule,
    RouterModule,
    SurveyModule,
    ModalTrainingModule,
    ModalTrainingAgreementModule
  ]
})
export class DetailTrainingModule { }
