<div class="row mr-0 ml-0 mt-3">
  <div class="col-12 d-flex">
    <div class="flex-grow-1">
      <div class="title-header mb-0"> Mes fichiers </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > <a href="#" routerLink="/directories">Liste des fichiers</a>
      <ng-container *ngFor="let bread of breadcrumb">
        > <a href="#" (click)="changeBread(bread, $event)">{{ bread['name'] }}</a>
      </ng-container>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <button class="btn btn-primary" (click)="addFolder()"><i class="fa fa-folder"></i>&nbsp; <span style="font-size:0.9em">Ajouter un dossier</span></button>
      <button class="btn btn-primary ml-3" (click)="addFile()"><i class="fa fa-plus"></i>&nbsp; <span style="font-size:0.9em">Ajouter un fichier</span></button>
    </div>
  </div>
</div>

<div class="row ml-0 mr-0">
  <app-no-result style="width: 100%; margin-top: 3rem;" *ngIf="elements.length === 0" title="Aucun fichier ni dossier" sub_title="Aucun fichier ni dossier n'est disponible."></app-no-result>
  <ng-container *ngIf="elements.length > 0">
    <div class="col-12 col-lg-4 mb-3" *ngFor="let obj of elements" (click)="selectElement(obj)">
      <div class="card">
        <div class="card-body card-plan-hover" [ngClass]="{'plan-opacity' : obj['opacity']}">
          <div class="d-flex text-nowrap">
            <div class="mr-3">
              <ng-container *ngIf="obj['type'] === 'folder' && !obj['loader']"><i class="fa fa-folder"
                                                                                  style="font-size:2em;"></i>
              </ng-container>
              <ng-container *ngIf="obj['type'] !== 'folder' && !obj['loader']"><i class="fa fa-file"
                                                                                  style="font-size:2em;"></i>
              </ng-container>
              <ng-container *ngIf="obj['loader']"><i class="fa fa-spin fa-spinner" style="font-size:2em;"></i>
              </ng-container>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <div class="font-weight-bold long-name" data-toggle="tooltip" data-placement="bottom" [title]="obj['name']">
                {{ obj['name'] }}
              </div>
              <small *ngIf="obj['type'] === 'folder'">Dossier</small>
              <small *ngIf="obj['type'] !== 'folder'">Fichier</small>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <i (click)="goTrash($event, obj)" class="fa fa-trash trash-link"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-plan-modal #deleteModal>{{ bindModal('plan', deleteModal) }}</app-plan-modal>
<app-confirm-modal #delete type="delete_data">{{ bindModal('delete', delete) }}</app-confirm-modal>
<app-boxplan-dir-modal #dir type="delete_data">{{ bindModal('dir', dir) }}</app-boxplan-dir-modal>
