import { Injectable } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { HttpClient } from '@angular/common/http';
import { SurveyResponseToSend } from '../interfaces/survey_response';

@Injectable()
export class SurveyRequestService extends RequestService {
  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  /**
   * Retrieve the correct survey
   *
   * @param category_type
   * @param training_date_id
   */
  public getParticipantSurvey(category_type, training_date_id) {
    return this.get('api/survey-participant/' + category_type + '?training_date_id=' + training_date_id);
  }

  public getClientContactSurvey(category_type, training_date_id) {
    return this.get('api/survey-client-contact/' + category_type + '?training_date_id=' + training_date_id);
  }

  /**
   * Response to a question from a user (client or participant)
   *
   * @param question The question id linked to the answer
   * @param user The user can be a client or a participant
   * @param answer This parameter can be an empty string
   */
  public answerQuestion(question: number, user: any, answer: string) {
    return this.post('', { question_id: question, user_id: user, user_answer: answer });
  }

  /**
   * Answer to all questions for a user (client or participant)
   *
   * @param answers
   * @param surveyId
   */
  public answerQuestions(answers: any, surveyId: number) {
    return this.post('api/answer-questions/' + surveyId, answers);
  }
}
