import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { NoResultModule } from '../no-result/no-result.module';

import { NotificationWidgetComponent } from './notification-widget.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NotificationWidgetComponent],
  exports: [NotificationWidgetComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    MatIconModule,
    NoResultModule,
    RouterModule
  ]
})
export class NotificationWidgetModule { }
