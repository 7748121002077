import { Routes } from '@angular/router';
import { AllQuotationsComponent } from './all-quotations/all-quotations.component';
import { QuotationsResolver } from '../../core/services/quotations/quotations.resolver';

export const quotationsRoutes: Routes = [
  {
    path: 'quotations',
    component: AllQuotationsComponent,
    resolve: {
      data: QuotationsResolver,
    }
  }
];
