export class File {
  public type;
  public name = '';
  public id = 0;
  public loader = true;
  public opacity = true;
  public file: any;

  constructor(file) {
    this.name = file.filename;
    this.type = 'file';
    this.file = file;
  }

  setProgress(progress) {
    this.loader = progress;
    this.opacity = progress;
  }

  getHttpData() {
    return {filename : this.file.filename, base64 : this.file.file};
  }
}
