<div class="row">
  <form [formGroup]="form" class="col-12">
    <ng-container *ngFor="let field of fields">

      <!-- INPUT TYPE TEXT MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'text' || field.type === 'url'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>
        <input matInput [type]="field.type" [placeholder]="field.label" [formControl]="field.formControl" [errorStateMatcher]="matcher">
        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('email') && !form.controls[field.property].hasError('required')">
          Le format de l'email est <strong>invalide</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <mat-label *ngIf="field.type === 'editor'">{{ field.showLabel && field.label }}</mat-label>
      <editor [init]="optionTinyMce" *ngIf="field.type === 'editor'" [formControlName]="field.property" class="mb-3"></editor>

      <!-- INPUT TYPE PASSWORD MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'password'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>
        <input matInput type="password" [placeholder]="field.label" [formControl]="field.formControl" [errorStateMatcher]="matcher">
        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('notSame')">
          Les mots de passe <strong>ne correspondent pas</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <!-- INPUT TYPE NUMBER MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'number'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>
        <input matInput type="number" [placeholder]="field.label" [formControl]="field.formControl" [errorStateMatcher]="matcher">
        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('min') && !form.controls[field.property].hasError('required')">
          Veuillez saisir un nombre <strong>plus grand</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('max') && !form.controls[field.property].hasError('required')">
          Veuillez saisir un nombre <strong>plus petit</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <!-- INPUT TYPE CURRENCY MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'currency'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>
        <input matInput type="text" appLeviatanCurrencyFormatter [placeholder]="field.label" [formControl]="field.formControl" [errorStateMatcher]="matcher">
        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('min') && !form.controls[field.property].hasError('required')">
          Veuillez saisir un nombre <strong>plus grand</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('max') && !form.controls[field.property].hasError('required')">
          Veuillez saisir un nombre <strong>plus petit</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <!-- TEXTAREA MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'textarea'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>
        <textarea matInput [placeholder]="field.label"></textarea>
        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <!-- SELECT MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'select'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>
        <mat-select [formControlName]="field.property" [multiple]="field.multiple" [compareWith]="field.compareFn ? field.compareFn : defaultCompareWith">
          <ng-container *ngIf="!field.group">
            <mat-option [value]="item.value" *ngFor="let item of field.selectItems">{{ item.label }}</mat-option>
          </ng-container>
          <ng-container *ngIf="field.group">
            <mat-optgroup *ngFor="let group of field.selectItems" [label]="group.origin">
              <mat-option *ngFor="let item of group.items" [value]="item.value">{{ item.label }}</mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <!-- DATE MATERIAL -->
      <mat-form-field class="w-100 mb-3" *ngIf="field.type === 'date'">
        <mat-label [attr.required]="hasRequiredField(form.controls[field.property])">{{ field.label }}</mat-label>

        <input matInput [matDatepicker]="datepicker" disabled [formControlName]="field.property">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker disabled="false"></mat-datepicker>

        <mat-hint *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <mat-error *ngIf="form.controls[field.property].hasError('required')">
          Ce champ est <strong>requis</strong>
        </mat-error>
        <mat-error *ngIf="form.controls[field.property].hasError('key')">
          {{ form.controls[field.property].errors['key'] }}
        </mat-error>
      </mat-form-field>

      <!-- BOOLEAN MATERIAL -->
      <mat-checkbox class="mb-3" *ngIf="field.type === 'boolean'" [formControlName]="field.property">{{ field.showLabel && field.label }}</mat-checkbox>

      <!-- FILE -->
      <div class="form-group mb-3" *ngIf="field.type === 'file'">
        <!-- WITHOUT FILE -->
        <div class="form-group" [hidden]="form.controls[field.property].value">
          <label for="file{{ field.property }}" class="mb-1">{{ field.label }}</label>

          <small class="form-text text-muted" *ngIf="field.fileType === 'image/jpeg,image/png'">
            Veuillez utiliser une image ne dépassant pas 200Opx * 2000px.
          </small>

          <div class="dropzone dropzone-multiple dz-clickable" appDragDrop (dropHandler)="onFileChange($event, field)">
            <div class="dz-default dz-message" (click)="chooseFile(field)">
              <span>Déposer votre fichier ici pour télécharger</span>
            </div>
            <input id="file{{ field.property }}" (change)="onFileChange($event, field)" type="file" [accept]="field.fileType" [hidden]="true">
          </div>
        </div>
        <!-- WITH FILE -->
        <div class="form-group" *ngIf="form.controls[field.property].value">
          <label for="file{{ field.property }}" class="mb-1">{{ field.label }}</label>

          <small class="form-text text-muted" *ngIf="field.fileType === 'image/jpeg,image/png'">
            Veuillez utiliser une image ne dépassant pas 2000px * 2000px.
          </small>

          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <img src="{{ checkPicture(field) }}" alt="image" class="avatar-img rounded" height="150" *ngIf="field.fileType === 'image/jpeg,image/png'">
                  <img src="/assets/img/file.png" alt="image" class="avatar-img rounded" height="150" *ngIf="field.fileType !== 'image/jpeg,image/png'">
                </div>

                <a class="href tooltipHtml" data-placement="bottom" title="Supprimer" (click)="removeFile(field)">
                  <mat-icon aria-hidden="false" aria-label="Supprimer">delete</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- COLOR PICKER -->
      <app-color-picker *ngIf="field.type === 'color'" [field]="field" [color]="field.formControl.value" (colorChange)="field.formControl.setValue($event)"></app-color-picker>

      <!-- SUB FIELDS -->
      <div *ngIf="field.type === 'form'">
        <div [formArrayName]="field.property" *ngIf="field.multiple">
          <div *ngFor="let subForm of form.controls[field.property]['controls']; let i = index;">
            <app-form [fields]="field.subFields" [entity]="entity" [errors]="errors" [loader]="loader" (returnEvent)="validateForm($event, subForm)" (intactEvent)="intactEvent.emit($event)" [initialForm]="false" class="w-100"></app-form>
            <button (click)="deleteField(field, i)" class="btn btn-primary mb-3">
              <span>Supprimer {{ field.label }}</span>
            </button>
          </div>
          <button (click)="addNewField(field)" class="btn btn-primary mb-3">
            <span>Ajouter {{ field.label }}</span>
          </button>
        </div>
      </div>

    </ng-container>

    <button mat-raised-button type="submit" class="mat-accent float-right btn btn-primary" (click)="saveForm()" [disabled]="loader" *ngIf="initialForm">Sauvegarder</button>
  </form>
</div>
