import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PageList } from '../../../core/list/page-list';
import { FilesRequestService } from '../../../core/services/files/files.request.service';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';
import PlanBox from '../../../core/interfaces/planbox';
import PlanBoxModalFile from '../../../core/interfaces/planboxfile';
import PlanBoxFile from '../../../core/interfaces/planboxfile';
import PlanBoxDir from '../../../core/interfaces/planboxdir';
import { Dir } from '../../../core/entities/dir-entity';
import { File } from '../../../core/entities/file-entity';
import { HttpResponse } from '../../../core/services/http.service';
import Breadcrumb from '../../../core/interfaces/breadcrumb/breadcrumb';
import Directory from '../../../core/interfaces/file/directory';

@Component({
  selector: 'app-detail-plans',
  templateUrl: './detail-files.component.html',
  styleUrls: ['./detail-files.component.scss'],
})
export class DetailFilesComponent extends PageList implements OnInit, AfterViewInit {
  private _modal = [];
  private _id: number;
  public elements: any[] = [];
  breadcrumb: Breadcrumb[] = [];

  /**
   * Class constructor
   * @param _activatedRoute
   * @param _plansService
   * @param _router
   * @param _notifier
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _plansService: FilesRequestService,
    private _router: Router,
    private _notifier: CustomNotifierService
  ) {
    super();
    this.http = new HttpResponse(this._notifier);
    this._id = _activatedRoute.snapshot.params.id;
  }

  /**
   * Call before view init
   */
  public ngOnInit() {
    this._activatedRoute.data.subscribe((response: any) => {
      this.http.successCustom = () => {
        this.elements = response.data.datas;
        this.total = response.data.total;
        this.pages = response.data.pages;
        this.breadcrumb = response.data.breadcrumb;
      };
      this.http.parse(response.data, false);
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      // this.loader = this.requestCount > 0;
    });
  }

  /**
   * Call after view init
   */
  public ngAfterViewInit() {
    // Modal add file
    this._modal['plan'].eventOnClose.subscribe((event: PlanBoxModalFile) => {
      if (event !== null) {
        const obj = new File(event);
        this.elements.push(obj);

        this._plansService.addPlanFile(event.id, obj.getHttpData()).subscribe(({id}) => {
          obj.id = id; // Update the file object with the id created in API
          obj.setProgress(false);
          this.http.errorCustom = () => {
            const indexDel = this.elements.length - 1;
            this.elements.splice(indexDel, 1);
          };
        });
      }
    });

    // Delete file or directory
    this._modal['delete'].eventOnClose.subscribe(event => {
      if (event !== null) {
        const index = this.elements.findIndex(item => {
          return item.id === event;
        });

        this._deleteDatas(this.elements[index], index);
      }
    });

    // Add directory modal
    this._modal['dir'].eventOnClose.subscribe((event?: Directory) => {
      if (event !== null) {
        const dir = new Dir(event.name);
        this.elements.push(dir);
        this._plansService.addPlanDirectory(this._id, {name : event.name}).subscribe((response: PlanBoxDir) => {
          dir.id = response.datas.id; // Set the new id to the directory
          dir.setProgress(false);
          this.http.errorCustom = () => {
            const indexDel = this.elements.length - 1;
            this.elements.splice(indexDel, 1);
          };
          this.http.parse(response);
        });
      }
    });
  }

  /**
   * Select specific directory
   * @param data
   */
  public selectElement(data) {
    if (data.type === 'folder') {
      data.loader = true;
      this._id = data.id; // Used for parent id
      this._router.navigateByUrl('/directory/' + data.id).then(() => {});
    } else {
      data.loader = true;
      this._plansService.getBoxPlanFile(data.id).subscribe((response: PlanBoxFile) => {
        if (!response.success) {
          this._notifier.errorCustom('Une erreur est survenue lors de la récupération du fichier');
          data.loader = false;
        } else {
          data.loader = false;
          const link = document.createElement('a');
          link.download = response.datas.datas.filename;
          link.href = 'data:application/octet-stream;base64,' + response.datas.datas.file;
          document.body.append(link);
          link.setAttribute('style', 'display: none');
          link.click();
          link.remove();
        }
      });

      // this.plansService.getBoxPlanFile(data['file']['fileid']).subscribe(response => {
      //   if (response['success']) {
      //     this.preview = 'data:application/' + response['datas']['datas']['ext'] + ';base64,' + response['datas']['datas']['file'];
      //   } else {
      //     this.notifier.errorCustom(response['error']['message']);
      //   }
      // });
    }
  }

  /**
   * Delete plan file
   * @param obj
   * @param index
   * @private
   */
  private _deleteDatas(obj, index) {
    if (obj.type === 'file') {
      obj.loader = true;
      this._plansService.deletePlanFile(obj.id).subscribe((response: PlanBoxFile) => {
        obj.loader = false;
        if (response.success) {
          this._notifier.successDelete();
          this.elements.splice(index, 1);
        } else {
          if (response.error.message !== undefined) {
            this._notifier.errorCustom(response.error.message);
          } else {
            this._notifier.errorRequest();
          }
        }
      });
    } else {
      obj.loader = true;
      this._plansService.deletePlanDirectory(obj.id).subscribe((response: PlanBoxDir) => {
        obj.loader = false;
        if (response.success) {
          this._notifier.successDelete();
          this.elements.splice(index, 1);
        } else {
          if (response.error.message !== undefined) {
            this._notifier.errorCustom(response.error.message);
          } else {
            this._notifier.errorRequest();
          }
        }

      });
    }
  }

  /**
   * Bind modal from view
   * @param index
   * @param deleteModal
   */
  public bindModal(index, deleteModal) {
    this._modal[index] = deleteModal;
  }

  /**
   * Add new folder in current affair
   */
  public addFolder() {
    this._modal['dir'].open(1);
  }

  /**
   * Send item to trash
   * @param $event
   * @param obj
   */
  public goTrash($event: MouseEvent, obj) {
    $event.preventDefault();
    $event.stopPropagation();

    this._modal['delete'].open(obj.id);
  }

  /**
   * Add new file to directory
   */
  public addFile() {
    this._modal['plan'].open(this._activatedRoute.snapshot.params.id);
  }

  /**
   * Navigate to another path in breadcrumb
   *
   * @param {Breadcrumb} breadcrumb The breadcrumb used to navigate
   * @private
   */
  changePathBreadcrumb(breadcrumb: Breadcrumb) {
    this._router.navigateByUrl('/plan' + breadcrumb.url).then(() => {});
  }

  changeBread(bread: Breadcrumb, event) {
    event.preventDefault();
    this._id = parseInt(bread.url);
    this._router.navigateByUrl('/directory/' + bread.url).then(() => {});
  }
}
