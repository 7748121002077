<div class="container" style="max-width:100%;display:flex;flex-direction: column;height:100%;">
  <div class="row justify-content-center">
    <div class="col-12 justify-content-center d-flex pt-4 pb-4" style="box-shadow: 0 1px 3px rgba(7,7,7,0.3);border-top:solid 10px #3e79a0;">
      <div (click)="goLogin()" class="link-cursor" style="background:url('../../../../assets/img/logo.png');width:160px;height:118px;"></div>
    </div>
  </div>
  <div class="flex-grow-1 row d-block" style="background:url('../../../../assets/img/pattern.jpg');">
    <div class="row justify-content-center p-3 d-flex" >
      <div class="card" id="cgu-card">
        <div class="card-header d-flex">
          <div class="flex-grow-1">
            <b>Conditions générales d'utilisation</b>
          </div>
          <div class="button-arrow" (click)="goLogin()">
            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
              <g class="arrow-head">
                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
              </g>
              <g class="arrow-body">
                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
              </g>
            </svg>
          </div>
        </div>
        <div class="card-body">
          <div class="col-12 justify cgu">
            <h1>Pr&eacute;ambule et objet</h1>
            <p>Le site internet www.batisafe.fr (ci-apr&egrave;s le &laquo; SITE &raquo;) est la propri&eacute;t&eacute;
              exclusive de la soci&eacute;t&eacute; BatiSafe, soci&eacute;t&eacute; par actions simplifi&eacute;e au capital
              de 100 000 euros, immatricul&eacute;e au Registre du Commerce et des Soci&eacute;t&eacute;s de Chamb&eacute;ry
              sous le num&eacute;ro RCS 509 666 616 et dont le si&egrave;ge social est situ&eacute; 101 rue Maurice Herzog
              &ndash; 73420 M&eacute;ry (ci-apr&egrave;s d&eacute;nomm&eacute;e &laquo; BATISAFE &raquo;).</p>
            <p>T&eacute;l +33 4 79 61 29 81 &ndash; Adresse mail : contact@batisafe.fr</p>
            <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation ont vocation &agrave;
              s&rsquo;appliquer &agrave; toute personne naviguant sur le SITE (ci-apr&egrave;s &laquo; l&rsquo;INTERNAUTE
              &raquo; ou collectivement &laquo; les INTERNAUTES &raquo;).</p>
            <p>En utilisant le SITE, l&rsquo;INTERNAUTE reconnait express&eacute;ment &ecirc;tre li&eacute; par
              les conditions d&rsquo;utilisation telles que d&eacute;finies ci-apr&egrave;s, les accepter et les
              respecter.</p>
            <h1>Informations pr&eacute;sent&eacute;es sur le SITE</h1>
            <p>Les photographies et images pr&eacute;sentes sur le SITE sont non-contractuelles.</p>
            <h1>Liens vers d&rsquo;autres sites</h1>
            <p>Le SITE peut contenir des hyperliens ou pointer de toute autre mani&egrave;re vers des sites web
              ou des pages web administr&eacute;es par de tierces parties. BATISAFE n&rsquo;est en aucun cas responsable de
              leur contenu et de leurs caract&eacute;ristiques. Le fait que BATISAFE propose des liens n&rsquo;implique en
              aucun cas l&rsquo;approbation implicite du contenu de ces sites ou pages web.</p>
            <h1>Garanties et responsabilit&eacute;s</h1>
            <p>BATISAFE d&eacute;cline toute responsabilit&eacute; notamment :</p>
            <ul>
              <li>pour toute interruption du fonctionnement du SITE</li>
              <li>pour toute survenance de bogues</li>
              <li>pour tout dommage r&eacute;sultant d&rsquo;une intrusion frauduleuse d&rsquo;un tiers ayant entra&icirc;n&eacute;
                une modification des informations mises &agrave; disposition sur le SITE
              </li>
              <li>si un des liens hypertexte r&eacute;f&eacute;renc&eacute;s sur le SITE venait &agrave; fournir un contenu
                contraire &agrave; la biens&eacute;ance et aux lois en vigueur.
              </li>
            </ul>
            <p>De mani&egrave;re g&eacute;n&eacute;rale, BATISAFE d&eacute;cline toute responsabilit&eacute; pour
              tout dommage, mat&eacute;riel ou immat&eacute;riel, direct ou indirect, quelles qu&rsquo;en soient les causes,
              origines, natures ou cons&eacute;quences en ce compris notamment les pertes de profits, de client&egrave;le, de
              donn&eacute;es ou tout autre perte de biens incorporels pouvant survenir &agrave; raison de l&rsquo;acc&egrave;s
              de quiconque au SITE ou de l&rsquo;impossibilit&eacute; d&rsquo;y acc&eacute;der ou du cr&eacute;dit accord&eacute;
              &agrave; une quelconque information provenant directement ou indirectement de ce dernier.</p>
            <p>Les INTERNAUTES d&eacute;clarent conna&icirc;tre et comprendre internet, ses al&eacute;as et ses
              limites et notamment ses caract&eacute;ristiques fonctionnelles et performances techniques, les risques d&rsquo;interruption,
              les temps de r&eacute;ponses pour consulter, interroger ou transf&eacute;rer des informations, les risques,
              quels qu&rsquo;ils soient, inh&eacute;rents &agrave; toute connexion sur le r&eacute;seau.</p>
            <h1>Propri&eacute;t&eacute; intellectuelle</h1>
            <p>Sont de la propri&eacute;t&eacute; de BATISAFE et soumis aux lois r&eacute;gissant la propri&eacute;t&eacute;
              intellectuelle, notamment la mise en page, la structure et les &eacute;l&eacute;ments graphiques et textuels du
              SITE.</p>
            <p>L&rsquo;utilisation du SITE ne conf&egrave;re aucun droit aux INTERNAUTES. Ces droits restent la
              propri&eacute;t&eacute; exclusive de BATISAFE.</p>
            <p>En cons&eacute;quence, les INTERNAUTES ne peuvent en aucun cas et d&rsquo;aucune mani&egrave;re,
              reproduire, repr&eacute;senter, diffuser, commercialiser, conc&eacute;der tout ou partie de l&rsquo;un
              quelconque des &eacute;l&eacute;ments reproduits sur le SITE et tout ou partie du SITE en g&eacute;n&eacute;ral,
              sans l&rsquo;accord pr&eacute;alable et expr&egrave;s de BATISAFE.</p>
            <p>En outre, il est interdit aux INTERNAUTES de r&eacute;aliser des copies du SITE sans l&rsquo;autorisation
              pr&eacute;alable de BATISAFE.</p>
            <p>Toute utilisation illicite de tout ou partie du SITE pourra donner lieu &agrave; des
              poursuites.</p>
            <p>La cr&eacute;ation d&rsquo;un lien vers le SITE doit faire l&rsquo;objet d&rsquo;une autorisation
              pr&eacute;alable et &eacute;crite de la part de BATISAFE.</p>
            <h1>Donn&eacute;es personnelles</h1>
            <p>Les donn&eacute;es personnelles collect&eacute;es et ult&eacute;rieurement trait&eacute;es par
              BATISAFE sont celles que les INTERNAUTES transmettent volontairement via le SITE &agrave; BATISAFE, ou qui ont
              fait l&rsquo;objet d&rsquo;une collecte ant&eacute;rieure sp&eacute;ciale soumise &agrave; autorisation.</p>
            <p>Les INTERNAUTES autorisent BATISAFE &agrave; sauvegarder ces informations personnelles dans un
              fichier de BATISAFE en vue du profilage automatis&eacute; des INTERNAUTES, de la gestion de la client&egrave;le,
              d&rsquo;&eacute;tude de march&eacute;.</p>
            <p>Toutefois, BATISAFE s&rsquo;engage &agrave; ne pas transmettre les donn&eacute;es fournies
              &agrave; des tiers.</p>
            <p>Ces traitements ont fait l&rsquo;objet d&rsquo;une d&eacute;claration aupr&egrave;s de la CNIL
              (enregistr&eacute;e sous le n&deg; 1847141).</p>
            <p>Les INTERNAUTES sont inform&eacute;s que, conform&eacute;ment &agrave; la loi du 6 janvier 1978
              dite loi &laquo; Informatique et Libert&eacute;s &raquo; modifi&eacute;e par la loi du 6 ao&ucirc;t 2004, ils
              disposent des droits suivants :</p>
            <p>Le droit de s&rsquo;opposer, pour des motifs l&eacute;gitimes, &agrave; ce que les donn&eacute;es
              &agrave; caract&egrave;re personnel le concernant fassent l&rsquo;objet de traitements autres que ceux annonc&eacute;s
              aux pr&eacute;sentes auxquels il a consenti ;</p>
            <p>Le droit de s&rsquo;opposer, sans frais, &agrave; ce que les donn&eacute;es collect&eacute;es
              fassent l&rsquo;objet d&rsquo;un traitement commercial actuel ou futur par le responsable du traitement ;</p>
            <p>Sous r&eacute;serve de justifier de son identit&eacute;, le droit de s&rsquo;informer sur les
              traitements auxquels ses donn&eacute;es &agrave; caract&egrave;re personnel donnent lieu ;</p>
            <p>Le droit d&rsquo;obtenir des informations relatives aux traitements concernant les donn&eacute;es
              &agrave; caract&egrave;re personnel g&eacute;r&eacute;es par BATISAFE et toutes informations permettant de conna&icirc;tre
              et, au besoin, contester la logique qui pr&eacute;side aux traitements des dites donn&eacute;es. L&rsquo;INTERNAUTE,
              pour ce faire, doit justifier de son identit&eacute; ;</p>
            <p>Sous r&eacute;serve &eacute;galement de la justification de son identit&eacute;, le droit d&rsquo;obtenir
              copie des donn&eacute;es &agrave; caract&egrave;re personnel le concernant, ainsi qu&rsquo;un droit de
              rectification, de mise &agrave; jour ou de suppression de tout ou partie des dites donn&eacute;es.</p>
            <p>Pour toute question ou demande relative &agrave; la protection de la vie priv&eacute;e, les
              INTERNAUTES pourront contacter BATISAFE &agrave; l&rsquo;adresse suivante : contact@batisafe.fr ou par courrier
              postal &agrave; l&rsquo;adresse suivante : BATISAFE &ndash; 101 rue Maurice Herzog &ndash; 73420 M&eacute;ry</p>
            <h1>Cookies</h1>
            <p>Un cookie est un fichier texte install&eacute; sur le disque dur du terminal de connexion,
              &agrave; la demande du site consult&eacute; par un navigateur. Il enregistre des informations relatives &agrave;
              la navigation sur internet et permet audit site de d&eacute;terminer si ce m&ecirc;me navigateur s&rsquo;est d&eacute;j&agrave;
              connect&eacute; auparavant. Sa dur&eacute;e de conservation est d&eacute;finie par l&rsquo;exploitant du site et
              peut se limiter &agrave; la session de navigation sur le site en question ou lui &ecirc;tre
              sup&eacute;rieure.</p>
            <p>Conform&eacute;ment &agrave; l&rsquo;Ordonnance du 24 ao&ucirc;t 2011 relative &agrave; l&rsquo;application
              des directives europ&eacute;ennes en mati&egrave;re de protection de la vie priv&eacute;e sur Internet, un
              cookie ne peut &ecirc;tre implant&eacute; sur l&rsquo;ordinateur des INTERNAUTES sans leur consentement pr&eacute;alable.</p>
            <p>Pour information, les cookies utilis&eacute;s sur le SITE par BATISAFE ont pour finalit&eacute; d&rsquo;am&eacute;liorer
              l&rsquo;exp&eacute;rience utilisateur, la s&eacute;curit&eacute; et &agrave; des fins statistiques.</p>
            <p>Par ailleurs, les INTERNAUTES conservent la possibilit&eacute; de refuser l&rsquo;implantation de
              &laquo; cookies &raquo; en configurant son navigateur de la mani&egrave;re suivante :</p>
            <ul>
              <li>Si l&rsquo;INTERNAUTE navigue sur Internet avec Internet Explorer 6 (Microsoft) : cliquer &laquo; outils
                &raquo;, &laquo; options Internet &raquo;, &laquo; confidentialit&eacute; &raquo;. Cliquer sur le bouton
                &laquo; avanc&eacute; &raquo;, cocher la case &laquo; Ignorer la gestion automatique des cookies &raquo;,
                cocher les deux cases &laquo; refuser &raquo;, puis valider. (IE doit &ecirc;tre ferm&eacute; pour que la
                configuration soit prise en compte)
              </li>
              <li>Si l&rsquo;INTERNAUTE navigue sur Firefox 1.5 (Mozilla) : Cliquer &laquo; outils &raquo;, &laquo; options
                &raquo;, &laquo; vie priv&eacute;e &raquo;, onglet &laquo; cookies &raquo;. D&eacute;cocher &laquo; Autoriser
                les sites &agrave; placer des cookies &raquo;.
              </li>
              <li>Si l&rsquo;INTERNAUTE navigue sur Firefox 2 (Mozilla) : Cliquer &laquo; outils &raquo;, &laquo; options
                &raquo;, &laquo; vie priv&eacute;e &raquo;. D&eacute;cocher &laquo; accepter les cookies &raquo; ou cliquer
                sur le bouton &laquo; exceptions &raquo; et entrer le nom du site et cliquer sur &laquo; bloquer &raquo;.
              </li>
              <li>Si l&rsquo;INTERNAUTE navigue sur Google Chrome : Cliquer &laquo; Param&egrave;tres &raquo; dans la barre d&rsquo;outils
                du navigateur, puis &laquo; Afficher les param&egrave;tres avanc&eacute;s &raquo;. Dans la section &laquo;
                Confidentialit&eacute; &raquo;, cliquer &laquo; Param&egrave;tres de contenu &raquo;. Dans la section &laquo;
                Cookies &raquo;, choisir &laquo; Bloquer les cookies par d&eacute;faut &raquo;.
              </li>
              <li>Si l&rsquo;INTERNAUTE navigue sur Safari : Dans la barre de menu, choisir &laquo; Edition &raquo;, puis
                cliquer &laquo; Pr&eacute;f&eacute;rences&hellip; &raquo;, choisir l&rsquo;ic&ocirc;ne &laquo; S&eacute;curit&eacute;
                &raquo; puis cocher l&rsquo;option &laquo; Jamais &raquo;.
              </li>
            </ul>
            <p>Les INTERNAUTES peuvent ainsi s&rsquo;opposer &agrave; l&rsquo;enregistrement des cookies en
              suivant la proc&eacute;dure ci-avant d&eacute;crite, en fonction de leur navigateur.</p>
            <p>Toutefois, il est pr&eacute;cis&eacute; que les INTERNAUTES ne pourront pas b&eacute;n&eacute;ficier
              de certaines fonctionnalit&eacute;s du SITE s&rsquo;ils n&rsquo;acceptent pas l&rsquo;implantation de cookies,
              notamment : les pr&eacute;f&eacute;rences de navigation, l&rsquo;acc&egrave;s aux services personnalis&eacute;s
              (gestion des annonces, recherche).</p>
            <p>Le SITE peut renvoyer &agrave; des &laquo; plugins sociaux &raquo; (Facebook, Twitter, Youtube,
              Viadeo, Likedin&hellip;) qui permettent de proposer la recommandation de contenus, d&rsquo;utiliser des donn&eacute;es
              de profils pour des commentaires, l&rsquo;enregistrement ou la compl&eacute;tion de formulaires ou d&rsquo;afficher
              des contenus en provenance des sites sociaux.</p>
            <p>BATISAFE n&rsquo;est pas responsable de l&rsquo;impl&eacute;mentation par le(s) r&eacute;seau(x)
              social(aux) de cookies-tiers sur l&rsquo;ordinateur des INTERNAUTES.</p>
            <p>BATISAFE utilise la plateforme marketing Webmecanik Automation pour suivre la navigation des
              visiteurs sur le site, collecter de l&rsquo;information sur leurs pr&eacute;f&eacute;rences et g&eacute;rer les
              formulaires pr&eacute;sent sur le site. Webmecanik Automation enregistre de l&rsquo;information sur les pages
              que vous visitez, la dur&eacute;e de la visite, les pages de provenance, etc. L&rsquo;expiration indiqu&eacute;e
              ci-dessous repr&eacute;sente la dur&eacute;e de vie d&rsquo;un cookie sur votre navigateur.</p>
            <p>mtc_id <br/>Expiration : Fin de session <br/>mtc_sid <br/>Expiration : Fin de session <br/>mautic_session_id
              <br/>Expiration : 1 an <br/>mautic_referer_id <br/>Expiration : 1 an <br/>mautic_focus_x (x est remplac&eacute;
              par un nombre (un ID). Permet l&rsquo;affichage de popin sur le site internet.) <br/>Expiration : Jamais</p>
            <h1>S&eacute;curit&eacute;</h1>
            <p>BATISAFE porte une attention particuli&egrave;re &agrave; la protection des donn&eacute;es
              personnelles des INTERNAUTES contre toute forme de dommage, perte, d&eacute;tournement, intrusion, divulgation,
              alt&eacute;ration ou destruction.</p>
            <p>Cette vigilance s&rsquo;effectue :</p>
            <ul>
              <li>par l&rsquo;utilisation des moyens de cryptographie dans la transmission des donn&eacute;es (SSL) ;</li>
              <li>par un engagement de stricte confidentialit&eacute; du personnel de BATISAFE qui aurait acc&egrave;s aux
                donn&eacute;es personnelles des INTERNAUTES ;
              </li>
              <li>par des sauvegardes des donn&eacute;es sur le SITE.</li>
            </ul>
            <h1>Modifications des pr&eacute;sentes conditions g&eacute;n&eacute;rales
              d&rsquo;utilisation</h1>
            <p>BATISAFE se r&eacute;serve le droit de modifier les dispositions des pr&eacute;sentes conditions g&eacute;n&eacute;rales
              d&rsquo;utilisation et ce &agrave; tout moment et sans pr&eacute;avis. Les INTERNAUTES seront automatiquement li&eacute;s
              par ces modifications et devront donc p&eacute;riodiquement v&eacute;rifier si leur utilisation du SITE est
              conforme aux dispositions des pr&eacute;sentes conditions g&eacute;n&eacute;rales d&rsquo;utilisation.</p>
            <h1>Loi applicable, comp&eacute;tence juridictionnelle et ind&eacute;pendance
              des clauses</h1>
            <p>L&rsquo;exploitation et l&rsquo;utilisation du SITE est soumise au droit fran&ccedil;ais.</p>
            <p>En cas de litige concernant notamment l&rsquo;utilisation ou le contenu du SITE, la comp&eacute;tence
              juridictionnelle est celle des tribunaux fran&ccedil;ais. Le tribunal comp&eacute;tent sera d&eacute;sign&eacute;
              selon les r&egrave;gles de proc&eacute;dure en vigueur en France.</p>
            <p>Dans le cas o&ugrave; une ou plusieurs stipulations des pr&eacute;sentes conditions devaient
              &ecirc;tre tenues pour invalides, la validit&eacute; des autres stipulations ne sauraient &ecirc;tre remise en
              cause sauf si elles pr&eacute;sentaient un caract&egrave;re indissociable avec la disposition
              invalid&eacute;e.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 m-O">
      <div class="card d-flex" style="display:flex;padding:0 20px;height:auto;line-height:80px;flex-direction:row;">
        <div class="d-block d-lg-none"><a href="https://batisafe.fr" target="_blank">&copy; Batisafe.fr</a></div>
        <div class="w-50 d-lg-block d-none"><a href="https://batisafe.fr" target="_blank">Batisafe.fr</a> - Tous droits réservés</div>
        <div class="text-right flex-grow-1 d-block d-lg-none">
          <a href="/mentions">Légales</a> | <a href="/cgu">CGU</a>
        </div>
        <div class="text-right flex-grow-1 d-none d-lg-block" style="margin-right: 2.8rem;">
          <a href="/mentions">Mentions légales</a> | <a href="/cgu">Conditions générales d'utilisation</a>
        </div>
      </div>
    </div>
  </div>
</div>
