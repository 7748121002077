import { Routes } from '@angular/router';
import { AllFilesComponent } from './all-files/all-files.component';
import { FilesResolver } from '../../core/services/files/files.resolver';
import { DetailFilesComponent } from './detail-files/detail-files.component';
import { FilesDetailResolver } from '../../core/services/files/filesDetail.resolver';

export const filesRoutes: Routes = [
  {
    path: 'directories',
    component: AllFilesComponent,
    resolve: {
      data: FilesResolver,
    }
  },
  {
    path: 'directory/:id',
    component: DetailFilesComponent,
    resolve: {
      data: FilesDetailResolver,
    }
  }
];
