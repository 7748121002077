import { Routes } from '@angular/router';

import { NotificationsComponent } from './notifications.component';

export const notificationsRoutes: Routes = [
  {
    path: 'notifications',
    component: NotificationsComponent
  }
];
