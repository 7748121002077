import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validate a password
 *
 * @param minimumLength By default, the minimum length is 8
 * @param searchUpperCase By default, check for upper case
 * @param searchLowerCase By default, check for lower case
 * @param searchNumber By default, check for number
 * @param searchSpecialChar By default, check for special chars
 * @param passwordRegex If provided. All previous params will be ignored for this regex
 * @param passwordRegexErrorMessage Provide it if a password regex is set for a return error message.
 *                                  The regex can be everything, the length or the strength procedure couldn't be known.
 *                                  Add a custom error message to be precise with the
 *                                  functioning of the regex to best help the user.
 */
export default function ValidatorPassword(
  minimumLength: number = 8,
  searchUpperCase: boolean = true,
  searchLowerCase: boolean = true,
  searchNumber: boolean = true,
  searchSpecialChar: boolean = true,
  passwordRegex: RegExp | string = null,
  passwordRegexErrorMessage: string = ''
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Avoid some treatment if the control value is null
    if (control.value === null) {
      return null;
    }

    /*
    If the password regex is set, it will override all of previous params set and the match will be on the regex
     */
    if (passwordRegex) {
      if (typeof passwordRegex === 'string') {
        passwordRegex = new RegExp(passwordRegex);
      }
      const testPassword = passwordRegex.test(control.value);
      // If the given password match the regular expression, return null (no errors found)
      return testPassword ? null : {
        password: {
          value: control.value,
          message: passwordRegexErrorMessage ?? 'Le mot de passe n\'est pas valide'
        }
      };
    } else {
      const validLength = control.value.length > minimumLength; // In all cases, the length is required

      let validUpperCase = true;
      if (searchUpperCase) {
        const regexUpperCase = new RegExp('[A-Z]');
        validUpperCase = regexUpperCase.test(control.value);
      }

      let validLowerCase = true;
      if (searchLowerCase) {
        const regexLowerCase = new RegExp('[a-z]');
        validLowerCase = regexLowerCase.test(control.value);
      }

      let validNumber = true;
      if (searchNumber) {
        const regexNumber = new RegExp('\\d');
        validNumber = regexNumber.test(control.value);
      }

      let validSpecialChar = true;
      if (searchSpecialChar) {
        const regexSpecialChar = new RegExp('[^A-Za-z0-9]');
        validSpecialChar = regexSpecialChar.test(control.value);
      }

      const errorObject = {
        password: {
          value: control.value,
          message: ''
        }
      };
      if (!validLength && !errorObject.password.message.length) {
        if (minimumLength === 0) {
          errorObject.password.message = 'Le mot de passe ne doit pas être vide';
        } else {
          errorObject.password.message = 'Le mot de passe doit faire plus de ' + minimumLength;
          if (minimumLength === 1) {
            errorObject.password.message += ' caractère';
          } else {
            errorObject.password.message += ' caractères';
          }
        }
      }
      if (!validUpperCase && !errorObject.password.message.length) {
        errorObject.password.message = 'Le mot de passe doit contenir au moins une lettre majuscule';
      }
      if (!validLowerCase && !errorObject.password.message.length) {
        errorObject.password.message = 'Le mot de passe doit contenir au moins une lettre minuscule';
      }
      if (!validNumber && !errorObject.password.message.length) {
        errorObject.password.message = 'Le mot de passe doit contenir au moins un numéro';
      }
      if (!validSpecialChar && !errorObject.password.message.length) {
        errorObject.password.message = 'Le mot de passe doit contenir au moins caractère spécial';
      }

      // If all conditions are verified, return null (no errors found)
      return validLength && validUpperCase && validLowerCase && validNumber && validSpecialChar ? null : errorObject;
    }
  };
}
