export const environment = {
  production: false,
  SERVER_HOST: 'http://leviatan.leviatan.fr:8000/',
  // API_PATH: 'https://leviatan.leviatan.fr:8000/',
  // API_PATH: 'https://127.0.0.1:8000/',
  API_PATH: 'https://preprod.api.yield.dev-leviatan.fr/',
  API_PATH_ERP: 'https://local.api.batisafe.fr:8888/api/v1/',
  API_DOMAIN: 'local.api.leviatan.fr',
  ERP_HOST: 'http://localhost:4201',
  ERP_DOMAIN: 'localhost',
  API_KEY : '2_1f9uj3oiu0n44s0kkcwksskcss4osogogkgk0ockgg8kgs8sg5',
  API_SECRET : '35k1tnpizzeo0wk44o4skw0w4ws44o08wwcksc40g4w4w0css1',
  ERP_ENTERPRISE : 2,
};

export const maps = {
  API_KEY: ''
};
