import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';
import { PageList } from '../../../core/list/page-list';
import { QuotationsRequestService } from '../../../core/services/quotations/quotations.request.service';
import { InvoicesRequestService } from '../../../core/services/invoices/invoices.request.service';
import { InvoiceRedirectDashboard } from '../../../core/interfaces/dashboard/invoice-dashboard';
import Column from '../../../core/list/column';
import Invoice from '../../../core/interfaces/invoice/invoice';
import Example from '../../../core/interfaces/example';
import { DomSanitizer } from '@angular/platform-browser';
import { ListComponent } from '../../../core/list/list.component';
import { PreviewService } from '../../../core/services/helper/preview.service';

@Component({
  selector: 'app-all-invoices',
  templateUrl: './all-invoices.component.html',
  styleUrls: ['./all-invoices.component.scss'],
})

export class AllInvoicesComponent extends PageList implements OnInit, AfterViewInit {
  public columns: Column[] = [];
  public invoices: Example[] = [];
  public preview: any;
  private list: any;
  public menu: any;

  /**
   * Class constructor
   * @param _activatedRoute
   * @param _quotationService
   * @param _invoiceService
   * @param _previewService
   * @param _notifier
   * @param sanitizer
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _quotationService: QuotationsRequestService,
    private _invoiceService: InvoicesRequestService,
    private _previewService: PreviewService,
    private _notifier: CustomNotifierService,
    public sanitizer: DomSanitizer
  ) {
    super();
  }

  /**
   * Called before view init
   */
  public ngOnInit() {
    this.menu = [
      {
        callback : 'download',
        name : 'Télécharger',
        icon : 'get_app',
        color : 'default'
      }
    ];

    // Set preview to false
    if (this.preview === undefined) {
      this.preview = false;
    }

    // Get data with resolver
    this._activatedRoute.data.subscribe(response => {
      this.invoices = response.data.datas;
      this.total = response.data.total;
      this.pages = response.data.pages;

      response.data.forms.forEach(item => {
        this.columns.push({ label: item.label, showLabel: true, sort: item.name, property: item.name, checked: true, type: item.type });
      });
    });

    // Init request progress
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      // this.loader = this.requestCount > 0;
    });
  }

  /**
   * Called after view init
   */
  public ngAfterViewInit() {
    this._activatedRoute.queryParams.subscribe((params: InvoiceRedirectDashboard) => {
      if (params.source !== undefined && params.source === 'dashboard') {
        this.updateList({
          action: 'select',
          value: {id: params.id}
        });
      }
    });
  }

  /**
   * get data list
   */
  public getData() {
    this.requestInProgress.next(true);

    this._invoiceService.getInvoices(this.search, this.sort, this.order, this.limit, this.page).subscribe((response: Invoice) => {
      if (response.success) {
        this.invoices = response.datas;
        this.columns = [];

        response.forms.forEach(item => {
          this.columns.push({ label: item.label, showLabel: true, sort: item.name, property: item.name, checked: true, type: item.type });
        });
        this.total = response.total;
        this.pages = response.pages;
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * Dispatch action
   * @param data
   */
  public updateList(data) {
    if (data.action === 'pageSize') {
      this.changePageSize(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    } else if (data.action === 'sort') {
      this.sort = data.value;
      this.order = data.order;
      this.getData();
    } else if (data.action === 'search') {
      this.search = data.value;
      this.page = 1;
      this.getData();
    } else if (data.action === 'download') {
      this._previewService.toggleLoader();
      this._invoiceService.getInvoice(data.value.id).subscribe(response => {
        const link = document.createElement('a');
        link.download = data.value.customField.number + '.pdf';
        link.href = 'data:application/octet-stream;base64,' + response[`datas`];
        document.body.append(link);
        link.setAttribute('style', 'display: none');
        link.click();
        link.remove();
        this._previewService.toggleLoader();
      });
    } else if (data.action === 'select') {
      this._previewService.toggleLoader('preview');
      this.preview = null;
      this._invoiceService.getInvoice(data.value.id).subscribe(response => {
        this._previewService.toggleLoader('preview');
        this.preview = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response[`datas`]);
        this.list.id = data.value.id;
        this.list.showPreview(this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response[`datas`]));
      });
    }
  }

  bindList(list: ListComponent) {
    if (this.list === undefined) {
      this.list = list;
    }
  }

  /**
   * Check the loader value from service
   *
   * @return {boolean}
   */
  get isLoaderPreview(): boolean {
    return this._previewService.loaderPreview;
  }

  /**
   * Check the loader value for preview menu from service
   */
  get isLoaderPreviewMenu(): boolean {
    return this._previewService.loaderPreviewMenu;
  }
}
