import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';
import { TheonormeRequestService } from './theonorme.request.service';

import * as moment from 'moment';

@Injectable()
export class TheonormeResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private theonormeServices: TheonormeRequestService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      if (route.routeConfig.path === 'theonorme/questions') {
        this.requestInProgress.next(true);
        this.theonormeServices.getQuestions('', '', '', 12, 1, this.token).subscribe((response: any) => {
          data.questions = response.datas;
          data.hasPack = response.hasPack;
          data.canCreate = response.canCreate;
          data.uniqQuestion = response.uniqQuestion;
          data.uniqAnswer = response.uniqAnswer;
          data.forms = response.form;
          data.total = response.count;
          data.pages = response.pages;
          data.success = response.success;
          this.requestInProgress.next(false);
        });
      } else if (route.routeConfig.path === 'theonorme/question/:questionId') {
        this.requestInProgress.next(true);
        this.theonormeServices.getQuestion(route.params.questionId, this.token).subscribe((response: any) => {
          data.question = response.data.question;
          data.answer = response.data.answer;
          this.requestInProgress.next(false);
        });
      } else if (route.routeConfig.path === 'theonorme/veilles' || route.routeConfig.path === 'theonorme/alertes-reglementaires') {
        this.requestInProgress.next(true);

        const isAlert = route.routeConfig.path === 'theonorme/alertes-reglementaires';
        this.theonormeServices.getVeilles(isAlert, '', '', '', 6, 1, '', '', this.token)
          .subscribe((response: any) => {
            data.veilles = response.data.veilles;
            data.total = response.data.total;
            data.pages = response.data.pages;
            data.success = response.success;
            data.message = response.message;
            data.access = response.access;
            this.requestInProgress.next(false);
          });
      }
    });
  }
}
