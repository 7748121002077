import { Routes } from '@angular/router';
import { AllExamplesComponent } from './all-examples/all-examples.component';
import { EditExampleComponent } from './edit-example/edit-example.component';

import { PendingChangesGuard } from '../../core/utils/pending.changes.guard';
import { ExamplesResolver } from '../../core/services/examples/examples.resolver';

export const examplesRoutes: Routes = [
  {
    path: 'examples',
    component: AllExamplesComponent,
    resolve: {
      data: ExamplesResolver,
    }
  },
  {
    path: 'examples/create',
    component: EditExampleComponent,
    resolve: {
      data: ExamplesResolver,
    },
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'examples/:exampleId',
    component: EditExampleComponent,
    resolve: {
      data: ExamplesResolver,
    },
    canDeactivate: [PendingChangesGuard]
  },
];
