import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { AllCommentsComponent } from './all-comments.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormModule } from '../../../core/form/form.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { MatButtonModule } from '@angular/material/button';
import { NoResultModule } from '../../../core/no-result/no-result.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    MatIconModule,
    ConfirmModalModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    NoResultModule
  ],
  declarations: [AllCommentsComponent]
})

export class AllCommentsModule {}
