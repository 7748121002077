<div class="container" id="auth">
  <div class="d-flex flex-md-row flex-column align-items-center justify-content-center p-3 h-100">
    <div class="row" id="auth-options">
      <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 pr-4s">

        <div class="row">
          <div class="logo-box">
            <img src="assets/img/logo-blanc.png" alt="logo" />
          </div>
          <div class="col-12 text">
            Vous dirigez une société et vous souhaitez découvrir les services proposés par Batisafe.<br/>
            Pour cela il vous suffit de créer un compte pour vous tenir informé de
            toutes les dernières nouveautés.
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary btn-medium button btn-white"
                    (click)="cardRegister = true; cardLogin = false; accEnter = true; accPart = false">
              Créer un compte
            </button>
          </div>
        </div>

        <!--<div class="row" style="margin-top: 3rem;" *ngIf="false">-->
        <!--  <div class="col-12 text">-->
        <!--    Vous êtes un particulier et vous désirez découvrir les services proposés par Batisafe pour les-->
        <!--    particuliers.<br/>Il vous suffit de vous inscrire en quelques clics pour accéder aux dernières-->
        <!--    nouveautés.-->
        <!--  </div>-->
        <!--  <div class="col-12 text-center">-->
        <!--    <button class="btn btn-primary btn-medium button"-->
        <!--            (click)="cardRegister = true; cardLogin = false; accPart = true; accEnter = false">-->
        <!--      Créer un compte particulier-->
        <!--    </button>-->
        <!--  </div>-->
        <!--</div>-->

      </div>
      <div class="col-xl-6"></div>
    </div>

    <div class="row" id="auth-forms">
      <div class="col-sm-12">
        <!-- Card login -->
        <ng-container *ngIf="cardLogin">
          <div class="card-header d-flex">
            <div class="flex-grow-1 h-100" *ngIf="!resetPassword">
              <div class="card-form-title">
                Me connecter
              </div>
              <div class="card-form-subtitle">
                Afin d'accéder à l'intégralité de vos données et aux dernières informations fournis par nos services,
                il vous suffit de vous identifier sur le portail.
              </div>
            </div>
            <div class="flex-grow-1 h-100" *ngIf="resetPassword">
              <div class="card-form-title" style="display: flex; justify-content: space-between;">
                <div>
                  Mot de passe oublié
                </div>
                <div class="button-arrow rotate" *ngIf="resetPassword" (click)="resetPasswordLost()">
                  <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                    <g class="arrow-head">
                      <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor"
                            stroke-width="2"/>
                    </g>
                    <g class="arrow-body">
                      <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="card-form-subtitle">
                Pour récupérer votre mot de passe, vous devez saisir votre adresse email ci-dessous. Un email
                contenant la procédure de récupération vous sera transmis.
              </div>
            </div>
            <!--<div class="d-lg-none d-md-block" (click)="showLogin = !showLogin;">
              <mat-icon aria-hidden="true" aria-label="retour" *ngIf="!showLogin">expand_more</mat-icon>
              <mat-icon aria-hidden="true" aria-label="retour" *ngIf="showLogin">expand_less</mat-icon>
            </div>-->
          </div>
          <div class="card-body" *ngIf="showLogin">
            <ng-container *ngIf="!resetPassword">
              <form [formGroup]="loginForm">
                <div class="form-group">
                  <label for="validationEmail" class="required">Email</label>
                  <input type="email" formControlName="email" class="form-control" id="validationEmail"
                         placeholder="exemple@mail.fr"
                         [ngClass]="{ 'is-invalid': loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched) }"
                         (keyup)="clearError()">
                  <div
                    *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)"
                    class="invalid-feedback">
                    {{ getErrorMessage("email") }}
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label for="validationPassword" class="required">Mot de Passe</label>
                    </div>
                  </div>

                  <div class="input-group input-group-merge">
                    <input [type]="showPassword ? 'text' : 'password'" formControlName="password"
                           class="form-control form-control-appended"
                           id="validationPassword"
                           [ngClass]="{ 'is-invalid': loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched) }"
                           (keyup)="clearError()">
                    <div class="input-group-append" (click)="toggle()">
                      <span class="input-group-text link-cursor">
                        <fa-icon [icon]="faEye" *ngIf="showPassword"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!showPassword"></fa-icon>
                      </span>
                    </div>
                    <div
                      *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)"
                      class="invalid-feedback">
                      {{ getErrorMessage("password") }}
                    </div>
                  </div>
                  <div style="font-size:0.8em;" class="text-right mt-1">
                    <a href="#" (click)="forgotPassword($event)">Mot de passe oublié ?</a>
                  </div>
                </div>

                <div class="row" style="margin-top: 2rem;">
                  <div class="col">
                    <div class="text-center">
                      <button class="btn btn-primary mb-3 action-button" (click)="logIn()"
                              [ngClass]="{ 'is-loading is-loading-sm': isSigningIn }"
                              [disabled]="isSigningIn || loginForm.invalid">
                        Connexion
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
            <ng-container *ngIf="resetPassword">
              <div class="form-group">
                <label for="passwordEmail" class="required">Email</label>
                <input type="email" class="form-control" id="passwordEmail" placeholder="exemple@mail.fr"
                       [(ngModel)]="resetPasswordEmail">
              </div>


              <div class="row" style="margin-top: 2rem;">
                <div class="col">
                  <div class="text-center">
                    <button class="btn btn-primary mb-3" style="font-size:0.9em;" (click)="sendPasswordReset()"
                            [ngClass]="{ 'is-loading is-loading-sm': passwordRequestProgress }"
                            [disabled]="passwordRequestProgress || resetPasswordEmail === undefined || resetPasswordEmail === null || resetPasswordEmail === ''">
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- Card registration -->
        <ng-container *ngIf="cardRegister">
          <div class="card-body flex-grow-1 h-100">
            <ng-container *ngIf="accEnter && !accPart">
              <div class="card-form-title" style="display: flex; justify-content: space-between;">
                <div>
                  Créer un compte professionnel
                </div>
                <div class="button-arrow rotate"
                     *ngIf="EnterpriseStep"
                     (click)="EnterpriseStep = false"
                     title="Retour à la première étape"
                >
                  <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                    <g class="arrow-head">
                      <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor"
                            stroke-width="2"/>
                    </g>
                    <g class="arrow-body">
                      <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="card-form-subtitle">
                Validez les deux étapes du formulaire d'inscription afin de créer votre compte entreprise.
              </div>

              <div class="d-flex justify-content-center align-items-center pt-3">
                <div class="d-flex flex-column justify-content-center align-items-center" style="font-size:0.8em;">
                  <div class="step step-active">1</div>
                  <div class="step-active-text">Vous</div>
                </div>
                <div [ngClass]="EnterpriseStep ? 'step-sep step-sep-active' : 'step-sep'"></div>
                <div class="d-flex flex-column justify-content-center align-items-center" style="font-size:0.8em;">
                  <div [ngClass]="EnterpriseStep ? 'step step-active' : 'step'">2</div>
                  <div [ngClass]="EnterpriseStep ? 'step-active-text' : ''">Entreprise</div>
                </div>
              </div>

              <form [formGroup]="RegisterEnterForm">
                <ng-container *ngIf="!EnterpriseStep">
                  <div class="register-form">
                    <label for="register-enter-email" class="required">Email</label>
                    <input type="email" formControlName="email" (keyup)="checkEmail(true)" class="form-control"
                           id="register-enter-email"
                           placeholder="exemple@mail.fr"
                           [ngClass]="{ 'is-invalid': getEntFormControl('email').invalid && (getEntFormControl('email').dirty || getEntFormControl('email').touched) }">
                    <div
                      *ngIf="getEntFormControl('email').invalid && (getEntFormControl('email').dirty || getEntFormControl('email').touched)"
                      class="invalid-feedback">
                      {{ getErrorMessage("renterEmail") }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-lastname" class="required">Civilité</label>
                        <ng-select formControlName="civility" bindValue="id" bindLabel="name"
                                   [items]="[{id : 'M.', name : 'M.'}, {id : 'Mme', name : 'Mme'}]"
                                   id="register-enter-civility"
                                   [ngClass]="{ 'is-invalid': getEntFormControl('civility').invalid && (getEntFormControl('civility').dirty || getEntFormControl('civility').touched) }"></ng-select>
                      </div>
                    </div>
                    <div class="col-md-5 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-lastname" class="required">Nom</label>
                        <input type="text" formControlName="lastname" class="form-control"
                               id="register-enter-lastname"
                               [ngClass]="{ 'is-invalid': getEntFormControl('lastname').invalid && (getEntFormControl('lastname').dirty || getEntFormControl('lastname').touched) }">
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-firstname" class="required">Prénom</label>
                        <input type="text" formControlName="firstname" class="form-control"
                               id="register-enter-firstname"
                               [ngClass]="{ 'is-invalid': getEntFormControl('firstname').invalid && (getEntFormControl('firstname').dirty || getEntFormControl('firstname').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="sep"></div>

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-password" class="required">Mot de passe</label>
                        <input type="password" formControlName="password" class="form-control"
                               id="register-enter-password"
                               [ngClass]="{ 'is-invalid': getEntFormControl('password').invalid && (getEntFormControl('password').dirty || getEntFormControl('password').touched) }">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-passwordr" class="required">Ressaisir le mot de passe</label>
                        <input type="password" formControlName="passwordr" class="form-control"
                               id="register-enter-passwordr"
                               [ngClass]="{ 'is-invalid': getEntFormControl('passwordr').invalid && (getEntFormControl('passwordr').dirty || getEntFormControl('passwordr').touched) }">
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        *ngIf="getEntFormControl('password').invalid && (getEntFormControl('password').dirty || getEntFormControl('password').touched)"
                        style="font-size:0.8em;" class="text-danger">
                        {{ getPasswordError(getEntFormControl('password')) }}
                      </div>
                      <div
                        *ngIf="(getEntFormControl('passwordr').value !== '' && getEntFormControl('password').value !== '') && (getEntFormControl('passwordr').value !== getEntFormControl('password').value)"
                        style="font-size:0.8em;" class="text-danger">
                        Les mots de passes ne sont pas identiques
                      </div>
                    </div>
                  </div>

                  <div class="register-form text-center pt-4 pb-3">
                    <button class="btn btn-primary p-2 action-button" [disabled]="stepOneCheck()"
                            (click)="registerStepTwo()">
                      <span *ngIf="!loaderRegister">Étape suivante</span>
                      <span *ngIf="loaderRegister"><i class="fa fa-spin fa-spinner"></i></span>
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="EnterpriseStep">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-phone" class="required">Téléphone</label>
                        <ngx-intl-tel-input
                          id="register-enter-phone"
                          [class]="'form-control p-0'"
                          [enableAutoCountrySelect]="false"
                          [enablePlaceholder]="true"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.France"
                          maxLength="15"
                          [tooltipField]="TooltipLabel.Name"
                          [phoneValidation]="true"
                          [separateDialCode]="true"
                          formControlName="phone"
                          [ngClass]="{ 'is-invalid': getEntFormControl('phone').invalid && (getEntFormControl('phone').dirty || getEntFormControl('phone').touched) }"
                        ></ngx-intl-tel-input>
                      </div>

                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-contact-phone" class="required">Mobile professionnel</label>
                        <ngx-intl-tel-input
                          id="register-enter-contact-phone"
                          [class]="'form-control p-0'"
                          [enableAutoCountrySelect]="false"
                          [enablePlaceholder]="true"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.France"
                          maxLength="15"
                          [tooltipField]="TooltipLabel.Name"
                          [phoneValidation]="true"
                          [separateDialCode]="true"
                          formControlName="contactPhone"
                          [ngClass]="{ 'is-invalid': getEntFormControl('contactPhone').invalid && (getEntFormControl('contactPhone').dirty || getEntFormControl('contactPhone').touched) }"
                        ></ngx-intl-tel-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="register-form">
                        <label for="register-enter-job" class="required">Poste occupé</label>
                        <input type="text" formControlName="job" class="form-control" id="register-enter-job"
                               [ngClass]="{ 'is-invalid': getEntFormControl('job').invalid && (getEntFormControl('job').dirty || getEntFormControl('job').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="sep"></div>

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-siret" class="required">Siret</label>
                        <input type="number" minlength="14" maxlength="14" mask="00000000000000"
                               formControlName="siret" class="form-control" id="register-enter-siret"
                               [ngClass]="{ 'is-invalid': getEntFormControl('siret').invalid && (getEntFormControl('siret').dirty || getEntFormControl('siret').touched) }">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-name" class="required">Nom de l'entreprise</label>
                        <input type="text" formControlName="name" class="form-control" id="register-enter-name"
                               [ngClass]="{ 'is-invalid': getEntFormControl('name').invalid && (getEntFormControl('name').dirty || getEntFormControl('name').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="register-form">
                        <label for="register-enter-address" class="required">Adresse</label>
                        <input type="text" formControlName="address" class="form-control" id="register-enter-address"
                               [ngClass]="{ 'is-invalid': getEntFormControl('address').invalid && (getEntFormControl('address').dirty || getEntFormControl('address').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="register-form">
                        <label for="register-enter-address2">Complément d'adresse</label>
                        <input type="text" formControlName="address2" class="form-control"
                               id="register-enter-address2"
                               [ngClass]="{ 'is-invalid': getEntFormControl('address2').invalid && (getEntFormControl('address2').dirty || getEntFormControl('address2').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-zipcode" class="required">Code postal</label>
                        <input type="text" formControlName="zip_code" placeholder="00000" minlength="5" maxlength="5"
                               class="form-control" id="register-enter-zipcode"
                               [ngClass]="{ 'is-invalid': getEntFormControl('zip_code').invalid && (getEntFormControl('zip_code').dirty || getEntFormControl('zip_code').touched) }">
                      </div>
                    </div>

                    <div class="col-md-8 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-city" class="required">Ville</label>
                        <input type="text" formControlName="city" class="form-control" id="register-enter-city"
                               [ngClass]="{ 'is-invalid': getEntFormControl('city').invalid && (getEntFormControl('city').dirty || getEntFormControl('city').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="register-form text-center pt-4 pb-3">
                    <button class="btn btn-primary p-2 action-button" [disabled]="!this.RegisterEnterForm.valid"
                            (click)="createAccount(1)">
                      <span *ngIf="!loaderRegister">Inscription</span>
                      <span *ngIf="loaderRegister"><i class="fa fa-spin fa-spinner"></i></span>
                    </button>
                  </div>

                </ng-container>
              </form>

              <div class="small have-account">
                Vous avez déjà un compte ?
                <span class="redirect-login" (click)="returnToLoginPage()">
                      Connectez-vous
                    </span>
              </div>

            </ng-container>
            <ng-container *ngIf="accPart">
              <div class="card-form-title" style="display: flex; justify-content: space-between;">
                <div>
                  Créer un compte particulier
                </div>
                <div class="button-arrow rotate"
                     *ngIf="accPartStep"
                     (click)="accPartStep = false"
                     title="Retour à la première étape"
                >
                  <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                    <g class="arrow-head">
                      <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor"
                            stroke-width="2"/>
                    </g>
                    <g class="arrow-body">
                      <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="card-form-subtitle">
                Afin de créer un nouveau compte, veuillez compléter le formulaire d'inscription.
              </div>

              <form [formGroup]="RegisterPartForm">
                <ng-container *ngIf="!accPartStep">
                  <div class="register-form">
                    <label for="register-part-email" class="required">Email</label>
                    <input type="email" formControlName="email" class="form-control" id="register-part-email"
                           placeholder="exemple@mail.fr" (keyup)="checkEmail()"
                           [ngClass]="{ 'is-invalid': getPartFormControl('email').invalid && (getPartFormControl('email').dirty || getPartFormControl('email').touched) }">
                    <div
                      *ngIf="getPartFormControl('email').invalid && (getPartFormControl('email').dirty || getPartFormControl('email').touched)"
                      class="invalid-feedback">
                      {{ getErrorMessage("rpartEmail") }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 col-sm-12">
                      <div class="register-form">
                        <label for="register-enter-lastname" class="required">Civilité</label>
                        <ng-select formControlName="civility" bindValue="id" bindLabel="name"
                                   [items]="[{id : 'M.', name : 'M.'}, {id : 'Mme', name : 'Mme'}]"
                                   id="register-part-civility"
                                   [ngClass]="{ 'is-invalid': getPartFormControl('civility').invalid && (getPartFormControl('civility').dirty || getPartFormControl('civility').touched) }"></ng-select>

                      </div>
                    </div>
                    <div class="col-md-5 col-sm-12">
                      <div class="register-form">
                        <label for="register-part-lastname" class="required">Nom</label>
                        <input type="text" formControlName="lastname" class="form-control" id="register-part-lastname"
                               [ngClass]="{ 'is-invalid': getPartFormControl('lastname').invalid && (getPartFormControl('lastname').dirty || getPartFormControl('lastname').touched) }">
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="register-form">
                        <label for="register-part-firstname" class="required">Prénom</label>
                        <input type="text" formControlName="firstname" class="form-control"
                               id="register-part-firstname"
                               [ngClass]="{ 'is-invalid': getPartFormControl('firstname').invalid && (getPartFormControl('firstname').dirty || getPartFormControl('firstname').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="sep"></div>

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-part-password" class="required">Mot de passe</label>
                        <input type="password" formControlName="password" class="form-control"
                               id="register-part-password"
                               (keyup)="cleanPasswordPart()"
                               [ngClass]="{ 'is-invalid': getPartFormControl('password').invalid && (getPartFormControl('password').dirty || getPartFormControl('password').touched) }">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="register-form">
                        <label for="register-part-passwordr" class="required">Ressaisir le mot de passe</label>
                        <input type="password" formControlName="passwordr" class="form-control"
                               id="register-part-passwordr"
                               (keyup)="cleanPasswordPart()"
                               [ngClass]="{ 'is-invalid': getPartFormControl('passwordr').invalid && (getPartFormControl('passwordr').dirty || getPartFormControl('passwordr').touched) }">
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        *ngIf="getPartFormControl('password').invalid && (getPartFormControl('password').dirty || getPartFormControl('password').touched)"
                        style="font-size:0.8em;" class="text-danger">
                        {{ getPasswordError(getPartFormControl('password')) }}
                      </div>
                      <div
                        *ngIf="(getPartFormControl('passwordr').value !== '' && getPartFormControl('password').value !== '') && (getPartFormControl('passwordr').value !== getPartFormControl('password').value)"
                        style="font-size:0.8em;" class="text-danger">
                        Les mots de passes ne sont pas identiques
                      </div>
                    </div>
                  </div>

                  <div class="register-form text-center pt-4 pb-3">
                    <button class="btn btn-primary p-2 action-button" (click)="registerPartStepTwo()"
                            [disabled]="checkFirstPartUserValid()">
                      <span *ngIf="!loaderRegister">Inscription</span>
                      <span *ngIf="loaderRegister"><i class="fa fa-spin fa-spinner"></i></span>
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="accPartStep">
                  <div class="row">
                    <div class="col-12">
                      <div class="register-form">
                        <label for="register-part-address" class="required">Adresse</label>
                        <input type="text" formControlName="address" class="form-control" id="register-part-address"
                               [ngClass]="{ 'is-invalid': getPartFormControl('address').invalid && (getPartFormControl('address').dirty || getPartFormControl('address').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="register-form">
                        <label for="register-part-address2">Complément d'adresse</label>
                        <input type="text" formControlName="address2" class="form-control" id="register-part-address2"
                               [ngClass]="{ 'is-invalid': getPartFormControl('address2').invalid && (getPartFormControl('address2').dirty || getPartFormControl('address2').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <div class="register-form">
                        <label for="register-part-zipcode" class="required">Code postal</label>
                        <input type="text" formControlName="zip_code" placeholder="00000" minlength="5" maxlength="5"
                               class="form-control" id="register-part-zipcode"
                               [ngClass]="{ 'is-invalid': getPartFormControl('zip_code').invalid && (getPartFormControl('zip_code').dirty || getPartFormControl('zip_code').touched) }">
                      </div>
                    </div>

                    <div class="col-md-8 col-sm-12">
                      <div class="register-form">
                        <label for="register-part-city" class="required">Ville</label>
                        <input type="text" formControlName="city" class="form-control" id="register-part-city"
                               [ngClass]="{ 'is-invalid': getPartFormControl('city').invalid && (getPartFormControl('city').dirty || getPartFormControl('city').touched) }">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="register-form">
                        <label for="register-part-phone" class="required">Téléphone</label>
                        <ngx-intl-tel-input
                          id="register-part-phone"
                          [class]="'form-control p-0'"
                          [enableAutoCountrySelect]="false"
                          [enablePlaceholder]="true"
                          [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.France"
                          maxLength="15"
                          [tooltipField]="TooltipLabel.Name"
                          [phoneValidation]="true"
                          [separateDialCode]="true"
                          formControlName="phone"
                          [ngClass]="{ 'is-invalid': getPartFormControl('phone').invalid && (getPartFormControl('phone').dirty || getPartFormControl('phone').touched) }"
                        ></ngx-intl-tel-input>
                      </div>
                    </div>
                  </div>

                  <div class="register-form text-center pt-4 pb-3">
                    <button [ngStyle]="{width : '150px'}" class="btn btn-primary p-2" (click)="createAccount(0)"
                            [disabled]="!RegisterPartForm.valid">
                      <span *ngIf="!loaderRegister">Inscription</span>
                      <span *ngIf="loaderRegister"><i class="fa fa-spin fa-spinner"></i></span>
                    </button>
                  </div>
                </ng-container>
              </form>

              <div class="small have-account">
                Vous avez déjà un compte ?
                <span class="redirect-login" (click)="returnToLoginPage()">
                      Connectez-vous
                    </span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 m-O">
      <div class="card d-flex" style="display:flex;padding:10px 20px;height:45px;flex-direction:row;">
        <div class="d-block d-lg-none"><a href="https://batisafe.fr" target="_blank">&copy; Batisafe.fr</a></div>
        <div class="w-50 d-lg-block d-none"><a href="https://batisafe.fr" target="_blank">Batisafe.fr</a> - Tous droits
          réservés
        </div>
        <div class="text-right flex-grow-1 d-block d-lg-none">
          <a href="/mentions">Légales</a> | <a href="/cgu">CGU</a>
        </div>
        <div class="text-right flex-grow-1 d-none d-lg-block">
          <a href="/mentions" class="mr-2">Mentions légales</a> | <a href="/cgu" class="ml-2">Conditions générales
          d'utilisation</a>
        </div>
      </div>
    </div>
  </div>
</div>
