<div class="row mr-0 ml-0 mt-3">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> Mes fichiers </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Liste des fichiers </div>
    </div>
  </div>
</div>


<div class="row ml-4 mr-4">
  <div class="col-12">
    <app-list
      [columns]="columns"
      [items]="affairs"
      [total]="total"
      [pages]="pages"
      [page]="page"
      [hideAdd]="true"
      [loader]="loader"
      [hideAction]="true"
      (returnEvent)="updateList($event)" class="w-100"></app-list>
  </div>
</div>
