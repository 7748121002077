import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../../http.cancel.service';

import * as moment from 'moment';

import { CategoryVeilleRequestService } from './category-veille.request.service';

@Injectable()
export class CategoryVeilleResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private categoryService: CategoryVeilleRequestService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      this.categoryService.getCategoryVeille().subscribe((response: any) => {
        data = response;
        this.requestInProgress.next(false);
      });
    });
  }
}
