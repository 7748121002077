import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

export class FormChecker {
  private readonly originalValue: any;
  private lastNotify: boolean;
  constructor(
    private form: FormGroup,
    private replaySubject?: ReplaySubject<any>
  ) {
    this.originalValue = JSON.stringify(this.form.value);
    if (this.form.valid) {
      this.replaySubject.next(true);
      this.lastNotify = true;
    }
    this.form.valueChanges.subscribe(() => {
      const currentValue = JSON.stringify(this.form.value);
      if (this.originalValue !== currentValue) {
        this.markFormGroupUntouched(this.form);
        if (this.replaySubject && (this.lastNotify == null || this.lastNotify === true)) {
          this.lastNotify = false;
          this.replaySubject.next(false);
        }
      } else {
        if (this.replaySubject) {
          this.replaySubject.next(true);
        } else {
          this.form.markAsPristine();
        }
        this.lastNotify = true;
      }
    });
  }
  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupUntouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      if (control.dirty) {
        control.markAsTouched();
      }
      if (control.controls) {
        this.markFormGroupUntouched(control);
      }
    });
  }
}
