import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class ExamplesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public getExamples(search = '', sort = '', order = '', limit = 0, page = 1, token = null) {
    return this.get('examples?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page, {}, token);
  }

  public getExample(exampleId, token = null) {
    return this.get('examples/' + exampleId, {}, token);
  }

  public createExample(example) {
    return this.post('examples', { example });
  }

  public updateExample(exampleId, example) {
    return this.put('examples/' + exampleId, { example });
  }

  public deleteExample(exampleId) {
    return this.delete('examples/' + exampleId);
  }
}
