import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AllExamplesComponent } from './all-examples.component';
import { ListModule } from '../../../core/list/list.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    ListModule,
    ConfirmModalModule
  ],
  declarations: [AllExamplesComponent]
})

export class AllExamplesModule {}
