import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';

import * as moment from 'moment';

import ExamplesResponse from '../../interfaces/response/examples.response';
import { ExamplesRequestService } from '../examples/examples.request.service';
import { AccountRequestService } from './account.request.service';
import Invoice from '../../interfaces/invoice/invoice';
import UserMyAccountResponse from '../../interfaces/user/response/my_account';

@Injectable()
export class AccountResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private accountService: AccountRequestService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });


      this.requestInProgress.next(true);
      this.accountService.getAccountInfo().subscribe((response: UserMyAccountResponse) => {
        data = response;
        this.requestInProgress.next(false);
      });

      // this.requestInProgress.next(true);
      // this.examplesService.getExample(this.token).subscribe((response: ExamplesResponse) => {
      //   data.example = response.example;
      //   this.requestInProgress.next(false);
      // });
    });
  }
}
