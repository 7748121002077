/**
 * Import Angular modules
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

/**
 * Import external libraries
 */
import * as moment from 'moment';
import * as _ from 'lodash';

/**
 * Import interfaces
 */
import Notification from '../interfaces/notification/notification';
import NotificationResponse from '../interfaces/response/notification.response';
import Response from '../interfaces/response/response';

/**
 * Import request services
 */
import { NotificationRequestService } from './notification.request.service';

/**
 * Import services
 */
import { NotificationService } from '../services/notification/notification.service';
import { matDialogAnimations } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.scss'],
  animations: [
    trigger('notifications', [
      transition(':enter', [
        query('@notification', stagger(200, animateChild()), {optional: true})
      ])
    ]),
    trigger('notification', [
      transition(':enter', [
        style({transform: 'scale(0.5)', opacity: 0}),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({transform: 'scale(1)', opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'scale(1)', opacity: 1, height: '*'}),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px'
          }))
      ])
    ])
  ]
})
export class NotificationWidgetComponent implements OnInit {
  @Input() color: any;
  @Output() returnEvent = new EventEmitter<any>();

  objectKeys = Object.keys;
  moment = moment;

  /**
   * Notification widget constructor
   *
   * @param {NotificationService} notificationService
   * @param {DomSanitizer} sanitizer
   * @param {Router} _router
   * @param {NotificationRequestService} _notificationRequest
   */
  constructor(
    public notificationService: NotificationService,
    public sanitizer: DomSanitizer,
    private _router: Router,
    private _notificationRequest: NotificationRequestService
  ) {
    moment.locale('fr');
  }

  ngOnInit(): void {
    if (this.color === undefined) {
      this.color = '#1A77B9'; // Default color
    }
    this.getNotifications(); // Retrieve the xx first notifications
  }

  /**
   * Return the action done
   *
   * @param {string} action
   * @param value
   */
  returnAction(action: string, value: any) {
    this.returnEvent.emit({action, value});
  }

  /**
   * Get last notifications
   */
  getNotifications() {
    this.notificationService.loader = true;
    this._notificationRequest.getAllNotifications('', 'created', 'desc', 10, 1)
      .subscribe((response: NotificationResponse) => {
        this.notificationService.count = response.specificCount;
        this.notificationService.pages = response.pages;
        this.notificationService.notifications =
          _.groupBy(response.datas, (result) => moment(result.created.date).startOf('month').format('MMMM YYYY'));
        this.notificationService.loader = false;
      });
  }

  /**
   * Mark notification as seen
   *
   * @param {Notification} notification
   */
  notificationSeen(notification: Notification) {
    notification.isViewed = true;
    this.notificationService.count = this.notificationService.count - 1;
    // this.notificationService.removeNotificationFromNotSeen(notification);
    this._notificationRequest.updateNotification(notification).subscribe(() => {});
  }

  /**
   * Mark all notifications as seen
   */
  markAllSeen() {
    if (this.notificationService.count > 0) {
      this._notificationRequest.markAllNotificationsAsSeen()
        .subscribe((response: Response) => {
          if (response.success) {
            this.notificationService.count = 0;
            // this.notificationService.removeNotificationFromNotSeen(null, true);
            this.notificationService.markAllNotificationsAsSeen();
          }
        });
    }
  }

  /**
   * Redirect to notifications center
   */
  goNotificationsCenter() {
    this._router.navigateByUrl('/notifications');
  }

  getNotViewed(notification) {
    const temp = [];
    notification.forEach(value => {
      if (!value.isViewed) {
        temp.push(value);
      }
    });
    return temp;
  }
}
