<div class="row h-100" style="min-height:700px;position:relative;max-height:100%;">
  <div *ngIf="(!isMobile && preview) || !preview" [ngClass]="preview !== false || loaderPreview ? 'col-6 h-100 p-3 d-flex flex-column card pr-3' : 'col-12 card p-3 h-100 d-flex flex-column'" style="max-height:100%;">

    <div class="d-flex mb-2">
      <div class="search-bar col pl-0">
        <div class="input-group mb-2">
          <label for="search-bar"></label>
          <input type="text" class="form-control border-radius" [(ngModel)]="search" (keyup)="searchData()" aria-label="Barre de recherche" placeholder="Rechercher" id="search-bar">
          <div class="input-group-append">
          <span class="input-group-text">
            <mat-icon aria-hidden="false" aria-label="Rechercher">search</mat-icon>
          </span>
          </div>
        </div>
      </div>

      <div class="filter col-auto" *ngIf="!hideAdd">
        <a mat-mini-fab (click)="createEventAction()" *ngIf="createEvent" aria-label="Ajouter un utilisateur">
          <mat-icon aria-hidden="false" aria-label="Ajouter">add</mat-icon>
        </a>
        <a mat-mini-fab routerLink="create" *ngIf="!createEvent" aria-label="Ajouter un utilisateur">
          <mat-icon aria-hidden="false" aria-label="Ajouter">add</mat-icon>
        </a>
      </div>

      <div class="filter dropdown col-auto pr-0 pl-0" autoClose="outside">
        <button mat-mini-fab class="dropdown-ellipses dropdown-toggle" style="height:36px;" [disabled]="!columns.length" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <mat-icon aria-hidden="false" aria-label="Filtrer">filter_list</mat-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right filter-height" style="cursor:pointer;">
          <fieldset class="checkbox dropdown-item href" style="font-size:0.8em;" *ngFor="let table of columns" (click)="filterTable($event, table)">
            <div *ngIf="table.checked">
              <i class="fa fa-check-circle text-success"></i> {{ table.label }}
            </div>
            <div *ngIf="!table.checked">
              <i class="fa fa-circle"></i> {{ table.label }}
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="flex-grow-1" style="max-height:calc(100% - 90px);position:relative;" [perfectScrollbar]="config">
      <div class="table-responsive">
        <table class="table table-striped table-bordered dataTable list-table" cellspacing="0">
          <thead>
          <tr>
            <ng-container *ngIf="!hideSort">
              <th *ngFor="let column of columns"
                  (click)="sortBy(column.sort)"
                  [hidden]="!column.checked"
                  [ngClass]="sort === column.sort ? order == 'asc' ? 'sorting_asc' : 'sorting_desc' : 'sorting'">
                {{ column.showLabel ? column.label : '' }}
              </th>
            </ng-container>
            <ng-container *ngIf="hideSort">
              <th *ngFor="let column of columns"
                  [hidden]="!column.checked">
                {{ column.showLabel ? column.label : '' }}
              </th>
            </ng-container>
            <th *ngIf="!hideAction"></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="items !== undefined && items.length && !loader">
            <tr *ngFor="let item of items" class="table-line">
              <!-- If the data as custom fields -->
              <ng-container *ngIf="hasCustomField">
                <td
                  *ngFor="let column of columns"
                  [hidden]="!column.checked"
                  [width]="column.type === 'icon' ? '20' : ''"
                  [routerLink]="column.linkData ? [item.id + '/' + column.linkData] : []"
                  [class.link-cursor]="column.linkData"
                  (click)="select(item)"
                >
                  {{ getDefaultType(column.type) ? getProperty(item, column) : '' }}

                  <div *ngIf="column.type === 'status' && item.customField[column.property] !== undefined" class="status-container">
                    <div [ngStyle]="{background : item.customField[column.property]['color']}" class="status-chip">
                      {{ item.customField[column.property]['name'] }}
                    </div>
                  </div>

                  {{ column.type === 'date' ? (getProperty(item, column) | date : 'dd/MM/yyyy') : '' }}
                  <div *ngIf="column.type === 'currency'">{{(getProperty(item, column))}} €</div>
                  {{ column.type === 'boolean' ? (getProperty(item, column) ? 'Oui' : 'Non' ) : '' }}
                  <i *ngIf="column.type === 'icon'" [ngClass]="'fa fa-' + getProperty(item, column)"></i>
                  <img [src]="environment.SERVER_HOST + column.linkImg + getProperty(item, column)" alt="" *ngIf="column.type === 'img' && getProperty(item, column.property)" height="60">
                  <img [src]="column.defaultImg" alt="" *ngIf="column.type === 'img' && !getProperty(item, column)" height="60">
                </td>
              </ng-container>

              <!-- If the data as not custom fields -->
              <ng-container *ngIf="!hasCustomField">
                <td *ngFor="let column of columns" (click)="select(item)">
                  <!-- For notifications -->
                  <ng-container *ngIf="column.property === 'isViewed'">
                    {{ item[column.property] === true ? 'Oui' : 'Non' }}
                  </ng-container>

                  <!-- For generic -->
                  <ng-container *ngIf="(isTrainingList && column.property !== 'status') && column.property !== 'isViewed'">
                    {{ item[column.property] }}
                  </ng-container>

                  <ng-container *ngIf="isTrainingList && column.property === 'status'">
                  <span [ngClass]="'training-status-' + item[column.property]['status']">
                    {{ item[column.property]['label'] }}
                  </span>
                  </ng-container>
                </td>
              </ng-container>

              <td class="actions" width="100" *ngIf="!hideAction">
                <!--<a
                  (click)="modalTraining.open(item)"
                  *ngIf="isTrainingList && isTrainingClient"
                  mat-button
                  class="dropdown-item tooltipHtml"
                  title="Liste des participants"
                >
                  <mat-icon aria-hidden="false" aria-label="Liste des participants">
                    supervisor_account
                  </mat-icon>
                </a>-->

                <!--<button title="Editer" (click)="download(item.id)" *ngIf="downloadRequest !== undefined">
                  Télécharger
                </button>-->

                <!--<a mat-button class="dropdown-item tooltipHtml" title="Supprimer" (click)="modal.open(item)">
                  <mat-icon aria-hidden="false" aria-label="Supprimer">delete</mat-icon>
                </a>-->

                <!--<a mat-button class="dropdown-item" [routerLink]="[item.id + '/logs']">
                  <mat-icon aria-hidden="false" aria-label="Historique">history</mat-icon>
                </a>-->
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!items.length && !loader">
            <tr class="no-result" *ngIf="(!items || !items.length) && !loader">
              <td [attr.colspan]="getColSpan()">
                <app-no-result width="200" title="Aucune donnée"></app-no-result>
                <ng-container *ngIf="currentPage === 'trainings' || currentPage === 'quotations'">
                  <div style="display: flex;justify-content: center;margin-top: 1rem;margin-bottom: 1rem;">
                    <ng-container *ngIf="currentPage === 'quotations'">
                      <a mat-button class="d-flex mr-2"
                         style="width: fit-content; background-color: #E6332A; color: white;"
                         aria-label="Nouvelle demande" title="Faire une demande" href="https://www.batiregistre.fr/devis-tarifs" target="_blank">
                        Demander un devis Batiregistre
                      </a>

                      <a mat-button class="d-flex"
                         style="width: fit-content; background-color: #3e79a0; color: white;"
                         aria-label="Nouvelle demande" title="Faire une demande" href="https://www.batisafe.fr/devis-gratuit/formations" target="_blank">
                        Demander un devis Batisafe
                      </a>
                    </ng-container>

                    <ng-container *ngIf="currentPage === 'trainings'">
                      <a mat-button class="d-flex"
                         style="width: fit-content; background-color: #3e79a0; color: white;"
                         aria-label="Nouvelle demande" title="Faire une demande" href="https://www.batisafe.fr/formations/formations-sur-mesure" target="_blank">
                        Demander une formation
                      </a>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="loader">
            <tr>
              <td [colSpan]="getColSpan()" class="text-center"><i class="fa fa-spin fa-spinner"></i></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-4 mb-3">
      <app-pagination *ngIf="items" [page]="page" [limit]="limit" [pageSize]="items.length" [pages]="pages" [total]="total" (returnEvent)="changePagination($event)"></app-pagination>
    </div>
  </div>

  <!--
  ////////////////
  PDF Preview
  ///////////////
  -->
  <div [ngClass]="isMobile ? 'col-12 pl-3' : 'col-6 pl-3'" class="d-flex flex-column" id="preview-div" *ngIf="preview !== false || loaderPreview">
    <div class="card" style="min-height:100%;">
      <div class="d-flex preview-header">
        <div class="mr-4 link-cursor" style="line-height: 36px;" (click)="closePreview()">
          <mat-icon class="preview-close-icon" aria-hidden="false" aria-label="Masquer l'aperçu">close</mat-icon>
        </div>
        <div class="flex-grow-1 preview-name">
          {{ preview === null ? 'Chargement en cours' : objectPreview !== undefined ? (objectPreview['customField']['number'] + (objectPreview['customField']['name'] ? ' - ' + objectPreview['customField']['name'] : '')) : 'Chargement en cours' }}
        </div>
        <div *ngIf="preview !== false" style="width:40px;">
          <ng-container *ngIf="loaderPreviewMenu">
            <div style="display: flex; justify-content: center;">
              <mat-progress-spinner mode="indeterminate" [diameter]="30">
              </mat-progress-spinner>
            </div>
          </ng-container>

          <ng-container *ngIf="!loaderPreviewMenu && !loaderPreview">
            <button mat-button class="d-flex tooltipHtml pr-0" data-toggle="dropdown" data-placement="bottom"
                    aria-label="Menu de prévisualisation" title="Menu de prévisualisation">
              <mat-icon aria-hidden="false" aria-label="Filtrer">more_vert</mat-icon>
            </button>
          </ng-container>

          <span *ngIf="!loaderPreviewMenu && !loaderPreview" class="dropdown-menu dropdown-menu-right">
          <ng-container *ngIf="loaderPreviewMenu">
            <div style="display: flex; justify-content: center; padding-top: .8rem; padding-bottom: .8rem;">
              <mat-progress-spinner mode="indeterminate" [diameter]="30">
              </mat-progress-spinner>
            </div>
          </ng-container>

          <ng-container *ngIf="!loaderPreviewMenu && !loaderPreview && objectPreview !== undefined">
            <ng-container *ngFor="let nav of menu">
              <a class="dropdown-item href list-pointer" *ngIf="isVisible(objectPreview['customField'], nav)"
                 (click)="emitMenu(nav)" style="font-size: .9em;"
                 [ngStyle]="nav['color'] !== 'default' ? {'color' : nav['color']} : {}">
                <mat-icon style="margin-right: 7px; padding-top: 2px;" class="align-icon" aria-hidden="false"
                          aria-label="Filtrer">{{ nav['icon'] }}</mat-icon>
                {{ nav['name'] }}
              </a>
            </ng-container>
          </ng-container>
        </span>
        </div>
      </div>

      <ng-container *ngIf="loaderPreview">
        <div>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="text-center pt-5 d-flex justify-content-center align-items-center flex-column h-100">
          <div class="text-center d-flex justify-content-center align-items-center">
            <mat-progress-spinner mode="indeterminate" [diameter]="30"></mat-progress-spinner>
          </div>
          <div>Chargement en cours</div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loaderPreview">
        <div *ngIf="isMobile" class="d-flex h-100 align-items-center flex-column">
          <div class="p-4">Prévisualisation non disponible sur mobile</div>
          <button class="btn btn-success" (click)="download()">Télécharger</button>
        </div>
        <iframe *ngIf="preview !== null && !isMobile" class="flex-grow-1 w-100" [src]="preview" ></iframe>
      </ng-container>
    </div>
  </div>

</div>
<app-confirm-modal [type]="'delete_data'" #deleteModal>{{ bindModal(deleteModal) }}</app-confirm-modal>
