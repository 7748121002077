<div class="row mr-0 ml-0 mt-3">
  <div class="col-12 d-flex">
    <div class="flex-grow-1 ml-2">
      <div class="title-header mb-0">Mes tickets </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Liste de mes tickets </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mr-2">
      <div class="d-flex mt-sm-0">
        <div class="dl">
          <button type="submit" class="btn btn-primary" [routerLink]="['create']">
            <span>Ajouter un ticket</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row ml-2 mr-2">
  <div class="col-12">
    <div class="alert-ticket">
      <i class="fa fa-exclamation d-inline-block mr-2"></i> Vous souhaitez nous poser une question, demander un devis, témoigner, réclamer, féliciter,… ? Merci de nous en faire part via ce système de tickets.
    </div>
  </div>
</div>



<div class="card card-scroll ml-4 mr-4">
  <div class="card-body">

    <app-ticket-list
              [columns]="columns"
              [items]="tickets"
              [total]="total"
              [pages]="pages"
              [page]="page"
              [hideAdd]="true"
              [loader]="loader"
              [hideAction]="false"
              [hasCustomField]="false"
              (returnEvent)="updateList($event)" #list
              class="w-100">{{ bindList(list) }}
    </app-ticket-list>
  </div>
</div>
