import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class TicketsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public getTickets(search = '', sort = '', order = '', limit = 0, page = 1, token = null) {
    return this.get('api/tickets?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page, {}, token);
  }

  public getTicket(ticketId, token = null) {
    return this.get('api/get-ticket/' + ticketId, {}, token);
  }

  public createTicket(ticket) {
    return this.post('api/create-ticket', { ticket });
  }

  public deleteTicket(ticketId) {
    return this.delete('api/delete-ticket/' + ticketId);
  }

  public createComment(ticketId, comment) {
    return this.post('api/create-comment/' + ticketId, { comment });
  }

  closeTicket(ticketId: number) {
    return this.put('api/close-ticket/' + ticketId, {});
  }

  markAsRead(ticketId) {
    return this.put('api/mark-as-read/' + ticketId, {});
  }

  downloadFile(fileId, token = null) {
    return this.get('api/download-comment-file/' + fileId, {}, token);
  }
}
