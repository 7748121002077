import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Sanitizer,
  SimpleChanges
} from '@angular/core';

import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

import { environment } from '../../../environments/environment';

import Column from './column';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PreviewService } from '../services/helper/preview.service';
import PreviewMenu from '../interfaces/preview_menu';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() columns: Column[];
  @Input() items: any[];
  @Output() returnEvent = new EventEmitter<any>();

  @Input() search = '';
  @Input() sort = 'id';
  @Input() order = 'asc';
  @Input() limit = 0;
  @Input() page = 1;
  @Input() total = 0;
  @Input() pages = 1;
  @Input() hideAction: boolean;
  @Input() isTrainingList: boolean;
  @Input() isTrainingClient: boolean;
  @Input() hideAdd;
  @Input() hideSort = false;
  @Input() preview;
  @Output() previewChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() menu;
  @Input() createEvent;
  @Input() loader;
  @Input() loaderPreview: boolean;
  @Input() loaderPreviewMenu: boolean;
  @Input() hasCustomField: boolean;
  @Input() currentPage: string;

  environment = environment;
  modal: ConfirmModalComponent;
  public config: PerfectScrollbarConfigInterface = {};

  timeout;
  objectPreview: any;
  base64: any;
  isMobile: boolean;
  id: any;

  constructor(
    public sanitizer: DomSanitizer,
    private _previewService: PreviewService
  ) { }

  ngOnInit() {
    if (this.hasCustomField === undefined) {
      this.hasCustomField = true;
    }

    if (this.preview === undefined) {
      this.preview = false;
    }

    if (this.hideAction === undefined) {
      this.hideAction = false;
    }

    if (this.hideAdd === undefined) {
      this.hideAdd = false;
    }

    if (this.isTrainingList === undefined) {
      this.isTrainingList = false;
    }

    if (this.isTrainingClient === undefined) {
      this.isTrainingClient = false;
    }

    this.isMobile = (document.body.offsetWidth < 992);
  }

  ngAfterViewInit() {
    this.modal.eventOnClose.subscribe(event => {
      if (event) {
        this.returnEvent.emit({ action: 'delete', value: event });
      }
    });
  }

  ngOnDestroy() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    // @ts-ignore
    for (const tooltip of tooltips) {
      tooltip.remove();
    }
  }

  sortBy(property: string) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.order = 'asc';
      this.sort = property;
    }
    this.returnEvent.emit({ action: 'sort', value: property, order: this.order });
  }

  changePagination(data) {
    this.returnEvent.emit(data);
  }

  filterTable(event, table) {
    table.checked = !table.checked;
    event.stopPropagation();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.returnEvent.emit({ action: 'search', value: this.search });
    }, 500);
  }

  bindModal(deleteModal: ConfirmModalComponent) {
    this.modal = deleteModal;
  }

  getColSpan() {
    let sum = 2;
    this.columns.forEach(column => {
      if (column.checked) {
        sum++;
      }
    });
    return sum;
  }

  getProperty(item: any, object: any) {
    if (typeof object.property === 'string') {
      if (object.property === 'date') {
        return item.customField[object.property].date;
      } else if (object.type === 'agencies') {
        return item.customField[object.property].label;
      }

      if (item.customField !== undefined) {
        if (typeof item.customField[object.property] === 'object' && item.customField[object.property] !== null) {
          return moment(item.customField[object.property].date).format('DD/MM/YYYY');
        } else if (object.type === 'currency') {
          return parseFloat(item.customField[object.property]).toFixed(2).replace('.', ',');
        } else {
          return item.customField[object.property];
        }
      }

    } else {
      let column = '';
      object.property.forEach(prop => {
        if (column) {
          column = column[prop];
        } else {
          column = item.customField[prop];
        }
      });
      return column;
    }
  }

  getDefaultType(type) {
    if (type === 'text' ||
      type === 'select' ||
      type === 'select_link' ||
      type === 'select_array' ||
      type === 'users' ||
      type === 'user_signed' ||
      type === 'agencies' ||
      type === 'number_auto' ||
      type === 'number' ||
      type === 'percent' ||
      type === 'editor') {
      return true;
    }

    return false;
  }

  clickColumn(event) {
  }

  emitMenu(id, object = null) {
    this.returnEvent.emit({ action: id['callback'], value: (object === null) ? this.objectPreview : object });
  }

  select(item: any) {
    this.objectPreview = item;
    this.returnEvent.emit({ action: 'select', value: item });
  }

  closePreview() {
    this.preview = false;
    this.previewChange.emit(this.preview);
  }

  /**
   * Display or not the menus
   *
   * @param objectPreviewElement
   * @param nav
   */
  isVisible(objectPreviewElement: any, nav: PreviewMenu) {
    if (nav.cases_display && nav.cases_display.length) {
      for (const caseDisplay of nav.cases_display) {
        return objectPreviewElement[`status`][`name`] === caseDisplay;
      }
    }
    // TODO: Remove this if everything is ok
    if (nav.condition_equal && nav.condition_equal !== '') {
      return objectPreviewElement[`status`][`name`] !== nav.condition_equal;
    }
    if (nav.condition_not_equal && nav.condition_not_equal !== '') {
      return objectPreviewElement[`status`][`name`] !== nav.condition_not_equal;
    }

    // TODO: See invoices to remove this
    if (nav.condition_not_equal !== undefined) {
      if (objectPreviewElement[nav.condition_name] !== undefined) {
        if (typeof objectPreviewElement[nav.condition_name] === 'string') {
          return objectPreviewElement[nav.condition_name] !== nav.condition_not_equal;
        } else if (typeof objectPreviewElement[nav.condition_name] === 'object') {
          if (objectPreviewElement[nav.condition_name].value !== undefined) {
            return objectPreviewElement[nav.condition_name].value !== nav.condition_not_equal;
          } else if (objectPreviewElement[nav.condition_name].name !== undefined) {
            return objectPreviewElement[nav.condition_name].name !== nav.condition_not_equal;
          }
        }
      }
    }
    return true;
  }

  createEventAction() {
    this.returnEvent.emit({ action: 'create'});
  }

  showPreview(preview) {
    this.isMobile = (document.body.offsetWidth < 992);
    this.preview = preview;
  }

  formatBase64(preview) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(preview.replace('octet-stream', 'pdf'));
  }

  download(data = null) {
    if (this.isTrainingList && this.isTrainingClient) {
      this.returnEvent.emit({
        action: 'download-agreement',
        value: {
          training_id: data.training_entity.id,
          training_date_id: data.training_date_entity.id,
          quotation_id: data.quotation.id
        }
      });
    } else {
      this.returnEvent.emit({ action: 'download', value : {id : this.id}});
    }
  }
}
