import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UsersRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public getUsers(search = null, sort = null, order = null, limit = null, page = null, token = null) {
    return this.get('users?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page, {}, token);
  }

  public getUser(userId, token = null) {
    return this.get('users/' + userId, {}, token);
  }

  public createUser(user) {
    return this.post('users', { user });
  }

  public updateUser(userId, user) {
    return this.put('users/' + userId, { user });
  }

  public deleteUser(userId) {
    return this.delete('users/' + userId);
  }
}
