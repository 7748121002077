import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { MatIconModule } from '@angular/material/icon';
import { NoResultModule } from '../../core/no-result/no-result.module';
import { DashboardResolver } from '../../core/services/dashboard/dashboard.resolver';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NoResultModule,
    RouterModule,
    MatTooltipModule,
    MatButtonModule
  ],
  declarations: [DashboardComponent],
  providers: [
    DashboardResolver
  ]
})

export class DashboardModule {}
