<div id="confirm-modal" class="modal modal-confirm fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">Ajouter un dossier</h1>
          </div>
        </div>

        <button type="button" class="close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body">
        <form [formGroup]="modalForm">
        <div class="mb-3">
          <label class="d-block font-weight-bold mb-0" required>Nom du dossier</label>
          <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': name.invalid && (name.dirty || name.touched) }"  />
        </div>
        </form>
      </div>

      <div class="modal-footer">
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 mr-2" (click)="cancel()">
          Annuler
        </button>
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 ml-2" [disabled]="modalForm.invalid" (click)="validate()">
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
