import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SurveyRequestService } from './survey.request.service';

import Survey from '../interfaces/survey';
import SurveyCategory from '../interfaces/survey_category';
import SurveyQuestion from '../interfaces/survey_question';

@Injectable()
export class SurveyService {
  private _survey?: Survey;
  private _surveyCategory?: SurveyCategory;
  private _surveyQuestions?: SurveyQuestion[];

  constructor(
    private _router: Router,
    private _surveyRequestService: SurveyRequestService
    ) {
  }
  get survey(): Survey {
    return this._survey;
  }

  set survey(value: Survey) {
    this._survey = value;
  }

  get surveyCategory(): SurveyCategory {
    return this._surveyCategory;
  }

  set surveyCategory(value: SurveyCategory) {
    this._surveyCategory = value;
  }

  get surveyQuestions(): SurveyQuestion[] {
    return this._surveyQuestions;
  }

  set surveyQuestions(value: SurveyQuestion[]) {
    this._surveyQuestions = value;
  }

  clearSurvey(): void {
    this._survey = null;
  }

  clearSurveyCategory(): void {
    this._surveyCategory = null;
  }

  clearSurveyQuestions(): void {
    this._surveyQuestions = null;
  }

  /**
   * Update a specific question at the given index
   *
   * @param index The index to update
   * @param value The new value
   */
  updateSurveyQuestion(index: number, value: SurveyQuestion): void {
    this._surveyQuestions[index] = value;
  }

  /**
   * Add a new question in survey questions' array
   *
   * @param newQuestion The new question to insert
   * @param beginning If the question need to be inserted at the beginning of array
   */
  addSurveyQuestionBeginning(newQuestion: SurveyQuestion, beginning: boolean = false): SurveyQuestion[] {
    if (beginning) {
      this._surveyQuestions.unshift(newQuestion);
    } else {
      this._surveyQuestions.push(newQuestion);
    }
    return this.surveyQuestions;
  }
}
