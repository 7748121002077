<div class="container" style="max-width:100%;display:flex;flex-direction: column;height:100%;">
  <div class="row justify-content-center">
    <div class="col-12 justify-content-center d-flex pt-4 pb-4" style="box-shadow: 0px 1px 3px rgba(7,7,7,0.3);border-top:solid 10px #1a77b9;">
      <div style="background:url('../../../../assets/img/sprite.png');width:136px;height:116px;"></div>
    </div>
  </div>
  <div class="row justify-content-center" style="background:url('../../../../assets/img/pattern.jpg');flex:1;">
    <div class="col-12 pt-5">
      <div class="col-12 text-center"><i class="fa fa-spin fa-spinner"></i></div>
      <div class="col-12 text-center">Activation de votre compte en cours, merci de patienter</div>
    </div>
  </div>
</div>
