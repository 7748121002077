import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';

import { ComponentCanDeactivate } from '../../../core/utils/component-can-deactivate';
import Ticket from '../../../core/interfaces/ticket/ticket';

import { FormBuilder, Validators } from '@angular/forms';
import { TicketsRequestService } from '../../../core/services/tickets/tickets.request.service';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';

@Component({
  selector: 'app-all-comments',
  templateUrl: './all-comments.component.html',
  styleUrls: ['./all-comments.component.scss'],
})
export class AllCommentsComponent implements OnInit, ComponentCanDeactivate, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isIntact = true;
  errors: { general?: { code: string; message: string } };
  ticket: Ticket;
  comments: any[] = [];
  fileToUpload: File | null = null;
  sendFiles: any[] = [];
  ticketId: number;
  notRead = false;

  messageForm = this.fb.group({
    comment: [null, [Validators.required]]
  });

  requestInProgress = new Subject();
  requestCount = 0;
  loader: boolean;

  modal: ConfirmModalComponent;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private notifier: CustomNotifierService,
    private activatedRoute: ActivatedRoute,
    private ticketsServices: TicketsRequestService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(response => {
      if (response.data && response.data.ticket) {
        this.ticket = response.data.ticket;
        this.comments = response.data.comments;
        this.comments.forEach(comment => {
          if (!comment.is_read) {
            this.notRead = true;
          }
        });
      }
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.ticketId = this.activatedRoute.snapshot.params.ticketId;
    this.scrollToBottom();
    if (this.notRead) {
      this.markAsReadComment();
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.isIntact) {
      return true;
    } else {
      const observable = new Observable<boolean>(observer => {
        this.modal.eventOnClose.subscribe(event => {
          if (event) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      });
      this.modal.open(1);
      return observable;
    }
  }

  bindModal(pendingModal: ConfirmModalComponent) {
    this.modal = pendingModal;
  }
  /**
   * Send message to api
   * Then call sendFile if files
   */
  sendMessage() {
    if (this.messageForm.valid) {
      let message: any = {};

      if (this.messageForm.get('comment').value) {
        this.requestInProgress.next(true);

        message = {
          comment: this.messageForm.get('comment').value,
          from_support: false,
          files: this.sendFiles,
        };

        this.ticketsServices.createComment(this.ticketId, message).subscribe((response: any) => {
          this.requestInProgress.next(false);
          if (response.success === true) {
            this.ticket = response.data.ticket;
            this.comments = response.data.comments;
            this.sendFiles = [];
            this.messageForm.reset();
            // this.notifier.successCreate();
          }
        });
      }
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  /**
   * Add files to send when user trigger send message
   */
  joinFile() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      Array.from(fileUpload.files).forEach(file => {
        this._joinFile(file);
      });
      fileUpload.value = '';
    };
    fileUpload.click();
  }
  private _joinFile(data) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(data);
    fileReader.onload = () => {
      this.sendFiles.push({
        name: data.name,
        base64: fileReader.result
      });
    };

  }
  removeFileFromArray(file) {
    const index = this.sendFiles.indexOf(file);
    if (index > -1) {
      this.sendFiles.splice(index, 1);
    }
  }

  downloadFile(file) {
    let request;

    this.requestInProgress.next(true);

    request = this.ticketsServices.downloadFile(file.id);

    request.subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (!response.success) {
        this.notifier.errorDownload();
      } else {
        const link = document.createElement('a');
        link.download = response.datas.datas.filename;
        link.href = 'data:application/octet-stream;base64,' + response.datas.datas.file;
        document.body.append(link);
        link.setAttribute('style', 'display: none');
        link.click();
        link.remove();
      }
    });
  }

  closeTicket(ticketId: number) {
    let request;

    request = this.ticketsServices.closeTicket(ticketId);

    this.requestInProgress.next(true);
    request.subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        this.notifier.successUpdate();
        this.isIntact = true;
        this.router.navigate(['/tickets']).then();
      } else {
        this.errors = response.errors;
      }
    });
  }
  markAsReadComment() {
    let request;

    request = this.ticketsServices.markAsRead(this.ticketId);

    this.requestInProgress.next(true);
    request.subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        this.notRead = false;
      } else {
        this.errors = response.errors;
      }
    });
  }

  autogrowTextHeight() {
    const textArea = document.getElementById('textarea');
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
