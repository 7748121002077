import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface RequestSend {
  id: number;
  cancelPendingRequests: Subject<void>;
}

@Injectable()
export class HttpCancelService {
  private static cancelPendingRequestsArray$: RequestSend[] = [];

  static cancelPendingRequests(token) {
    const found = this.cancelPendingRequestsArray$.findIndex(value => {
      return value.id === token;
    });
    if (found !== -1) {
      this.cancelPendingRequestsArray$[found].cancelPendingRequests.next();
      this.cancelPendingRequestsArray$.splice(found, 1);
    }
  }

  static onCancelPendingRequests(token) {
    const found = this.cancelPendingRequestsArray$.findIndex(value => {
      return value.id === token;
    });
    if (found !== -1) {
      return this.cancelPendingRequestsArray$[found].cancelPendingRequests;
    } else {
      const requestSend = {
        id: token,
        cancelPendingRequests: new Subject<void>()
      };
      this.cancelPendingRequestsArray$.push(requestSend);
      return requestSend.cancelPendingRequests;
    }
  }

  constructor() {}
}
