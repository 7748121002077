import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { QuotationsRequestService } from './quotations.request.service';

import Quotation from '../../interfaces/quotation/quotation';

@Injectable()
export class QuotationsService {
  private _quotation?: Quotation;

  constructor(
    private _router: Router,
    private _quotationRequestService: QuotationsRequestService
  ) {
  }

  get quotation(): Quotation|null {
    return this._quotation;
  }

  set quotation(quotation: Quotation) {
    this._quotation = quotation;
  }

  clearQuotation(): void {
    this._quotation = null;
  }
}
