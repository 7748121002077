import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HttpCancelService } from './http.cancel.service';

import { environment } from '../../../environments/environment';

@Injectable()

/**
 * Groups all generic functions for requests to the API.
 */
export class RequestService {

  constructor(
    protected http: HttpClient,
  ) {}

  /**
   * Generic GET method request.
   *
   * @param path The route needed for the request.
   * @param options The options to send.
   * @param token null
   */
  protected get(path: string, options: any = {}, token = null) {
    return new Observable((observer) => {
      this.http.get(environment.API_PATH + path, {...options, withCredentials: false}).pipe(
        takeUntil(HttpCancelService.onCancelPendingRequests(token))).subscribe(response => {
        observer.next(response);
      });
    });
  }

  protected getExt(path: string, options: any = {}, token = null) {
    return new Observable((observer) => {
      this.http.get(path, {...options, withCredentials: false}).pipe(
        takeUntil(HttpCancelService.onCancelPendingRequests(token))).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic GET method request for ERP.
   *
   * @param path The route needed for the request.
   * @param options The options to send.
   * @param token null
   */
  protected getErp(path: string, options: any = {}, token = null) {
    return new Observable((observer) => {
      this.http.get(environment.API_PATH_ERP + path, {...options, withCredentials: false}).pipe(
        takeUntil(HttpCancelService.onCancelPendingRequests(token))).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic POST method request.
   *
   * @param path The route needed for the request.
   * @param data The data to send.
   */
  protected post(path: string, data: any) {
    return new Observable((observer) => {
      this.http.post(environment.API_PATH + path, data, { withCredentials: false }).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic PUT method request.
   *
   * @param path The route needed for the request.
   * @param data The data to send.
   */
  protected put(path: string, data: any) {
    return new Observable((observer) => {
      this.http.put(environment.API_PATH + path, data, { withCredentials: false }).subscribe(response => {
        observer.next(response);
      });
    });
  }

  /**
   * Generic DELETE method request.
   *
   * @param path The route needed for the request.
   */
  protected delete(path: string) {
    return new Observable((observer) => {
      this.http.delete(environment.API_PATH + path, { withCredentials: false }).subscribe(response => {
        observer.next(response);
      });
    });
  }
}
