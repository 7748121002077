<div class="color-picker mb-3">
  <div class="summary-info" (click)="toggleColors()">
    <div class="info">
      <p class="style">{{ field.showLabel && field.label }}</p>
      <p class="style-value">{{ color }}</p>
    </div>
    <div class="circle" [style.background]="getBackground(color)"></div>
  </div>
  <div class="opened" *ngIf="show">
    <div class="colors">
      <ng-container *ngFor="let defaultColor of defaultColors">
        <div class="circle" [style.background]="getBackground(defaultColor)" (click)="changeColor(defaultColor)"></div>
      </ng-container>
    </div>
  </div>
</div>
