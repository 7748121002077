<div class="row ml-0 mr-0 mt-3">
  <div class="col-12 d-flex">
    <div class="flex-grow-1 pl-2">
      <div class="title-header mb-0"> Question n°{{ question.id }} </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > <a href="#" routerLink="/theonorme/questions" class="breadcrum-link">Assistance BatiSafe</a> > Question n°{{ question.id }} </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mr-2">
    </div>
  </div>
</div>

<div class="card ml-4 mr-4 mb-3">
  <div class="card-body p-3">
    <div style="font-size:0.9em;margin-bottom:15px;"><b> {{ question['date'] }}</b></div>
    {{ question['question'] }}
  </div>
</div>

<div class="card ml-4 mr-4 mb-3" *ngIf="!answer">
  <div class="card-body p-4">
    <div class="w-100 text-center">
      <app-no-result width="300" picture="../../../assets/img/empty.svg" title="Aucune réponse" subTitle="Vous n'avez pas encore reçus de réponse."></app-no-result>
      <a href="#" class="btn btn-primary mt-3" routerLink="/theonorme/questions">Revenir à la liste des questions</a>
    </div>
  </div>
</div>

<div class="card ml-4 mr-4 mb-3" *ngIf="answer">
  <div class="card-body p-3">
    <div style="font-size:0.9em;margin-bottom:15px;"><b>Réponse de {{ answer['user_name'] }} le {{ answer['date'] }}</b></div>
    {{ answer['answer'] }}
  </div>
</div>
