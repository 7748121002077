import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { AuthService } from '../../../core/auth/auth.service';

import { TrainingsRequestService } from '../../../core/services/trainings/trainings.request.service';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';
import { TrainingForParticipant } from '../../../core/interfaces/training/training';
import { TrainingDateForParticipant } from '../../../core/interfaces/training/training_date';
import { TrainingParticipantForParticipantResponse } from '../../../core/interfaces/training/response/training_participant';

import { TrainingsService } from '../../../core/services/trainings/trainings.service';
import { ModalTrainingComponent } from '../../../core/modals/training/training.component';
import { ModalTrainingAgreementComponent } from '../../../core/modals/training-agreement/training-agreement.component';

import { zipFilesBase64 } from '../../../core/utils/zipFiles';

import * as moment from 'moment';
import { QuotationsService } from '../../../core/services/quotations/quotations.service';

@Component({
  selector: 'app-detail-training',
  templateUrl: './detail-training.component.html',
  styleUrls: ['./detail-training.component.scss']
})
export class DetailTrainingComponent implements OnInit, AfterViewInit {
  /**
   * Training participant id
   * @private
   */
  private _id: number;
  private _trainingModal: ModalTrainingComponent;
  private _trainingAgreementModal: ModalTrainingAgreementComponent;

  /** Training date to be displayable and readable for hooman */
  training_date_clean: string;

  /** The training date is currently running */
  training_date_is_running: boolean;
  /** The training is passed/finished */
  training_date_is_passed: boolean;
  /** The training date is not already set */
  training_date_is_not_set: boolean;
  /** Has completed the pre training survey */
  completed_pre_training_survey: boolean;
  /** Has completed the satisfaction survey */
  completed_satisfaction_survey: boolean;
  /** Has completed the post satisfaction survey */
  completed_post_training_survey: boolean;
  /** 3 months after the training, it is the post training survey */
  eligible_to_post_training_survey: boolean;
  signed_agreement: boolean;
  signed_agreement_file_exists: boolean;

  isParticipant: boolean;

  clientContactAlreadyRespondedSurvey: boolean;

  totalSignInSheets = 0;
  totalParticipants = 0;
  totalMaterialFiles = 0;
  totalCertificates = 0;
  totalParticipantsResponses = 0;

  loadClickCard = false;

  /**
   * Detail Training component constructor
   *
   * @param _activatedRoute
   * @param _router
   * @param _notifier
   * @param _authService
   * @param _trainingRequestService
   * @param _trainingService
   * @param _quotationService
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notifier: CustomNotifierService,
    private _authService: AuthService,
    private _trainingRequestService: TrainingsRequestService,
    private _trainingService: TrainingsService,
    private _quotationService: QuotationsService
  ) { }

  ngOnInit(): void {
    this.isParticipant = this._authService.user.training_participant_access;
    this.training_date_is_passed = false;
    this.training_date_is_running = false;
    this.training_date_is_not_set = false;
    this.completed_pre_training_survey = false;
    this.completed_satisfaction_survey = false;
    this.clientContactAlreadyRespondedSurvey = false;
    this.signed_agreement_file_exists = false;
    this.signed_agreement = false;

    // Participant
    if (this.isParticipant) {
      this._activatedRoute.data.subscribe((response: TrainingParticipantForParticipantResponse) => {
        this._id = response.data.id;
        this.training = response.data.training;
        this.trainingDate = response.data.training_date;
        this.completed_pre_training_survey = response.data.complete_pre_survey;
        this.completed_satisfaction_survey = response.data.complete_satisfaction_survey;
        this.completed_post_training_survey = response.data.complete_post_survey;
        this.totalMaterialFiles = response.data.total_material_files;
        this._cleanDate();
      });
    } else { // Client user
      this._activatedRoute.data.subscribe((response: any) => {
        this._trainingService.trainingDate = response.data.training_date;
        this._trainingService.training = response.data.training;
        this._trainingService.quotation = response.data.quotation;

        this.training = response.data.training;
        this.trainingDate = response.data.training_date;
        this.totalParticipants = response.data.total_participants;
        this.clientContactAlreadyRespondedSurvey = response.data.already_respond_to_survey;
        this.totalSignInSheets = response.data.total_sign_in_sheets;
        this.totalCertificates = response.data.total_certificates;
        this.totalParticipantsResponses = response.data.total_participants_responses;
        this.signed_agreement = response.data.signed_agreement;
        this.signed_agreement_file_exists = response.data.signed_agreement_file_exists;
        this._cleanDate();
      });
    }
  }

  ngAfterViewInit(): void {
    this._trainingAgreementModal.eventOnClose.subscribe(event => {
      if (event === 'success') {
        this.signed_agreement_file_exists = true;
      }
    });
  }

  get training(): TrainingForParticipant {
    return this._trainingService.trainingForParticipant;
  }

  set training(training: TrainingForParticipant) {
    this._trainingService.trainingForParticipant = training;
  }

  get trainingDate(): TrainingDateForParticipant {
    return this._trainingService.trainingDateForParticipant;
  }

  set trainingDate(trainingDate: TrainingDateForParticipant) {
    this._trainingService.trainingDateForParticipant = trainingDate;
  }

  /**
   * Download a training file.
   * Choose from list what you want to dl.
   *
   * @param fileId
   * @param fileType File type to download. No default value.
   */
  downloadFile(
    fileId: number,
    fileType: 'certificate' | 'convocation' | 'material' | 'agreement' | 'sign-in-sheets' | 'participants-responses-survey'
  ) {
    if (this.training_date_is_not_set) {
      return this._notifier.errorCustom('Vous ne pouvez pas télécharger de fichiers actuellement');
    }

    this.loadClickCard = true;
    /**
     * Create link element in document
     *
     * @param fileName
     * @param base64
     */
    const createElement = (fileName: string, base64: string) => {
      const link = document.createElement('a');
      link.setAttribute('style', 'display: none;');
      link.href = base64;
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
    };

    // fileId => Really used ? Potentially to be removed
    if (fileType === 'certificate') {
      if (this.isParticipant) {
        this._trainingRequestService.getCertificate(this._id)
          .subscribe((response: any) => {
            if (response.success) {
              createElement(response.file_name, response.file);
            } else {
              this._notifier.errorCustom('Le document n\'a pas pu être téléchargé');
            }
            this.loadClickCard = false;
          });
      } else {
        this._trainingRequestService.getAllCertificates(this._trainingService.trainingDate.id)
          .subscribe((response: any) => {
            if (response.success) {
              zipFilesBase64('Attestations.zip', response.files, 'file_name', 'file');
            } else {
              this._notifier.errorCustom('Les attestations n\'ont pas pu être téléchargées');
            }
            this.loadClickCard = false;
          });
      }
    } else if (fileType === 'convocation') {
      if (this.isParticipant) {
        this._trainingRequestService.getConvocation(this._id)
          .subscribe((response: any) => {
            if (response.success) {
              createElement(response.file_name, response.file);
            } else {
              this._notifier.errorCustom('Le document n\'a pas pu être téléchargé');
            }
            this.loadClickCard = false;
          });
      } else {
        this._trainingRequestService.getAllConvocations(this._trainingService.trainingDate.id)
          .subscribe((response: any) => {
            if (response.success) {
              zipFilesBase64('Convocations.zip', response.files, 'file_name', 'file');
            } else {
              this._notifier.errorCustom('Les convocations n\'ont pas pu être téléchargées');
            }
            this.loadClickCard = false;
          });
      }
    } else if (fileType === 'material') {
      this._trainingRequestService.getTrainingDateMaterials(this.trainingDate.id)
        .subscribe((response: any) => {
          if (response.success) {
            zipFilesBase64('Supports-formation.zip', response.files, 'name', 'base64');
          } else {
            this._notifier.errorCustom('Le document n\'a pas pu être téléchargé');
          }
          this.loadClickCard = false;
        });
    } else if (fileType === 'agreement') {
      // It will download the signed agreement if is signed, else, it will download the non signed agreement
      this._trainingRequestService.getTrainingDateAgreement(this.trainingDate.id)
        .subscribe((response: any) => {
          if (response.success) {
            createElement(response.file_name, response.file);
          } else {
            this._notifier.errorCustom('Le document n\'a pas pu être téléchargé');
          }
          this.loadClickCard = false;
        });
    } else if (fileType === 'sign-in-sheets') {
      this._trainingRequestService.getSignInSheetsParticipants(this._authService.user.id, this.trainingDate.id)
        .subscribe((response: any) => {
          if (response.success) {
            if (response.total > 0) {
              zipFilesBase64('Feuilles-emargements.zip', response.sign_in_sheets, 'label', 'base64');
            } else {
              this._notifier.errorCustom('Il n\'y a aucune feuille d\'émargements à télécharger');
            }
          } else {
            this._notifier.errorCustom('Les feuilles d\'émargements n\'ont pas pu être téléchargées');
          }
          this.loadClickCard = false;
        });
    } else if (fileType === 'participants-responses-survey') {
      // Yes, more check, is better
      if (!this.isParticipant) {
        this._trainingRequestService.getParticipantsSurveyResponses(this._trainingService.quotation.id)
          .subscribe((response: any) => {
            if (response.success) {
              if (response.responses) {
                createElement(response.file_name, response.survey_responses);
              } else {
                this._notifier.errorCustom('Aucune réponse n\'est disponible');
              }
            } else {
              this._notifier.errorRequest();
            }
            this.loadClickCard = false;
          });
      }
    }
  }

  openSurvey(survey: 'pre-survey'|'satisfaction'|'post-survey'|'client-contact' = null) {
    if (this.training_date_is_not_set) {
      return this._notifier.errorCustom('Vous ne pouvez pas répondre à des questionnaires actuellement');
    }

    const navigationExtras: NavigationExtras = {
      queryParams: {
        survey_type: null,
        training_date_id: this.trainingDate.id
      }
    };

    // In this case, is a client-contact and the training is passed. Ask him for the organization
    if (!this.isParticipant && this.training_date_is_passed && survey === 'client-contact') {
      navigationExtras.queryParams.survey_type = 4;
      return this._router.navigate(['/training/survey'], navigationExtras);
    }

    // In this case, redirect to the pre training survey
    // if (!this.training_date_is_passed && !this.training_date_is_running && !this.completed_pre_training_survey) {
    if (!this.completed_pre_training_survey) {
      navigationExtras.queryParams.survey_type = 1;
      return this._router.navigate(['/training/survey'], navigationExtras);
    }

    // if (this.training_date_is_passed) {
    // In this case redirect to the satisfaction survey
    if (!this.completed_satisfaction_survey && survey === 'satisfaction') {
      navigationExtras.queryParams.survey_type = 2;
      return this._router.navigate(['/training/survey'], navigationExtras);
    }

    // In this case redirect to the post training survey (after 3 months)
    if (this.eligible_to_post_training_survey && !this.completed_post_training_survey && survey === 'post-survey') {
      navigationExtras.queryParams.survey_type = 3;
      return this._router.navigate(['/training/survey'], navigationExtras);
    }
    // }
  }

  openPreTrainingSurvey() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        survey_type: 1,
        training_date_id: this.trainingDate.id
      }
    };

    return this._router.navigate(['/training/survey'], navigationExtras);
  }

  binModal(modalType: 'participants' | 'send_agreement', trainingModal: any) {
    if (modalType === 'participants') {
      this._trainingModal = trainingModal;
    } else if (modalType === 'send_agreement') {
      this._trainingAgreementModal = trainingModal;
    }
  }

  openModal(modalToOpen: 'participants' | 'send_agreement') {
    if (modalToOpen === 'participants') {
      this._trainingModal.open(
        this._trainingService.training,
        this._trainingService.quotation,
        this._trainingService.trainingDate
      );
    } else if (modalToOpen === 'send_agreement') {
      this._trainingAgreementModal.open(this.signed_agreement_file_exists);
    }
  }

  /**
   * Clean the dates to be readable in html.<br/>
   * Cases that will be display :
   * <ul>
   *   <li>01/01/1970</li>
   *   <li>01/01/1970 au 01/02/1970</li>
   * </ul>
   *
   * Also check on training date if is not set
   *
   * @private
   */
  private _cleanDate() {
    if (!this.trainingDate.start_at && !this.trainingDate.ended_at) {
      this.training_date_is_running = false;
      this.training_date_is_passed = false;
      this.eligible_to_post_training_survey = false;
      this.training_date_is_not_set = true;
    } else {
      /** Now date time moment object */
      const now = moment();
      /** Training date start in moment object */
      const training_date_start = moment(this.trainingDate.start_at.date);
      /** Training date end in moment object */
      const training_date_end = moment(this.trainingDate.ended_at.date);
      /** Training date start format to string */
      const training_date_start_format: string = training_date_start.format('DD/MM/YYYY');
      /** Training date end format to string */
      const training_date_end_format: string = training_date_end.format('DD/MM/YYYY');
      this.training_date_clean = training_date_start_format !== training_date_end_format ?
        training_date_start_format + ' au ' + training_date_end_format :
        training_date_start_format;

      if (now.isSameOrAfter(training_date_start)) { // The training has begun or is passed
        if (now.isSameOrBefore(training_date_end)) { // The training is now and is not finished
          this.training_date_is_running = true;
        } else {
          this.training_date_is_passed = true;
        }
      }

      if (now.isSameOrBefore(training_date_end)) { // The training is now and is not finished
        this.training_date_is_running = true;
      }

      this.eligible_to_post_training_survey = now.isSameOrAfter(training_date_end.add(3, 'month'));
    }
  }
}
