import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent implements AfterViewInit, OnInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalForm: FormGroup;
  question = new FormControl('', []);

  modalElConfirm = null;
  id: number;
  file: any;
  answer: any;
  quest: any;

  constructor(
    private rootNode: ElementRef,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.modalForm = this.formBuilder.group({
      question: this.question,
    });
  }

  open(question = null, answer = null) {
    // this.id = id;
    this.answer = answer;
    this.quest = question;
    console.log(this.quest);
    this.modalElConfirm.modal('show');
  }

  cancel() {
    this.eventOnClose.next(null);
    this.clearForm();
    this.modalElConfirm.modal('hide');
  }

  validate() {
    this.eventOnClose.next({
      id : this.id,
      question : this.question.value,
    });
    this.clearForm();
    this.modalElConfirm.modal('hide');
  }

  ngAfterViewInit() {
    this.modalElConfirm = $(this.rootNode.nativeElement).find('div.modal-confirm');

    const self = this;
    $('#confirm-modal').on('hidden.bs.modal', () => {
      this.clearForm();
      self.eventOnClose.next(null);
    });
  }

  clearForm() {
    this.question.setValue('');
  }
}
