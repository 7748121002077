import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../core/auth/auth.service';
import { AuthRequestService } from '../../core/services/auth/auth.request.service';
import { DashboardRequestService } from '../../core/services/dashboard/dashboard.request.service';
import { FilesRequestService } from '../../core/services/files/files.request.service';
import { DashboardService } from '../../core/services/dashboard/dashboard.service';
import { CustomNotifierService } from '../../core/services/custom.notifier.service';

import QuotationDashboard, { ResponseYieldQuotation } from '../../core/interfaces/dashboard/quotation-dashboard';
import InvoiceDashboard, { ResponseYieldInvoice } from '../../core/interfaces/dashboard/invoice-dashboard';
import TrainingDashboard, { ResponseYieldTraining } from '../../core/interfaces/dashboard/training-dashboard';
import DashboardData, { DashboardDataYield } from '../../core/interfaces/dashboard/dashboard';
import FileDashboard, { ResponseYieldFile } from '../../core/interfaces/dashboard/file-dashboard';
import WebinarDashboard, { ResponseYieldWebinar, WebinarBatiregistreBatisafe } from '../../core/interfaces/dashboard/webinar-dashboard';
import TicketDashboard, { ResponseYieldTicket } from '../../core/interfaces/dashboard/ticket-dashboard';
import VeilleDashboard, { ResponseYieldVeille } from '../../core/interfaces/dashboard/veille-dashboard';
import PlanBoxFile from '../../core/interfaces/planboxfile';
import {parseString} from 'xml2js';
import * as moment from 'moment';

import { orderBy } from "lodash";
import CustomCard from '../../core/interfaces/dashboard/custom-card';
import PortalConfig from "../../core/interfaces/portal-config";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loaderQuotations: boolean;
  loaderInvoices: boolean;
  loaderTrainings: boolean;
  loaderFiles: boolean;
  loaderWebinars: boolean;
  loaderTickets: boolean;
  loaderVeilles: boolean;
  loaderArticles: boolean;

  isParticipant: boolean;

  imageBatisafePath: string = '../../../assets/img/dashboard/batisafe.png';
  imageBatiregistrePath: string = '../../../assets/img/dashboard/batiregistre.png';
  imageTheonormePath: string = '../../../assets/img/dashboard/theonorme.png';

  countRefused = 0;
  countAccepted = 0;
  countWaiting = 0;
  countTicket = 0;

  moment = moment;

  constructor(
    public authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _elementRef: ElementRef,
    private _notifier: CustomNotifierService,
    private _authRequestService: AuthRequestService,
    private _dashboardRequestService: DashboardRequestService,
    private _dashboardService: DashboardService,
    private _dirFileRequestService: FilesRequestService
  ) {
    this.loaderQuotations = true;
    this.loaderInvoices = true;
    this.loaderTrainings = true;
    this.loaderFiles = true;
    this.loaderWebinars = true;
    this.loaderTickets = true;
    this.loaderVeilles = true;
    this.loaderArticles = true;
    this.isParticipant = authService.user.training_participant_access;
  }

  get quotations(): QuotationDashboard[] {
    return this._dashboardService.quotations;
  }

  set quotations(quotations: QuotationDashboard[]) {
    this._dashboardService.quotations = quotations;
  }

  get invoices(): InvoiceDashboard[] {
    return this._dashboardService.invoices;
  }

  set invoices(invoices: InvoiceDashboard[]) {
    this._dashboardService.invoices = invoices;
  }

  get trainings(): TrainingDashboard[] {
    return this._dashboardService.trainings;
  }

  set trainings(trainings: TrainingDashboard[]) {
    this._dashboardService.trainings = trainings;
  }

  get articles(): any[] {
    return this._dashboardService.articles;
  }

  set articles(articles: any[]) {
    this._dashboardService.articles = articles;
  }

  get files(): FileDashboard[] {
    return this._dashboardService.files;
  }

  set files(files: FileDashboard[]) {
    this._dashboardService.files = files;
  }

  get webinars(): WebinarBatiregistreBatisafe {
    return this._dashboardService.webinars;
  }

  set webinars(webinars: WebinarBatiregistreBatisafe) {
    this._dashboardService.webinars = webinars;
  }

  get tickets(): TicketDashboard[] {
    return this._dashboardService.tickets;
  }

  set tickets(tickets: TicketDashboard[]) {
    this._dashboardService.tickets = tickets;
  }

  get veilles(): VeilleDashboard[] {
    return this._dashboardService.veilles;
  }

  set veilles(veilles: VeilleDashboard[]) {
    this._dashboardService.veilles = veilles;
  }

  get customCards(): CustomCard[] {
    return this._dashboardService.customCards;
  }

  set customCards(customCards: CustomCard[]) {
    this._dashboardService.customCards = customCards;
  }

  get portalConfig(): PortalConfig {
    return this._dashboardService.portalConfig;
  }

  set portalConfig(portalConfig: PortalConfig) {
    this._dashboardService.portalConfig = portalConfig;
  }

  ngOnInit() {
    this._activatedRoute.data.subscribe((response: DashboardData) => {
      if (response.data.success) {
        localStorage.setItem('portalConfig', JSON.stringify(response.data.portal_config))
        this.portalConfig = response.data.portal_config;
        this._setDashboardData(response.data);
      } else {
        this._notifier.errorCustom('Les données n\'ont pas pu être chargées.');
      }
    });

    this._dashboardRequestService.getBatisafeBlog().subscribe(response => {
      let parent = this;
      let totalBlogs = 0;
      parseString(response['batisafe'], {trim: true}, function (err, result) {
        if( parent._dashboardService.articles === undefined)
          parent._dashboardService.articles = [];

        result['rss']['channel'][0]['item'].forEach((article, index) => {
          if(index > 1)
            return;

          parent._dashboardService.articles.push({src : 'batisafe', title : article['title'], link: article['link'], date : article['pubDate'][0]});
        });
        totalBlogs++;
      });
      parseString(response['batiregistre'], {trim: true}, function (err, result) {
        if( parent._dashboardService.articles === undefined)
          parent._dashboardService.articles = [];

        result['rss']['channel'][0]['item'].forEach((article, index) => {
          if(index > 1)
            return;

          parent._dashboardService.articles.push({src : 'batiregistre', title : article['title'], link: article['link'], date : article['pubDate'][0]});
        });
        totalBlogs++;
      });
      parseString(response['theonorme'], {trim: true}, function (err, result) {
        if( parent._dashboardService.articles === undefined)
          parent._dashboardService.articles = [];

        result['rss']['channel'][0]['item'].forEach((article, index) => {
          if(index > 1)
            return;

          parent._dashboardService.articles.push({src : 'theo', title : article['title'], link: article['link'], date : article['pubDate'][0]});
        });
        totalBlogs++;
      });

      if (totalBlogs === 3) {
        this.loaderArticles = false;
      }

      console.log(this._dashboardService.articles);
    });
  }

  /**
   * Redirect to somewhere when click on element line
   *
   * @param url The url to redirect
   * @param params Params to add if needed
   */
  redirect(url: string, params: any = {}) {
    if (Object.keys(params).length) {
      this._router.navigate([url], {queryParams: params}).then();
    } else {
      this._router.navigate([url]).then();
    }
  }

  /**
   * Reload a specific component (card) or all the dashboard
   *
   * @param componentName The component to reload
   */
  reloadComponent(componentName: 'dashboard' | 'quotations' | 'invoices' | 'trainings' | 'files' | 'webinars' | 'tickets' | 'veilles') {
    // Do nothing when the component is refreshing
    if (componentName === 'dashboard' && (
      this.loaderQuotations && this.loaderInvoices &&
      this.loaderTrainings && this.loaderFiles &&
      this.loaderWebinars && this.loaderTickets &&
      this.loaderVeilles
    )
    ) {
      return null;
    } else if (componentName === 'quotations' && this.loaderQuotations) {
      return null;
    } else if (componentName === 'invoices' && this.loaderInvoices) {
      return null;
    } else if (componentName === 'trainings' && this.loaderTrainings) {
      return null;
    } else if (componentName === 'files' && this.loaderFiles) {
      return null;
    } else if (componentName === 'webinars' && this.loaderWebinars) {
      return null;
    } else if (componentName === 'tickets' && this.loaderTickets) {
      return null;
    } else if (componentName === 'veilles' && this.loaderVeilles) {
      return null;
    }

    const defaultErrorMessage = 'Les données n\'ont pas pu être récupérées';

    if (componentName === 'dashboard') {
      this.loaderQuotations = true;
      this.loaderInvoices = true;
      this.loaderTrainings = true;
      this.loaderFiles = true;
      this.loaderWebinars = true;
      this.loaderTickets = true;
      this.loaderVeilles = true;
      this._dashboardRequestService.getDashboardDataFirstLoad(this.authService.user.training_participant_access)
        .subscribe((response: DashboardDataYield) => {
          if (response.success) {
            this._setDashboardData(response);
          } else {
            this._notifier.errorCustom(defaultErrorMessage);
          }
        });
    } else if (componentName === 'quotations') {
      this.loaderQuotations = true;
      this._dashboardRequestService.getQuotations().subscribe((response: ResponseYieldQuotation) => {
        if (response.success) {
          this.quotations = response.quotations;
        } else {
          this._notifier.errorCustom(defaultErrorMessage);
        }
        this.loaderQuotations = false;
      });
    } else if (componentName === 'invoices') {
      this.loaderInvoices = true;
      this._dashboardRequestService.getInvoices().subscribe((response: ResponseYieldInvoice) => {
        if (response.success) {
          this.invoices = response.invoices;
        } else {
          this._notifier.errorCustom(defaultErrorMessage);
        }
        this.loaderInvoices = false;
      });
    } else if (componentName === 'trainings') {
      this.loaderTrainings = true;
      this._dashboardRequestService.getTrainings(this.authService.user.training_participant_access)
        .subscribe((response: ResponseYieldTraining) => {
          if (response.success) {
            this.trainings = response.trainings;
          } else {
            this._notifier.errorCustom(defaultErrorMessage);
          }
          this.loaderTrainings = false;
        });
    } else if (componentName === 'files') {
      this.loaderFiles = true;
      this._dashboardRequestService.getFiles().subscribe((response: ResponseYieldFile) => {
        if (response.success) {
          this.files = response.files;
        } else {
          this._notifier.errorCustom(defaultErrorMessage);
        }
        this.loaderFiles = false;
      });
    } else if (componentName === 'webinars') {
      this.loaderWebinars = true;
      this._dashboardRequestService.getWebinars().subscribe((response: ResponseYieldWebinar) => {
        if (response.success) {
          this.webinars = response.webinars;
          this._orderArrayWebinars();
        } else {
          this._notifier.errorCustom(defaultErrorMessage);
        }
        this.loaderWebinars = false;
      });
    } else if (componentName === 'tickets') {
      this.loaderTickets = true;
      this.countTicket = 0;
      this._dashboardRequestService.getTickets().subscribe((response: ResponseYieldTicket) => {
        if (response.success) {
          this.tickets = response.tickets;
          this.countTicket = this.tickets.length;
        } else {
          this._notifier.errorCustom(defaultErrorMessage);
        }
        this.loaderTickets = false;
      });
    } else if (componentName === 'veilles') {
      this.loaderVeilles = true;
      this._dashboardRequestService.getVeilles().subscribe((response: ResponseYieldVeille) => {
        if (response.success) {
          this.veilles = response.veilles;
        } else {
          this._notifier.errorCustom(defaultErrorMessage);
        }
        this.loaderVeilles = false;
      });
    } else {
      // Reload the entire dashboard
      this.loaderQuotations = true;
      this.loaderInvoices = true;
      this.loaderTrainings = true;
      this.loaderFiles = true;
      this.loaderWebinars = true;
      this.loaderTickets = true;
      this.loaderVeilles = true;

      this._dashboardRequestService.getDashboardDataFirstLoad(this.authService.user.training_participant_access)
        .subscribe((response: DashboardDataYield) => {
          if (response.success) {
            this._setDashboardData(response);
          } else {
            this._notifier.errorCustom(defaultErrorMessage);
          }
        });
    }
  }

  /**
   * Download file on client hardware
   *
   * @param file The file to download
   * @param event
   */
  downloadFile(file: FileDashboard, event) {
    event.stopPropagation();
    file.loaderDownload = true;
    let base64 = '';
    if (file.base64) { // If the file has a base64 value, no need to retrieve from a request
      base64 = file.base64;
    } else { // Else, the file hasn't a base64 value, need to retrieve the file from OneDrive
      this._dirFileRequestService.getBoxPlanFile(file.id)
        .subscribe((response: PlanBoxFile) => {
          if (response.success) {
            base64 = response.datas.datas.file;
          } else {
            this._notifier.errorCustom('Une erreur est survenue, le fichier n\'a pas pu être récupéré');
          }
          file.loaderDownload = false;
        });
    }

    // Create a link in html, set the file and download it
    const link = document.createElement('a');
    link.download = file.name;
    link.href = 'data:application/octet-stream;base64,' + base64;
    document.body.append(link);
    link.setAttribute('style', 'display: none');
    link.click();
    link.remove();
    file.loaderDownload = false;
  }

  /**
   * Navigate to another url when click on custom card in a new tab
   *
   * @param url The url to navigate by
   */
  customCardNavigate(url: string) {
    window.open(url, '_blank');
  }

  /**
   * Return multiple class names for the custom card. Each keys are CSS classes
   *
   * @param currentCustomCard The custom card to add css
   */
  customCardClassNames(currentCustomCard: CustomCard): Object {
    const classNames = {
      'mb-2': true,
      'p-0': false,
      'link-cursor': false
    };
    if (currentCustomCard.picture) {
      classNames['p-0'] = true;
    }
    if (currentCustomCard.link) {
      classNames['link-cursor'] = true;
    }

    return classNames;
  }

  collapseCard(bool, veille) {
    veille.collapse = !bool;
  }

  /**
   * Set the data for the dashboard in variables.
   * Set also all the loaders to false
   *
   * @param dashboardData The data retrieved and need to set on the global data for dashboard
   * @param allLoaderFalse All loaders will be set to false when the data are treated
   * @private
   */
  private _setDashboardData(dashboardData: DashboardDataYield, allLoaderFalse: boolean = true) {
    this.quotations = dashboardData.quotations;
    this.invoices = dashboardData.invoices;
    this.trainings = dashboardData.trainings;
    this.files = dashboardData.files;
    this.webinars = dashboardData.webinars;
    this.tickets = dashboardData.tickets;
    this.veilles = dashboardData.veilles;
    this.customCards = dashboardData.customCards;

    this.quotations.forEach((quotation: QuotationDashboard) => {
      if (quotation.state.name === 'Refusé' || quotation.state.level === 3) {
        this.countRefused += 1;
      } else if (quotation.state.name === 'Accepté' || quotation.state.level === 2) {
        this.countAccepted += 1;
      } else if (quotation.state.name === 'Attente commercial' || quotation.state.level === 1) {
        this.countWaiting += 1;
      }
    });
    this.countTicket = this.tickets.length;

    this._orderArrayWebinars();

    if (allLoaderFalse) {
      this.loaderQuotations = false;
      this.loaderInvoices = false;
      this.loaderTrainings = false;
      this.loaderFiles = false;
      this.loaderWebinars = false;
      this.loaderTickets = false;
      this.loaderVeilles = false;
    }
  }

  /**
   * To group all webinars according to dates, to avoid having 3 dates for Batisafe (15/01/2000, 02/02/2000, 04/05/2000)
   * then 2 dates for Batiregistre starting during the same periods (12/01/2000, 03/04/2000).
   * So that in the end the table looks like something easier
   * to read (12/01/2000(BR), 15/01/2000(BS), 02/02/2000(BS), 03/04/2000(BR), 04/05/2000(BS))
   *
   * @private
   */
  private _orderArrayWebinars() {
    let mergedWebinars: WebinarDashboard[] = []; // Create an array which is going to be filled with all the webinars combined
    this.webinars.batisafe.forEach((webinar: WebinarDashboard) => mergedWebinars.push(webinar));

    this.webinars.batiregistre.forEach((webinar: WebinarDashboard) => mergedWebinars.push(webinar));

    // Use _lodash to order the array by date
    this.webinars.merged = orderBy(mergedWebinars, (item) => new Date(item.session.estimatedStartedAt), 'asc');;
  }
}
