import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { CustomNotifierService } from '../../../../core/services/custom.notifier.service';

import { ComponentCanDeactivate } from '../../../../core/utils/component-can-deactivate';

import { ConfirmModalComponent } from '../../../../core/confirm-modal/confirm-modal.component';
import { TheonormeRequestService } from '../../../../core/services/theonorme/theonorme.request.service';


@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss'],
})
export class CreateQuestionComponent implements OnInit, ComponentCanDeactivate {
  isIntact = true;
  errors: { general?: { code: string; message: string } };

  optionTinyMce: {
    height: 800, menubar: false, plugins: ['link preview table code lists'],
    toolbar: 'undo redo | formatselect fontselect fontsizeselect | \
    bold italic underline strikethrough backcolor forecolor | preview | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent link tabl'
  };


  messageForm = this.fb.group({
    question: [null, [Validators.required]],
  });
  requestInProgress = new Subject();
  requestCount = 0;
  loader: boolean;

  modal: ConfirmModalComponent;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private questionsServices: TheonormeRequestService,
    private notifier: CustomNotifierService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.isIntact) {
      return true;
    } else {
      const observable = new Observable<boolean>(observer => {
        this.modal.eventOnClose.subscribe(event => {
          if (event) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      });
      this.modal.open(1);
      return observable;
    }
  }
  sendMessage() {
    if (this.messageForm.valid) {
      let message: any = {};

      if (this.messageForm.get('question').value && this.messageForm.get('question').value) {
        this.requestInProgress.next(true);

        message = {
          question: this.messageForm.get('question').value,
        };

        this.questionsServices.createQuestion(message).subscribe((response: any) => {
          this.requestInProgress.next(false);
          if (response.success === true) {
            this.notifier.successCreate();
            this.router.navigate(['/theonorme/questions']).then();
          }
        });
      }
    }
  }

  bindModal(pendingModal: ConfirmModalComponent) {
    this.modal = pendingModal;
  }

  autogrowTextHeight() {
    const textArea = document.getElementById('textarea');
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
