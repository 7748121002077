<ul class="navbar-nav float-right">
  <li class="nav-item dropdown" ngbDropdown>
    <a class="nav-link waves-effect waves-dark pointer"
      aria-haspopup="true"
      aria-expanded="false"
      ngbDropdownToggle>

      <mat-icon aria-hidden="false" aria-label="Icone des notifications">notifications</mat-icon>
      <span *ngIf="!notificationService.loader && notificationService.count !== 0"
        class="badge"
        [ngStyle]="notificationService.count > 9 ? { 'right': '-8px' } : { 'right': '-3px' }">
        {{ notificationService.count }}
      </span>
    </a>

    <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown no-padding" ngbDropdownMenu>
      <span class="with-arrow"><span class="bg-primary"></span></span>

      <ul class="list-style-none no-padding">
        <li>
          <div class="drop-title text-white" [style]="{'backgroundColor': color}">
            <h4 class="notification-header">
              {{ notificationService.count === 1 ? notificationService.count + ' Nouvelle' : notificationService.count + ' Nouvelles' }}
            </h4>
            <span class="notification-subheader">
              {{ notificationService.count === 1 ? 'Notification' : 'Notifications'  }}
            </span>
          </div>
        </li>

        <li>
          <div class="message-center notifications">
            <div *ngIf="notificationService.loader" class="is-loading is-loading-black loader-content"></div>

            <div *ngFor="let key of objectKeys(notificationService.notifications); let first = first">
              <ng-container *ngIf="getNotViewed(notificationService.notifications[key]) && notificationService.notifications[key].length">
                <div *ngFor="let notification of getNotViewed(notificationService.notifications[key]); let index = index; let last = last" @notification>
                  <h4 *ngIf="index === 0 && !notification.isViewed" class="category-title">{{ key }}</h4>
                  <a class="message-item pointer"
                     (click)="notificationSeen(notification)">

                    <i class="mdi font-24" [ngClass]="notification.icon"></i>

                    <div class="mail-content" [ngStyle]="{'margin-bottom': last ? '0.5rem': ''}">
                      <h5 class="message-title mb-2">{{ notification.title }}</h5>
                      <span class="mail-desc">{{ notification.body }}</span>
                      <span class="time">{{ moment(notification.created.date).fromNow() }}</span>
                    </div>
                  </a>
                  <hr *ngIf="!last">
                </div>
              </ng-container>
            </div>

            <div class="mt-3" *ngIf="!notificationService.loader && (!objectKeys(notificationService.notifications).length || !notificationService.notifications[objectKeys(notificationService.notifications)[0]].length)">
              <app-no-result width="202"
                [title]="'Nouveau départ'"
                [subTitle]="'Vous n\'avez aucune notification'"
              ></app-no-result>
            </div>
          </div>
        </li>

        <li>
          <button class="marked mb-0" [routerLink]="'/notifications'">
            <small class="text-muted">Tout afficher</small>
          </button>
          <button class="marked mb-0" [ngClass]="notificationService.count === 0 ? 'disabled' : 'pointer'" (click)="markAllSeen()">
            <small class="text-muted">Tout marquer comme non lu</small>
          </button>
          <!--<a class="nav-link text-center m-b-5 pointer" [href]="'/notifications'">Tout afficher</a>-->
          <!--<a class="nav-link text-center m-b-5"-->
          <!--  [ngClass]="notificationService.count === 0 ? 'disabled' : 'pointer'"-->
          <!--  (click)="markAllSeen()">-->
          <!--  Tout marquer comme lu-->
          <!--</a>-->
        </li>
      </ul>
    </div>
  </li>
</ul>
