import { Routes } from '@angular/router';
import { AllTicketsComponent } from './all-tickets/all-tickets.component';
import { CreateTicketComponent } from './create-ticket/create-ticket.component';

import { PendingChangesGuard } from '../../core/utils/pending.changes.guard';
import { TicketsResolver } from '../../core/services/tickets/tickets.resolver';
import { AllCommentsComponent } from './all-comments/all-comments.component';

export const ticketsRoutes: Routes = [
  {
    path: 'tickets',
    component: AllTicketsComponent,
    resolve: {
      data: TicketsResolver,
    }
  },
  {
    path: 'tickets/create',
    component: CreateTicketComponent,
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'ticket/:ticketId',
    component: AllCommentsComponent,
    resolve: {
      data: TicketsResolver,
    },
    canDeactivate: [PendingChangesGuard],
  },
];
