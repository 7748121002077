import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { AuthRequestService } from '../services/auth/auth.request.service';

import User from '../interfaces/user/user';
import AuthResponse from '../interfaces/response/auth.response';

@Injectable()
export class AuthService {
  user: User;
  userChange = new Subject<User>();

  loader = false;
  loaderChange = new Subject<boolean>();

  constructor(
    private router: Router,
    private authRequestService: AuthRequestService
  ) {
    this.loaderChange.subscribe((value) => {
      this.loader = value;
    });
    this.user = JSON.parse(localStorage.getItem('user'));
    this.userChange.next(this.user);
    // this.me();
  }

  login() {
    this.router.navigate(['/']).then(() => {});
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/login']).then(() => {});
  }

  me() {
    this.authRequestService.me().subscribe((response: AuthResponse) => {
      if (response.success) {
        localStorage.setItem('user', JSON.stringify(response.user));
        this.user = response.user;
        this.userChange.next(this.user);
      }
    });
  }
}
