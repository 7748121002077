import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AllTrainingsComponent } from './all-trainings.component';
import { ListModule } from '../../../core/list/list.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { NoResultModule } from '../../../core/no-result/no-result.module';
import { ModalTrainingModule } from '../../../core/modals/training/training.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    ListModule,
    ConfirmModalModule,
    NoResultModule,
    ModalTrainingModule
  ],
  declarations: [AllTrainingsComponent]
})

export class AllTrainingsModule {}
