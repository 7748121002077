import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class QuotationsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Retrieve all quotations
   *
   * @param {string} search
   * @param {string} sort
   * @param {string} order (ASC or DESC)
   * @param {number} limit
   * @param {number} page
   * @param token
   */
  public getQuotations(
    search: string = '',
    sort: string = '',
    order: string = '',
    limit: number = 0,
    page: number = 1,
    token = null
  ) {
    return this.get(
      'api/portalquotations?search=' +
      search + '&sort=' +
      sort + '&order=' +
      order + '&limit=' +
      limit + '&page=' +
      page,
      {},
      token
    );
  }

  /**
   * Retrieve a specific quote
   *
   * @param {number} id
   * @param {*} token
   */
  public getQuotation(id: number, token = null) {
    return this.get('api/portalQuotationPdf/' + id, {}, token);
  }

  /**
   * Accept a quote
   *
   * @param {number} id
   * @param {*} data
   */
  public accept(id: number, data) {
    return this.post('api/portalquotationsaccepted/' + id, data);
  }

  /**
   * When user accept a quote who's a training
   * and need to be validated by sales representative
   * or project manager.
   *
   * @param {number} id
   * @param {*} data
   */
  public acceptTraining(id: number, data) {
    return this.post('api/portal-quotation-accept-training/' + id, data);
  }

  /**
   * Update the supporting document previously sent on the given quote
   *
   * @param quotationId
   * @param data
   */
  public updateSupportingDocument(quotationId: number, data) {
    return this.put('api/portal-quotation-update-supporting-document/' + quotationId, data);
  }

  public refuse(id, data) {
    return this.post('api/portalquotationrefused/' + id, data);
  }

  public updateExample(exampleId, example) {
    return this.put('examples/' + exampleId, { example });
  }

  public deleteExample(exampleId) {
    return this.delete('examples/' + exampleId);
  }
}
