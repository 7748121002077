export class HttpResponse {
  notifier: any;
  errorCustom: any;
  successCustom: any;

  constructor(notifier) {
    this.notifier = notifier;

  }

  public parse(response, notifier = true) {
    if (response['success']) {
      this._success(response, notifier);
    } else {
      this._error(response, notifier);
    }
  }

  private _success(response, notifier) {
    if (notifier) {
      this.notifier.successCreate();
    }

    if (typeof this.successCustom !== 'undefined') {
      this.successCustom(response);
    }
  }

  private _error(response, notifier) {
    if (response.error.message !== undefined && notifier) {
      this.notifier.errorCustom(response.error.message);
    } else if(notifier) {
      this.notifier.errorRequest();
    }

    if (typeof this.errorCustom !== 'undefined') {
      this.errorCustom(response);
    }
  }
}
