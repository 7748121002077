import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuotationsResolver } from '../../core/services/quotations/quotations.resolver';
import { AllInvoicesModule } from './all-invoices/all-invoices.module';
import { InvoicesResolver } from '../../core/services/invoices/invoices.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllInvoicesModule
  ],
  providers: [
    QuotationsResolver,
    InvoicesResolver
  ]
})
export class InvoicesModule {}
