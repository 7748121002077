import { UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

/**
 * Uses Angular url matching engine to allow the
 * use of regexes with urls.
 *
 * To compare, `path` is the simplified version of` matcher`,
 * more complete and which allows better splitting
 * the routes, to do more processing.
 *
 * The first parameter can be a string without a regex
 * so that the path is strictly equal, or a parameter
 * (`:id` or `:slug` for example) that must be followed by a regex
 * so that the parameter is not considered a strict string to compare.
 *
 * @param path Strict string (`users` for example) or
 *             a parameter (`:id` for example). **Warning !** If it's
 *             a parameter, a regex must be provided.
 *
 * @param regex If the path is a parameter a regex must be applied.
 *
 * @author Etienne Blanc-Coquand <e.blanc@leviatan.fr>
 */
export default function urlMatcher(path: string, regex: RegExp = null): UrlMatcher {
  /**
   * This matcher function will check the current
   * browser url through `url` parameter and the `path` given.
   *
   * If the `path` parameter is followed by a `regex`
   * parameter (not `null`), then it will be a
   * parameter with variable value.
   *
   * @param url This is the url in browser, it will split the url in array.
   *
   * @param group Don't know. Need to update this doc later.
   *
   * @author Etienne Blanc-Coquand <e.blanc@leviatan.fr>
   */
  function matcher(url: UrlSegment[], group: UrlSegmentGroup): UrlMatchResult {
    // Test if a regex is set. If not, a strict comparison
    // is made with the path.
    if (regex !== null) {
      if (url.length > 0 && url[0].path.match(regex)) {
        // Retrieve the param for the object returned as the key name
        // (`:id` will become `id` which means that `route.params.id`
        // will be accessible etc...).
        const keyPath = path.substr(1);

        return {
          consumed: [url[0]],
          posParams: { [keyPath]: url[0] }
        };
      }
    } else {
      // Match if the url path is strictly equal to path
      if (url.length > 0 && url[0].path === path) {
        return {
          consumed: [url[0]]
        };
      }
    }

    // Nothing is found or the url array length is 0.
    return null;
  }

  // Return matcher is separate from function
  // declaration for documentation.
  return matcher;
}
