import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { DashboardResolver } from '../../core/services/dashboard/dashboard.resolver';

export const dashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    resolve: {
      data: DashboardResolver
    }
  },
];
