import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainingAgreementService {
  trainingAgreements: any = [];

  constructor() { }
}
