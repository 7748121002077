import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';

import { ComponentCanDeactivate } from '../../../core/utils/component-can-deactivate';

import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import Ticket from '../../../core/interfaces/ticket/ticket';
import { TicketsRequestService } from '../../../core/services/tickets/tickets.request.service';


@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
})
export class CreateTicketComponent implements OnInit, ComponentCanDeactivate {
  isIntact = true;
  errors: { general?: { code: string; message: string } };
  ticket: Ticket;
  files: any[] = [];
  fileToUpload: File | null = null;
  sendFiles: any[] = [];

  optionTinyMce: {
    height: 800, menubar: false, plugins: ['link preview table code lists'],
    toolbar: 'undo redo | formatselect fontselect fontsizeselect | \
    bold italic underline strikethrough backcolor forecolor | preview | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent link tabl'
  };


  messageForm = this.fb.group({
    content: [null, [Validators.required]],
    comment: [null, [Validators.required]]
  });

  requestInProgress = new Subject();
  requestCount = 0;
  loader: boolean;

  modal: ConfirmModalComponent;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private ticketsServices: TicketsRequestService,
    private notifier: CustomNotifierService,
  ) {}

  ngOnInit() {
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.isIntact) {
      return true;
    } else {
      const observable = new Observable<boolean>(observer => {
        this.modal.eventOnClose.subscribe(event => {
          if (event) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      });
      this.modal.open(1);
      return observable;
    }
  }

  sendMessage() {
    if (this.messageForm.valid) {
      let message: any = {};

      if (this.messageForm.get('content').value && this.messageForm.get('comment').value) {
        this.requestInProgress.next(true);

        message = {
          content: this.messageForm.get('content').value,
          comment: this.messageForm.get('comment').value,
          from_support: false,
          files: this.sendFiles,
        };

        this.ticketsServices.createTicket(message).subscribe((response: any) => {
          this.requestInProgress.next(false);
          if (response.success === true) {
            this.notifier.successCreate();
            this.router.navigate(['/tickets']).then();
          }
        });
      }
    }
  }

  bindModal(pendingModal: ConfirmModalComponent) {
    this.modal = pendingModal;
  }

  /**
   * Send message to api with file
   * Recursive function
   */
  /**
   * Add files to send when user trigger send message
   */
  joinFile() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      Array.from(fileUpload.files).forEach(file => {
        this._joinFile(file);
      });
      fileUpload.value = '';
    };
    fileUpload.click();
  }
  private _joinFile(data) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(data);
    fileReader.onload = () => {
      this.sendFiles.push({
        name: data.name,
        base64: fileReader.result
      });
    };

  }
  removeFileFromArray(file) {
    const index = this.sendFiles.indexOf(file);
    if (index > -1) {
      this.sendFiles.splice(index, 1);
    }
  }

  autogrowTextHeight() {
    const textArea = document.getElementById('textarea');
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
