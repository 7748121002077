import { Injectable } from '@angular/core';
import Training, { TrainingForParticipant } from '../../interfaces/training/training';
import Quotation from '../../interfaces/quotation/quotation';
import TrainingDate, { TrainingDateForParticipant } from '../../interfaces/training/training_date';
import TrainingParticipant, { TrainingParticipantInformation } from '../../interfaces/training/training_participant';
import { Router } from '@angular/router';
import { TrainingsRequestService } from './trainings.request.service';

@Injectable()
export class TrainingsService {
  private _training?: Training;
  private _trainingDate?: TrainingDate;
  private _trainingDates?: TrainingDate[];
  private _quotation?: Quotation;
  private _trainingParticipant?: TrainingParticipant;
  private _trainingParticipants?: TrainingParticipant[];
  private _trainingParticipantInformation?: TrainingParticipantInformation;
  private _trainingParticipantsInformation?: TrainingParticipantInformation[];
  private _trainingForParticipant?: TrainingForParticipant;
  private _trainingDateForParticipant?: TrainingDateForParticipant;

  constructor(
    private _router: Router,
    private _trainingRequestService: TrainingsRequestService,
  ) {
  }

  get training(): Training {
    return this._training;
  }

  set training(value: Training) {
    this._training = value;
  }

  get trainingDate(): TrainingDate {
    return this._trainingDate;
  }

  set trainingDate(value: TrainingDate) {
    this._trainingDate = value;
  }

  get quotation(): Quotation {
    return this._quotation;
  }

  set quotation(value: Quotation) {
    this._quotation = value;
  }

  get trainingDates(): TrainingDate[] {
    return this._trainingDates;
  }

  set trainingDates(value: TrainingDate[]) {
    this._trainingDates = value;
  }

  get trainingParticipant(): TrainingParticipant {
    return this._trainingParticipant;
  }

  set trainingParticipant(value: TrainingParticipant) {
    this._trainingParticipant = value;
  }

  get trainingParticipants(): TrainingParticipant[] {
    return this._trainingParticipants;
  }

  set trainingParticipants(value: TrainingParticipant[]) {
    this._trainingParticipants = value;
  }

  get trainingParticipantInformation(): TrainingParticipantInformation {
    return this._trainingParticipantInformation;
  }

  set trainingParticipantInformation(value: TrainingParticipantInformation) {
    this._trainingParticipantInformation = value;
  }

  get trainingParticipantsInformation(): TrainingParticipantInformation[] {
    return this._trainingParticipantsInformation;
  }

  set trainingParticipantsInformation(value: TrainingParticipantInformation[]) {
    this._trainingParticipantsInformation = value;
  }

  get trainingDateForParticipant(): TrainingDateForParticipant {
    return this._trainingDateForParticipant;
  }

  set trainingDateForParticipant(value: TrainingDateForParticipant) {
    this._trainingDateForParticipant = value;
  }

  get trainingForParticipant(): TrainingForParticipant {
    return this._trainingForParticipant;
  }

  set trainingForParticipant(value: TrainingForParticipant) {
    this._trainingForParticipant = value;
  }

  cleanTrainingDates(): void {
    this._trainingDates = [];
  }

  cleanTraining(): void {
    this._training = null;
  }

  cleanTrainingDate(): void {
    this._trainingDate = null;
  }

  cleanQuotation(): void {
    this._quotation = null;
  }
}
