import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuotationModalComponent } from './quotation-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule],
  declarations: [QuotationModalComponent],
  exports: [QuotationModalComponent]
})

export class QuotationModalModule {}
