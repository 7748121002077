import { Routes } from '@angular/router';

import { AllAccountComponent } from './all-account.component';
import { AccountResolver } from '../../core/services/account/account.resolver';
import { PatrimonyResolver } from '../../core/services/theonorme/patrimony/patrimony.resolver';
import { CategoryVeilleResolver } from '../../core/services/theonorme/category-veille/category-veille.resolver';
import { EstablishmentTypeResolver } from '../../core/services/theonorme/establishmentType/establishment-type.resolver';

export const accountRoutes: Routes = [
  {
    path: 'account',
    component: AllAccountComponent,
    resolve: {
      account: AccountResolver,
      patrimony: PatrimonyResolver,
      category_veille: CategoryVeilleResolver,
      establishments: EstablishmentTypeResolver,
    }
  }
];
