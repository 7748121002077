<div class="pagination-content" id="pagination">
  <div [className]="'d-flex justify-content-between flex-wrap ' + classes">
    <div class="d-flex col-auto">
      <div class="col-auto d-flex">
        <button mat-button class="d-flex tooltipHtml" data-toggle="dropdown" data-placement="bottom"
                aria-label="Affichage par page" title="Affichage par page">
          <mat-icon aria-hidden="false" aria-label="Filtrer">more_vert</mat-icon>
        </button>

        <span class="dropdown-menu dropdown-menu-left">
          <a *ngFor="let dropdown_item of dropdownItemsByPage" class="dropdown-item href pointer"
             [attr.aria-label]="'Afficher ' + dropdown_item + ' éléments'"
             (click)="returnAction('pageSize', dropdown_item)">
            {{ dropdown_item }}
          </a>
        </span>
      </div>

      <small class="col form-text text-muted d-flex align-self-center mt-0 element">
        Éléments {{ limit * (page - 1) + 1 }}-{{ limit * (page - 1) + pageSize }}
        sur {{ total }}
      </small>
    </div>

    <nav class="justify-content-end col-auto">
      <ul class="pagination justify-content-end mb-0">
        <li class="page-item" [ngClass]="{ 'disabled': page <= 1 }"
            (click)="returnAction('page', (page - 1))">
          <a class="page-link" [attr.aria-label]="page <= 1  ? 'Bouton précédent : Désactivé, vous êtes déja sur la première page' : 'Aller à la page précédente'">
            Précédent
          </a>
        </li>

        <li class="page-item" *ngIf="page - 2 > 1"
            (click)="returnAction('page', (1))">
          <a class="page-link" aria-label="Page 1">1</a>
        </li>

        <li class="page-item" *ngIf="page - 3 > 1"><a class="page-link page-dotted">...</a></li>

        <li class="page-item" *ngIf="page - 2 >= 1"
            (click)="returnAction('page', (page - 2))">
          <a class="page-link" [attr.aria-label]="'Page ' + (page - 2)">{{ page - 2 }}</a>
        </li>
        <li class="page-item" *ngIf="page - 1 >= 1"
            (click)="returnAction('page', (page - 1))">
          <a class="page-link" [attr.aria-label]="'Page ' + (page - 1)">{{ page - 1 }}</a>
        </li>

        <li class="page-item active">
          <a class="page-link disabled" [attr.aria-label]="'Page ' + page">{{ page }}</a>
        </li>

        <li class="page-item" *ngIf="page + 1 <= pages"
            (click)="returnAction('page', (page + 1))">
          <a class="page-link" [attr.aria-label]="'Page ' + (page + 1)">{{ page + 1 }}</a>
        </li>
        <li class="page-item" *ngIf="page + 2 <= pages"
            (click)="returnAction('page', (page + 2))">
          <a class="page-link" [attr.aria-label]="'Page ' + (page + 2)">{{ page + 2 }}</a>
        </li>

        <li class="page-item" *ngIf="page + 3 < pages">
          <a class="page-link page-dotted">...</a>
        </li>

        <li class="page-item" *ngIf="page + 2 < pages"
            (click)="returnAction('page', (pages))">
          <a class="page-link" [attr.aria-label]="'Page ' + pages + ', c\'est la dernière page'">
            {{ pages }}
          </a>
        </li>

        <li class="page-item" [ngClass]="{ 'disabled': page >= pages }"
            (click)="returnAction('page', (page + 1))">
          <a class="page-link" [attr.aria-label]="page >= pages ? 'Bouton suivant : Désactivé, vous êtes déja sur la dernière page' : 'Aller à la page suivante'">
            Suivant
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
