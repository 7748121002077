import { Routes } from '@angular/router';

import { CguComponent } from './cgu/cgu.component';
import { MentionsComponent } from './mentions/mentions.component';

export const legalsRoutes: Routes = [
  {
    path: 'cgu',
    component: CguComponent
  },
  {
    path: 'mentions',
    component: MentionsComponent
  }
];
