<div id="confirm-modal" class="modal modal-confirm fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">Refuser un devis</h1>
          </div>
        </div>

        <button type="button" class="close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body">
        <form [formGroup]="modalForm">
        <div class="mb-3">Vous allez refuser le devis qui vous à été proposé, nous en sommes désolé.</div>
        <div class="mb-3">
          <label class="d-block font-weight-bold mb-0" required>Raison</label>
          <small class="d-block smallBlock">Veuillez nous indiquer les raisons du refus</small>
          <ng-select [items]="[
          {value : 'Trop cher', name : 'Le devis est trop cher'},
          {value : 'Fait autrement',  name : 'J\'ai trouvé une autre alternative'},
          {value : 'Mission annulée', name : 'Je ne suis plus intéressé'},
          {value : 'À trouvé mieux', name : 'J\'ai une meilleur offre'},
          {value : 'Ne correspond pas aux attentes', name : 'Ne correspond pas à mes attentes'}
          ]" bindValue="value" bindLabel="name" style="padding:0;" formControlName="reason" class="form-control" [ngClass]="{ 'is-invalid': reason.invalid && (reason.dirty || reason.touched) }" ></ng-select>
        </div>
        <div class="mb-3">
          <label for="comment" class="d-block font-weight-bold mb-0">Commentaire</label>
          <small class="d-block smallBlock">Vous pouvez laisser un commentaire supplémentaire à l'attention du commercial.</small>
          <textarea id="comment" formControlName="comment" class="form-control" [ngClass]="{ 'is-invalid': comment.invalid && (comment.dirty || comment.touched) }"></textarea>
        </div>
        </form>
      </div>

      <div class="modal-footer">
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 mr-2" (click)="cancel()">
          Annuler
        </button>
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 ml-2" [disabled]="modalForm.invalid" (click)="validate()">
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
