<div id="trainingModal" class="modal modal-confirm" tabindex="-1" role="dialog" aria-hidden="true"
     data-backdrop="static" data-keyboard="false">
  <div class="modal-content">
    <div class="modal-header align-items-center">
      <div class="row">
        <div class="col">
          <h1 class="header-title">Formation</h1>
          <h2 class="subtitle" *ngIf="!loaderParticipants && step === 2">
            Participants {{ trainingDate.select_date }}
          </h2>
        </div>
      </div>

      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="step === 1">
        <ng-container *ngIf="loaderDates">
          <div class="row text-center">
            <div class="col-12">
              <i class="fa fa-spinner fa-spin"></i>
              <br/>Récupération des dates pour la formation
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!loaderDates && trainingDates">
          <ng-container *ngIf="trainingDates.length == 0">
            <div class="row">
              <div class="col-12">
                <p>Aucune date de formation n'est disponible actuellement.</p>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="trainingDates.length > 0">
            <div class="row">
              <div class="col-12">
                <p>Choisissez une date de formation dans la liste ci-dessous</p>
              </div>
              <div class="col-12">
                <form [formGroup]="trainingDatesForm">
                  <div>
                    <label for="dates">Dates de formation</label>
                    <ng-select
                      id="dates"
                      formControlName="trainingDatesSelect"
                      bindLabel="select_date"
                      bindValue="id"
                      placeholder="Sélectionnez une date"
                      (change)="changeSelectDate($event)"
                      [items]="trainingDates"
                      [(ngModel)]="selectedDate">
                    </ng-select>
                  </div>

                  <div *ngIf="selectedDate !== null && selectedDate !== undefined">

                    <ng-container *ngIf="loaderPlaces">
                      <div class="row text-center" style="margin-top: 1rem;">
                        <div class="col-12">
                          <i class="fa fa-spinner fa-spin"></i>
                          <br/>Récupération des places disponibles
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!loaderPlaces && totalFreePlaces === 0">
                      <div class="row" style="margin-top: 1rem;">
                        <div class="col-12">
                          <p>Il n'y a plus de place disponible pour cette date.
                            Veuillez modifier la date choisie</p>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!loaderPlaces && totalFreePlaces > 0">
                      <div class="row" style="margin-top: 1rem;">
                        <div class="col-12">
                          <label for="participants">Total de participants</label>
                          <input type="number"
                                 class="form-control"
                                 id="participants"
                                 formControlName="totalParticipants"
                                 [placeholder]="totalFreePlaces + ' participants maximum'"
                                 [min]="1"
                                 [max]="totalFreePlaces">
                        </div>
                      </div>
                    </ng-container>


                  </div>
                </form>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div *ngIf="step === 2">
        <ng-container *ngIf="loaderParticipants">
          <div class="row text-center">
            <div class="col-12">
              <i class="fa fa-spinner fa-spin"></i>
              <br/>Récupération des participants
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!loaderParticipants">
          <div class="subtitle-training-date-not-set bg-warning d-flex" style="font-size:0.9em;" *ngIf="trainingDateNotSet">
            <div class="p-2"><i class="fa fa-exclamation text-white"></i></div>
            <div class="text-white flex-grow-1">Note importante : Les participants ne pourront récupérer des fichiers tant que la formation
              n'aura pas de date de renseigné </div>
          </div>

          <ng-container *ngIf="!participantForm.length">
            <div style="font-size: 1em; margin-top: 1rem; margin-bottom: 1rem;">
              Ajoutez dès à présent votre premier participant en cliquant sur le
              <span class="text-empty" (click)="addNewFormRow()">bouton + ci-dessous</span>.
            </div>
          </ng-container>

          <form [formGroup]="participantsForm">
            <ng-container formArrayName="participants">
              <div *ngFor="let participant of participantsForm.controls['participants']['controls']; let i = index">
                <div [formGroupName]="i">
                  <div class="row participant-form">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-11">
                          <label class="required" [attr.for]="'participant_email_' + i">Email</label>
                        </div>
                        <div class="col-1" *ngIf="!trainingDatePassed && !trainingDateRunning">
                          <mat-icon aria-hidden="false"
                                    aria-label="Supprimer le participant"
                                    class="link-cursor"
                                    [title]="'Enlever le participant de la date de formation'"
                                    (click)="clearParticipant(participant, i)"
                          >
                            delete
                          </mat-icon>
                        </div>
                      </div>
                      <input required type="email"
                             [className]="trainingDatePassed || trainingDateRunning ? 'disable' : null"
                             class="form-control participant-input" [id]="'participant_email_' + i"
                             formControlName="email" [attr.disabled]="trainingDatePassed || trainingDateRunning ? 'disabled' : null">
                    </div>
                    <div class="col-4">
                      <label class="required" [attr.for]="'participant_first_name_' + i">Prénom</label>
                      <input required type="text"
                             [className]="trainingDatePassed || trainingDateRunning ? 'disable' : null"
                             class="form-control" [id]="'participant_first_name_' + i"
                             formControlName="first_name" [attr.disabled]="trainingDatePassed || trainingDateRunning ? 'disabled' : null">
                    </div>
                    <div class="col-4">
                      <label class="required" [attr.for]="'participant_last_name_' + i">Nom</label>
                      <input required type="text"
                             [className]="trainingDatePassed || trainingDateRunning ? 'disable' : null"
                             class="form-control" [id]="'participant_last_name_' + i"
                             formControlName="last_name" [attr.disabled]="trainingDatePassed || trainingDateRunning ? 'disabled' : null">
                    </div>
                    <div class="col-4">
                      <label class="required" [attr.for]="'participant_civility_' + i">Civilité</label>
                      <select
                        [id]="'participant_civility_' + i"
                        [className]="trainingDatePassed || trainingDateRunning ? 'form-control disable' : 'form-control'"
                        [attr.disabled]="trainingDatePassed || trainingDateRunning ? 'disabled' : null"
                        formControlName="civility"
                        type="text"
                        required
                      >
                        <option value="M.">Monsieur</option>
                        <option value="Mme">Madame</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer" *ngIf="!loader && !loaderDates && !loaderParticipants">
      <div class="col-12">
        <div class="row" id="participants-counter" *ngIf="step == 2">
          <div class="col-10 header-counter">
            <h5>
              {{ participantForm.length + '/' + countPlacesReserved }} participant{{ countPlacesReserved === 1 ? '' : 's' }}
            </h5>
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-primary btn-add-participants"
              [title]="participantForm.length === countPlacesReserved || trainingDateRunning || trainingDatePassed ?
                'Vous ne pouvez pas ajouter plus de participants à cette date' :
                'Ajouter un participant à la date formation'"
              (click)="addNewFormRow()"
              [disabled]="participantForm.length === countPlacesReserved || loaderButtons || trainingDateRunning || trainingDatePassed"
            >
              +
            </button>
          </div>

        </div>

        <div class="row">
          <div class="col-6">
            <button class="btn btn-primary col w-100 m-r-5" (click)="close()" [disabled]="loaderButtons">
              Retour
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-primary col w-100 m-l-5" (click)="nextStep()"
                    [disabled]="trainingDatePassed || trainingDateRunning || loaderButtons || !participantsForm.valid"
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
