export class Dir {
  public type;
  public name = '';
  public id = 0;
  public loader = true;
  public opacity = true;

  constructor(name) {
    this.name = name;
    this.type = 'folder';
  }

  setProgress(progress) {
    this.loader = progress;
    this.opacity = progress;
  }
}
