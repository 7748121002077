<div class="card">
  <div class="card-header d-flex">
    <mat-icon aria-hidden="false" aria-label="">person</mat-icon>
    <h5 class="card-title">
      Utilisateur
    </h5>
  </div>
  <div class="card-body">
    <app-form [fields]="fields" [entity]="example" [errors]="errors" [loader]="loader" (returnEvent)="validateForm($event)" (intactEvent)="intactForm($event)" class="w-100"></app-form>
  </div>
</div>
<app-confirm-modal [type]="'verification'" #pendingModal>{{ bindModal(pendingModal) }}</app-confirm-modal>
