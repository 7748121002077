<div class="row mr-0 ml-0 mt-3">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> {{ isAlert ? 'Alertes réglementaires' : 'Veille réglementaire' }} </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > {{ isAlert ? 'Alertes réglementaires' : 'Veille réglementaire' }} </div>
    </div>
  </div>
</div>

<div class="card card-scroll ml-4 mr-4" *ngIf="access || isAlert">
  <div class="card-body">
    <div class="row h-100" style="min-height:700px;position:relative;max-height:100%;">
      <div class="col-12 h-100 d-flex flex-column" style="max-height:100%;">

        <!-- SEARCH BAR -->
        <div class="row listAlias d-flex align-items-center">
          <div class="search-bar col-12 mb-3">
            <div class="input-group">
              <label for="search-bar"></label>
              <input type="text" class="form-control border-radius" [(ngModel)]="search" aria-label="Barre de recherche" placeholder="Rechercher" id="search-bar">
            </div>
          </div>

          <!-- DATE FROM -->
          <div class="col-lg-5 col-12">
            <div class="form-group mb-3">
              <input type="date" [(ngModel)]="beginDate" [ngModelOptions]="{standalone: true}" class="form-control" id="beginDate" placeholder="Date du" data-toggle="flatpickr">
            </div>
          </div>
          <!-- DATE TO -->
          <div class="col-lg-5 col-12">
            <div class="form-group mb-3">
              <input type="date" [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}" class="form-control" id="endDate" placeholder="Date au" data-toggle="flatpickr">
            </div>
          </div>

          <div class="col-lg-2 col-12">
            <div class="form-group mb-3 w-100">
              <input *ngIf="!loader" type="button" style="min-width:215px;" value="Rechercher" class="btn btn-primary search" [disabled]="loader" (click)="searchData()" />
              <button *ngIf="loader" type="button" style="min-width:215px;" class="btn btn-primary search" [disabled]="loader"><i class="fa fa-spin fa-spinner"></i></button>
            </div>
          </div>

        </div>

        <div class="is-loading loader-content mb-4" *ngIf="loader"></div>

        <div class="row card-veilles border-veille mb-3" *ngIf="!loader && veilles.length">
          <div role="tablist" class="accordion_2 w-100" [attr.id]="'veille' + index" *ngFor="let veille of veilles; let index = index; let first = first">
            <div class="card mb-4" style="border:none!important;">
              <div class="card-header mb-0" style="background:#f9f9f9;border:solid 1px #ededed;" role="tab">
                <div class="d-flex align-items-center">
                  <mat-icon *ngIf="!veille['collapse']" aria-hidden="false" aria-label="" data-toggle="collapse" [attr.href]="'#VeilleList' + index" aria-expanded="true" class="collapsed margin-right" (click)="collapseCard(false, veille)">keyboard_arrow_right</mat-icon>
                  <mat-icon *ngIf="veille['collapse']" aria-hidden="false" aria-label="" data-toggle="collapse" [attr.href]="'#VeilleList' + index" aria-expanded="true" class="collapsed margin-right" (click)="collapseCard(true, veille)">keyboard_arrow_down</mat-icon>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 blue" style="font-size:0.9em;">{{ veille['title'] }}</h6>
                    <div class="d-flex flex-row mb-1">
                      <div *ngFor="let categorie_veille of veille['veilles_categories_veilles']; let index = index; let last = last;">
                        <div class="d-flex d-inline-block">
                          <div class="category" [ngClass]="{'ml-2 mr-2': index !== 0 }">{{ (index !== 0)? ' | ' : '' }}</div>
                          <div class="category">{{ categorie_veille['categorie_veille']['categorie'] }}</div>
                        </div>
                      </div>
                      <div class="category ml-1"> - {{ veille['created'] | date: 'dd/MM/yyyy' }}</div>
                    </div>
                    <small class="newspaper">{{ 'JOURNAL OFFICIEL : ' + veille['newspaper'] }}</small>
                  </div>
                </div>
              </div>
              <div class="card-body collapse" [attr.id]="'VeilleList' + index" role="tabpanel" [attr.data-parent]="'#veille' + index">
                <p class="mb-0" style="font-size:0.9em;" [innerHTML]="veille['content']"></p>
              </div>

              <ng-container *ngIf="!isAlert && access">
                <div class="pl-4 pt-4" *ngIf="veille['analyse']">
                  <h5>Notre analyse</h5>
                  <p style="font-size:0.9em;" [innerHTML]="veille['analyse']"></p>
                </div>
                <div class="pl-4" *ngIf="veille['conclusion']">
                  <h5>Notre conclusion</h5>
                  <p style="font-size:0.9em;" [innerHTML]="veille['conclusion']"></p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- NO RESULT -->
        <div class="row listAlias" *ngIf="!loader && !veilles.length">
          <div class="col-12">
            <app-no-result *ngIf="!veilles.length" class="h-100" title="Aucune donnée" width="200"></app-no-result>
            <div *ngIf="updatePatrimony" class="d-flex align-items-center justify-content-center mt-4">
              <input type="button" style="width:unset;" value="Modifier mon patrimoine" class="btn btn-primary search" [routerLink]="'/account'"/>
            </div>
          </div>
        </div>

        <!-- PAGINATION -->
        <div class="mt-2">
          <app-pagination *ngIf="veilles" [page]="page" [limit]="limit" [pageSize]="veilles.length" [pages]="pages" [total]="total" (returnEvent)="changePagination($event)"></app-pagination>
        </div>

      </div>
    </div>

  </div>
</div>

<div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center mt-4" *ngIf="!access && !isAlert">
  <app-no-result width="400" picture="../../../assets/img/subscribe.svg" title="Découvrir la veille réglementaire" subTitle="Vous n'avez pas encore accès à cette page. Pour cela, vous devez souscrire au pack Veille réglementaire. N'hésitez pas à contacter Batisafe."></app-no-result>
  <a href="mailto:contact@batisafe.fr" target="_blank" class="btn btn-primary mt-3">Contacter BatiSafe</a>
</div>
