import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllQuestionsModule } from './questions/all-questions/all-questions.module';
import { CreateQuestionModule } from './questions/create-question/create-question.module';
import { AllAnswersModule } from './questions/all-answer/all-answers.module';
import { AllVeillesModule } from './veilles/all-veilles.module';

import { TheonormeResolver } from '../../core/services/theonorme/theonorme.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllQuestionsModule,
    AllAnswersModule,
    CreateQuestionModule,
    AllVeillesModule,
  ],
  providers: [
    TheonormeResolver,
  ]
})
export class TheonormeModule {}
