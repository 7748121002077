import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllTicketsModule } from './all-tickets/all-tickets.module';
import { CreateTicketModule } from './create-ticket/create-ticket.module';
import { AllCommentsModule } from './all-comments/all-comments.module';

import { TicketsResolver } from '../../core/services/tickets/tickets.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllTicketsModule,
    AllCommentsModule,
    CreateTicketModule,
  ],
  providers: [
    TicketsResolver,
  ]
})
export class TicketsModule {}
