import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class CustomNotifierService {

  constructor(
    private notifier: NotifierService,
  ) {}

  errorsForm(errors: any, form: FormGroup) {
    if (errors && 'general' in errors) {
      this.notifier.notify('error', errors.general.message);
    } else if (errors) {
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          if (key in form.controls) {
            form.controls[key].setErrors(({ key: errors[key] }));
            form.controls[key].markAsTouched();
          }
        }
      }
    }
  }

  errors(errors: any) {
    if (errors && 'general' in errors) {
      this.notifier.notify('error', errors.general.message);
    }
  }

  errorRequest() {
    this.notifier.notify(
      'error',
      'Une erreur est survenue avec le serveur, veuillez ressayer ultérieurement. Si le problème persiste, contactez un administrateur.'
    );
  }

  errorNotAuthorized() {
    this.notifier.notify('error', 'Vous n\'avez pas les droits pour accéder à cette page.');
  }

  errorNotConnected() {
    this.notifier.notify('error', 'Vous n\'êtes pas connecté, veuillez vous connecter.');
  }

  errorDisconnected() {
    this.notifier.notify('error', 'Vous n\'êtes plus connecté, veuillez vous reconnecter.');
  }

  errorDownload() {
    this.notifier.notify('error', 'Le fichier que vous souhaitez télécharger n\'existe pas dans notre base de données.');
  }

  errorCustom(message) {
    this.notifier.notify('error', message);
  }

  successCreate() {
    this.notifier.notify('success', 'Création effectuée avec succès.');
  }

  successUpdate() {
    this.notifier.notify('success', 'Modification effectuée avec succès.');
  }

  successDelete() {
    this.notifier.notify('success', 'Suppression effectuée avec succès.');
  }

  successEmail() {
    this.notifier.notify('success', 'Un email vous a été envoyé.');
  }

  successPayment() {
    this.notifier.notify('success', 'Paiement effectuée avec succès.');
  }

  successDownload() {
    this.notifier.notify('success', 'Téléchargement effectué avec succès.');
  }

  successCustom(message) {
    this.notifier.notify('success', message);
  }

}
