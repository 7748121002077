import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class TrainingsRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Retrieves all trainings for the client
   *
   * @param {number} client
   * @param {string} search
   * @param {string} sort
   * @param {string} order
   * @param {number} limit
   * @param {number} page
   * @param token
   */
  public getTrainings(client: number,
                      search: string = '',
                      sort: string = '',
                      order: string = '',
                      limit: number = 0,
                      page: number = 1,
                      token = null) {
    return this.get('');
  }

  /**
   * Retrieves all trainings dates for the client, including the quotation, the train and participants
   *
   * @param {string} search
   * @param {string} sort
   * @param {string} order
   * @param {number} limit
   * @param {number} page
   * @param token
   */
  public getTrainingsDates(search: string = '',
                           sort: string = '',
                           order: string = '',
                           limit: number = 0,
                           page: number = 1,
                           token = null) {
    return this.get('api/portal-trainings-dates?search=' + search +
      '&sort=' + sort +
      '&order=' + order +
      '&limit=' + limit +
      '&page=' + page);
  }

  /**
   * Retrieve all training dates for the given training
   *
   * @param {number} trainingId
   */
  public getTrainingDates(trainingId: number) {
    return this.get('api/portal-training-dates/' + trainingId);
  }

  /**
   * Create participants for the given training
   *
   * @param participants
   */
  public createTrainingParticipants(participants) {
    return this.post('api/portal-training-participants', participants);
  }

  /**
   * Update participants for the given training
   *
   * @param participants
   */
  public updateTrainingParticipants(participants) {
    return this.put('api/portal-training-participants', participants);
  }

  /**
   * Reserve places for participants
   *
   * @param {number} trainingDate
   * @param places
   */
  public reserveParticipantsPlaces(trainingDate: number, places) {
    return this.put('api/portal-training-reserve-places/' + trainingDate, places);
  }

  /**
   * Retrieve participants for training date (using quotation)
   *
   * @param {number} quotation
   */
  public retrieveParticipantsQuotation(quotation: number) {
    return this.get('api/portal-quotation-get-participants/' + quotation);
  }

  /**
   * Retrieve free places for a training date
   *
   * @param {number} trainingDate
   */
  public retrieveTrainingDateFreePlaces(trainingDate: number) {
    return this.get('api/portal-free-places-training-date/' + trainingDate);
  }

  /**
   * Retrieve one specific training date
   *
   * @param {number} trainingDate
   */
  public getTrainingDate(trainingDate: number) {
    return this.get('api/portal-training-date/' + trainingDate);
  }

  /**
   * Retrieve all participants for the training date
   *
   * @param {number} trainingDates
   */
  public getParticipants(trainingDates: number) {
    return this.get('');
  }

  /**
   * As a client, retrieve the convocations for participants
   *
   * @param trainingDateId
   */
  public getAllConvocations(trainingDateId: number) {
    return this.get('api/portal-client-convocations/' + trainingDateId);
  }

  /**
   * As a client, retrieve the certificates for participants
   *
   * @param trainingDateId
   */
  public getAllCertificates(trainingDateId: number) {
    return this.get('api/portal-client-certificates/' + trainingDateId);
  }

  /**
   * Send an agreement signed file
   *
   * @param quotationId
   * @param file
   */
  public sendAgreementSigned(quotationId: number, file) {
    return this.post('api/portal-signed-agreement/' + quotationId, file);
  }

  /**
   * Retrieve the previous agreement signed file
   *
   * @param quotationId
   */
  public getPreviousAgreementSigned(quotationId: number) {
    return this.get('api/portal-signed-agreement/' + quotationId);
  }

  /**
   * Retrieve all survey responses given by participants on the training date
   *
   * @param quotationId
   */
  public getParticipantsSurveyResponses(quotationId: number = null) {
    return this.get('api/portal-client-get-survey-responses/' + quotationId);
  }

  // public getTrainingsList(search = '', sort = '', order = '', limit = 0, page = 1, token = null) {
  //   return this.getErp('formations?search=' + search +
  //  '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page, {}, token);
  // }


  // ///////////////////////////////////////////
  // || Participants ||||||||||||||||||||||||||
  // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  /**
   * Get trainings dates for the participant
   *
   * @param {string} search
   * @param {string} sort
   * @param {string} order
   * @param {number} limit
   * @param {number} page
   * @param token
   */
  public getTrainingsDatesParticipant(search: string = '',
                                      sort: string = '',
                                      order: string = '',
                                      limit: number = 12,
                                      page: number = 1,
                                      token = null) {
    return this.get('api/portal-participants-trainings-dates?search=' + search +
      '&sort=' + sort +
      '&order=' + order +
      '&limit=' + limit +
      '&page=' + page);
  }

  /**
   * Get the training date for the participant
   *
   * @param {number} trainingParticipantId The `id` column in `training_participants` table
   */
  public getTrainingDateParticipant(trainingParticipantId: number) {
    return this.get('api/portal-participant-training/' + trainingParticipantId);
  }

  /**
   * Participant sign the agreement
   *
   * @param {any} agreement
   */
  public signAgreement(agreement: any) {
    return this.put('', agreement);
  }

  /**
   * Retrieve the agreement file to download
   *
   * @param quotationId
   */
  public getAgreement(quotationId: number) {
    return this.get('api/portal-training-agreement/' + quotationId);
  }

  /**
   * Retrieve the training date material
   *
   * @param {number} trainingDateId
   */
  public getTrainingDateMaterials(trainingDateId: number) {
    return this.get('api/portal-training-materials/' + trainingDateId);
  }

  /**
   * Retrieve the training date agreement
   *
   * @param trainingDateId
   */
  public getTrainingDateAgreement(trainingDateId: number) {
    return this.get('api/portal-training-agreement/' + trainingDateId);
  }

  /**
   * Retrieve the convocation of training date for the participant
   *
   * @param {number} trainingDateId
   */
  public getConvocation(trainingDateId: number) {
    return this.get('api/portal-participant-convocation/' + trainingDateId);
  }

  /**
   * Retrieve the training certificate of training date for the participant
   *
   * @param {number} trainingDateId
   */
  public getCertificate(trainingDateId: number) {
    return this.get('api/portal-participant-certificate/' + trainingDateId);
  }

  /**
   * Retrieve all participants sign in sheets
   *
   * @param clientId
   * @param trainingDateId
   */
  public getSignInSheetsParticipants(clientId, trainingDateId) {
    return this.get('api/portal/sign-in-sheets/' + trainingDateId);
  }
}
