import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Sanitizer,
  SimpleChanges
} from '@angular/core';

import TicketColumn from './ticket-column';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import { PreviewService } from '../../../core/services/helper/preview.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
export class TicketListComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() columns: TicketColumn[];
  @Input() items: any[];
  @Output() returnEvent = new EventEmitter<any>();

  @Input() search = '';
  @Input() sort = 'created';
  @Input() order = 'desc';
  @Input() limit = 0;
  @Input() page = 1;
  @Input() total = 0;
  @Input() pages = 1;
  @Input() hideAction;
  @Input() hideAdd;
  @Input() preview;
  @Output() previewChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() menu;
  @Input() createEvent;
  @Input() loader;
  @Input() loaderPreview: boolean;
  @Input() hasCustomField: boolean;

  environment = environment;
  modal: ConfirmModalComponent;
  public config: PerfectScrollbarConfigInterface = {};

  timeout;
  objectPreview: any;
  base64: any;
  isMobile: boolean;
  id: any;

  constructor(
    public sanitizer: DomSanitizer,
    private _previewService: PreviewService
  ) {
    this.loaderPreview = this._previewService.loader; // Set the default value to avoid overlay display
  }

  ngOnInit() {
    if (this.hasCustomField === undefined) {
      this.hasCustomField = true;
    }

    if (this.preview === undefined) {
      this.preview = false;
    }

    if (this.hideAction === undefined) {
      this.hideAction = false;
    }

    if (this.hideAdd === undefined) {
      this.hideAdd = false;
    }

    this.isMobile = (document.body.offsetWidth < 992);
  }

  ngAfterViewInit() {
    this.modal.eventOnClose.subscribe(event => {
      if (event) {
        this.returnEvent.emit({ action: 'delete', value: event });
      }
    });
  }

  ngOnDestroy() {
    const tooltips = document.getElementsByClassName('tooltip') as HTMLCollection;
    // @ts-ignore
    for (const tooltip of tooltips) {
      tooltip.remove();
    }
  }

  sortBy(property: string) {
    if (this.sort === property) {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    } else {
      this.order = 'asc';
      this.sort = property;
    }
    this.returnEvent.emit({ action: 'sort', value: property, order: this.order });
  }

  changePagination(data) {
    this.returnEvent.emit(data);
  }

  filterTable(event, table) {
    table.checked = !table.checked;
    event.stopPropagation();
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.returnEvent.emit({ action: 'search', value: this.search });
    }, 500);
  }

  bindModal(deleteModal: ConfirmModalComponent) {
    this.modal = deleteModal;
  }

  getColSpan() {
    let sum = 2;
    this.columns.forEach(column => {
      if (column.checked) {
        sum++;
      }
    });
    return sum;
  }

  getProperty(item: any, object: any) {
    if (typeof object.property === 'string') {
      if (object.property === 'date') {
        return item.customField[object.property].date;
      } else if (object.type === 'agencies') {
        return item.customField[object.property].label;
      }

      if (typeof item.customField[object.property] === 'object' && item.customField[object.property] !== null) {
        return moment(item.customField[object.property].date).format('DD/MM/YYYY');
      } else if (object.type === 'currency') {
        return parseFloat(item.customField[object.property]).toFixed(2).replace('.', ',');
      } else {
        return item.customField[object.property];
      }
    } else {
      let column = '';
      object.property.forEach(prop => {
        if (column) {
          column = column[prop];
        } else {
          column = item.customField[prop];
        }
      });
      return column;
    }
  }

  getDefaultType(type) {
    if (type === 'text' ||
      type === 'select' ||
      type === 'select_link' ||
      type === 'select_array' ||
      type === 'users' ||
      type === 'user_signed' ||
      type === 'agencies' ||
      type === 'number_auto' ||
      type === 'number' ||
      type === 'percent' ||
      type === 'editor') {
      return true;
    }

    return false;
  }

  clickColumn(event) {
  }

  emitMenu(id, object = null) {
    this.returnEvent.emit({ action: id['callback'], value: (object === null) ? this.objectPreview : object });
  }

  select(item: any) {
    this.objectPreview = item;

    this.returnEvent.emit({ action: 'select', value: item });
  }

  closePreview() {
    this.preview = false;
    this.previewChange.emit(this.preview);
  }

  isVisible(objectPreviewElement: any, nav: any) {
    if (nav['condition_notegal'] !== undefined) {
      if (objectPreviewElement[nav['condition_name']] != undefined) {
        if (typeof objectPreviewElement[nav['condition_name']] === 'string') {
          return objectPreviewElement[nav['condition_name']] !== nav['condition_notegal'];
        } else if (typeof objectPreviewElement[nav['condition_name']] === 'object') {
          if (objectPreviewElement[nav['condition_name']]['value'] !== undefined) {
            return objectPreviewElement[nav['condition_name']]['value'] !== nav['condition_notegal'];
          } else if (objectPreviewElement[nav['condition_name']]['name'] !== undefined) {
            return objectPreviewElement[nav['condition_name']]['name'] !== nav['condition_notegal'];
          }
        }
      }
    }

    return true;
  }

  createEventAction() {
    this.returnEvent.emit({ action: 'create'});
  }

  showPreview(preview) {
    this.isMobile = (document.body.offsetWidth < 992);
    this.preview = preview;
  }

  formatBase64(preview) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(preview.replace('octet-stream', 'pdf'))
  }

  download() {
    this.returnEvent.emit({ action: 'download', value : {id : this.id}});
  }
}
