import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AllQuotationsComponent } from './all-quotations.component';
import { ListModule } from '../../../core/list/list.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { QuotationModalModule } from '../../../core/quotation-modal/quotation-modal.module';
import { NoResultModule } from '../../../core/no-result/no-result.module';
import { QuotationRefuseModalModule } from '../../../core/quotation-refuse-modal/quotation-refuse-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    ListModule,
    ConfirmModalModule,
    QuotationModalModule,
    NoResultModule,
    QuotationRefuseModalModule
  ],
  declarations: [AllQuotationsComponent]
})

export class AllQuotationsModule {}
