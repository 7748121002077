import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrainingsResolver } from '../../core/services/trainings/trainings.resolver';
import { AllFilesModule } from './all-files/all-files.module';
import { FilesResolver } from '../../core/services/files/files.resolver';
import { DetailFilesModule } from './detail-files/detail-files.module';
import { FilesDetailResolver } from '../../core/services/files/filesDetail.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllFilesModule,
    DetailFilesModule
  ],
  providers: [
    FilesResolver,
    FilesDetailResolver
  ]
})
export class FilesModule {}
