import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { TheonormeRequestService } from '../../../core/services/theonorme/theonorme.request.service';
import { NotifierService } from 'angular-notifier';

declare function Flatpickr(): any;

@Component({
  selector: 'app-all-veilles',
  templateUrl: './all-veilles.component.html',
  styleUrls: ['./all-veilles.component.scss'],
})
export class AllVeillesComponent implements OnInit, OnDestroy {
  requestInProgress = new Subject();
  requestCount = 0;

  public loader: boolean;
  public collapse: boolean;
  public updatePatrimony: boolean;

  veilles = [];
  timeout: any;

  beginDate = '';
  endDate = '';

  total = 0;
  pages = 1;
  search = '';
  sort = '';
  order = 'asc';
  limit = 6;
  page = 1;
  access = false;

  isAlert: boolean;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private theonormeServices: TheonormeRequestService,
  ) {}

  ngOnInit() {
    this.isAlert = this.activatedRoute.snapshot.routeConfig.path === 'theonorme/alertes-reglementaires'

    this.activatedRoute.data.subscribe(response => {
      // Set access, regardless of the result of the query
      if (response.data) {
        if (response.data.access !== undefined) {
          this.access = response.data.access;
        }

        if (response.data.success && response.data.veilles) {
          this.veilles = response.data.veilles;
          this.total = response.data.total;
          this.pages = response.data.pages;
          this.updatePatrimony = false;
        } else {
          this.updatePatrimony = true;
        }
      }
      Flatpickr();
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
  }

  ngOnDestroy() {
    const flatPicker = document.getElementsByClassName('flatpickr-calendar') as HTMLCollection;
    for (let i = flatPicker.length; i >= 0; i--) {
      if (flatPicker[i]) {
        flatPicker[i].remove();
      }
    }
  }

  collapseCard(bool, veille) {
    veille.collapse = !bool;
  }

  getData() {
    this.requestInProgress.next(true);

    this.theonormeServices.getVeilles(this.isAlert, this.search, this.sort, this.order, this.limit, this.page,
      this.beginDate, this.endDate).subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        this.veilles = response.data.veilles;
        this.total = response.data.total;
        this.pages = response.data.pages;
      }
    });
  }

  searchData() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.page = 1;
      this.refreshData();
    }, 500);
  }

  refreshData(sort = this.sort, order = this.order, page = this.page) {
    this.sort = sort;
    this.order = order;
    this.page = page;
    this.getData();
  }

  changePagination(data) {
    if (data.action === 'pageSize') {
      this.refreshPage(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    }
  }
  refreshPage(limit) {
    this.limit = limit;
    this.page = 1;
    this.refreshData();
  }
  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getData();
    }
  }
}
