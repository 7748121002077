import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth/auth.guard';
import { LayoutComponent } from './core/layout/layout.component';

import { dashboardRoutes } from './pages/dashboard/dashboard.routing';
import { authRoutes } from './pages/auth/auth.routing';
import { examplesRoutes } from './pages/examples/examples.routing';
import { quotationsRoutes } from './pages/quotations/quotations.routing';
import { invoicesRoutes } from './pages/invoices/invoices.routing';
import { trainingsRoutes } from './pages/trainings/trainings.routing';
import { filesRoutes } from './pages/files/files.routing';
import { accountRoutes } from './pages/account/account.routing';
import { legalsRoutes } from './pages/legals/legals.routing';
import { notificationsRoutes } from './pages/notifications/notifications.routing';
import { ticketsRoutes } from './pages/tickets/tickets.routing';
import { theonormeRoutes } from './pages/theonorme/theonorme.routing';
import { notFoundRoutes } from './pages/not-found/not-found.routing';
import { not } from 'rxjs/internal-compatibility';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      ...dashboardRoutes,
      ...examplesRoutes,
      ...quotationsRoutes,
      ...invoicesRoutes,
      ...trainingsRoutes,
      ...filesRoutes,
      ...accountRoutes,
      ...notificationsRoutes,
      ...ticketsRoutes,
      ...theonormeRoutes,
      // ...componentRoutes,
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      ...legalsRoutes,
    ]
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    children: [
      ...authRoutes
    ]
  },
  {
    path: '**',
    component: LayoutComponent,
    children: [
      ...notFoundRoutes,
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
