import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

import { PageList } from '../../core/list/page-list';
import Column from '../../core/list/column';

import { AuthService } from '../../core/auth/auth.service';

import { FilesRequestService } from '../../core/services/files/files.request.service';
import { AccountRequestService } from '../../core/services/account/account.request.service';
import { CustomNotifierService } from '../../core/services/custom.notifier.service';

import { UserUpdate, UserUpdatePassword } from '../../core/interfaces/user/response/update';
import { PatrimonyRequestService } from '../../core/services/theonorme/patrimony/patrimony.request.service';
import ValidatorPassword from "../../core/custom-validators/password.directive";

@Component({
  selector: 'app-all-account',
  templateUrl: './all-account.component.html',
  styleUrls: ['./all-account.component.scss'],
})

export class AllAccountComponent extends PageList implements OnInit {
  public columns: Column[] = [];
  public loader: boolean;
  public loaderPassword: boolean;
  public loaderAccount: boolean;
  public loaderPatrimony: boolean;
  public isNew: boolean;
  public patrimonyId: number;

  public account;

  public accountForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, ValidatorPassword()]),
    password_r: new FormControl('', [Validators.required, ValidatorPassword()])
  });

  selectedEstablishments: [];
  selectedRegistries: [];
  arrayEstablishmentTypes = [];
  arrayCategories = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _plansService: FilesRequestService,
    private _notifier: NotifierService,
    private _customNotifier: CustomNotifierService,
    private _accountService: AccountRequestService,
    private _patrimonyService: PatrimonyRequestService,
  ) {
    super();
  }

  /**
   * On init
   */
  ngOnInit() {
    // Set loader on true
    this.loader = true;
    this.loaderPassword = false;
    this.loaderAccount = false;

    // Get resolver data
    this._activatedRoute.data.subscribe((response: any) => {

      if (response.account && response.account.success) {
        const user = response.account.user;
        this.accountForm.get('firstname').setValue(user.firstname);
        this.accountForm.get('lastname').setValue(user.lastname);
        this.accountForm.get('email').setValue(user.email);
      } else {
        this._customNotifier.errorCustom(response.data.message);
      }

      if (response.patrimony && response.patrimony.success) {
        if (response.patrimony.data !== null) {
          this.patrimonyId = response.patrimony.data.id;
          this.selectedEstablishments = response.patrimony.data.establishments;
          this.selectedRegistries = response.patrimony.data.registries;
          this.isNew = false;
        } else {
          this.isNew = true;
          this.selectedEstablishments = [];
          this.selectedRegistries = [];
        }
      } else {
        this._customNotifier.errorCustom(response.data.message);
      }

      if (response.category_veille && response.category_veille.success) {
        this.arrayCategories = response.category_veille.data.categorie_veilles;
      } else {
        this._customNotifier.errorCustom(response.data.message);
      }

      if (response.establishments && response.establishments.success) {
        this.arrayEstablishmentTypes = response.establishments.data.building_types;
      } else {
        this._customNotifier.errorCustom(response.data.message);
      }
    });
  }

  accountUpdate() {
    const obj = {
      firstname: this.accountForm.get('firstname').value,
      lastname: this.accountForm.get('lastname').value,
    };

    this.loaderAccount = true;
    this._accountService.updateAccountInfo(obj).subscribe((response: UserUpdate) => {
      this.loaderAccount = false;
      if (response.success) {
        this._notifier.notify('success', response.message);
        this.authService.user.firstname = response.user.firstname;
        this.authService.user.lastname = response.user.lastname;

        /*
        Not clean but temp for fast pre-production
         */
        const user = JSON.parse(localStorage.getItem('user'));
        user.firstname = response.user.firstname;
        user.lastname = response.user.lastname;
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        this._notifier.notify('error', response.message);
      }
    });
  }

  passwordUpdate() {
    this.loaderPassword = true;
    this._accountService.updateAccountPassword({ password: this.accountForm.get('password').value })
      .subscribe((response: UserUpdatePassword) => {
        this.loaderPassword = false;
        if (response.success) {
          this.accountForm.get('password').reset();
          this.accountForm.get('password_r').reset();
          this._notifier.notify('success', response.message);
        } else {
          this._notifier.notify('error', response.message);
        }
      });
  }

  modifyPatrimony() {
    const data = {
      establishments : this.selectedEstablishments,
      registries : this.selectedRegistries
    };

    this.loaderPatrimony = true;
    let request;
    if (this.isNew) {
      request = this._patrimonyService.createPatrimony(data);
    } else {
      request = this._patrimonyService.updatePatrimony(this.patrimonyId, data);
    }
    request.subscribe((response: any) => {
        this.loaderPatrimony = false;
        if (response.success) {
          if (response.data) {
            this.isNew = false;
            this.patrimonyId = response.data.id;
            this.selectedEstablishments = response.data.establishments;
            this.selectedRegistries = response.data.registries;
          }
          this._notifier.notify('success', response.message);
        } else {
          this._notifier.notify('error', response.message);
        }
      });
  }
}
