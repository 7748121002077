<div class="row mr-0 ml-0 mt-3">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> Formations </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Liste des formations </div>
    </div>
  </div>
</div>

<div class="row ml-4 mr-4">
  <div class="col-12">
     <!--Pas de faute sur "inscrits", voir https://leconjugueur.lefigaro.fr/conjugaison/verbe/inscrire_negation-pronominal.html -->
    <!--<app-no-result *ngIf="!trainingDates.length" title="Aucune formation"-->
    <!--               subTitle="Vous ne vous êtes pas inscrits à au moins une formation">-->
    <!--</app-no-result>-->
    <!--<app-list *ngIf="trainingDates.length"-->
    <app-list [hideAdd]="true"
              [hideAction]="true"
              [hideSort]="true"
              [columns]="columns"
              [items]="trainingDates"
              [total]="total"
              [pages]="pages"
              [page]="page"
              [hasCustomField]="false"
              [isTrainingList]="true"
              [isTrainingClient]="!isParticipant"
              [currentPage]="'trainings'"
              (returnEvent)="updateList($event)"
              class="w-100"
    >
    </app-list>
  </div>

  <app-modal-training #trainingModal>{{ binModal(trainingModal) }}</app-modal-training>
</div>
