import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

import Training from '../../interfaces/training/training';
import TrainingParticipant, { TrainingParticipantInformation } from '../../interfaces/training/training_participant';
import TrainingDate, { TrainingDates } from '../../interfaces/training/training_date';
import { TrainingsRequestService } from '../../services/trainings/trainings.request.service';
import Quotation from '../../interfaces/quotation/quotation';
import {
  TrainingParticipantCreateResponse,
  TrainingParticipantRetrieveResponse,
  TrainingParticipantUpdateResponse
} from '../../interfaces/training/response/training_participant';
import { TrainingDateFreePlacesResponse } from '../../interfaces/training/response/training_date';
import { CustomNotifierService } from '../../services/custom.notifier.service';
import Field from '../../form/field';

import * as _ from 'lodash';
import { TrainingsService } from '../../services/trainings/trainings.service';

declare var $: any;

@Component({
  selector: 'app-modal-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class ModalTrainingComponent implements AfterViewInit, OnInit {
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  public view = {};
  public modalOptions: NgbModalOptions;
  public modalTraining = null;
  public moment = moment;

  public fields: Field[] = [
    {
      label: 'Identifiant', showLabel: false, property: 'id', type: 'number',
      formControl: new FormControl({ value: '', disable: true })
    },
    {
      label: 'Email', showLabel: true, property: 'email', type: 'text',
      formControl: new FormControl({ value: '', disable: false }, [Validators.email, Validators.required])
    },
    {
      label: 'Prénom', showLabel: true, property: 'first_name', type: 'text',
      formControl: new FormControl({ value: '', disable: false }, [Validators.required])
    },
    {
      label: 'Nom', showLabel: true, property: 'last_name', type: 'text',
      formControl: new FormControl({ value: '', disable: false }, [Validators.required])
    },
    {
      label: 'Civilité', showLabel: true, property: 'civility', type: 'select',
      selectItems: [
        { value: 'M.', label: 'Monsieur' },
        { value: 'Mme.', label: 'Madame' },
        { value: 'Mlle.', label: 'Mademoiselle' }
      ],
      formControl: new FormControl({ value: '', disable: false }, [Validators.required])
    }
  ];

  /* Not sure to keep. Can be used to check if the client has already reserved places for these participants */
  public dateAlreadyReserved: boolean;
  public loader: boolean;
  public loaderDates: boolean;
  public loaderParticipants: boolean;
  public loaderPlaces: boolean;
  public loaderButtons: boolean;
  public isCreation: boolean;
  public trainingDatePassed: boolean;
  public trainingDateRunning: boolean;
  public trainingDateNotSet: boolean;

  /* Current step on the training validation */
  public step: number;
  public totalFreePlaces: number;
  public countPlacesReserved: number;

  public idsNotPresent: any = [];

  /* The date chosen */
  public selectedDate: any;

  // //////////////////////
  // Forms
  // //////////////////////
  /* Form array for each participants */
  public participantsForm = this._formBuilder.group({
    participants: this._formBuilder.array([]) as FormArray
  });

  /* Controller for participants in form array */
  public participantsFormCtrl = this.participantsForm.get('participants') as FormArray;

  /* Form for each training dates */
  public trainingDatesForm: FormGroup;

  /* Form for total participants of a training date */
  public totalParticipants = new FormControl(
    '',
    [
      Validators.required,
      Validators.min(1)
    ]
  );

  /* Form select for training dates */
  public trainingDatesSelect = new FormControl(
    '',
    [
      Validators.required
    ]
  );
  // //////////////////////
  // End forms
  // //////////////////////

  constructor(
    private _formBuilder: FormBuilder,
    private _rootNode: ElementRef,
    private _notifier: CustomNotifierService,
    private _trainingsRequestService: TrainingsRequestService,
    private _trainingsService: TrainingsService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      ariaLabelledBy: 'confirm-modal'
    };
  }

  get training(): Training {
    return this._trainingsService.training;
  }

  set training(training: Training) {
    this._trainingsService.training = training;
  }

  get trainingDate(): TrainingDate {
    return this._trainingsService.trainingDate;
  }

  set trainingDate(trainingDate: TrainingDate) {
    this._trainingsService.trainingDate = trainingDate;
  }

  get trainingDates(): TrainingDate[] {
    return this._trainingsService.trainingDates;
  }

  set trainingDates(trainingDates: TrainingDate[]) {
    this._trainingsService.trainingDates = trainingDates;
  }

  get quotation(): Quotation {
    return this._trainingsService.quotation;
  }

  set quotation(quotation: Quotation) {
    this._trainingsService.quotation = quotation;
  }

  /**
   * Load component
   */
  ngOnInit(): void {
    this.loader = false;
    this.loaderDates = false;
    this.loaderParticipants = false;
    this.loaderPlaces = false;
    this.trainingDatePassed = false;
    this.trainingDateRunning = false;
    this.trainingDateNotSet = false;
    this.isCreation = false;
    this.trainingDatesForm = this._formBuilder.group({
      totalParticipants: this.totalParticipants,
      trainingDatesSelect: this.trainingDatesSelect
    });
  }

  ngAfterViewInit() {
    this.modalTraining = $(this._rootNode.nativeElement).find('div.modal-confirm');
    const self = this;
    $('#trainingModal').on('hidden.bs.modal', () => {
      this._cleanComponent();
      self.eventOnClose.next({ action: 'close' });
    });
  }

  /**
   * Open the modal.<br/>
   * The modal allows to do these actions.<br/>
   * If the <u>step is 1</u>, there is no date selected for the training,
   * then, the client must choose the date (if one of the date(s) is(are) scheduled for the training)
   * and assign a number of places to reserve.
   * <i>/!\ Please note that the customer will not be able to modify the places allocated (after validation)
   * and will not be able to reserve more places than available.</i><br/>
   * If the <u>step is 2</u>, the date and the number of places are reserved,
   * the user can only modify the information of the participants.
   *
   * @param {Training} training
   * @param {Quotation} quotation
   * @param {TrainingDate?} date
   * @param {number} step
   * <ul>
   *   <li>Step 1 : Choose date and places for participants</li>
   *   <li>Step 2 : Update participants informations</li>
   * </ul>
   */
  open(training: Training, quotation: Quotation, date: TrainingDate = null, step: 1|2 = 1) {
    // Reset the component when open it
    this._cleanComponent();

    // If client already has participants linked to the training, he already reserved a date.
    this.step = date !== null ? 2 : 1;
    this.dateAlreadyReserved = this.step === 2;
    // Directly set the training variable with the current training
    this.training = training;
    this.quotation = quotation;

    if (this.step === 1) {
      this._retrieveDates(training);
      this.isCreation = true;
    } else {
      this._retrieveParticipants();

      // this.trainingDate = date;
      // this.trainingDate.start_format = moment(this.trainingDate.start_at.date).format('DD/MM/YYYY');
      // this.trainingDate.end_format = moment(this.trainingDate.ended_at.date).format('DD/MM/YYYY');
      if (!this.trainingDate.start_at && !this.trainingDate.ended_at) {
        this.trainingDateNotSet = true;
      } else {
        /* Now date time moment object */
        const now = moment();
        /* Training date start in moment object */
        const training_date_start = moment(this.trainingDate.start_at.date);
        /* Training date end in moment object */
        const training_date_end = moment(this.trainingDate.ended_at.date);

        if (now.isSameOrAfter(training_date_start)) { // The training has begun or is passed
          if (now.isSameOrBefore(training_date_end)) { // The training is now and is not finished
            this.trainingDateRunning = true;
          } else {
            this.trainingDatePassed = true;
          }
        }

        if (this.trainingDate.start_at_format !== this.trainingDate.ended_at_format) {
          this.trainingDate.select_date = 'du ' + this.trainingDate.start_at_format + ' au ' + this.trainingDate.ended_at_format;
        } else {
          this.trainingDate.select_date = 'du ' + this.trainingDate.start_at_format;
        }
      }
    }

    this.modalTraining.modal('show');
  }

  /**
   * When close modal, do some actions like clean forms etc...
   * And return an event object.
   *
   * @param {string} eventClose Some actions are made different the event provided
   */
  close(eventClose: string = null) {
    if (eventClose === 'update_training_participants') {
      this.eventOnClose.next({
        action: eventClose,
        training_participants: this.trainingParticipants,
        quotation: this.quotation,
        training: this.training,
        training_date: this.trainingDate
      });
    } else if (eventClose === 'create_training_participants' || this.isCreation) {
      this.eventOnClose.next({
        action: eventClose,
        training_participants: this.trainingParticipants,
        total_training_participants: this.totalParticipants.value,
        quotation: this.quotation,
        training: this.training,
        training_date: this.trainingDate
      });
    } else {
      this.eventOnClose.next({
        action: 'close'
      });
    }

    this._cleanComponent();
    this.modalTraining.modal('hide');
  }

  /**
   * When the select date is changed, retrieve free places
   *
   * @param event
   */
  changeSelectDate(event: TrainingDate) {
    this.trainingDate = event;
    this._retrieveTrainingDatesFreePlaces(event);
  }

  /**
   * Add new entry in the participants form array by pushing it.
   *
   * @param {TrainingParticipant|TrainingParticipantInformation?} participant The participant can be provided if
   *                                                                          is retrieved from database, else,
   *                                                                          keep empty.
   */
  addParticipant(participant: any = null): void {
    if (participant && participant[`email`] !== null) {
      const newGroup = this._getParticipantFormGroup();
      if (participant) {
        newGroup.setValue({
          id: participant.id,
          email: participant.email,
          first_name: participant.first_name,
          last_name: participant.last_name,
          civility: participant.civility
        });
      }
      this.participantsFormCtrl.push(newGroup);
    } else {
      // temp
      const newGroup = this._getParticipantFormGroup();
      if (participant) {
        newGroup.setValue({
          id: participant.id,
          email: null,
          first_name: null,
          last_name: null,
          civility: null
        });
      }
      this.participantsFormCtrl.push(newGroup);
    }
  }

  /**
   * Change step on modal.<br/>
   * The first step is date selection and places reservation.<br/>
   * The second step is participants information.
   */
  nextStep() {
    if (this.step === 1) {
      this._reserveParticipantsPlaces();
      for (let i = 0; i < this.totalParticipants.value; i++) {
        this.addParticipant();
      }
    } else {
      // If a smart kid is having fun removing the disabled attribute in the html, do another check here
      if (!this.trainingDatePassed && !this.isCreation) {
        this._createParticipants(true);
      }

      if (this.isCreation) {
        this._createParticipants();
      }
    }
  }

  /**
   * Clear the participant information and remove the form row
   *
   * @param participant
   * @param index The index in the participants form to remove
   */
  clearParticipant(participant: FormGroup, index: number) {
    // Retrieve the id and set it in the idsNotSetArray (if want to re-add someone)
    this.idsNotPresent.push(participant.controls[`id`].value);

    // Retrieve all participants in form
    const participantsFormArray = this.participantsForm.controls[`participants`] as FormArray;
    participantsFormArray.removeAt(index);
  }

  /**
   * Add a new participant in the form
   */
  addNewFormRow() {
    const newParticipantRow: TrainingParticipantInformation = {
      id: this.idsNotPresent[0],
      email: null,
      first_name: null,
      last_name: null,
      civility: null
    };
    this.idsNotPresent.shift(); // Remove the first element (present in the form)
    this.addParticipant(newParticipantRow);
  }

  get trainingParticipants(): TrainingParticipantInformation[] {
    return this._trainingsService.trainingParticipantsInformation;
  }

  set trainingParticipants(trainingParticipants: TrainingParticipantInformation[]) {
    this._trainingsService.trainingParticipantsInformation = trainingParticipants;
  }

  /**
   * Retrieve all dates for the current training.
   *
   * @param {Training} training The current training
   * @private
   */
  private _retrieveDates(training: Training): void {
    this.loaderDates = true;
    this._trainingsRequestService.getTrainingDates(training.id)
      .subscribe((response: TrainingDates) => {
        if (response.success) {
          const now = moment(); // Do `now` once to avoid reset variable in for each loop
          response.dates.forEach((date: TrainingDate, index: number) => {
            date.start_format = moment(date.start_at.date).format('DD/MM/YYYY');
            date.end_format = moment(date.ended_at.date).format('DD/MM/YYYY');
            // If the date begin and end are the same, only set the begin date. Else, set `start` -> `end`
            date.select_date = date.start_format !== date.end_format ? date.start_format + ' -> ' + date.end_format : date.start_format;

            // If the start date for the training is higher than now, add the training date to array
            if (now < moment(date.start_at.date)) {
              this.trainingDates.push(date);
            }
          });
        }
        this.loaderDates = false;
      });
  }

  /**
   * Clean the component by reset forms, set step to 1 and already reserved to false
   *
   * @private
   */
  private _cleanComponent(): void {
    this.totalFreePlaces = 0;
    this.countPlacesReserved = 0;
    this.step = 1;
    this.dateAlreadyReserved = false;
    this.loader = false;
    this.loaderDates = false;
    this.loaderParticipants = false;
    this.loaderButtons = false;
    this.loaderPlaces = false;
    this.isCreation = false;
    this.totalFreePlaces = null;

    this.totalParticipants.reset();
    this.trainingDatesSelect.reset();

    this.trainingDatesForm.reset();

    this.participantsForm.reset([]);
    this.participantsFormCtrl.reset([]);
    this.participantsFormCtrl.clear();
  }

  /**
   * Retrieve participants for the training date using quotation
   * @private
   */
  private _retrieveParticipants(): void {
    this.loaderParticipants = true;
    this._trainingsRequestService.retrieveParticipantsQuotation(this.quotation.id)
      .subscribe((response: TrainingParticipantRetrieveResponse) => {
        if (response.success) {
          this.countPlacesReserved = response.participants.length;
          for (const participant of response.participants) {
            if (participant.email !== null) {
              this.addParticipant(participant);
            } else {
              this.idsNotPresent.push(participant.id);
            }
          }
          this.loaderParticipants = false;
        }
      });
  }

  /**
   * Retrieve free places for the training date selected
   *
   * @param {TrainingDate} trainingDate Places will be retrieved from this training date
   * @private
   */
  private _retrieveTrainingDatesFreePlaces(trainingDate: TrainingDate): void {
    this.loaderPlaces = true;
    this._trainingsRequestService.retrieveTrainingDateFreePlaces(trainingDate.id)
      .subscribe((response: TrainingDateFreePlacesResponse) => {
        if (response.success) {
          this.totalFreePlaces = response.free;
          this.totalParticipants.setValidators([Validators.max(response.free)]);
        }
        this.loaderPlaces = false;
      });
  }

  /**
   * Create or update participants for a training
   *
   * @param {boolean} isUpdate Request need to be an update or a create
   * @private
   */
  private _createParticipants(isUpdate: boolean = false) {
    this.participantsForm.disable();
    this.loaderButtons = true;
    /**
     * Return a correct training participant information array
     */
    const formParticipants = (): TrainingParticipantInformation[] => {
      const participantsArray: TrainingParticipantInformation[] = [];
      // First, add all participants from the form in the return array
      this.participantsForm.value.participants.forEach((participantForm: TrainingParticipantInformation) => {
        participantsArray.push(participantForm);
      });
      // Next, send the not set users (in case of a participant is removed, his form group doesn't
      // exists and it will not be updated in API)
      this.idsNotPresent.forEach((idNotPresent: number) => {
        participantsArray.push({
          id: idNotPresent,
          email: null,
          first_name: null,
          last_name: null,
          civility: null
        });
      });
      return participantsArray;
    };

    const sendData = {
      users: formParticipants(),
      training_date_id: this.trainingDate.id,
      quotation_id: this.quotation.id
    };
    if (isUpdate) {
      this._trainingsRequestService.updateTrainingParticipants(sendData)
        .subscribe((response: TrainingParticipantUpdateResponse) => {
          if (response.success) {
            this._notifier.successUpdate();
          } else {
            this._notifier.errorRequest();
          }
          this.loaderButtons = false;
          this.participantsForm.enable();
          this.close('update_training_participants');
        });
    } else {
      this._trainingsRequestService.createTrainingParticipants(sendData)
        .subscribe((response: TrainingParticipantCreateResponse) => {
          if (response.success) {
            this._notifier.successCreate();
          } else {
            this._notifier.errorRequest();
          }
          this.loaderButtons = false;
          this.participantsForm.enable();
          this.close('create_training_participants');
        });
    }
  }

  /**
   * Reserve participants places for the training date
   *
   * @private
   */
  private _reserveParticipantsPlaces() {
    const toSend = {
      places_to_reserve: this.totalParticipants.value,
      training: this.training.id,
      quotation: this.quotation.id
    };

    this._trainingsRequestService.reserveParticipantsPlaces(this.selectedDate, toSend)
      .subscribe((response: any) => {
        if (response.success) {
          // Update the training date select date name for next modal subtitle
          if (this.trainingDate.start_format !== this.trainingDate.end_format) {
            this.trainingDate.select_date = 'du ' + this.trainingDate.start_format + ' au ' + this.trainingDate.end_format;
          } else {
            this.trainingDate.select_date = 'du ' + this.trainingDate.start_format;
          }

          this.step = 2;
        }
      });
  }

  /**
   * Create a new participant form group and return it
   * @private
   */
  private _getParticipantFormGroup(): FormGroup {
    const newFormGroup = this._formBuilder.group({});
    this.fields.forEach((field: Field) => {
      newFormGroup.addControl(field.property, _.cloneDeep(field.formControl));
    });
    return newFormGroup;
  }

  get participantForm(): FormArray {
    return this.participantsForm.get('participants') as FormArray;
  }
}
