<div id="confirm-modal" class="modal modal-confirm fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">Question gratuite</h1>
          </div>
        </div>

        <button type="button" class="close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body" *ngIf="quest === false">
        <form [formGroup]="modalForm">
        <div class="mb-3">Vous pouvez poser une question gratuitement en complétant le formulaire ci-dessous.</div>

        <div class="mb-3">
          <label for="question" class="d-block font-weight-bold mb-0">Question</label>
          <small class="d-block smallBlock">Détaillé votre question le plus précisément possible.</small>
          <textarea id="question" formControlName="question" class="form-control" [ngClass]="{ 'is-invalid': question.invalid && (question.dirty || question.touched) }"></textarea>
        </div>
        </form>
      </div>
      <div class="modal-body" *ngIf="quest !== null && quest !== false">
        <b class="d-block">Question</b>
        <div>
          {{ quest }}
        </div>
        <b class="d-block mt-3"  *ngIf="answer !== false">Réponse de BatiSafe</b>
        <div class="mt-1"  *ngIf="answer !== false" style="max-height:400px;overflow-y:scroll;">
          {{ answer }}
        </div>

        <b class="d-block mt-3" *ngIf="answer === false">Réponse de BatiSafe</b>
        <div class="mt-1" *ngIf="answer === false">
          <app-no-result width="200" picture="../../../assets/img/empty.svg" title="Aucune réponse" subTitle="Vous n'avez pas encore reçus de réponse."></app-no-result>
        </div>
      </div>

      <div class="modal-footer">
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 mr-2" (click)="cancel()">
          Annuler
        </button>
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 ml-2" [disabled]="modalForm.invalid" (click)="validate()">
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
