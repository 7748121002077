import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';

import { AuthService } from '../../auth/auth.service';
import { HttpCancelService } from '../../services/http.cancel.service';
import { SurveyRequestService } from './survey.request.service';
import { ResponseSurveyParticipant } from '../interfaces/response/survey';

@Injectable()
export class SurveyResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private _router: Router,
    private _surveyService: SurveyRequestService,
    private _authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this._router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      this.requestInProgress.next(true);
      const params = route.queryParams;
      // 1 = Pre-training survey
      // 2 = Satisfaction survey
      // 3 = Recall survey (3 months later or post-training)
      // 4 = Customer satisfaction survey to get their opinion on the organization
      let type = null;
      if (params.survey_type === '1') {
        type = 'pre-survey';
      } else if (params.survey_type === '2') {
        type = 'satisfaction';
      } else if (params.survey_type === '3') {
        type = 'post-survey';
      } else if (params.survey_type === '4') {
        type = 'client-contact';
      }

      if (type !== null) {
        if (this._authService.user.training_participant_access) {
          this._surveyService.getParticipantSurvey(type, params.training_date_id)
            .subscribe((response: ResponseSurveyParticipant) => {
              if (response.success) {
                data.success = response.success;
                data.questions = response.questions;
                data.survey = response.survey;
                if (params.training_date_id) {
                  data.training_date_id = parseInt(params.training_date_id, 10);
                }
              }
              this.requestInProgress.next(false);
            });
        } else {
          this._surveyService.getClientContactSurvey(type, params.training_date_id)
            .subscribe((response: any) => {
              if (response.success) {
                data.success = response.success;
                data.questions = response.questions;
                data.survey = response.survey;
                if (params.training_date_id) {
                  data.training_date_id = parseInt(params.training_date_id, 10);
                }
              }

              this.requestInProgress.next(false);
            });
        }
      }
    });
  }
}
