import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ListModule } from '../../core/list/list.module';
import { ConfirmModalModule } from '../../core/confirm-modal/confirm-modal.module';
import { AllAccountComponent } from './all-account.component';

import { AccountResolver } from '../../core/services/account/account.resolver';
import { NgSelectModule } from '@ng-select/ng-select';
import { PatrimonyResolver } from '../../core/services/theonorme/patrimony/patrimony.resolver';
import { CategoryVeilleResolver } from '../../core/services/theonorme/category-veille/category-veille.resolver';
import { EstablishmentTypeResolver } from '../../core/services/theonorme/establishmentType/establishment-type.resolver';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    ListModule,
    ConfirmModalModule,
    NgSelectModule
  ],
  providers: [AccountResolver, PatrimonyResolver, CategoryVeilleResolver, EstablishmentTypeResolver],
  declarations: [AllAccountComponent]
})

export class AllAccountModule {}
