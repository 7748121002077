import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CguModule } from './cgu/cgu.module';
import { MentionsModule } from './mentions/mentions.module';

@NgModule({
  imports: [
    CommonModule,
    CguModule,
    MentionsModule
  ]
})
export class LegalsModule { }
