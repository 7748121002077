import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AllTicketsComponent } from './all-tickets.component';

import { TicketListModule } from '../ticket-list/ticket-list.module';
import { ConfirmModalModule } from '../../../core/confirm-modal/confirm-modal.module';
import { NoResultModule } from '../../../core/no-result/no-result.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    TicketListModule,
    ConfirmModalModule,
    NoResultModule
  ],
  declarations: [AllTicketsComponent]
})

export class AllTicketsModule {}
