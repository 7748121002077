import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionsComponent } from './mentions.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [MentionsComponent],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class MentionsModule { }
