import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Compiler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Subject, onErrorResumeNext, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { sha256 } from 'js-sha256';
import { environment } from '../../../environments/environment';
import { CustomNotifierService } from './custom.notifier.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  refreshInProgress: Subject<any>;
  requestInProgressSubject = new Subject();

  requestInProgress = 0;

  needToGoBack = false;
  disconnected = false;
  notConnected = false;
  serverError = false;

  constructor(
    private router: Router,
    private compiler: Compiler,
    private loadingBar: LoadingBarService,
    private notifier: CustomNotifierService,
    private authService: AuthService
  ) {
    this.requestInProgressSubject.subscribe((value: number) => {
      this.requestInProgress = value;
      if (value === 0) {
        loadingBar.complete();
        if (this.needToGoBack) {
          this.needToGoBack = false;
          this.notifier.errorNotAuthorized();
          if (document.location.hostname !== 'localhost') {
            this.router.navigate(['/']).then(() => {});
          }
        } else if (this.disconnected) {
          this.disconnected = false;
          this.notifier.errorDisconnected();
          localStorage.clear();
          this.router.navigate(['/auth/login']).then(() => {});
        } else if (this.notConnected) {
          this.notifier.errorNotConnected();
          this.notConnected = false;
          localStorage.clear();
          this.router.navigate(['/auth/login']).then(() => {});
        } else if (this.serverError) {
          this.serverError = false;
        }
      }
    });
  }

  static addBearer(request: HttpRequest<any>, token: string = null) {
    if (!token) {
      token = localStorage.getItem('accessToken');
    }

        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            Enterprise: environment.ERP_ENTERPRISE.toString(),
            SetCookie: 'HttpOnly;Secure;SameSite=Strict'
          }
        });
  }

  static bearerExpired(next: HttpHandler): Observable<HttpEvent<any>> {
    const refresh = localStorage.getItem('refreshToken');
    const request = new HttpRequest('POST', environment.API_PATH + 'oauth/v2/refresh', { refresh }, { withCredentials: false });
    return next.handle(request);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestInProgressSubject.next(this.requestInProgress + 1);
    request = InterceptorService.addBearer(request);

    return onErrorResumeNext(next.handle(request).pipe(
      catchError(error => {
        this.serverError = true;
        this.requestInProgressSubject.next(this.requestInProgress - 1);
        return of(error);
      }),
      mergeMap((event: HttpEvent<any>) => {
        return new Observable<any>((observer) => {

          /** API Yield **/
          if(event['status'] === 500) {
            this.notifier.errorRequest();
          }
          else if(event['status'] === 401 && event['error'] !== undefined) {
            if(event['error']['error_description'] === 'The access token provided has expired.') {
              if (this.refreshInProgress) {
                this.refreshInProgress.subscribe(() => {
                  this.refreshInProgress.unsubscribe();
                  request = InterceptorService.addBearer(request);
                  next.handle(request).subscribe(resendResponse => {
                    if (resendResponse instanceof HttpResponse) {
                      observer.next(resendResponse);
                    }
                  });
                });
              } else {
                this.refreshInProgress = new Subject();
                InterceptorService.bearerExpired(next).subscribe(refreshResponse => {
                  if (refreshResponse instanceof HttpResponse) {
                    localStorage.setItem('accessToken', refreshResponse.body.access_token);

                    request = InterceptorService.addBearer(request);
                    next.handle(request).subscribe(resendResponse => {
                      if (resendResponse instanceof HttpResponse) {
                        observer.next(resendResponse);
                      }
                    });

                    this.refreshInProgress.next(true);
                    this.refreshInProgress = null;
                  }
                });
              }
            }
          }


          /** API BATI **/
          if (event instanceof HttpResponse) {
            if (!event.body.success) {
              if (event.body.errors &&
                event.body.errors.general &&
                event.body.errors.general.code === 'EXPIRED_BEARER') {
                if (this.refreshInProgress) {
                  this.refreshInProgress.subscribe(() => {
                    this.refreshInProgress.unsubscribe();
                    request = InterceptorService.addBearer(request);
                    next.handle(request).subscribe(resendResponse => {
                      if (resendResponse instanceof HttpResponse) {
                        observer.next(resendResponse);
                      }
                    });
                  });
                } else {
                  this.refreshInProgress = new Subject();
                  InterceptorService.bearerExpired(next).subscribe(refreshResponse => {
                    if (refreshResponse instanceof HttpResponse) {
                      localStorage.setItem('accessToken', sha256(refreshResponse.body.accessToken));

                      request = InterceptorService.addBearer(request);
                      next.handle(request).subscribe(resendResponse => {
                        if (resendResponse instanceof HttpResponse) {
                          observer.next(resendResponse);
                        }
                      });

                      this.refreshInProgress.next(true);
                      this.refreshInProgress = null;
                    }
                  });
                }
              } else if (event.body.errors &&
                event.body.errors.general &&
                event.body.errors.general.code === 'INVALID_BEARER') {
                this.disconnected = true;
              } else if (event.body.errors &&
                event.body.errors.general &&
                event.body.errors.general.code === 'NO_BEARER') {
                this.notConnected = true;
              } else if (event.body.errors &&
                event.body.errors.general &&
                event.body.errors.general.code === 'NOT_AUTHORIZED') {
                this.needToGoBack = true;
              } else {
                observer.next(event);
              }
            } else {
              observer.next(event);
            }
            this.requestInProgressSubject.next(this.requestInProgress - 1);
          } else if (event instanceof HttpErrorResponse) {
            if (event.status === 401) {
              if (event.error.violations.message === 'token_bearer_expired') { // Token expired
                if (this.refreshInProgress) {
                  this.refreshInProgress.subscribe(() => {
                    this.refreshInProgress.unsubscribe();
                    request = InterceptorService.addBearer(request);
                    next.handle(request).subscribe(resendResponse => {
                      if (resendResponse instanceof HttpResponse) {
                        resendResponse.body['success'] = true;
                        observer.next(resendResponse);
                      }
                    });
                  });
                } else {
                  this.refreshInProgress = new Subject();
                  InterceptorService.bearerExpired(next).subscribe(refreshResponse => {
                    if (refreshResponse instanceof HttpResponse) {
                      if (typeof refreshResponse.body.violations === 'object') {
                        this.authService.logout();
                        this.notifier.errorDisconnected();
                      } else {
                        this.compiler.clearCache();
                        localStorage.setItem('accessToken', refreshResponse.body['access_token']);
                        localStorage.setItem('refreshToken', refreshResponse.body['refresh_token']);

                        request = InterceptorService.addBearer(request);
                        next.handle(request).subscribe(resendResponse => {
                          if (resendResponse instanceof HttpResponse) {
                            resendResponse.body['success'] = true;
                            observer.next(resendResponse);
                          }
                        });

                        this.refreshInProgress.next(true);
                        this.refreshInProgress = null;
                      }
                    }
                  });
                }
              } else if (event.error.violations.message === 'oauth_authentification_required') {
                // Not logged
                this.authService.logout();
                this.notifier.errorNotConnected();
              } else if (event.error.violations.message !== undefined) {
                // Not logged
                this.notifier.errorCustom(event.error.violations.message);
              } else {
                // Not authorized
                // history.back();
                this.notifier.errorNotAuthorized();
              }
            } else {
              if (event.error.violations !== undefined && event.error.violations.message !== undefined) {
                if (event.status === 404) {
                  this.router.navigateByUrl('/');
                }
                this.notifier.errorCustom(event.error.violations.message);
              }

              const httpResponse = new HttpResponse({ body: { success: false, error: event.error }});
              observer.next(httpResponse);
            }
          }
        });
      })
    ));
  }
}
