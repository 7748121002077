import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';

import * as moment from 'moment';

import { TrainingsRequestService } from './trainings.request.service';
import { AuthService } from '../../auth/auth.service';
import { TrainingsService } from './trainings.service';

@Injectable()
export class DetailTrainingResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  /**
   * Detail Training resolver constructor
   *
   * @param {Router} _router
   * @param {TrainingsRequestService} _trainingsRequestService
   * @param {AuthService} _authService
   * @param {TrainingsService} _trainingService
   */
  constructor(
    private _router: Router,
    private _trainingsRequestService: TrainingsRequestService,
    private _authService: AuthService,
    private _trainingService: TrainingsService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this._router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      this.requestInProgress.next(true);
      // Participant
      if (this._authService.user.training_participant_access) {
        this._trainingsRequestService.getTrainingDateParticipant(route.params.id)
          .subscribe((response: any) => {
            data.success = response.success;
            data.id = response.id;
            data.training_date = response.training_date;
            data.training = response.training;
            data.complete_post_survey = response.complete_post_survey;
            data.complete_satisfaction_survey = response.complete_satisfaction_survey;
            data.complete_pre_survey = response.complete_pre_survey;
            data.total_material_files = response.total_material_files;

            this.requestInProgress.next(false);
          });
      } else { // Client contact
        this._trainingsRequestService.getTrainingDate(route.params.id)
          .subscribe((response: any) => {
            data.success = response.success;
            data.training_date = response.training_date;
            data.training = response.training;
            data.quotation = response.quotation;
            data.already_respond_to_survey = response.already_respond_to_survey;
            data.total_participants = response.total_participants;
            data.total_participants_responses = response.total_participants_responses;
            data.total_sign_in_sheets = response.total_sign_in_sheets;
            data.total_certificates = response.total_certificates;
            data.signed_agreement = response.signed_agreement;
            data.signed_agreement_file_exists = response.signed_agreement_file_exists;

            this._trainingService.training = response.training_date;
            this._trainingService.quotation = response.training;
            this._trainingService.trainingDate = response.quotation;
            this.requestInProgress.next(false);
          });
      }
    });
  }
}
