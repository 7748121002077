import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginModule } from './login/login.module';
import { AuthRequestService } from '../../core/services/auth/auth.request.service';
import { PasswordLostModule } from './password-lost/password-lost.module';
import { ActivateModule } from './activate/activate.module';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    PasswordLostModule,
    ActivateModule
  ],
  declarations: [],
  providers: [
    AuthRequestService
  ]
})
export class AuthModule {}
