<div class="row ml-0 mr-0 mt-3">
  <div class="col-12 d-flex">
    <div class="flex-grow-1 pl-2">
      <div class="title-header mb-0"> Questions </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Assistance BatiSafe </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mr-2" *ngIf="hasPack">
      <div class="d-flex mt-sm-0">
        <div class="dl">
          <button
            type="submit"
            class="btn btn-primary"
            [routerLink]="['create']"
            [disabled]="!canCreate"
          >
            <span>Poser une question</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card card-scroll ml-4 mr-4" *ngIf="hasPack">
  <div class="card-body">
    <app-question-list
                     [columns]="columns"
                     [items]="questions"
                     [total]="total"
                     [pages]="pages"
                     [page]="page"
                     [hideAdd]="true"
                     [loader]="loader"
                     [hideAction]="false"
                     [hasCustomField]="false"
                     (returnEvent)="updateList($event)" #list
                     class="w-100">{{ bindList(list) }}
    </app-question-list>
  </div>
</div>

<div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center mt-4" *ngIf="!hasPack">
  <app-no-result width="400" picture="../../../assets/img/subscribe.svg" title="Découvrir l'assistance BatiSafe" subTitle="Vous n'avez pas encore accès à cette page. Pour cela, vous devez souscrire au pack Assistance Batisafe. N'hésitez pas à contacter Batisafe."></app-no-result>
  <div class="text-center mt-2" style="font-size:0.9em;" *ngIf="canCreate">Vous disposez d'une question gratuite unique</div>
  <div class="text-center mt-2" style="font-size:0.9em;" *ngIf="!canCreate">Vous avez déjà posé votre question gratuite</div>
  <div class="text-center">
    <a href="mailto:contact@batisafe.fr" target="_blank" class="btn btn-primary mt-3">Contacter BatiSafe</a>
    <button (click)="askUniqueQuestion($event)" class="btn btn-primary mt-3 ml-3">{{ canCreate ? 'Poser ma question' : 'Consulter la réponse' }}</button>
  </div>
</div>

<app-question-modal #questionModal>{{ bindModal(questionModal) }}</app-question-modal>
