import { Routes } from '@angular/router';

import urlMatcher from '../../core/utils/urlMatcher';

import { AllTrainingsComponent } from './all-trainings/all-trainings.component';
import { DetailTrainingComponent } from './detail-training/detail-training.component';
import { SurveyComponent } from '../../core/survey/survey.component';

import { TrainingsResolver } from '../../core/services/trainings/trainings.resolver';
import { DetailTrainingResolver } from '../../core/services/trainings/detail_training.resolver';
import { SurveyResolver } from '../../core/survey/services/survey.resolver';

export const trainingsRoutes: Routes = [
  {
    path: 'trainings',
    component: AllTrainingsComponent,
    resolve: {
      data: TrainingsResolver,
    }
  },
  {
    path: 'training',
    children: [
      {
        matcher: urlMatcher(':id', new RegExp(/^[0-9]+$/)),
        component: DetailTrainingComponent,
        resolve: {
          data: DetailTrainingResolver
        }
      },
      {
        path: 'survey',
        component: SurveyComponent,
        resolve: {
          data: SurveyResolver
        }
      }
    ]
  }
];
