import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-boxplan-dir-modal',
  templateUrl: './boxplan-dir-modal.component.html',
  styleUrls: ['./boxplan-dir-modal.component.scss']
})
export class BoxplanDirModalComponent implements AfterViewInit, OnInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalForm: FormGroup;
  name = new FormControl('', [Validators.required]);

  modalElConfirm = null;
  id: number;
  file: any;

  constructor(
    private rootNode: ElementRef,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.modalForm = this.formBuilder.group({
      name: this.name,
    });
  }

  open(id) {
    this.id = id;
    this.name.reset();
    this.modalElConfirm.modal('show');
  }

  cancel() {
    this.eventOnClose.next(null);
    this.modalElConfirm.modal('hide');
  }

  validate() {
    this.eventOnClose.next({
      id : this.id,
      name : this.name.value,
    });
    this.modalElConfirm.modal('hide');
  }

  ngAfterViewInit() {
    this.modalElConfirm = $(this.rootNode.nativeElement).find('div.modal-confirm');

    const self = this;
    $('#confirm-modal').on('hidden.bs.modal', () => {
      self.eventOnClose.next(null);
    });
  }
}
