import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AllQuestionsComponent } from './all-questions.component';

import { QuestionListModule } from '../question-list/question-list.module';
import { ConfirmModalModule } from '../../../../core/confirm-modal/confirm-modal.module';
import { NoResultModule } from '../../../../core/no-result/no-result.module';
import { QuestionModalModule } from '../../../../core/modals/question/question-modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    QuestionListModule,
    ConfirmModalModule,
    NoResultModule,
    QuestionModalModule
  ],
  declarations: [AllQuestionsComponent]
})

export class AllQuestionsModule {}
