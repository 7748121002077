<div class="scroll dashboard">
  <div class="w-100 dashboard-margin p-3 row">

    <div class="col-12">
      <div class="title-header mb-0">
        Bonjour {{ authService.user.firstname + ' ' + authService.user.lastname }},
      </div>
      <div class="title-small mb-3">
        Bienvenue sur votre tableau de bord
      </div>
    </div>

    <div class="col-xl-9 col-12 mb-4">
      <div class="row mb-0" *ngIf="!isParticipant">
        <div class="col-3 accepted">
          <div class="card d-flex flex-row" style="min-height: 7rem; align-items: center; padding-top: .7rem;">
            <div class="pl-3 pr-4 pt-3 pb-3">
              <i class="fa fa-check" style="font-size:2em;color:#2ab0ff;"></i>
            </div>
            <div>
              <div class="number" style="font-size:1.5em;">
                {{ countAccepted }}
              </div>
              <div class="text" style="font-size:0.9em;color:#999;">
                Devis accepté(s)
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 accepted">
          <div class="card d-flex flex-row" style="min-height: 7rem; align-items: center; padding-top: .7rem;">
            <div class="pl-3 pr-4 pt-3 pb-3">
              <i class="fa fa-file-invoice" style="font-size:2em;color:#2ab0ff;"></i>
            </div>
            <div>
              <div class="number" style="font-size:1.5em;">
                {{ countWaiting }}
              </div>
              <div class="text" style="font-size:0.9em;color:#999;">
                Devis en attente
              </div>
            </div>

          </div>
        </div>
        <div class="col-3 accepted">
          <div class="card d-flex flex-row" style="min-height: 7rem; align-items: center; padding-top: .7rem;">
            <div class="pl-3 pr-4 pt-3 pb-3">
              <i class="fa fa-times" style="font-size:2em;color:#2ab0ff;"></i>
            </div>
            <div>
              <div class="number" style="font-size:1.5em;">
                {{ countRefused }}
              </div>
              <div class="text" style="font-size:0.9em;color:#999;">
                Devis refusé(s)
              </div>
            </div>

          </div>
        </div>
        <div class="col-3 accepted">
          <div class="card d-flex flex-row" style="min-height: 7rem; align-items: center; padding-top: .7rem;">
            <div class="pl-3 pr-4 pt-3 pb-3">
              <i class="fa fa-comments" style="font-size:2em;color:#2ab0ff;"></i>
            </div>
            <div>
              <div class="number" style="font-size:1.5em;">
                {{ countTicket }}
              </div>
              <div class="text" style="font-size:0.9em;color:#999;">
                Ticket(s) non lu(s)
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row mb-0">
        <!-- Webinars -->
        <div class="col-12 mt-4">
          <div class="quotations h-100 card">
            <div class="d-flex justify-content-between">
              <div class="title">Webinaires</div>
              <span class="refresh-icon">
                  <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('webinars')">refresh
                  </mat-icon>
                </span>
            </div>
            <div class="is-loading loader-content mb-4" *ngIf="loaderWebinars"></div>
            <div class="d-md-flex row" *ngIf="!loaderWebinars && webinars.merged.length">
              <div class="col-3 mb-0" style="border-bottom:none;" *ngFor="let webinar of webinars.merged; let last = last;">
                <div class="media button-arrow">
                  <div class="media-body mr-2" [ngClass]="{ 'mb-2' : !last }">
                    <a class="d-flex align-items-center justify-content-between move-card border-bottom-0" [ngClass]="{'border-unset': last}" target="_blank" [href]="webinar['session']['roomLink']">
                      <div class="d-flex flex-column">
                        <small class="text-small mb-0 pre-line" style="font-weight: bold; color: #3e79a0">

                          <ng-container *ngIf="webinar['event']['owner'] === 'TN'">
                            <img [src]="imageTheonormePath" width="26px" alt="Webinaire Theonorme" matTooltip="Théonorme">
                          </ng-container>
                          <ng-container *ngIf="webinar['event']['owner'] === 'BS'">
                            <img [src]="imageBatisafePath" width="17px" alt="Webinaire Batisafe" matTooltip="Batisafe">
                          </ng-container>
                          <ng-container *ngIf="webinar['event']['owner'] === 'BR'">
                            <img [src]="imageBatiregistrePath" alt="Webinaire Batiregistre" matTooltip="Batiregistre">
                          </ng-container>
                          <span style="vertical-align: middle; margin-left: .3rem;">
                              {{ webinar['session']['estimatedStartedAt'] | date: 'dd/MM/yyyy' }} à {{ webinar['session']['estimatedStartedAt'] | date: 'h:mm' }}
                            </span>
                        </small>

                        <small class="text-small mb-0 pre-line">
                          {{ webinar['event']['name'] }}
                        </small>

                      </div>

                      <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                        <g class="arrow-head">
                          <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                        </g>
                        <g class="arrow-body">
                          <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                        </g>
                      </svg>

                    </a>
                  </div>
                </div>

              </div>
            </div>
            <app-no-result *ngIf="!loaderWebinars && !webinars.merged.length" class="h-100" title="Aucun webinaire" width="200"></app-no-result>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Veilles Theonorme -->
        <div class="col-12 mt-3 mb-3" *ngIf="!isParticipant">
          <div class="veilles card">
            <div class="d-flex justify-content-between">
              <div class="title" [routerLink]="['/theonorme/veilles']">Veille réglementaire</div>
              <span class="refresh-icon">
                  <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('veilles')">refresh
                  </mat-icon>
                </span>
            </div>
            <div class="is-loading loader-content mb-4" *ngIf="loaderVeilles"></div>
            <div class="d-md-flex flex-column justify-content-between" *ngIf="!loaderVeilles && veilles.length">
              <div *ngFor="let veille of veilles; let last = last;">
                <div class="card-veille move-card button-arrow mb-2 d-flex justify-content-between" style="background:#f9f9f9;border-radius:5px;border-bottom:none;" [ngClass]="{'border-unset': last}"  (click)="redirect('/theonorme/veilles',{ source: 'dashboard', id: veille.id })">
                  <h6 class="" style="font-size:0.8em;color:#575757;font-weight:normal;">{{ veille['title'] }}</h6>
                  <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                    <g class="arrow-head">
                      <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                    </g>
                    <g class="arrow-body">
                      <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <app-no-result *ngIf="!loaderVeilles && !veilles.length" class="h-100" title="Aucune veille réglementaire" width="200"></app-no-result>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!isParticipant">
        <!-- Quotations card -->
        <div class="col-xl-6 col-12 mb-xl-0 mb-4">
          <div class="quotations h-100 card">
            <div class="d-flex justify-content-between">
              <div class="title" [routerLink]="['/quotations']">Devis</div>
              <span class="refresh-icon">
                  <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('quotations')">refresh
                  </mat-icon>
                </span>
            </div>
            <div class="is-loading loader-content mb-4 is-loading-card" *ngIf="loaderQuotations"></div>
            <div class="d-md-flex flex-column justify-content-between" *ngIf="!loaderQuotations && quotations.length">
              <div *ngFor="let quotation of quotations; let last = last;">
                <div class="media button-arrow">
                  <div class="media-body mr-2" [ngClass]="{ 'mb-2' : !last }">
                    <div class="d-flex align-items-center justify-content-between move-card" style="padding:5px;border-bottom:none;" [ngClass]="{'border-unset': last}" (click)="redirect('/quotations',{ source: 'dashboard', id: quotation.id })">

                      <ng-container *ngIf="quotation.important">
                        <small
                          *ngIf="quotation.importantMessage !== ''"
                          [matTooltip]="quotation.importantMessage"
                          matTooltipPosition="above"
                          matTooltipShowDelay="100"
                          class="text-small mb-0 pre-line" style="min-width: 4.4rem;">
                          <mat-icon class="important-icon">warning</mat-icon> &nbsp;
                          {{ quotation.number }}
                        </small>
                        <small
                          *ngIf="quotation.importantMessage === ''"
                          class="text-small mb-0 pre-line" style="min-width: 4.4rem;">
                          <mat-icon class="important-icon">warning</mat-icon> &nbsp;
                          {{ quotation.number }}
                        </small>
                      </ng-container>
                      <small *ngIf="!quotation.important" class="text-small mb-0 pre-line" style="min-width: 4.4rem;">
                        {{ quotation.number }}
                      </small>

                      <small class="text-small mb-0 pre-line color-button" [ngStyle]="{'background-color' : quotation.state.color }">
                        {{ quotation.state.name }}
                      </small>

                      <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                        <g class="arrow-head">
                          <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                        </g>
                        <g class="arrow-body">
                          <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                        </g>
                      </svg>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <app-no-result *ngIf="!loaderQuotations && !quotations.length" class="h-100" title="Aucun devis" width="200"></app-no-result>
          </div>
        </div>

        <!-- Invoices card -->
        <div class="col-xl-6 col-12 flex-fill">
          <div class="invoices h-100 card">
            <div class="d-flex justify-content-between">
              <div class="title" [routerLink]="['/invoices']">Factures</div>
              <span class="refresh-icon">
                  <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('invoices')">refresh
                  </mat-icon>
                </span>
            </div>
            <div class="is-loading loader-content mb-4 is-loading-card" *ngIf="loaderInvoices"></div>
            <div class="d-md-flex flex-column justify-content-between" *ngIf="!loaderInvoices && invoices.length">
              <div *ngFor="let invoice of invoices; let last = last;">
                <div class="media">
                  <div class="media-body mr-2" [ngClass]="{ 'mb-2' : !last }">
                    <div class="d-flex align-items-center justify-content-between move-card button-arrow"  [ngClass]="{'border-unset': last}" (click)="redirect('/invoices',{ source: 'dashboard', id: invoice.id })">
                      <ng-container *ngIf="invoice.important">
                        <small
                          *ngIf="invoice.importantMessage !== ''"
                          [matTooltip]="invoice.importantMessage"
                          matTooltipPosition="above"
                          matTooltipShowDelay="100"
                          class="text-small mb-0 pre-line">
                          <mat-icon class="important-icon">warning</mat-icon> &nbsp;
                          {{ invoice.name }}
                        </small>
                        <small
                          *ngIf="invoice.importantMessage === ''"
                          class="text-small mb-0 pre-line">
                          <mat-icon class="important-icon">warning</mat-icon> &nbsp;
                          {{ invoice.name }}
                        </small>
                      </ng-container>
                      <small *ngIf="!invoice.important" class="text-small mb-0 pre-line">
                        {{ invoice.name }}
                      </small>
                      <small class="text-small mb-0 pre-line color-button" [ngStyle]="{'background-color' : invoice.state.color }">
                        {{ invoice.state.name }}
                      </small>

                      <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                        <g class="arrow-head">
                          <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                        </g>
                        <g class="arrow-body">
                          <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                        </g>
                      </svg>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <app-no-result *ngIf="!loaderInvoices && !invoices.length" class="h-100" title="Aucune facture" width="200"></app-no-result>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-12 h-100 d-flex flex-column">
        <!-- Buttons actions -->
        <div *ngIf="portalConfig.display_dashboard_new_quote_batiregistre_button || portalConfig.display_dashboard_new_quote_batisafe_button || portalConfig.display_dashboard_referenced_batifire_button"
             class="card col-12"
             style="background-color: transparent !important; border: none !important; padding-top: 2px; padding-left: 0; padding-right: 0; align-items: center;">
          <a mat-button class="d-flex mb-2" *ngIf="portalConfig.display_dashboard_new_quote_batiregistre_button"
             style="width: 100%; background-color: #E6332A; color: white; justify-content: center;"
             aria-label="Nouvelle demande" title="Faire une demande" href="https://www.batiregistre.fr/devis-tarifs" target="_blank">
            Demander un devis Batiregistre
          </a>

          <a mat-button class="d-flex mb-2 btn btn-primary" *ngIf="portalConfig.display_dashboard_new_quote_batisafe_button"
             style="width: 100%; background-color: #3e79a0; color: white; justify-content: center;"
             aria-label="Nouvelle demande" title="Faire une demande" href="https://www.batisafe.fr/devis-gratuit/formations" target="_blank">
            Demander un devis Batisafe
          </a>

          <a mat-button class="d-flex btn btn-dark" *ngIf="portalConfig.display_dashboard_referenced_batifire_button"
             style="width: 100%; font-size:0.9em; color: white; justify-content: center;"
             aria-label="Être référencé par Batifire" title="Être référencé par Batifire" href="mailto:contact@batisafe.fr" target="_blank">
            Être référencé par Batifire
          </a>
        </div>

        <!-- Custom cards -->
        <ng-container *ngIf="customCards && customCards.length">
          <ng-container *ngFor="let customCard of customCards">
            <!--
              `isActive` property is already checked in API retrieve, it doesn't cost anything to redo a little check here
            -->
            <div class="card customCard col-12 mt-0" [ngClass]="customCardClassNames(customCard)"
                 *ngIf="customCard.isActive" (click)="customCard.link ? customCardNavigate(customCard.link) : null"
                 [title]="customCard.title">
              <ng-container *ngIf="customCard.picture">
                <img [src]="customCard.picture" [alt]="customCard.title">
              </ng-container>
              <ng-container *ngIf="!customCard.picture">
                <h6 class="title" *ngIf="customCard.title">{{ customCard.title }}</h6>
                <p class="description" *ngIf="customCard.description">{{ customCard.description }}</p>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <!-- Tickets card -->
        <div class="card col-12"  *ngIf="!isParticipant"
          [ngClass]="portalConfig.display_dashboard_new_quote_batiregistre_button || portalConfig.display_dashboard_new_quote_batisafe_button || portalConfig.display_dashboard_referenced_batifire_button ? 'mt-3' : ''">
          <div class="card-header d-flex justify-content-between">
            <div class="title" [routerLink]="['/tickets']" style="color:#2ab0ff;">Ticket(s) non lu(s)</div>
            <span class="refresh-icon">
              <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('tickets')">refresh
              </mat-icon>
            </span>
          </div>
          <div class="is-loading loader-content mb-4" *ngIf="loaderTickets"></div>
          <div class="d-md-flex flex-column justify-content-between" *ngIf="!loaderQuotations && quotations.length">
            <div *ngFor="let ticket of tickets; let last = last;">
              <div class="media">
                <div class="media-body mr-2" [ngClass]="{ 'mb-0' : !last }">
                  <div style="box-shadow:none!important;" class="d-flex align-items-center justify-content-between move-card button-arrow border-unset" (click)="redirect('/ticket/' + ticket.id)">
                    <div class="mr-3">
                      <i class="fa fa-comment"></i>
                    </div>
                    <small class="text-small mb-0 pre-line flex-grow-1">
                       {{ ticket.content }}
                    </small>

                    <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                      <g class="arrow-head">
                        <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                      </g>
                      <g class="arrow-body">
                        <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                      </g>
                    </svg>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="!loaderTickets && !tickets.length" class="text-center h-100 d-flex justify-content-center align-items-center flex-column">
            <i class="fa fa-comments d-block" style="font-size: 2.5em;color: #f1f1f1;"></i>
            <div style="color: #ddd;padding:20px;font-size:0.9em;">Aucun ticket en attente</div>
          </div>
        </div>

        <!-- Files card -->
        <div class="card col-12 mt-4" *ngIf="!isParticipant">
          <div class="card-header d-flex justify-content-between">
            <div class="title" [routerLink]="['/directories']" style="color:#2ab0ff;">Boîtes à plan</div>
            <span class="refresh-icon">
              <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('files')">refresh
              </mat-icon>
            </span>
          </div>
          <div class="is-loading loader-content mb-4" *ngIf="loaderFiles"></div>
          <div class="card-body" *ngIf="!loaderFiles && files.length">
            <div class="row">
              <div class="col-12 d-md-flex flex-column justify-content-between">
                <div *ngFor="let file of files; let last = last;">
                  <div class="media">
                    <div class="media-body mr-2" [ngClass]="{ 'mb-2' : !last }">
                      <div style="border: none;padding: 10px 5px;box-shadow:none!important;" class="d-flex align-items-center justify-content-between move-card button-arrow" [ngClass]="{'border-unset': last}" (click)="redirect(file.redirectUrl)">
                        <div class="d-flex align-items-center justify-content-center" [matTooltip]="'Télécharger ' + file.name + ' sur votre poste'">
                          <small class="text-small mb-0 pre-line ml-2">
                            {{ file.name }}
                          </small>
                        </div>

                          <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                            <g class="arrow-head">
                              <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                            </g>
                            <g class="arrow-body">
                              <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                            </g>
                          </svg>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div *ngIf="!loaderFiles && !files.length" class="text-center h-100 d-flex justify-content-center align-items-center flex-column">
            <i class="fa fa-folder d-block" style="font-size: 2.5em;color: #f1f1f1;"></i>
            <div style="color: #ddd;padding:20px;font-size:0.9em;">Aucun fichier</div>
          </div>
        </div>

        <!-- Trainings card -->
        <div class="card col-12 mt-4">
          <div class="card-header d-flex justify-content-between">
            <div class="title" [routerLink]="['/trainings']" style="color:#2ab0ff;">Formations</div>
            <span class="refresh-icon">
              <mat-icon aria-hidden="false" aria-label="Rafraichir" [title]="'Recharger les données'" (click)="reloadComponent('trainings')">refresh
              </mat-icon>
            </span>
          </div>
          <div class="is-loading loader-content mb-4" *ngIf="loaderTrainings"></div>
          <div class="card-body" *ngIf="!loaderTrainings">
            <div class="row justify-content-center" *ngIf="trainings.length === 0">
              <a mat-button class="d-flex mb-2"
                 style="width: 80%; background-color: #3e79a0; color: white; justify-content: center;"
                 aria-label="Nouvelle demande" title="Faire une demande" href="https://www.batisafe.fr/formations/formations-sur-mesure" target="_blank">
                Demander une formation
              </a>
            </div>
            <div class="row" *ngIf="trainings.length">
              <div class="col-12 d-md-flex flex-column justify-content-between">
                <div *ngFor="let training of trainings; let last = last;">
                  <div class="media" [title]="'Nom de la formation : ' + training.training.name">
                    <div class="media-body mr-2" [ngClass]="{ 'mb-0' : !last }">
                      <div class="d-flex align-items-center justify-content-between move-card button-arrow border-none" style="border:none!important;box-shadow:none;" [ngClass]="{'border-unset': last}" (click)="redirect('training/' + (this.isParticipant ? training.redirectParticipant.toString() : training.trainingDate.id.toString()), {})">
                        <div class="d-flex flex-column">
                          <small class="text-small mb-0 pre-line ml-2 font-weight-bold">
                            {{ training.training.name }}
                          </small>
                          <div>
                            <small class="text-small mb-0 pre-line ml-2">
                              <span>
                                Le {{ training.trainingDate.dateStart | date: 'dd/MM/yyyy' }} à {{ training.trainingDate.dateStart | date: 'h:mm' }}
                              </span>

                              <span class="pre-line ml-2" [style]="'color: ' + training.trainingDate.status.color">
                                {{ training.trainingDate.status.label }}
                              </span>
                            </small>
                          </div>
                        </div>

                        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
                            <g class="arrow-head">
                              <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
                            </g>
                            <g class="arrow-body">
                              <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
                            </g>
                          </svg>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="!loaderTrainings && !trainings.length" class="text-center h-100 d-flex justify-content-center align-items-center flex-column">
            <i class="fa fa-school d-block" style="font-size: 2.5em;color: #f1f1f1;"></i>
            <div style="color: #ddd;padding:20px;font-size:0.9em;">Aucune formation</div>
          </div>
        </div>

        <!-- Articles card -->
        <div class="card col-12 mt-4">
          <div class="card-header d-flex justify-content-between">
            <div class="title" style="color:#2ab0ff;">Articles</div>
          </div>
          <div class="is-loading loader-content mb-4" *ngIf="loaderArticles"></div>
          <div class="card-body pl-2" *ngIf="!loaderArticles && articles.length">
            <div *ngFor="let article of articles" class="mb-2 d-flex align-items-center" style="font-size:0.9em;">
              <img [src]="imageBatisafePath" *ngIf="article.src === 'batisafe'" width="25" height="18" class="pr-2" alt="Batisafe" matTooltip="Batisafe">
              <img [src]="imageBatiregistrePath" *ngIf="article.src === 'batiregistre'" width="25" height="20" class="pr-2" alt="Batiregistre" matTooltip="Batiregistre">
              <img [src]="imageTheonormePath" *ngIf="article.src === 'theo'" width="25" height="20" class="pr-2" alt="Theonorme" matTooltip="Theonorme">
              <div class="flex-grow-1" style="max-width: calc(100% - 25px);">
                <a class="d-block" [href]="article.link" target="_blank" style="text-overflow: ellipsis;overflow: hidden;white-space: pre;max-width: 100%;">{{article.title}}</a>
                <small class="block">{{ moment(article.date).format('DD/MM/YYYY') }}</small>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
