import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../request.service';

@Injectable()
export class FilesRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  public getBoxPlan(search = '', sort = '', order = '', limit = 0, page = 1, token = null) {
    return this.get('api/portalPlanBox?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page, {}, token);
  }

  public getBoxPlanDetail(id, search = '', sort = '', order = '', limit = 0, page = 1, token = null) {
    return this.get('api/portalPlanBox/' + id + '?search=' + search + '&sort=' + sort + '&order=' + order + '&limit=' + limit + '&page=' + page, {}, token);
  }

  public getBoxPlanFile(id, token = null) {
    return this.get('api/portalPlanBoxFile/' + id, {}, token);
  }

  public addPlanFile(id, data, token = null) {
    return this.post('api/portalPlanBoxFile/' + id, data);
  }

  public deletePlanFile(id, token = null) {
    return this.delete('api/portalPlanBoxFile/' + id);
  }

  public addPlanDirectory(id, data, token = null) {
    return this.post('api/portalPlanBoxDir/' + id, data);
  }

  public deletePlanDirectory(id, token = null) {
    return this.delete('api/portalPlanBoxDir/' + id);
  }
}
