import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNotifierService } from '../../../core/services/custom.notifier.service';

import { TicketsRequestService } from '../../../core/services/tickets/tickets.request.service';
import { PageList } from '../../../core/list/page-list';

import Column from '../../../core/list/column';
import { TicketListComponent } from '../ticket-list/ticket-list.component';

@Component({
  selector: 'app-all-tickets',
  templateUrl: './all-tickets.component.html',
  styleUrls: ['./all-tickets.component.scss'],
})
export class AllTicketsComponent extends PageList implements OnInit {
  columns: Column[] = [];
  tickets: any[];
  public loader: boolean;
  private list: TicketListComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ticketsService: TicketsRequestService,
    private notifier: CustomNotifierService,
  ) {
    super();
  }

  ngOnInit() {

    this.activatedRoute.data.subscribe(response => {

      if (response.data && response.data.success) {
        this.tickets = response.data.tickets;

        this.total = response.data.total;
        this.pages = response.data.pages;

        response.data.forms.forEach(item => {
          this.columns.push({ label: item.label, showLabel: true, sort: item.name, property: item.name, checked: true, type: item.type });
        });

      } else {
        this.notifier.errors(response.data.errors);
      }
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
  }

  getData() {
    this.requestInProgress.next(true);

    this.ticketsService.getTickets(this.search, this.sort, this.order, this.limit, this.page).subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        console.log(response);
        this.tickets = response.datas;
        this.total = response.count;
        this.pages = response.pages;
      }
    });
  }

  deleteData(ticketId) {
    this.requestInProgress.next(true);
    this.ticketsService.deleteTicket(ticketId).subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        this.notifier.successDelete();
        this.changePage(1);
      } else {
        this.notifier.errors(response.errors);
      }
    });
  }

  updateList(data) {
    if (data.action === 'pageSize') {
      this.changePageSize(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    } else if (data.action === 'sort') {
      this.sort = data.value;
      this.order = data.order;
      this.getData();
    } else if (data.action === 'search') {
      this.search = data.value;
      this.page = 1;
      this.getData();
    } else if (data.action === 'delete') {
      this.deleteData(data.value.id);
    } else if (data.action === 'select') {
      this.router.navigate(['/ticket/' + data.value.id]).then(() => {});
    }
  }

  bindList(list: TicketListComponent) {
    if (this.list === undefined) {
      this.list = list;
    }
  }
}
