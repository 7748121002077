import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  loader = false;
  loaderChange = new Subject<boolean>();

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.loaderChange.subscribe((value) => {
      this.loader = value;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.loaderChange.next(true);
    if (state.url.match('/auth/')) {
      this.loaderChange.next(false);
      return true;
    } else {
      if (next.firstChild !== null) {
        const currentUrl = next.firstChild.routeConfig.path;
        if (this.authService.user || currentUrl === 'mentions' || currentUrl === 'cgu') {
          setTimeout(() => {
            this.loaderChange.next(false);
          }, 1000);
          return true;
        } else {
          this.router.navigate(['/auth/login']).then(() => {});
          setTimeout(() => {
            this.loaderChange.next(false);
          }, 1000);
          return false;
        }
      }
    }
  }
}
