<div id="confirm-modal" class="modal modal-confirm fade" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">
              <ng-container *ngIf="!updateSupportingDocument">
                Accepter un devis
              </ng-container>
              <ng-container *ngIf="updateSupportingDocument">
                Modifier le justificatif
              </ng-container>
            </h1>
          </div>
        </div>

        <button type="button" class="close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body">
        <ng-container *ngIf="loader">
          <div class="row text-center" style="margin-top: 1rem;">
            <div class="col-12">
              <i class="fa fa-spinner fa-spin"></i>
              <br/>Récupération d'informations
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!loader && updateSupportingDocument">
          <form [formGroup]="modalForm">
            <div class="mb-3">
              Vous pouvez modifier le justificatif précédemment envoyé
            </div>
            <div class="mb-3">
              <label class="d-block font-weight-bold mb-0" required>Justificatif</label>
              <small class="d-block smallBlock">Le justificatif doit être un scan du devis signé avec bon pour accord</small>
              <input #receiptFile type="file" (change)="handleUpload($event)" class="form-control-file" />
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="!loader && !updateSupportingDocument">
          <form [formGroup]="modalForm">
            <div class="mb-3">
              Veuillez remplir le formulaire ci-dessous avec l'adresse de facturation et joindre le bon pour accord avant de confirmer la validation.
            </div>
            <div class="mb-3">
              <label class="d-block font-weight-bold mb-0" required>Email</label>
              <small class="d-block smallBlock">L'adresse email est utilisée pour vous transmettre des documents par voie électronique</small>
              <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched) }" />
            </div>
            <div class="mb-3">
              <label class="d-block font-weight-bold mb-0" required>Adresse de facturation</label>
              <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': address.invalid && (address.dirty || address.touched) }"  />
            </div>
            <div class="mb-3">
              <label class="d-block font-weight-bold mb-0">Complément d'adresse</label>
              <input type="text" formControlName="address1" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="d-block font-weight-bold mb-0">Complément d'adresse (bis)</label>
              <input type="text" formControlName="address2" class="form-control" />
            </div>
            <div class="row">
              <div class="mb-3 col-4">
                <label class="d-block font-weight-bold mb-0" required>Code postal</label>
                <input type="text" formControlName="zipcode" class="form-control" [ngClass]="{ 'is-invalid': zipcode.invalid && (zipcode.dirty || zipcode.touched) }" />
              </div>
              <div class="mb-3 col-8">
                <label class="d-block font-weight-bold mb-0" required>Ville</label>
                <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': city.invalid && (city.dirty || city.touched) }" />
              </div>
            </div>
            <div class="mb-3">
              <label class="d-block font-weight-bold mb-0" required>Justificatif</label>
              <small class="d-block smallBlock">Le justificatif doit être un scan du devis signé avec bon pour accord</small>
              <input #receiptFile type="file" (change)="handleUpload($event)" class="form-control-file" />
            </div>
          </form>
        </ng-container>
      </div>

      <div class="modal-footer" *ngIf="!loader">
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 mr-2" (click)="cancel()">
          Annuler
        </button>
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 ml-2" [disabled]="file === undefined || modalForm.invalid" (click)="validate()">
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
