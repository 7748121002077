import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { NotifierModule } from 'angular-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/auth/auth.guard';
import { AuthService } from './core/auth/auth.service';
import { PendingChangesGuard } from './core/utils/pending.changes.guard';
import { TrainingsService } from './core/services/trainings/trainings.service';
import { SurveyService } from './core/survey/services/survey.service';
import { QuotationsService } from './core/services/quotations/quotations.service';

import { PagesModule } from './pages/pages.module';

import { CustomNotifierService } from './core/services/custom.notifier.service';
import { InterceptorService } from './core/services/interceptor.service';
import { PreviewService } from './core/services/helper/preview.service';

import { UsersRequestService } from './core/services/users/users.request.service';
import { ExamplesRequestService } from './core/services/examples/examples.request.service';
import { GlobalErrorHandler } from './core/utils/global-error-handler';
import { EditorModule } from '@tinymce/tinymce-angular';
import { QuotationsRequestService } from './core/services/quotations/quotations.request.service';
import { InvoicesRequestService } from './core/services/invoices/invoices.request.service';
import { SurveyRequestService } from './core/survey/services/survey.request.service';
import { TrainingsRequestService } from './core/services/trainings/trainings.request.service';
import { FilesRequestService } from './core/services/files/files.request.service';
import { NotificationService } from './core/services/notification/notification.service';
import { NotificationRequestService } from './core/notification-widget/notification.request.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AccountRequestService } from './core/services/account/account.request.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketsRequestService } from './core/services/tickets/tickets.request.service';
import { PatrimonyRequestService } from './core/services/theonorme/patrimony/patrimony.request.service';
import { CategoryVeilleRequestService } from './core/services/theonorme/category-veille/category-veille.request.service';
import { EstablishmentTypeRequestService } from './core/services/theonorme/establishmentType/establishment-type.request.service';
import { TheonormeRequestService } from './core/services/theonorme/theonorme.request.service';
import { DashboardRequestService } from './core/services/dashboard/dashboard.request.service';
import { DashboardService } from './core/services/dashboard/dashboard.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    CoreModule,
    PagesModule,
    EditorModule,

    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
        },
        vertical: {
          distance: 0,
          gap: 0
        }
      },
      behaviour: {
        stacking: 1,
        autoHide: 5000,
        onMouseover: false,
        onClick: 'hide'
      },
      animations: {
        hide: {
          preset: 'slide',
        }
      }
    }),
    NgbModule,
  ],
  providers: [
    PendingChangesGuard,
    AuthGuard,
    AuthService,

    ExamplesRequestService,
    QuotationsRequestService,
    InvoicesRequestService,
    UsersRequestService,
    SurveyRequestService,
    SurveyService,
    QuotationsService,
    TrainingsRequestService,
    TrainingsService,
    FilesRequestService,
    AccountRequestService,
    PerfectScrollbarModule,
    CustomNotifierService,
    NotificationService,
    NotificationRequestService,
    TicketsRequestService,
    InterceptorService,
    TheonormeRequestService,
    PreviewService,
    PatrimonyRequestService,
    CategoryVeilleRequestService,
    EstablishmentTypeRequestService,
    DashboardRequestService,
    DashboardService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
