import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardRequestService extends RequestService {
  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  /**
   * Returnbatisafe blog articles
   */
  public getBatisafeBlog() {
    return this.get('api/dashboard-articles');
  }

  /**
   * Return X last invoices and invoices as alert
   * @param limit Limit to display
   */
  public getInvoices(limit: number = 5) {
    return this.get('api/portal-invoices-dashboard?limit=' + limit);
  }

  /**
   * Return X last quotations and quotations as alert
   * @param limit Limit to display
   */
  public getQuotations(limit: number = 5) {
    return this.get('api/portal-quotations-dashboard?limit=' + limit);
  }

  /**
   * Return X last trainings and trainings to come
   * @param isParticipant To retrieve training dates for participant or client
   * @param limit Limit to display
   */
  public getTrainings(isParticipant: boolean, limit: number = 5) {
    return this.get('api/portal-trainings-dashboard?limit=' + limit + '&participant=' + isParticipant);
  }

  /**
   * Return X last webinars to come (using LiveStorm API)
   * @param limit Limit to display
   */
  public getWebinars(limit: number = 5) {
    return this.get('api/portal-webinars-dashboard?limit=' + limit);
  }

  /**
   * Return X last files
   * @param limit Limit to display
   */
  public getFiles(limit: number = 5) {
    return this.get('api/portal-files-dashboard?limit=' + limit);
  }

  /**
   * Return X last tickets
   * @param limit Limit to display
   */
  public getTickets(limit: number = 5) {
    return this.get('api/portal-tickets-dashboard?limit=' + limit);
  }

  /**
   * Return X last veilles
   * @param limit Limit to display
   */
  public getVeilles(limit: number = 3) {
    return this.get('api/portal-veilles-dashboard?limit=' + limit);
  }

  /**
   * On first load of dashboard, retrieve all data in one request.
   *
   * @param isParticipant To retrieve training dates for participant or client
   * @param limitInvoices
   * @param limitQuotations
   * @param limitTrainings
   * @param limitWebinars
   * @param limitFiles
   * @param limitTickets
   * @param limitVeilles
   */
  public getDashboardDataFirstLoad(
    isParticipant: boolean,
    limitInvoices: number = 5,
    limitQuotations: number = 5,
    limitTrainings: number = 5,
    limitWebinars: number = 5,
    limitFiles: number = 5,
    limitTickets: number = 5,
    limitVeilles: number = 3,
  ) {
    return this.get('api/dashboard-first-load?limitInvoices=' + limitInvoices +
      '&limitQuotations=' + limitQuotations +
      '&limitTrainings=' + limitTrainings +
      '&participant=' + isParticipant +
      '&limitWebinars=' + limitWebinars +
      '&limitFiles=' + limitFiles +
      '&limitTickets=' + limitTickets +
      '&limitVeilles=' + limitVeilles);
  }
}
