<div class="row mr-0 ml-0">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> Mon compte </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Gestion de compte </div>
    </div>
  </div>
</div>

<div class="row mt-2 ml-2 mr-2">

  <div class="col-6">
    <div class="card">
      <div class="card-body">
        <div class="title pl-0">Informations générales</div>
        <form [formGroup]="accountForm">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="email">Email</label>
                <input id="email" formControlName="email" type="text" class="form-control w-100" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="firstname">Prénom</label>
                <input id="firstname" formControlName="firstname" type="text" class="form-control w-100" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-0">
                <label class="w-100" for="lastname">Nom</label>
                <input id="lastname" formControlName="lastname" type="text" class="form-control w-100" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-4 text-right">
              <div class="form-group">
                <input *ngIf="!loaderAccount" type="button" style="min-width:115px;" value="Modifier" class="btn btn-primary" (click)="accountUpdate()" />
                <button *ngIf="loaderAccount" type="button" style="min-width:115px;" class="btn btn-primary" [disabled]="accountForm.get('password').value !== accountForm.get('password_r').value"><i class="fa fa-spin fa-spinner"></i></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card" style="min-height:376px;">
      <div class="card-body">
        <div class="title pl-0">Mot de passe</div>
        <div style="font-size:0.8em;margin-bottom:15px;font-style:italic;">Le mot de passe doit respecter les
          standards RGPD. Afin de garantir la sécurité de votre compte, veuillez ne pas saisir un mot de passe
          que vous avez déjà utilisé ou un mot de passe que vous utilisez sur d'autres plateformes.</div>
        <form [formGroup]="accountForm">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="password">Mot de passe</label>
                <input id="password"
                       formControlName="password"
                       type="password"
                       class="form-control w-100"
                       [ngClass]="accountForm.get('password').errors && accountForm.get('password').value ? 'border-danger' : ''"
                />

                <ng-container *ngIf="accountForm.get('password').errors && accountForm.get('password').value">
                  <small class="text-danger d-block mt-2 mb-2">{{ accountForm.get('password').errors.password.message }}</small>
                </ng-container>

              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-0 border-danger">
                <label class="w-100" for="password_r">Ressaisir le mot de passe</label>
                <input id="password_r"
                       formControlName="password_r"
                       type="password"
                       class="form-control w-100"
                       [ngClass]="accountForm.get('password_r').value && accountForm.get('password').value !== accountForm.get('password_r').value ? 'border-danger' : ''"
                />

                <ng-container *ngIf="accountForm.get('password_r').value && accountForm.get('password').value !== accountForm.get('password_r').value">
                  <small class="text-danger d-block mt-2 mb-2">Les mots de passes ne correspondent pas</small>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row" style="position:absolute;bottom:0;right:20px;">
            <div class="col-12 mt-4 text-right">
              <div class="form-group">
                <button
                  class="btn btn-primary"
                  style="min-width: 115px;"
                  (click)="passwordUpdate()"
                  [disabled]="accountForm.invalid || accountForm.get('password').value !== accountForm.get('password_r').value || loaderPassword"
                >
                  <ng-container *ngIf="loaderPassword">
                    <i class="fa fa-spin fa-spinner"></i>
                  </ng-container>
                  <ng-container *ngIf="!loaderPassword">
                    Modifier
                  </ng-container>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2 ml-2 mr-2">
  <div class="col-12 mt-3">
    <div class="card">
      <div class="card-body">
        <div class="title pl-0">Patrimoine</div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Types d'établissement</label>
                <ng-select [items]="arrayEstablishmentTypes"
                           [multiple]="true"
                           bindLabel="type"
                           notFoundText="Aucun résultat"
                           [selectableGroup]="true"
                           [closeOnSelect]="false"
                           bindValue="id"
                           placeholder="Sélectionnez un/plusieurs type(s)"
                           [(ngModel)]="selectedEstablishments" [ngModelOptions]="{ standalone: true }">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.type }}
                  </ng-template>
                </ng-select>

              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label>Registres</label>
                <ng-select [items]="arrayCategories"
                           [multiple]="true"
                           bindLabel="categorie"
                           notFoundText="Aucun résultat"
                           [selectableGroup]="true"
                           [closeOnSelect]="false"
                           bindValue="id"
                           placeholder="Sélectionnez un/des registre(s)"
                           [(ngModel)]="selectedRegistries" [ngModelOptions]="{ standalone: true }">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }" /> {{ item.categorie }}
                  </ng-template>
                </ng-select>

              </div>
            </div>

          </div>
          <div class="row text-right">
            <div class="col-12">
              <div class="form-group">
                <input *ngIf="!loaderPatrimony" type="button" style="min-width:115px;" value="Modifier" class="btn btn-primary" [disabled]="loaderPatrimony || !selectedRegistries.length || !selectedEstablishments.length" (click)="modifyPatrimony()" />
                <button *ngIf="loaderPatrimony" type="button" style="min-width:115px;" class="btn btn-primary" [disabled]="loaderPatrimony"><i class="fa fa-spin fa-spinner"></i></button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
