import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropDirective } from './drag-drop.directive';
import { LeviatanCurrencyFormatterDirective } from './leviatan.currency.formatter.directive';
import { LeviatanCurrencyPipe } from './leviatan.currency.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DragDropDirective,
    LeviatanCurrencyFormatterDirective,
  ],
  exports: [
    DragDropDirective,
    LeviatanCurrencyFormatterDirective,
  ],
  providers: [
    LeviatanCurrencyPipe,
  ]
})
export class UtilsModule {}
