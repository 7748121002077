<div class="no-result">
  <div class="image animation">
    <img [src]="picture" alt="" [width]="width">
  </div>

  <div class="title animation">{{ title }}</div>
  <ng-container *ngIf="subTitle">
    <div class="sub-title animation font-light">{{ subTitle }}</div>
  </ng-container>
  <ng-container *ngIf="!subTitle && subTitleService">
    <div class="sub-title animation font-light">{{ subTitleService }}</div>
  </ng-container>
</div>
