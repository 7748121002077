import { saveAs } from 'file-saver';

import * as JSZip from 'jszip';

/**
 * Create a new zip directory containing base64 files
 *
 * @param zipName The zip name will set the name for the given zip directory
 * @param files All files need to respect some structure in array => `[file_name: string, file_base_64: string]`. <br/>
 *              N.B. No need to split the base 64, can pass `"data:image/jpeg;base64,"`, it will be removed in function
 * @param fileNameKey To access the file name in for loop if the given `files` structure not
 *                    respect the `files` structure declaration
 * @param fileValue To access the file data in for loop if the given `files` structure not
 *                  respect the `files` structure declaration
 */
export function zipFilesBase64(zipName: string, files: any[], fileNameKey: string = null, fileValue: string = null) {
  // Update the zipName if the extension `.zip` is not set
  if (zipName.split('.zip').length === 1) {
    zipName += '.zip';
  }

  // Create the new zip object
  const zip = new JSZip();

  // For each file, add it to the zip object
  for (const file of files) {
    if (fileNameKey !== null && fileValue !== null) {
      // Remove the data url from file (example "data:image/jpeg;base64," is removed) using fileKey and fileValue
      zip.file(file[fileNameKey], file[fileValue].split(',')[1], { base64: true });
    } else {
      // Remove the data url from file (example "data:image/jpeg;base64," is removed)
      zip.file(file.file_name, file.file_base_64.split(',')[1], { base64: true });
    }
  }

  // Generate the zip as a blob
  zip.generateAsync({ type: 'blob' })
    .then((content) => {
      // Using file-saver, save the zip directory in client hardware
      saveAs(content, zipName);
    });
}
