import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';
import { TicketsRequestService } from './tickets.request.service';

import * as moment from 'moment';

@Injectable()
export class TicketsResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private ticketsService: TicketsRequestService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      if (route.routeConfig.path === 'tickets') {
        this.requestInProgress.next(true);
        this.ticketsService.getTickets('', '', '', 12, 1, this.token).subscribe((response: any) => {
          data.tickets = response.datas;
          data.forms = response.form;
          data.total = response.count;
          data.pages = response.pages;
          data.success = response.success;
          this.requestInProgress.next(false);
        });
      } else if (route.routeConfig.path === 'ticket/:ticketId') {
        this.requestInProgress.next(true);
        this.ticketsService.getTicket(route.params.ticketId, this.token).subscribe((response: any) => {
          data.ticket = response.data.ticket;
          data.comments = response.data.comments;
          data.files = response.data.files;
          this.requestInProgress.next(false);
        });
      }
    });
  }
}
