import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllTrainingsModule } from './all-trainings/all-trainings.module';
import { DetailTrainingModule } from './detail-training/detail-training.module';
import { SurveyModule } from '../../core/survey/survey.module';

import { TrainingsResolver } from '../../core/services/trainings/trainings.resolver';
import { DetailTrainingResolver } from '../../core/services/trainings/detail_training.resolver';
import { SurveyResolver } from '../../core/survey/services/survey.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllTrainingsModule,
    DetailTrainingModule,
    SurveyModule
  ],
  providers: [
    TrainingsResolver,
    DetailTrainingResolver,
    SurveyResolver
  ]
})
export class TrainingsModule {}
