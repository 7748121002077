<div class="container" style="max-width:100%;display:flex;flex-direction: column;height:100%;">
  <div class="row justify-content-center">
    <div class="col-12 justify-content-center d-flex pt-4 pb-4 header">
      <div class="logo"></div>
    </div>
  </div>
  <div class="flex-grow-1 row d-block" style="background:url('../../../../assets/img/pattern.jpg');">
    <div class="row justify-content-center p-3 d-flex">
      <div class="col-lg-6 col-md-12 col-xl-4 mb-3 mt-lg-3">
        <div class="card d-flex flex-column h-100 m">
          <div class="card-header">
            <div class="card-title">Réinitialiser le mot de passe</div>
            <div class="card-subtitle">
              Pour retrouver l'accès à votre compte, veuillez saisir dans le champ ci-dessous votre nouveau mot de
              passe.
            </div>
          </div>

          <div class="card-body">
            <form [formGroup]="newPasswordForm">
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label for="validationPassword" class="required">
                      <b>Nouveau mot de passe</b>
                    </label>
                  </div>
                </div>

                <div class="input-group input-group-merge">
                  <input
                    formControlName="password"
                    [type]="showPassword ? 'text' : 'password'"
                    id="validationPassword"
                    class="form-control form-control-appended"
                    [ngClass]="{ 'is-invalid': newPasswordForm.get('password').invalid && (newPasswordForm.get('password').dirty || newPasswordForm.get('password').touched) }"
                    (keyup)="clearError()"
                  >
                  <div class="input-group-append" (click)="this.showPassword = !this.showPassword">
                    <span class="input-group-text" style="cursor:pointer;">
                      <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
                    </span>
                  </div>
                  <div
                    *ngIf="newPasswordForm.get('password').invalid && (newPasswordForm.get('password').dirty || newPasswordForm.get('password').touched)"
                    class="invalid-feedback"
                  >
                    {{ newPasswordForm.get('password').errors.password.message }}
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-primary mb-3" (click)="resetPassword()"
                        [ngClass]="{ 'is-loading is-loading-sm': isSigningIn }"
                        [disabled]="isSigningIn || newPasswordForm.invalid">
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

