// tslint:disable:variable-name
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoResultService {
  constructor() { }

  private _title: string;
  private _subTitle: string;

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get subTitle(): string {
    return this._subTitle;
  }

  set subTitle(value: string) {
    this._subTitle = value;
  }
}
