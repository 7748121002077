import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllExamplesModule } from './all-examples/all-examples.module';
import { EditExampleModule } from './edit-example/edit-example.module';

import { ExamplesResolver } from '../../core/services/examples/examples.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllExamplesModule,
    EditExampleModule
  ],
  providers: [
    ExamplesResolver,
  ]
})
export class ExamplesModule {}
