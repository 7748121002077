import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import * as moment from 'moment';
import { HttpCancelService } from '../http.cancel.service';
import { DashboardRequestService } from './dashboard.request.service';
import { DashboardDataYield } from '../../interfaces/dashboard/dashboard';
import {AuthService} from '../../auth/auth.service';

@Injectable()
export class DashboardResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();

  requestCount = 0;

  constructor(
    private _router: Router,
    private _dashboardRequestService: DashboardRequestService,
    private _authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this._router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });

      if (route.routeConfig.path === '') {
        this.requestInProgress.next(true);

        this._dashboardRequestService.getDashboardDataFirstLoad(this._authService.user.training_participant_access)
          .subscribe((response: DashboardDataYield) => {
            data.success = response.success;
            data.invoices = response.invoices;
            data.quotations = response.quotations;
            data.trainings = response.trainings;
            data.webinars = response.webinars;
            data.files = response.files;
            data.tickets = response.tickets;
            data.veilles = response.veilles;
            data.customCards = response.customCards;
            data.portal_config = response.portal_config;

            this.requestInProgress.next(false);
          });
      }
    });
  }
}
