<div class="row mr-0 ml-0 mt-3">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> Factures </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Liste des factures </div>
    </div>
  </div>
</div>

<div class="row ml-4 mr-4">
  <div class="col-12">
    <app-list
      [columns]="columns"
      [items]="invoices"
      [total]="total"
      [pages]="pages"
      [page]="page"
      [hideAction]="true"
      [hideAdd]="true"
      [menu]="menu"
      [preview]="preview"
      [loaderPreview]="isLoaderPreview"
      [loaderPreviewMenu]="isLoaderPreviewMenu"
      (returnEvent)="updateList($event)" class="w-100"
      #list >
      {{ bindList(list) }}
    </app-list>
  </div>
</div>
