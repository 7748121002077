import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthService} from '../../../core/auth/auth.service';
import {AuthRequestService} from '../../../core/services/auth/auth.request.service';
import {CustomNotifierService} from '../../../core/services/custom.notifier.service';
import {CountryISO, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import RegisterEnterpriseResponse from '../../../core/interfaces/response/registerEnterprise.response';
import RegisterUserResponse from '../../../core/interfaces/response/registerUser.response';
import RegisterCheckEmailResponse from '../../../core/interfaces/response/registerCheckEmail.response';
import AuthResponse from '../../../core/interfaces/response/auth.response';
import LoginErrorResponse from '../../../core/interfaces/response/LoginError.response';
import ValidatorPassword from '../../../core/custom-validators/password.directive';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('routeTransition', [
      state('void', style({ width: '100%', height: 'auto', display: 'block', position: 'relative' }) ),
      state('*', style({ width: '100%', height: 'auto', display: 'block', position: 'relative' }) ),
      transition(':enter', [
        style({
          opacity: '0',
          overflow: 'hidden'
        }),
        animate('.5s linear', style({
          opacity: '1',
          overflow: 'hidden'
        }))
      ]),
      transition(':leave', [
        style({
        })
      ])
    ])
  ]
})

export class LoginComponent implements OnInit {
  public cardLogin = true;
  public cardRegister = false;
  public showPassword = false;
  public isSigningIn = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public errors: LoginErrorResponse = {};
  public accPart: boolean;
  public accPartStep: boolean;
  public accEnter: boolean;

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, ValidatorPassword(0, false, false, false, false)])
  });
  public RegisterPartForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, ValidatorPassword()]),
    passwordr: new FormControl('', [Validators.required, ValidatorPassword()]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    civility: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl('', []),
    zip_code: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
  });
  public RegisterEnterForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, ValidatorPassword()]),
    passwordr: new FormControl('', [Validators.required, ValidatorPassword()]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    job: new FormControl('', [Validators.required]),
    contactPhone: new FormControl('', [Validators.required]),
    siret: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    address2: new FormControl('', []),
    zip_code: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    civility: new FormControl('', [Validators.required]),
  });

  public EnterpriseStep: boolean;
  public loaderRegister = false;
  public loaderLog = false;
  public TooltipLabel = TooltipLabel;
  public CountryISO = CountryISO;
  public SearchCountryField = SearchCountryField;
  showRegister: any;
  showLogin: any;
  resetPassword: boolean;
  resetPasswordEmail: string;
  passwordRequestProgress: any;

  @HostBinding('@routeTransition') get routeTransition() {
    return;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private authRequestService: AuthRequestService,
    private notifier: CustomNotifierService,
    private router: Router
  ) {}

  ngOnInit() {
    this.accPartStep = false;
    this.resetPassword = false;
    this.passwordRequestProgress = false;

    this.showLogin = true;
    this.showRegister = true;

    // this.showRegister = (document.body.offsetWidth > 992);
    // this.showLogin = (document.body.offsetWidth > 992);
  }

  /**
   * Hide/show password button
   */
  public toggle() {
    this.showPassword = !this.showPassword;
  }

  /**
   * Return message error
   */
  public getErrorMessage(param) {
    return (this.errors[param] && this.errors[param].message);
  }

  /**
   * Return message error from custom validator password
   * @param control
   */
  public getPasswordError(control: FormControl) {
    return control.errors.password.message;
  }

  /**
   * Login function
   */
  public logIn() {
    this.errors = {};
    this.isSigningIn = true;
    this.loaderLog = true;

    this.authRequestService.login(this.loginForm.value).subscribe((response: AuthResponse) => {
      this.loaderLog = false;
      if (response.success) {
        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('refreshToken', response.refresh_token);
        localStorage.setItem('user', JSON.stringify(response.user)); // FIXME: Remove the support pack batisafe from localstorage to only keep it in user service
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('portalConfig', JSON.stringify(response.portal_config))
        localStorage.setItem('enterprises', JSON.stringify(response.enterprises));
        localStorage.setItem('enterprise', JSON.stringify(response.enterprises[0]));
        this.authService.user = response.user;
        this.authService.login();
      } else if (response.errors && !response.errors.general) {
        this.errors = response.errors;
        this.notifier.errorCustom('Les identifiants saisis sont incorrects');
      } else {
        this.notifier.errorCustom('Les identifiants saisis sont incorrects');
      }
      this.isSigningIn = false;
    });
  }

  /**
   * Clear all visual error
   */
  public clearError() {
    this.loginForm.get('email').updateValueAndValidity();
    this.loginForm.get('password').updateValueAndValidity();
  }

  /**
   * Check step one of enterprise register
   */
  public stepOneCheck() {
    if (this.accEnter) {
      this.RegisterEnterForm.controls.password.setValidators(ValidatorPassword());
      this.RegisterEnterForm.controls.passwordr.setValidators(ValidatorPassword());
    } else {
      this.RegisterPartForm.controls.password.setValidators(ValidatorPassword());
      this.RegisterPartForm.controls.passwordr.setValidators(ValidatorPassword());
    }
    return !(this.RegisterEnterForm.get('email').valid && this.RegisterEnterForm.get('firstname').valid &&
      this.RegisterEnterForm.get('password').valid && this.RegisterEnterForm.get('passwordr').valid &&
      this.RegisterEnterForm.get('lastname').valid);
  }

  /**
   * Check step to of enterprise register
   */
  public registerStepTwo() {
    if (this.RegisterEnterForm.controls.password.value !== this.RegisterEnterForm.controls.passwordr.value) {
      this.errors.renterPassword = { message: 'Les mots de passe saisis ne sont pas identiques.' };
      this.getEntFormControl('password').setErrors({ incorrect: true });
      this.getEntFormControl('passwordr').setErrors({ incorrect: true });
    } else {
      this.loaderRegister = true;
      this.getEntFormControl('contactPhone').reset();
      this.getEntFormControl('phone').reset();

      const data = {email : this.RegisterEnterForm.controls.email.value};
      this.authRequestService.checkEmail(data).subscribe((response: RegisterCheckEmailResponse) => {
        this.loaderRegister = false;
        if (response.success) {
          if (response.exist) {
            this.notifier.errorCustom(response.message);
            this.getEntFormControl('email').setErrors({incorrect: true});
            this.errors.renterEmail = { message : response.message };
          } else {
            this.EnterpriseStep = true;
          }
        } else {
          this.notifier.errorRequest();
        }
      });
    }
  }

  /**
   * Create an user account (enterprise or personal)
   *
   * @param accountType <br/> 0 = Personal account <br/> 1 = Enterprise account
   */
  createAccount(accountType: 0|1) {
    this.loaderRegister = true;

    if (accountType === 0) {
      if (this.RegisterPartForm.controls.password.value !== this.RegisterPartForm.controls.passwordr.value) {
        this.errors.rpartPassword = { message: 'Les mots de passe saisis ne sont pas identiques.' };
        this.getPartFormControl('password').setErrors({incorrect: true});
        this.getPartFormControl('passwordr').setErrors({incorrect: true});
        this.notifier.errorCustom('Les mots de passe saisis ne sont pas identiques.');
        return;
      }

      this.authRequestService.createAccount(accountType, this.RegisterPartForm.value)
        .subscribe((response: RegisterUserResponse) => {
        this.loaderRegister = false;

        if (response.success) {
          this.returnToLoginPage();
          this.notifier.successCustom(response.message);
        } else {
          this.notifier.errorCustom(response.error.message);
          this.RegisterPartForm.controls[response.error.form].setErrors({incorrect: true});
          this.errors.rpartEmail = {message : response.error.message};
        }
      });
    } else if (accountType === 1) {
      this.authRequestService.createAccount(accountType, this.RegisterEnterForm.value)
        .subscribe((response: RegisterEnterpriseResponse) => {
        this.loaderRegister = false;

        if (response.success) {
          this.returnToLoginPage();
          this.notifier.successCustom(response.message);
        } else {
          this.notifier.errorCustom(response.error.message);
        }
      });
    }
  }

  /**
   * Check if email address is valid
   *
   * @param enterprise To retrieve different FormControl in different FormGroup
   */
  checkEmail(enterprise: boolean = false) {
    if (enterprise) {
      if (!this.getEntFormControl('email').valid) {
        this.errors.renterEmail = { message : 'Vous devez saisir une adresse email valide' };
      }
    } else {
      if (!this.getPartFormControl('email').valid) {
        this.errors.rpartEmail = { message : 'Vous devez saisir une adresse email valide' };
      }
    }
  }

  /**
   * Check step to of enterprise register
   */
  public registerPartStepTwo() {
    if (this.RegisterPartForm.controls.password.value !== this.RegisterPartForm.controls.passwordr.value) {
      this.errors.rpartPassword = { message : 'Les mots de passe saisis ne sont pas identiques.' };
      this.getPartFormControl('password').setErrors({ incorrect: true });
      this.getPartFormControl('passwordr').setErrors({ incorrect: true });
    } else {
      this.loaderRegister = true;

      const data = { email : this.RegisterPartForm.controls.email.value };
      this.authRequestService.checkEmail(data).subscribe((response: RegisterCheckEmailResponse) => {
        this.loaderRegister = false;
        this.getPartFormControl('phone').reset();

        if (response.success) {
          if (response.exist) {
            this.notifier.errorCustom(response.message);
            this.getPartFormControl('email').setErrors({ incorrect: true });
            this.errors.rpartEmail = { message : response.message };
          } else {
            this.accPartStep = true;
          }
        } else {
          this.notifier.errorRequest();
        }
      });
    }
  }

  cleanPasswordPart() {
    if (this.RegisterPartForm.controls.password.value !== this.RegisterPartForm.controls.passwordr.value) {
      this.errors.rpartPassword = { message : 'Les mots de passe saisis ne sont pas identiques.' };
    } else {
      this.errors.rpartPassword = { message : '' };
    }
  }

  /**
   * Redirect to the forgot password page
   */
  public forgotPassword(event) {
    event.preventDefault();
    this.resetPassword = true;
    // this.router.navigateByUrl('/auth/forgotPassword');
  }

  sendPasswordReset() {
    this.passwordRequestProgress = true;
    this.authRequestService.forgotPassword({email : this.resetPasswordEmail}).subscribe((response: AuthResponse) => {
      this.passwordRequestProgress = false;
      if (response.success) {
        // this.router.navigateByUrl('/auth/login');
        this.notifier.successCustom('Un email avec un lien permettant de changer de mot de passe vous a été envoyé.');
        this.resetPassword = false;
        this.resetPasswordEmail = undefined;
      } else if (response.errors && !response.errors.general) {
        // this.errors = response.errors;
        this.notifier.errorRequest();
      }
    });
  }

  resetPasswordLost() {
    this.resetPassword = false;
    this.resetPasswordEmail = undefined;
    this.passwordRequestProgress = false;
  }

  /**
   * Display the login page and clear all forms.
   * This action is called when user click on "Already have an account" link or when a form is sent.
   */
  returnToLoginPage() {
    this.cardLogin = true;
    this.resetPassword = false;
    this.cardRegister = false;
    this.accEnter = false;
    this.accPart = false;
    this.accPartStep = false;
    this.EnterpriseStep = false;

    this.RegisterEnterForm.reset();
    this.RegisterPartForm.reset();
    this.loginForm.reset();
  }

  /**
   * Check if all fields for the first step of user creation are ok
   */
  checkFirstPartUserValid(): boolean {
    return this.getPartFormControl('password').invalid ||
      this.getPartFormControl('passwordr').invalid ||
      this.getPartFormControl('email').invalid ||
      this.getPartFormControl('firstname').invalid ||
      this.getPartFormControl('lastname').invalid;
  }

  /**
   * Get a FormControl from RegisterEnterForm FormGroup
   *
   * @param controlName
   */
  getEntFormControl(controlName: string): FormControl {
    return this.RegisterEnterForm.get(controlName) as FormControl;
  }

  /**
   * Get a FormControl from RegisterPartForm FormGroup
   *
   * @param controlName
   */
  getPartFormControl(controlName: string): FormControl {
    return this.RegisterPartForm.get(controlName) as FormControl;
  }
}
