<div class="row"
     infiniteScroll
     [infiniteScrollDistance]="scrollDistance"
     [infiniteScrollUpDistance]="scrollUpDistance"
     [infiniteScrollThrottle]="throttle"
     (scrolled)="onDown()"
     infiniteScrollContainer=".container-fluid"
     fromRoot="true">

  <div class="col-12 text-center" *ngIf="notificationService.loader">
    <div class="is-loading is-loading-black loader-content"></div>
  </div>

  <div class="col-12" *ngIf="!notificationService.loader">
    <div class="card card-scroll">
      <div class="card-header">
        <div class="d-flex row">
          <div class="d-flex col align-items-center">
            <div class="card-header-icon">
              <i class="mdi mdi-bell"></i>
            </div>

            <div>
              <h4 class="card-title">Vos notifications</h4>
              <h5 class="card-subtitle mb-0">Centre de notification</h5>
            </div>
          </div>

          <div class="col-12 col-md-auto d-flex no-block mt-md-0 mt-4">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button class="btn btn-primary card-button" id="dropdownBasic2" ngbDropdownToggle>
                Actions
              </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button ngbDropdownItem (click)="viewAll()"><i class="mdi mdi-checkbox-multiple-marked-outline"></i> Tout marquer comme lu</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div *ngFor="let key of objectKeys(notificationService.notifications)">
          <h4 class="category-title">{{ key }}</h4>
          <ul class="timeline timeline-left" @notifications>
            <li class="timeline-inverted timeline-item" *ngFor="let notification of notificationService.notifications[key]" @notification>
              <div class="timeline-badge success"><i class="mdi" [ngClass]="notification.icon"></i> </div>

              <div class="timeline-panel" [ngClass]="notification.isViewed ? 'is-viewed' : 'is-not-viewed'">
                <div class="timeline-heading">
                  <div class="d-flex justify-content-between">
                    <h5 class="timeline-title mb-0">{{ notification.title }}</h5>

                    <button class="marked mb-0" [disabled]="changeIsViewed" (click)="viewNotification(notification)">
                      <small class="text-muted">{{ notification.isViewed ? 'Marquer comme non lu' : 'Marquer comme lu' }}</small>
                    </button>
                  </div>

                  <p>
                    <small class="text-muted"><i class="fa fa-clock-o"></i> {{ moment(notification.created.date).fromNow() }}</small>
                  </p>
                </div>

                <div class="timeline-body">
                  <p>{{ notification.body }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <app-no-result [title]="'Nouveau départ'" [subTitle]="'Vous n\'avez aucune notification'" width="202"
                       *ngIf="!objectKeys(notificationService.notifications).length">
        </app-no-result>
      </div>
    </div>
  </div>
</div>
