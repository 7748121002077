<div class="row mr-0 ml-0 mt-3">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> Créer un ticket </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > <a href="#" routerLink="/tickets" class="breadcrum-link">Mes tickets</a> > Créer un ticket </div>
    </div>
  </div>
</div>

<div class="row ml-2 mr-2">
  <div class="col-12">
    <div class="alert-ticket">
      <i class="fa fa-exclamation d-inline-block mr-2"></i> Vous souhaitez nous poser une question, demander un devis, témoigner, réclamer, féliciter,… ? Merci de nous en faire part via ce système de tickets.
    </div>
  </div>
</div>

<div class="row ml-4 mr-4">
  <div class="card col-12">
  <div class="card-body">
    <form [formGroup]="messageForm">
      <div class="row">
        <div class="col-12 mb-3">
          <!-- LABEL -->
          <label required class="mb-2">Sujet</label>
          <!-- INPUT -->
          <input formControlName="content" class="form-control" placeholder=""
                 type="text">
          <!-- ERROR -->
          <mat-error *ngIf="messageForm.controls['content'].hasError('required')  && !messageForm.controls['content'].valid && messageForm.controls['content'].touched">
            <span>Ce champ ne peux pas être vide</span>
          </mat-error>
          <mat-error *ngIf="messageForm.controls['content'].hasError('key')">
            {{ messageForm.controls['content'].errors['key'] }}
          </mat-error>
        </div>

        <div class="col-12 mb-3">
          <!-- LABEL -->
          <label required class="mb-2">Message</label>
          <!-- INPUT -->
          <textarea id="textarea" (keyup)="autogrowTextHeight()" formControlName="comment" class="form-control" matInput placeholder="" type="text"></textarea>
          <!-- ERROR -->
          <mat-error *ngIf="messageForm.controls['comment'].hasError('required') && !messageForm.controls['comment'].valid && messageForm.controls['comment'].touched">
            <span>Ce champ ne peux pas être vide</span>
          </mat-error>
          <mat-error *ngIf="messageForm.controls['comment'].hasError('key')">
            {{ messageForm.controls['comment'].errors['key'] }}
          </mat-error>
        </div>

        <input id="fileUpload" multiple="multiple" name="fileUpload" style="display: none" type="file"/>

        <div class="col-12">
          <div class="row mb-2" *ngIf="sendFiles.length">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex" *ngFor="let file of sendFiles">
              <div class="card-file flex-fill">
                <img src="../assets/img/file_icon.png" [title]="file.name" alt=""/>
                <span>{{ file.name }}</span>
                <mat-icon aria-hidden="false" aria-label="" (click)="removeFileFromArray(file)">close</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-end mb-4">
          <button mat-raised-button id="button-file" class="float-right btn btn-primary" (click)="joinFile()" [disabled]="loader || messageForm.invalid">Joindre un fichier</button>
          <button mat-raised-button type="submit" class="float-right btn btn-primary ml-2" (click)="sendMessage()" *ngIf="!loader" [disabled]="messageForm.invalid">Envoyer</button>
          <button mat-raised-button type="submit" class="float-right btn btn-primary ml-2" style="min-width:92px;" *ngIf="loader" [disabled]="loader || messageForm.invalid"><i class="fa fa-spin fa-spinner"></i></button>
        </div>
      </div>

    </form>

  </div>
</div>
<app-confirm-modal [type]="'verification'" #pendingModal>{{ bindModal(pendingModal) }}</app-confirm-modal>
