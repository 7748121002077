<div class="card card-scroll">
  <div class="card-header d-flex">
    <mat-icon aria-hidden="false" aria-label="">assignment</mat-icon>
    <h5 class="card-title label-survey">
      &nbsp;Questionnaire de formation : <span class="survey-name">{{ getSurvey().name }}</span>
    </h5>
    <ng-container [ngTemplateOutlet]="accompanyingText"></ng-container>
  </div>
  <div class="card-body">
    <ng-container *ngIf="getQuestions().length === 0">
      <app-no-result title="Aucune question" sub_title="Ce questionnaire est bien vide">
      </app-no-result>
    </ng-container>

    <ng-container *ngIf="getQuestions().length > 0">
      <ng-container *ngIf="thanks">
        <div id="thanks">
          <h3 class="thanks-text">
            Merci d'avoir pris le temps de répondre au questionnaire, vous allez être redirigé automatiquement.
          </h3>
          <p>
            Vous n'êtes pas redirigé ?
            <a class="d-inline-block link-color" [routerLink]="['/trainings']">
              Cliquez-ici
            </a>
          </p>
        </div>
      </ng-container>

       <ng-container *ngIf="!thanks">
         <form [formGroup]="surveyForm" class="col-12">
           <div formArrayName="questions">
             <div class="mb-2" *ngFor="let control of questions.controls; let i = index">
               <ng-container *ngIf="getQuestions()[i]">
                 <ng-container [ngSwitch]="getQuestions()[i]['type']">
                   <ng-container *ngSwitchCase="'text'"
                                 [ngTemplateOutlet]="inputText"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'email'"
                                 [ngTemplateOutlet]="inputEmail"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'select'"
                                 [ngTemplateOutlet]="inputSelect"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'checkbox'"
                                 [ngTemplateOutlet]="inputCheckbox"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'radio'"
                                 [ngTemplateOutlet]="inputRadio"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'number'"
                                 [ngTemplateOutlet]="inputNumber"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'date'"
                                 [ngTemplateOutlet]="inputDate"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'phone'"
                                 [ngTemplateOutlet]="inputPhone"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                   <ng-container *ngSwitchCase="'textarea'"
                                 [ngTemplateOutlet]="inputTextArea"
                                 [ngTemplateOutletContext]="{ control: control, form: surveyForm, index: i }"
                   >
                   </ng-container>
                 </ng-container>
               </ng-container>
             </div>
           </div>
           <button class="btn btn-primary mt-2 mb-3" (click)="validSurvey()" [disabled]="!surveyForm.valid">
             Valider
           </button>
         </form>
       </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #inputText let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label class="label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <input matInput type="text" [formControl]="control">
    </mat-form-field>
  </div>
</ng-template>

<ng-template #inputEmail let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label class="label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <input matInput type="email" [formControl]="control">
    </mat-form-field>
  </div>
</ng-template>

<ng-template #inputSelect let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <span class="form-label label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</span>
    <select class="form-control" [name]="'select_' + index" [id]="'radio_' + index" [formControl]="control">
      <ng-container *ngFor="let question of matchReactiveFormQuestionAPIQuestion(index)['question_choices']; let i = index">
        <option [value]="question.question_option_value">
          {{ question.question_option_name }}
        </option>
      </ng-container>
    </select>
  </div>
</ng-template>

<ng-template #inputCheckbox let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <ng-container *ngIf="matchReactiveFormQuestionAPIQuestion(index)['slug'] === 'gdpr'">
      <mat-checkbox [formControl]="control">
        <span class="form-label label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</span>
      </mat-checkbox>

      <p style="font-size: .8rem; color: #b7b7b7; cursor: default;">
        En cochant cette case, vous acceptez, que Batisafe, stocke et traite vos informations dans le but d'améliorer ses services.
        <br>
        Ce contenu est créé par BatiSafe. Les données que vous soumettez sont envoyées à Batisafe.
        <br>
        Rappel : Ne partagez pas un mot de passe.
      </p>
    </ng-container>

    <ng-container *ngIf="matchReactiveFormQuestionAPIQuestion(index)['slug'] !== 'gdpr'">
      <mat-label class="label-question d-block">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <ng-container *ngFor="let question of matchReactiveFormQuestionAPIQuestion(index)['question_choices']; let i = index">
        <mat-checkbox class="d-block label-question" [formControl]="questions.controls[index]['controls'][i]">
          <span class="label-question-dark no-bold">{{ matchReactiveFormQuestionAPIQuestion(index, true, i)['question_option_name'] }}</span>
        </mat-checkbox>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #inputRadio let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <span class="form-label d-block label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</span>
    <mat-radio-group [formControl]="control">
      <ng-container *ngFor="let question of matchReactiveFormQuestionAPIQuestion(index)['question_choices']; let i = index">
        <mat-radio-button style="margin-right: .8rem; color: #495057;" [value]="question.question_option_value">
          {{ question.question_option_name }}
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
</ng-template>

<ng-template #inputNumber let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label class="label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <input matInput type="number" [formControl]="control">
    </mat-form-field>
  </div>
</ng-template>

<ng-template #inputDate let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label class="label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <input matInput type="date" [formControl]="control">
    </mat-form-field>
  </div>
</ng-template>

<ng-template #inputPhone let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label class="label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <input matInput type="tel" pattern="[0-9]{10}" placeholder="0123456789" maxlength="10" [formControl]="control">
    </mat-form-field>
  </div>
</ng-template>

<ng-template #inputTextArea let-control="control" let-form="form" let-index="index">
  <div [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label class="label-question">{{ matchReactiveFormQuestionAPIQuestion(index)['question'] }}</mat-label>
      <textarea matInput [formControl]="control"></textarea>
    </mat-form-field>
  </div>
</ng-template>

<!-- WIP on this template -->
<ng-template #accompanyingText>
  <!-- Pre-survey training -->
  <ng-container *ngIf="null">
    <h6>
      Afin de tirer le meilleur parti de votre formation, nous vous invitons à compléter ce questionnaire.<br/>
      Ces renseignements seront transmis à l’animateur qui s’efforcera de répondre au mieux à vos attentes.
    </h6>
  </ng-container>

  <!-- Satisfaction -->
  <ng-container *ngIf="null">
    <h6>
      Vous avez participé à une de nos formations.<br/>
      Afin d'améliorer nos prochaines formations, nous vous remercions de bien vouloir nous donner votre avis.
    </h6>
  </ng-container>

  <!-- Client satisfaction -->
  <ng-container *ngIf="null">
    <h6>
      Afin de pouvoir améliorer nos prochaines formations, nous vous remercions de bien vouloir remplir ce questionnaire.
    </h6>
  </ng-container>
</ng-template>
