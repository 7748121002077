import { Component, Output, ElementRef, EventEmitter, AfterViewInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-plan-modal',
  templateUrl: './plan-modal.component.html',
  styleUrls: ['./plan-modal.component.scss']
})
export class PlanModalComponent implements AfterViewInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  modalElConfirm = null;
  id: number;

  view = {
    delete_data: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> une donnée. Voulez-vous continuer ?'
    },
    delete_file: {
      title: 'Suppression',
      content: 'Vous êtes sur le point de <span class="strong">supprimer définitivement</span> un fichier. Voulez-vous continuer ?'
    },
    verification: {
      title: 'Attention',
      content: 'Les modifications que vous avez apportées <span class="strong">ne seront pas enregistrées</span> ' +
        'si vous quittez la page sans sauvegarder. Voulez-vous continuer ?'
    }
  };
  file: any;
  filename: any;
  email: any;

  constructor(
    private rootNode: ElementRef
  ) {}

  open(id) {
    this._clearFile();
    this.id = id;
    this.modalElConfirm.modal('show');
  }

  cancel() {
    this.eventOnClose.next(null);
    this.modalElConfirm.modal('hide');
  }

  validate() {
    this.eventOnClose.next({
      id : this.id,
      file : this.file,
      filename : this.filename
    });
    this.modalElConfirm.modal('hide');
  }

  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.file = reader.result;
      this.filename = file.name;
    };
  }

  ngAfterViewInit() {
    this.modalElConfirm = $(this.rootNode.nativeElement).find('div.modal-confirm');

    const self = this;
    $('#confirm-modal').on('hidden.bs.modal', () => {
      self.eventOnClose.next(null);
    });
  }

  private _clearFile() {
    this.file = undefined;
    this.email = undefined;
    this.filename = undefined;
  }
}
