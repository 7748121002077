import { Component, EventEmitter, Input, Output } from '@angular/core';
import Field from '../form/field';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  public defaultColors: string[] = [
    '#f0485b #e3001a',
    '#228ce3 #1b6eb1',
    '#3d4ac4 #2e3891',
    '#bf305d #a11843',
    '#009775 #047b60',
    '#29c9cd #25abae',
  ];
  public show = false;

  @Input() field: Field;
  @Input() color = '#f0485b #e3001a';
  @Output() colorChange = new EventEmitter();


  constructor() {}

  public toggleColors() {
    this.show = !this.show;
  }

  public changeColor(color: string) {
    this.color = color;
    this.colorChange.emit(this.color);
    this.show = false;
  }

  getBackground(defaultColor: string) {
    const colors = defaultColor.split(' ');
    if (colors.length === 1) {
      return defaultColor;
    } else {
      return 'linear-gradient(135deg,' +
        colors[0] + ' 0%,' +
        colors[0] + ' 50%,' +
        colors[1] + ' 50%,' +
        colors[1] + ' 100%' +
        ')';
    }
  }
}
