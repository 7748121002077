import { Component, Input, OnInit } from '@angular/core';
import { NoResultService } from './no-result.service';
import '../../../assets/img/zero-state.svg';

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss']
})

export class NoResultComponent implements OnInit {
  @Input() title: any;
  @Input() subTitle: any;
  @Input() width: any;
  @Input() picture: any;

  constructor(
    private noResultService: NoResultService
  ) {
    if (!this.width) {
      this.width = 200;
    }

    if (!this.picture) {
      this.picture = '../../../assets/img/zero-state.svg';
    }

    if (!this.title) {
      this.title = 'Aucune information à afficher';
    }
  }

  ngOnInit() {
  }

  get subTitleService(): string {
    return this.noResultService.subTitle;
  }
}
