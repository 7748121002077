<div class="card card-scroll">
  <div class="card-body">
    <div class="title-header mb-0">
      PAGE NON TROUVÉE
    </div>
    <div class="title-small">
      La page demandée n'a pas pu être trouvée.
    </div>
    <div class="title-small">
      Veuillez patienter, vous allez être redirigé sur votre tableau de bord
    </div>
  </div>
</div>
