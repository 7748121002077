import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../../core/auth/auth.service';
import { AuthRequestService } from '../../../core/services/auth/auth.request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';
import { Component, HostBinding, OnInit } from '@angular/core';
import AuthResponse from '../../../core/interfaces/response/auth.response';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
  animations: [
    trigger('routeTransition', [
      state('void', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      state('*', style({ width: '100%', height: '100%', display: 'block', position: 'relative' }) ),
      transition(':enter', [
        style({
          opacity: '0',
          overflow: 'hidden'
        }),
        animate('.5s linear', style({
          opacity: '1',
          overflow: 'hidden'
        }))
      ]),
      transition(':leave', [
        style({
        })
      ])
    ])
  ]
})

export class ActivateComponent implements OnInit {
   @HostBinding('@routeTransition') get routeTransition() {
    return;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private authRequestService: AuthRequestService,
    private routeActivate: ActivatedRoute,
    private notifier: CustomNotifierService,
    private route: Router
  ) {}

  ngOnInit() {
    this.authRequestService.activateAccount(this.routeActivate.snapshot.params.token).subscribe((response: AuthResponse) => {
      if (response.success) {
        this.notifier.successCustom('Votre compte est activé, vous pouvez maintenant vous connecter sur le portail.');
        this.route.navigateByUrl('/auth/login').then(r => {});
      } else {
        if (response.message !== undefined) {
          this.notifier.errorCustom(response.message);
        } else {
          this.notifier.errorRequest();
        }
      }
    });
  }
}
