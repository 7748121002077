import { Subject } from 'rxjs';

export class PageList {
  requestInProgress = new Subject();
  requestCount = 0;

  total = 0;
  pages = 1;

  public search = '';
  public sort = '';
  public order = 'asc';
  public limit = 12;
  public page = 1;
  public http;

  constructor() {}

  getData() {}
  deleteData(id: number) {}

  updateList(data) {
    if (data.action === 'pageSize') {
      this.changePageSize(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    } else if (data.action === 'sort') {
      this.sort = data.value;
      this.order = data.order;
      this.getData();
    } else if (data.action === 'search') {
      this.search = data.value;
      this.page = 1;
      this.getData();
    } else if (data.action === 'delete') {
      this.deleteData(data.value);
    }
  }

  changePageSize(limit) {
    this.limit = limit;
    this.page = 1;
    this.getData();
  }

  changePage(page) {
    if (page >= 1 && page <= this.pages) {
      this.page = page;
      this.getData();
    }
  }
}
