import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import QuotationDashboard from '../../interfaces/dashboard/quotation-dashboard';
import InvoiceDashboard from '../../interfaces/dashboard/invoice-dashboard';
import TrainingDashboard from '../../interfaces/dashboard/training-dashboard';
import FileDashboard from '../../interfaces/dashboard/file-dashboard';
import WebinarDashboard, { WebinarBatiregistreBatisafe } from '../../interfaces/dashboard/webinar-dashboard';
import TicketDashboard from '../../interfaces/dashboard/ticket-dashboard';
import VeilleDashboard from '../../interfaces/dashboard/veille-dashboard';
import CustomCard from '../../interfaces/dashboard/custom-card';
import PortalConfig from "../../interfaces/portal-config";

@Injectable()
export class DashboardService {
  constructor(
    private _router: Router
  ) {}

  private _quotations: QuotationDashboard[];
  private _invoices: InvoiceDashboard[];
  private _trainings: TrainingDashboard[];
  private _files: FileDashboard[];
  private _webinars: WebinarBatiregistreBatisafe;
  private _tickets: TicketDashboard[];
  private _veilles: VeilleDashboard[];
  private _customCards: CustomCard[];
  private _articles: any[];
  private _portalConfig: PortalConfig;

  get quotations(): QuotationDashboard[] {
    return this._quotations;
  }

  set quotations(quotations: QuotationDashboard[]) {
    this._quotations = quotations;
  }

  get invoices(): InvoiceDashboard[] {
    return this._invoices;
  }

  set invoices(invoices: InvoiceDashboard[]) {
    this._invoices = invoices;
  }

  get trainings(): TrainingDashboard[] {
    return this._trainings;
  }

  set trainings(trainings: TrainingDashboard[]) {
    this._trainings = trainings;
  }

  get files(): FileDashboard[] {
    return this._files;
  }

  set files(files: FileDashboard[]) {
    this._files = files;
  }

  get webinars(): WebinarBatiregistreBatisafe {
    return this._webinars;
  }

  set webinars(webinars: WebinarBatiregistreBatisafe) {
    this._webinars = webinars;
  }

  get tickets(): TicketDashboard[] {
    return this._tickets;
  }

  set tickets(tickets: TicketDashboard[]) {
    this._tickets = tickets;
  }

  get veilles(): VeilleDashboard[] {
    return this._veilles;
  }

  set veilles(veilles: VeilleDashboard[]) {
    this._veilles = veilles;
  }

  get customCards(): CustomCard[] {
    return this._customCards;
  }

  set customCards(customCards: CustomCard[]) {
    this._customCards = customCards;
  }

  get articles(): any[] {
    return this._articles;
  }

  set articles(veilles: any[]) {
    this._articles = veilles;
  }

  get portalConfig(): PortalConfig {
    return this._portalConfig;
  }

  set portalConfig(portalConfig: PortalConfig) {
    this._portalConfig = portalConfig;
  }
}
