import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllQuotationsModule } from './all-quotations/all-quotations.module';

import { QuotationsResolver } from '../../core/services/quotations/quotations.resolver';

@NgModule({
  imports: [
    CommonModule,
    AllQuotationsModule
  ],
  providers: [
    QuotationsResolver,
  ]
})
export class QuotationsModule {}
