<div class="row ml-0 mr-0 mt-3">
  <div class="col-12 d-flex">
    <div class="flex-grow-1 pl-2">
      <div class="title-header mb-0"> {{ training.level }}, {{ training.name }} </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > <a href="#" routerLink="/trainings" class="breadcrum-link">Liste des formations</a> > {{ training.level }}, {{ training.name }} </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mr-2">
    </div>
  </div>
</div>

<div class="row ml-4 mr-4" [className]="loadClickCard ? 'loading' : ''">
  <div class="col-9 mb-3 pl-0 pr-0" id="training-container">
    <div class="card card-scroll">
      <div class="card-body p-0">
       <div *ngIf="training.description" class="pl-3 pr-3 pt-3" style="font-size:0.9em;" id="training-description-container">
          <h2 class="blue-lighter">Description de la formation</h2>
          <div class="pb-3" [innerHTML]="training.description"></div>
        </div>

        <div *ngIf="training.goals" class="pl-3 pr-3" style="font-size:0.9em;" id="training-goals-container">
          <h2 class="blue-lighter">Objectifs de la formation</h2>
          <div class="pb-3" [innerHTML]="training.goals"></div>
        </div>

        <div *ngIf="training.publics" class="pl-3 pr-3" style="font-size:0.9em;" id="training-publics-container">
          <h2 class="blue-lighter">Public ciblé</h2>
          <div class="pb-3" [innerHTML]="training.publics"></div>
        </div>

        <div *ngIf="training.requirements" class="pl-3 pr-3" style="font-size:0.9em;" id="training-requirements-container">
          <h2 class="blue-lighter">Conditions</h2>
          <div class="pb-3" [innerHTML]="training.requirements"></div>
        </div>

        <div *ngIf="training.program" class="pl-3 pr-3" style="font-size:0.9em;" id="training-program-container">
          <h2 class="blue-lighter">Programme</h2>
          <div class="pb-3" [innerHTML]="training.program"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 mb-3 pr-0 pl-0" id="training-date-container">

    <div id="training-date-warning" style="font-size:1em!important;color:#fff!important;" *ngIf="isParticipant && !completed_pre_training_survey">
      <mat-icon style="vertical-align: middle;">warning</mat-icon>
      Vous devez remplir le questionnaire
      de préformation avant d'accéder aux documents
    </div>

    <div id="training-date-general-info" class="p-3">
      <h5>Dates de formation</h5>
      <p style="margin-bottom: 0; margin-top: -3px; font-size: .9rem;">
        du <b>{{ trainingDate.start_at_format }}</b> au <b>{{ trainingDate.ended_at_format }}</b>
      </p>

      <div style="cursor: default; margin-bottom: 1rem;"
           [title]="training_date_is_passed ? 'La formation est terminée' :
                    (training_date_is_not_set ? 'La formation n\'a pas encore de dates' :
                    (training_date_is_running ? 'La formation est actuellement en cours' :
                    (!training_date_is_passed && !training_date_is_running ? 'La formation est à venir' : '' )))">
        <div id="breathing-dot"
             [ngClass]="training_date_is_passed ? 'breathing-dot-passed' :
                      (training_date_is_not_set ? 'breathing-dot-not-set' :
                      (training_date_is_running ? 'breathing-dot-running' :
                      (!training_date_is_passed && !training_date_is_running ? 'breathing-dot-to-come' : '' )))"
        >
        </div>
        <span [ngClass]="training_date_is_passed ? 'breathing-dot-text-passed' :
                      (training_date_is_not_set ? 'breathing-dot-text-not-set' :
                      (training_date_is_running ? 'breathing-dot-text-running' :
                      (!training_date_is_passed && !training_date_is_running ? 'breathing-dot-text-to-come' : '' )))"
        >
        </span>
      </div>

      <div style="display: flex;">
        <div style="margin-right: 7px; margin-top: 11px; color: #f9fbfd;">
          <mat-icon>home</mat-icon>
        </div>
        <p [innerHTML]="trainingDate.address_clean"></p>
      </div>
    </div>

    <div id="training-date-specific-info" class="pl-3 pr-3 pb-4">
      <ng-container *ngIf="!isParticipant">

        <button class="btn btn-see-participants" style="font-size:0.9em;" (click)="openModal('participants')">
          Voir les participants
        </button>
        <br/>
        <button
          class="btn btn-outline-primary"
          style="margin-top: .8rem;"
          *ngIf="!signed_agreement && !signed_agreement_file_exists && !training_date_is_not_set"
          (click)="openModal('send_agreement')"
        >
          Envoyer la convention signée
        </button>
        <button
          class="btn btn-outline-primary"
          style="margin-top: .8rem;"
          *ngIf="!signed_agreement && signed_agreement_file_exists && !training_date_is_not_set"
          (click)="openModal('send_agreement')"
        >
          Modifier la convention envoyée
        </button>

        <ng-container
          *ngIf="!clientContactAlreadyRespondedSurvey && training_date_is_passed && signed_agreement && !training_date_is_not_set"
          [ngTemplateOutlet]="surveysTemplate">
        </ng-container>
        <ng-container *ngIf="!training_date_is_not_set" [ngTemplateOutlet]="filesTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="isParticipant">
        <ng-container
          *ngIf="(!training_date_is_not_set && (!completed_pre_training_survey  || ((!completed_satisfaction_survey && training_date_is_passed) || (!completed_post_training_survey && !eligible_to_post_training_survey && training_date_is_passed))))"
          [ngTemplateOutlet]="surveysTemplate">
        </ng-container>
        <ng-container *ngIf="!training_date_is_not_set" [ngTemplateOutlet]="filesTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
  <app-modal-training #trainingModal>{{ binModal('participants', trainingModal) }}</app-modal-training>
  <app-modal-training-agreement
    #trainingAgreementModal>{{ binModal('send_agreement', trainingAgreementModal) }}</app-modal-training-agreement>
</div>

<ng-template #surveysTemplate>
  <ng-container *ngIf="!isParticipant">
    <hr>
    <h5>Questionnaires</h5>
    <!-- No condition needed, is done on calling template -->
    <span
      class="link-cursor button-arrow d-flex align-items-center justify-content-start"
      (click)="openSurvey('client-contact')"
      style="display: inline-flex;">
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
          <g class="arrow-head">
            <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
          </g>
          <g class="arrow-body">
            <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
          </g>
        </svg>
        Questionnaire de satisfaction sur l'organisation
      </span>
  </ng-container>

  <ng-container *ngIf="isParticipant &&
                      (!completed_pre_training_survey ||
                      (training_date_is_passed && !completed_satisfaction_survey && (!completed_post_training_survey && eligible_to_post_training_survey)))
  ">
    <hr>
    <h5>Questionnaires</h5>

    <!-- In all cases -->
    <span *ngIf="!completed_pre_training_survey"
          title="Pour accéder aux documents, il est nécessaire de remplir ce questionnaire"
          class="link-cursor button-arrow d-flex align-items-center justify-content-start"
          style="display: inline-flex"
          (click)="openSurvey('pre-survey')">
          <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
            <g class="arrow-head">
              <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
            </g>
            <g class="arrow-body">
              <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
            </g>
          </svg>
          Questionnaire de pré-formation
    </span>

    <!-- Training is finish -->
    <ng-container *ngIf="training_date_is_passed">
      <span *ngIf="!completed_satisfaction_survey"
            class="link-cursor button-arrow d-flex align-items-center justify-content-start"
            style="display: inline-flex"
            (click)="openSurvey('satisfaction')"
      >
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
          <g class="arrow-head">
            <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
          </g>
          <g class="arrow-body">
            <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
          </g>
        </svg>
        Questionnaire de satisfaction
      </span>
      <br>
      <span *ngIf="!completed_post_training_survey && eligible_to_post_training_survey"
            class="link-cursor button-arrow d-flex align-items-center justify-content-start"
            style="display: inline-flex"
            (click)="openSurvey('post-survey')">
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
          <g class="arrow-head">
            <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
          </g>
          <g class="arrow-body">
            <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
          </g>
        </svg>
        Questionnaire de post-formation
      </span>
    </ng-container>
    <!-- Training not start -->
    <ng-container *ngIf="!training_date_is_passed && !training_date_is_running">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #filesTemplate>
  <hr>
  <h5>Documents</h5>
  <ng-container *ngIf="!isParticipant">
    <span class="link-cursor button-arrow d-flex align-items-center justify-content-start"
          (click)="downloadFile(1, 'agreement')" style="display: inline-flex">
      <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
        <g class="arrow-head">
          <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
        </g>
        <g class="arrow-body">
          <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
        </g>
      </svg>
      Convention de formation
    </span>
    <ng-container *ngIf="signed_agreement">
      <ng-container *ngIf="totalParticipants > 0">
        <br>
        <span
          title="Télécharge toutes les convocations des participants"
          class="link-cursor button-arrow d-flex align-items-center justify-content-start"
          (click)="downloadFile(1, 'convocation')"
          style="display: inline-flex"
        >
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
          <g class="arrow-head">
            <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
          </g>
          <g class="arrow-body">
            <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
          </g>
        </svg>
          Convocations des participants
        </span>
        <ng-container
          *ngIf="training_date_is_passed && eligible_to_post_training_survey && totalParticipantsResponses > 0">
          <br>
          <span title="Réponses données au questionnaire post-formation de la part des participants"
                class="link-cursor button-arrow d-flex align-items-center justify-content-start"
                (click)="downloadFile(1, 'participants-responses-survey')"
                style="display: inline-flex"
          >
            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
              <g class="arrow-head">
                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
              </g>
              <g class="arrow-body">
                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
              </g>
            </svg>
            Retours sur la formation
          </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="training_date_is_passed && totalCertificates > 0">
        <br>
        <span
          title="Télécharge toutes les attestations de formation des participants"
          class="link-cursor button-arrow d-flex align-items-center justify-content-start"
          (click)="downloadFile(1, 'certificate')"
          style="display: inline-flex"
        >
          <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
            <g class="arrow-head">
              <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
            </g>
            <g class="arrow-body">
              <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
            </g>
          </svg>
          Attestations de formation des participants
        </span>
        <ng-container *ngIf="totalSignInSheets > 0">
          <br>
          <span
            title="Télécharge toutes les feuilles d'émargements des participants"
            class="link-cursor button-arrow d-flex align-items-center justify-content-start"
            (click)="downloadFile(1, 'sign-in-sheets')"
            style="display: inline-flex"
          >
            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
              <g class="arrow-head">
                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
              </g>
              <g class="arrow-body">
                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
              </g>
            </svg>
            Feuilles d'émargements des participants
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isParticipant">
    <!-- In all cases -->
    <span
          class="link-cursor button-arrow d-flex align-items-center justify-content-start"
          [ngClass]="!completed_pre_training_survey ? 'blocked blocked-cursor' : 'link-cursor'"
          [title]="!completed_pre_training_survey ? 'Vous devez remplir le questionnaire de pré-formation avant de pouvoir télécharger' : ''"
          (click)="completed_pre_training_survey && downloadFile(1, 'convocation')"
          style="display: inline-flex;">
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
          <g class="arrow-head">
            <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
          </g>
          <g class="arrow-body">
            <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
          </g>
        </svg>
        Convocation
      </span>
    <span
          class="link-cursor button-arrow d-flex align-items-center justify-content-start"
          (click)="completed_pre_training_survey && downloadFile(1, 'material')"
          [ngClass]="!completed_pre_training_survey ? 'blocked blocked-cursor' : 'link-cursor'"
          [title]="!completed_pre_training_survey ? 'Vous devez remplir le questionnaire de pré-formation avant de pouvoir télécharger' : ''"
          style="display: inline-flex;">
        <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
          <g class="arrow-head">
            <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
          </g>
          <g class="arrow-body">
            <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
          </g>
        </svg>
         Supports de formation
      </span>




    <!-- Training is finish -->
    <ng-container *ngIf="training_date_is_passed">
      <br>
      <span class="link-cursor button-arrow d-flex align-items-center justify-content-start"
            (click)="downloadFile(0, 'certificate')" style="display: inline-flex">
            <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
              <g class="arrow-head">
                <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" stroke-width="2"/>
              </g>
              <g class="arrow-body">
                <path d="M3.5 4.5H0" stroke="currentColor" stroke-width="2"/>
              </g>
            </svg>
        Attestation de formation
      </span>
    </ng-container>

    <!-- Training is running -->
    <ng-container *ngIf="training_date_is_running">
    </ng-container>

    <!-- Training not start -->
    <ng-container *ngIf="!training_date_is_passed && !training_date_is_running">
    </ng-container>
  </ng-container>
</ng-template>
