import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PageList } from '../../../core/list/page-list';
import Column from '../../../core/list/column';

import { AuthService } from '../../../core/auth/auth.service';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';
import { TrainingsRequestService } from '../../../core/services/trainings/trainings.request.service';
import { QuotationsRequestService } from '../../../core/services/quotations/quotations.request.service';

import TrainingDate from '../../../core/interfaces/training/training_date';
import { TrainingDateAllResolverResponse } from '../../../core/interfaces/training/response/training_date';
import ItemColumn from '../../../core/interfaces/item_column';
import { ModalTrainingComponent } from '../../../core/modals/training/training.component';
import DataListTraining from '../../../core/interfaces/training/list/data-list-training';
import { TrainingsService } from '../../../core/services/trainings/trainings.service';
import { NoResultService } from '../../../core/no-result/no-result.service';

@Component({
  selector: 'app-all-trainings',
  templateUrl: './all-trainings.component.html',
  styleUrls: ['./all-trainings.component.scss'],
})
export class AllTrainingsComponent extends PageList implements AfterViewInit, OnInit {
  private _trainingModal: ModalTrainingComponent;

  public columns: Column[] = [];
  public pages = 1;
  public page = 1;
  public total = 0;
  public loaderTrainings = false;

  /**
   * Define if the current connected user is a participant to a training or not
   */
  public isParticipant: boolean;

  /**
   * @param _authService
   * @param _activatedRoute
   * @param _quotationService
   * @param _trainingRequestService
   * @param _trainingService
   * @param _notifier
   * @param _router
   * @param _noResultService
   */
  constructor(
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _quotationService: QuotationsRequestService,
    private _trainingRequestService: TrainingsRequestService,
    private _trainingService: TrainingsService,
    private _notifier: CustomNotifierService,
    private _router: Router,
    private _noResultService: NoResultService
  ) {
    super();
  }

  get trainingDates(): TrainingDate[] {
    return this._trainingService.trainingDates;
  }

  set trainingDates(trainingDates: TrainingDate[]) {
    this._trainingService.trainingDates = trainingDates;
  }

  ngOnInit() {
    this.isParticipant = this._authService.user.training_participant_access;
    this._activatedRoute.data.subscribe((response: TrainingDateAllResolverResponse) => {
      this.trainingDates = response.data.trainings_dates;
      this.total = response.data.total;
      this.pages = response.data.pages;

      response.data.forms.forEach((item: ItemColumn) => {
        this.columns.push({
          label: item.label,
          showLabel: true,
          sort: item.name,
          property: item.name,
          checked: true,
          type: item.type
        });
      });
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      // this.loader = this.requestCount > 0;
    });
  }

  ngAfterViewInit() {
    this._trainingModal.eventOnClose.subscribe((event: any) => {
      this._trainingRequestService.getTrainingsDates()
        .subscribe((response: any) => {
          if (response.success) {
            this.trainingDates = response.trainings_dates;
          }
        });
    });
  }

  updateList(data: DataListTraining) {
    this.loaderTrainings = true;
    this._trainingService.training = data.value.training_entity;
    this._trainingService.trainingDate = data.value.training_date_entity;

    if (data.action === 'search' || data.action === 'sort') {
      if (data.action === 'sort') {
        this.sort = data.value;
      }
      this.search = data.value;
      this.page = 1;
      this.getData();
      this.loaderTrainings = false;
    } else {
      if (this.isParticipant) {
        this._router.navigateByUrl('training/' + data.value.id).then(() => {});
      } else {
        this._trainingService.quotation = data.value.quotation;
        this._router.navigateByUrl('training/' + data.value.training_date_entity.id).then(() => {});
      }
    }

    // If the current connected user is a participant, navigate to a new route
    /*if (this.isParticipant) {
      this._router.navigateByUrl('training/' + data.value.id).then(() => {});
      this.loaderTrainings = false;
    } else {
      if (data.action === 'search') {
        this.search = data.value;
        this.page = 1;
        this.getData();
        this.loaderTrainings = false;
      } else {
        this._trainingService.setQuotation(data.value.quotation);
        this._router.navigateByUrl('training/' + data.value.training_date_entity.id).then(() => {});
        this.loaderTrainings = false;
      }

      // if (data.action === 'download-agreement') {
      //   this._trainingRequestService.getAgreement(data.value.quotation_id)
      //     .subscribe((response: any) => {
      //       if (response.success) {
      //         this.createElement(response.file_name, response.file);
      //       } else {
      //         this._notifier.errorCustom('Le document n\'a pas pu être téléchargé');
      //       }
      //     });
      // } else {
      //   // If the current connected user is not a participant, open the modal
      //   const training_date = data.value.training_date_entity === undefined ? null : data.value.training_date_entity;
      //   this._trainingModal.open(data.value.training_entity, data.value.quotation, training_date);
      // }
    }*/
  }

  /**
   * Create link element in document
   *
   * @param fileName
   * @param base64
   */
  createElement(fileName: string, base64: string) {
    const link = document.createElement('a');
    link.href = base64;
    link.download = fileName;
    document.body.append(link);
    link.setAttribute('style', 'display: none;');
    link.click();
    link.remove();
  }

  binModal(trainingModal: ModalTrainingComponent) {
    this._trainingModal = trainingModal;
  }

  getData() {
    this.requestInProgress.next(true);
    let request;

    if (this.isParticipant) {
      request = this._trainingRequestService.getTrainingsDatesParticipant(this.search, this.sort, this.order, this.limit, this.page)
    } else {
      request = this._trainingRequestService.getTrainingsDates(this.search, this.sort, this.order, this.limit, this.page);
    }

    request.subscribe((response: any) => {
        if (response.success) {
          this.trainingDates = response.trainings_dates;
          this.columns = [];

          response.forms.forEach(item => {
            this.columns.push({
              label: item.label,
              showLabel: true,
              sort: item.name,
              property: item.name,
              checked: true,
              type: item.type
            });
          });
          this.total = response.total;
          if (response.total === 0) {
            this._noResultService.subTitle = 'Aucune formation n\'a été trouvée';
          }
          this.pages = response.pages;
        }
        this.requestInProgress.next(false);
      });
  }
}
