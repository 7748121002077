import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomNotifierService } from '../../services/custom.notifier.service';
import { TrainingsRequestService } from '../../services/trainings/trainings.request.service';
import { TrainingAgreementService } from '../../services/trainings/training_agreement.service';
import { TrainingsService } from '../../services/trainings/trainings.service';

declare var $: any;

@Component({
  selector: 'app-modal-training-agreement',
  templateUrl: './training-agreement.component.html',
  styleUrls: ['./training-agreement.component.scss']
})
export class ModalTrainingAgreementComponent implements AfterViewInit {
  @Input() type;
  @Output() eventOnClose: EventEmitter<any> = new EventEmitter();

  public closeValue = null;

  public modalElConfirm = null;

  public fileAlreadyExists: boolean;
  public loader: boolean;
  public loaderButton: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private rootNode: ElementRef,
    private notifier: CustomNotifierService,
    private trainingsRequestService: TrainingsRequestService,
    private trainingsService: TrainingsService,
    public  trainingAgreementService: TrainingAgreementService
  ) {
    this.loader = false;
    this.loaderButton = false;
    trainingAgreementService.trainingAgreements = [];
  }

  open(fileAlreadyExists: boolean = false) {
    this.trainingAgreementService.trainingAgreements = [];
    this.fileAlreadyExists = fileAlreadyExists;

    if (fileAlreadyExists) {
      this.loader = true;
      this.trainingsRequestService.getPreviousAgreementSigned(this.trainingsService.quotation.id)
        .subscribe((response: any) => {
          if (response.success) {
            this.trainingAgreementService.trainingAgreements.push(
              {
                id: null,
                random_id: Date.now() + Math.floor(Math.random() * 1000),
                base64: response.file_signed,
                label: response.file_name,
              }
            );
          } else {
            this.notifier.errorRequest();
          }
          this.loader = false;
        });
    }
    this.modalElConfirm.modal('show');
  }

  cancel() {
    this.trainingAgreementService.trainingAgreements = [];
    this.eventOnClose.next(null);
    this.modalElConfirm.modal('hide');
  }

  validate() {
    this.loaderButton = true;
    this.trainingsRequestService.sendAgreementSigned(
      this.trainingsService.quotation.id,
      this.trainingAgreementService.trainingAgreements[0]
    )
      .subscribe((response: any) => {
        if (response.success) {
          this.notifier.successCreate();
          this.closeValue = 'success';
        } else {
          this.notifier.errorRequest();
        }
        this.trainingAgreementService.trainingAgreements = [];
        // this.eventOnClose.next(null);
        this.modalElConfirm.modal('hide');
        this.loaderButton = false;
      });
  }

  ngAfterViewInit(): void {
    this.modalElConfirm = $(this.rootNode.nativeElement).find('div.modal-agreement-training');

    const self = this;
    $('#modal-agreement-training').on('hidden.bs.modal', () => {
      self.eventOnClose.next(this.closeValue);
    });
  }

  /*
  Files part
  */
  /**
   * As the input to open the file explorer is hidden, the click is done in "background" from this piece of JS code
   */
  chooseFile(fieldName: string) {
    const element: HTMLElement = document.getElementById('file_' + fieldName) as HTMLElement;
    element.click();
  }

  /**
   * Add the new file on the array of files
   */
  onFileChange($event, fieldName: string) {
    for (const file of $event.target.files) {
      // Load file reader
      const fileReader: FileReader = new FileReader();
      // Promise the base 64 string for the file
      const filePromiseBase64 = new Promise((resolve) => {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
      });

      filePromiseBase64.then((response: string) => {
        // Insert the new object at the end of array
        this.trainingAgreementService.trainingAgreements.push(
          {
            id: null,
            random_id: Date.now() + Math.floor(Math.random() * 1000),
            base64: response,
            label: file.name,
            type: file.type
          }
        );
      });
    }

    const element: HTMLInputElement = document.getElementById('file_' + fieldName) as HTMLInputElement;
    element.value = '';
  }

  /**
   * Remove the file on the array
   */
  removeAddedFile(file) {
    // Search the array index for the file and remove it
    this.trainingAgreementService.trainingAgreements.splice(this.trainingAgreementService.trainingAgreements.indexOf(file));
  }

  /**
   * Download a file previously uploaded
   */
  downloadFile(file) {
    const link = document.createElement('a');
    link.setAttribute('style', 'display: none');
    link.download = file.label;
    link.href = file.base64;
    document.body.append(link);
    link.click();
    link.remove();
  }
}
