import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from '../../request.service';

@Injectable()
export class PatrimonyRequestService extends RequestService {
  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  // PATRIMONY
  public getPatrimony() {
    return this.get('api/get-patrimony', {});
  }
  public createPatrimony(data) {
    return this.post('api/create-patrimony', data);
  }
  public updatePatrimony(id, data) {
    return this.put('api/update-patrimony/' + id, data);
  }
}
