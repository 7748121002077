import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';

import { AuthService } from './core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Title';

  constructor(
    public authService: AuthService,
    private loadingBar: LoadingBarService,
    private router: Router,
  ) {
    const routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // routerEvent.unsubscribe();
        loadingBar.complete();
      }
      // if (event instanceof NavigationCancel) {
      //   this.routerEvent.unsubscribe();
      // }
      // if (event instanceof NavigationError) {
      //   this.routerEvent.unsubscribe();
      // }
    });
  }

  startLoading() {
    this.loadingBar.start();
  }

  stopLoading() {
    this.loadingBar.complete();
  }
}
