import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ActivateComponent } from './activate/activate.component';
import { PasswordLostComponent } from './password-lost/password-lost.component';

export const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'activate/:token',
    component: ActivateComponent
  },
  {
    path: 'resetPassword/:token',
    component: PasswordLostComponent
  }
];
