import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AuthService } from '../auth/auth.service';

import { environment } from '../../../environments/environment';

declare var $: any;

interface Menu {
  name: string;
  icon: string;
  url?: string;
  accessTrainingParticipant: boolean;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  environment = environment;

  menus: Menu[] = [
    {
      name: 'Tableau de bord',
      icon: 'fa fa-tachometer-alt',
      url: '/',
      accessTrainingParticipant: true
    },
    {
      name: 'Devis',
      icon: 'fa fa-file-invoice',
      url: '/quotations',
      accessTrainingParticipant: false
    },
    {
      name: 'Factures',
      icon: 'fa fa-file-invoice-dollar',
      url: '/invoices',
      accessTrainingParticipant: false
    },
    {
      name: 'Formations',
      icon: 'fa fa-school',
      url: '/trainings',
      accessTrainingParticipant: true
    },
    {
      name: 'Mes fichiers',
      icon: 'fa fa-folder-open',
      url: '/directories',
      accessTrainingParticipant: false
    },
  ];

  batisafe_menus: Menu[] = [
    {
      name: 'Mes tickets',
      icon: 'fa fa-comments',
      url: '/tickets',
      accessTrainingParticipant: false
    },
    {
      name: 'Veille réglementaire',
      icon: 'fa fa-book',
      url: '/theonorme/veilles',
      accessTrainingParticipant: false
    },
    {
      name: 'Alerte réglementaire',
      icon: 'fa fa-book',
      url: '/theonorme/alertes-reglementaires',
      accessTrainingParticipant: false
    },
  ];

  theonorme_menus: Menu[] = [
    {
      name: 'Questions',
      icon: 'fa fa-question-circle',
      url: '/theonorme/questions',
      accessTrainingParticipant: false
    },
  ];

  hideSidebar: boolean;

  currentUserIsTrainingParticipant: boolean;

  constructor(
    public authService: AuthService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.currentUserIsTrainingParticipant = this.authService.user.training_participant_access;
  }

  ngAfterViewInit() {}

  collapseSidebar() {
    const sidebar = document.getElementById('sidebar') as HTMLElement;
    const toolbar = document.getElementById('header-container') as HTMLElement;

    if (this.hideSidebar) {
      sidebar.className = sidebar.className.replace('sidebar-hide', '');
      toolbar.className = toolbar.className.replace('header-margin-left', '');
    } else {
      sidebar.className += ' sidebar-hide';
      toolbar.className += ' header-margin-left';
    }
    this.hideSidebar = !this.hideSidebar;
  }

  hideCollapse() {
    $('.collapse').collapse('hide');
  }

  setActiveNav(e) {
    const navLinks = document.getElementsByClassName('nav-link');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < navLinks.length; i++) {
      navLinks[i].className = navLinks[i].className.replace(' active', '');
    }
    e.className += ' active';
  }
}
