import { Injectable } from '@angular/core';
import Notification from '../../interfaces/notification/notification';

@Injectable()
export class NotificationService {
  count: number;
  pages: number;
  loader: boolean;
  notifications: any = [];
  notViewed: any = [];

  constructor() {
    this.count = 0;
    this.pages = 1;
    this.loader = false;
  }

  markAllNotificationsAsSeen() {
    for (const property in this.notifications) {
      if (this.notifications.hasOwnProperty(property)) {
        for (const key in this.notifications[property]) {
          if (this.notifications[property].hasOwnProperty(key)) {
            this.notifications[property][key].isViewed = true;
          }
        }
      }
    }
  }

  /**
   * Update the notifications array by removing the fresh view notification
   *
   * @param {Notification} notification The notification to remove from the array
   * @param {boolean} everything Remove all entries from the notifications array
   */
  removeNotificationFromNotSeen(notification: Notification | null = null, everything: boolean = false) {
    if (everything) {
      this.notifications = [];
    } else {
      for (const [key, value] of Object.entries(this.notifications)) {
        // @ts-ignore
        value.forEach((element: Notification, i) => {
          if (element.id === notification.id) {
            this.notifications[key].splice(i, 1);
          }
        });
      }
      for (const [key, value] of Object.entries(this.notViewed)) {
        // @ts-ignore
        value.forEach((element: Notification, i) => {
          if (element.id === notification.id) {
            this.notViewed[key].splice(i, 1);
          }
        });
      }
    }
  }
}
