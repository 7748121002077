<div class="row h-100" style="min-height:700px;position:relative;max-height:100%;">
  <div *ngIf="(!isMobile && preview) || !preview" [ngClass]="preview !== false ? 'col-6 h-100 d-flex flex-column' : 'col-12 h-100 d-flex flex-column'" style="max-height:100%;">

    <div class="d-flex mb-2">
      <div class="search-bar col pl-0">
        <div class="input-group mb-2">
          <label for="search-bar"></label>
          <input type="text" class="form-control border-radius" [(ngModel)]="search" (keyup)="searchData()" aria-label="Barre de recherche" placeholder="Rechercher" id="search-bar">
          <div class="input-group-append">
          <span class="input-group-text">
            <mat-icon aria-hidden="false" aria-label="Rechercher">search</mat-icon>
          </span>
          </div>
        </div>
      </div>

      <div class="filter col-auto" *ngIf="!hideAdd">
        <a mat-mini-fab (click)="createEventAction()" *ngIf="createEvent" aria-label="Ajouter un utilisateur">
          <mat-icon aria-hidden="false" aria-label="Ajouter">add</mat-icon>
        </a>
        <a mat-mini-fab routerLink="create" *ngIf="!createEvent" aria-label="Ajouter un utilisateur">
          <mat-icon aria-hidden="false" aria-label="Ajouter">add</mat-icon>
        </a>
      </div>

      <div class="filter dropdown col-auto pr-0 pl-0" autoClose="outside">
        <button mat-mini-fab class="dropdown-ellipses dropdown-toggle" [disabled]="!columns.length" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <mat-icon aria-hidden="false" aria-label="Filtrer">filter_list</mat-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right filter-height">
          <fieldset class="checkbox dropdown-item href" *ngFor="let table of columns" (click)="filterTable($event, table)">
            <input type="checkbox" class="href" [checked]="table.checked"> {{ table.label }}
          </fieldset>
        </div>
      </div>
    </div>

    <div class="flex-grow-1" style="max-height:calc(100% - 90px);position:relative;" [perfectScrollbar]="config">
      <div class="table-responsive">
        <table class="table table-striped table-bordered dataTable list-table">
          <thead>
          <tr>
            <th *ngFor="let column of columns"
                (click)="sortBy(column.sort)"
                [hidden]="!column.checked"
                [ngClass]="sort === column.sort ? order == 'asc' ? 'sorting_asc' : 'sorting_desc' : 'sorting'"
                [ngStyle]="{'width': column.type === 'text' ? '' : '150px'}">
              {{ column.showLabel ? column.label : '' }}
            </th>
            <th *ngIf="!hideAction"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of items" class="table-line">
            <!-- If the data as custom fields -->
            <ng-container *ngIf="hasCustomField">
              <td [width]="column.type === 'icon' ? '20' : ''" *ngFor="let column of columns" [hidden]="!column.checked" [routerLink]="column.linkData ? [item.id + '/' + column.linkData] : []" [class.link-cursor]="column.linkData" (click)="select(item)">
                {{ getDefaultType(column.type) ? getProperty(item, column) : '' }}
                <div style="padding:5px;color:#fff;border-radius:5px;" *ngIf="column.type === 'status' && item.customField[column.property] !== undefined" [ngStyle]="{display: 'inline-block', background : item.customField[column.property]['color']}"> {{ item.customField[column.property]['name'] }}</div>
                {{ column.type === 'date' ? (getProperty(item, column) | date : 'dd/MM/yyyy') : '' }}
                <div *ngIf="column.type === 'currency'">{{(getProperty(item, column))}} €</div>
                {{ column.type === 'boolean' ? (getProperty(item, column) ? 'Oui' : 'Non' ) : '' }}
                <i *ngIf="column.type === 'icon'" [ngClass]="'fa fa-' + getProperty(item, column)"></i>
                <img [src]="environment.SERVER_HOST + column.linkImg + getProperty(item, column)" alt="" *ngIf="column.type === 'img' && getProperty(item, column.property)" height="60">
                <img [src]="column.defaultImg" alt="" *ngIf="column.type === 'img' && !getProperty(item, column)" height="60">
              </td>
            </ng-container>

            <!-- If the data as not custom fields -->
            <ng-container *ngIf="!hasCustomField">
              <td *ngFor="let column of columns" (click)="select(item)" [hidden]="!column.checked">
                <!-- For notifications -->
                <ng-container *ngIf="column.property === 'isViewed'">
                  {{ item[column.property] === true ? 'Oui' : 'Non' }}
                </ng-container>
                <ng-container *ngIf="column.property !== 'isViewed' && column.type !== 'boolean'">
                  {{ item[column.property] }}
                </ng-container>

                <ng-container *ngIf="column.type === 'boolean'">
                  <span *ngIf="!item[column.property]" class="label label-open">Ouvert</span>
                  <span *ngIf="item[column.property]" class="label label-close">Fermé</span>
                </ng-container>
              </td>
            </ng-container>

            <td class="actions" width="100" *ngIf="!hideAction">
              <!--<button title="Editer" (click)="download(item.id)" *ngIf="downloadRequest !== undefined">
                Télécharger
              </button>-->

              <!--<a mat-button class="dropdown-item tooltipHtml" title="Supprimer" (click)="modal.open(item)">-->
              <!--  <mat-icon aria-hidden="false" aria-label="Supprimer">delete</mat-icon>-->
              <!--</a>-->
              <div class="dropdown current-user">
                <a aria-expanded="false" aria-haspopup="true" class="header-link dropdown-toggle" data-toggle="dropdown"
                   role="button">
                    <mat-icon aria-hidden="false" aria-label="Plus d'information">more_vert</mat-icon>
                </a>

                <ul class="dropdown-menu pull-down">
                  <li>
                    <a class="dropdown-item cursor-pointer" [routerLink]="['/ticket/' + item.id]">
                      <span>Consulter le ticket</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item cursor-pointer" (click)="modal.open(item)">
                      <span>Supprimer le ticket</span>
                    </a>
                  </li>
                </ul>
              </div>

            </td>
          </tr>
          <tr>
            <td *ngIf="loader" [colSpan]="getColSpan()" class="text-center"><i class="fa fa-spin fa-spinner"></i></td>
          </tr>
          <tr class="no-result" *ngIf="(!items || !items.length) && !loader">
            <td [attr.colspan]="getColSpan()">
              <app-no-result width="200" title="Aucune donnée"></app-no-result>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-4 mb-3">
      <app-pagination *ngIf="items" [page]="page" [limit]="limit" [pageSize]="items.length" [pages]="pages" [total]="total" (returnEvent)="changePagination($event)"></app-pagination>
    </div>
  </div>
  <div [ngClass]="isMobile ? 'col-12' : 'col-6'" class="d-flex flex-column" *ngIf="preview !== false" style="border: 1px solid rgba(0,0,0,0.125);border-top-left-radius: 5px;border-top-right-radius: 5px;padding:0;min-height:100%;">
    <div class="d-flex" style="background:rgba(0,0,0,0.03);margin:0;border-bottom: 1px solid rgba(0,0,0,0.125);padding:10px;border-top-left-radius: 5px;border-top-right-radius: 5px;">
      <div class="mr-4 link-cursor" style="line-height: 36px;" (click)="closePreview()"><mat-icon aria-hidden="false" aria-label="Masquer l'aperçus" style="position:absolute;margin-top:7px;">close</mat-icon></div>
      <div class="flex-grow-1" style="line-height: 36px;overflow:hidden;text-overflow:ellipsis;white-space: pre;word-wrap: break-word;" *ngIf="preview !== false">{{ preview === null ? 'Chargement en cours'  : objectPreview['customField']['name'] }}</div>
      <div *ngIf="preview !== false" style="width:40px;">
        <button mat-button class="d-flex tooltipHtml pr-0" data-toggle="dropdown" data-placement="bottom"
                aria-label="Menu de prévisualisation" title="Menu de prévisualisation">
          <mat-icon aria-hidden="false" aria-label="Filtrer">more_vert</mat-icon>
        </button>

        <span class="dropdown-menu dropdown-menu-right">
          <ng-container *ngFor="let nav of menu">
            <a class="dropdown-item href list-pointer" *ngIf="isVisible(objectPreview['customField'], nav)" (click)="emitMenu(nav)" [ngStyle]="nav['color'] !== 'default' ? {'color' : nav['color']} : {}">
              <mat-icon class="align-icon" aria-hidden="false" aria-label="Filtrer">{{nav['icon']}}</mat-icon> {{nav['name']}}
            </a>
          </ng-container>
        </span>
      </div>
    </div>
    <div id="overlay" *ngIf="loaderPreview">
      <span>Veuillez patienter...</span>
    </div>

    <div *ngIf="preview === null">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="preview === null" class="text-center pt-5 d-flex justify-content-center align-items-center flex-column h-100">
      <div class="text-center d-flex justify-content-center align-items-center">
        <mat-progress-spinner mode="indeterminate" [diameter]="30"></mat-progress-spinner>
      </div>
      <div>Chargement en cours</div>
    </div>
    <div *ngIf="isMobile" class="d-flex h-100 align-items-center flex-column">
      <div class="p-4">Prévisualisation on disponible sur mobile</div>
      <button class="btn btn-success" (click)="download()">Télécharger</button>
    </div>
    <iframe *ngIf="preview !== null && !isMobile" class="flex-grow-1 w-100" [src]="preview" ></iframe>
  </div>
</div>
<app-confirm-modal [type]="'delete_data'" #deleteModal>{{ bindModal(deleteModal) }}</app-confirm-modal>
