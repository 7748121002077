<div class="row mr-0 ml-0 mt-3">
  <div class="col-12">
    <div class="col-12">
      <div class="title-header mb-0"> Devis </div>
      <div class="title-small mb-3"> <a href="#" routerLink="/" class="breadcrum-link">Tableau de bord</a> > Liste des devis </div>
    </div>
  </div>
</div>

<div class="row ml-4 mr-4">
  <div class="col-12">
    <app-list
              [columns]="columns"
              [items]="quotations"
              [total]="total"
              [pages]="pages"
              [page]="page"
              [hideAdd]="true"
              [hideAction]="true"
              [menu]="menu"
              [loaderPreview]="isLoaderPreview"
              [loaderPreviewMenu]="isLoaderPreviewMenu"
              [currentPage]="'quotations'"
              (returnEvent)="updateList($event)" #list
              class="w-100">{{ bindList(list) }}</app-list>

  </div>

  <app-quotation-modal #deleteModal>{{ bindModal(deleteModal) }}</app-quotation-modal>
  <app-quotation-refuse-modal #refuseModal>{{ bindModalRefuse(refuseModal) }}</app-quotation-refuse-modal>
</div>
