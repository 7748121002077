import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageList } from '../../../core/list/page-list';
import { FilesRequestService } from '../../../core/services/files/files.request.service';
import Column from '../../../core/list/column';
import Plan from '../../../core/interfaces/plan';

@Component({
  selector: 'app-all-plans',
  templateUrl: './all-files.component.html',
  styleUrls: ['./all-files.component.scss'],
})

export class AllFilesComponent extends PageList implements OnInit {
  public columns: Column[] = [];
  public affairs: any[];
  public loader: boolean;

  /**
   * Class constructor
   * @param _activatedRoute
   * @param _plansService
   * @param router
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _plansService: FilesRequestService,
    public router: Router,
  ) {
    super();
  }

  /**
   * Called before view init
   */
  public ngOnInit() {
    // Set loader on true
    this.loader = true;

    // Get resolver data
    this._activatedRoute.data.subscribe(response => {
      this.loader = false;
      this.affairs = response.data.datas;
      this.total = response.data.total;
      this.pages = response.data.pages;

      response.data.forms.forEach(item => {
        this.columns.push({ label: item.label, showLabel: true, sort: item.name, property: item.name, checked: true, type: item.type });
      });
    });

    // Init request system progress
    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      // this.loader = this.requestCount > 0;
    });
  }

  /**
   * Get Data update
   */
  public getData() {
    this.requestInProgress.next(true);
    this.loader = true;
    this.affairs = [];

    this._plansService.getBoxPlan(this.search, this.sort, this.order, this.limit, this.page).subscribe((response: Plan) => {
      this.loader = false;
      if (response.success) {
        this.affairs = response.datas;
        this.columns = [];

        response.forms.forEach(item => {
          this.columns.push({ label: item.label, showLabel: true, sort: item.name, property: item.name, checked: true, type: item.type });
        });
        this.total = response.total;
        this.pages = response.pages;
      }
      this.requestInProgress.next(false);
    });
  }

  /**
   * Dispatch event
   * @param data
   */
  public updateList(data) {
    if (data.action === 'pageSize') {
      this.changePageSize(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    } else if (data.action === 'sort') {
      this.sort = data.value;
      this.order = data.order;
      this.getData();
    } else if (data.action === 'search') {
      this.search = data.value;
      this.page = 1;
      this.getData();
    } else if (data.action === 'select') {
      this.router.navigateByUrl('directory/' + data.value.id).then(() => {});
    }
  }
}
