import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NotificationWidgetModule } from '../../../../core/notification-widget/notification-widget.module';
import { ConfirmModalModule } from '../../../../core/confirm-modal/confirm-modal.module';
import { PaginationModule } from '../../../../core/pagination/pagination.module';
import { QuestionListComponent } from './question-list.component';
import { NoResultModule } from '../../../../core/no-result/no-result.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    MatIconModule,
    MatButtonModule,
    ConfirmModalModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    PerfectScrollbarModule,
    NotificationWidgetModule,
    NoResultModule
  ],
  declarations: [QuestionListComponent],
  exports: [QuestionListComponent]
})

export class QuestionListModule {}
