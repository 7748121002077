import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormControl, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { UsersRequestService } from '../../../core/services/users/users.request.service';
import { CustomNotifierService } from '../../../core/services/custom.notifier.service';

import { ComponentCanDeactivate } from '../../../core/utils/component-can-deactivate';

import Field from '../../../core/form/field';
import UsersResponse from '../../../core/interfaces/response/users.response';
import { ConfirmModalComponent } from '../../../core/confirm-modal/confirm-modal.component';
import Example from '../../../core/interfaces/example';


@Component({
  selector: 'app-edit-example',
  templateUrl: './edit-example.component.html',
  styleUrls: ['./edit-example.component.scss'],
})
export class EditExampleComponent implements OnInit, ComponentCanDeactivate {
  isIntact = true;
  errors: { general?: { code: string; message: string } };
  example: Example;
  exampleId: number;
  subExamples: Example[] = [];

  fields: Field[] = [
    {
      label: 'Email', showLabel: true, property: 'email', type: 'text',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Mot de passe', showLabel: true, property: 'password', type: 'password',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Confirmation du mot de passe', showLabel: true, property: 'confirm_password', type: 'password',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Lien', showLabel: true, property: 'link_data', type: 'url',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Choix de la couleur', showLabel: true, property: 'color', type: 'color',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Description courte', showLabel: true, property: 'description', type: 'textarea',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Contenu', showLabel: true, property: 'content', type: 'editor',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Quota', showLabel: true, property: 'quota', type: 'number',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Prix', showLabel: true, property: 'price', type: 'currency',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Date de début', showLabel: true, property: 'started_at', type: 'date',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Envoie de notification', showLabel: true, property: 'is_notification', type: 'boolean',
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Photo', showLabel: true, property: 'picture', type: 'file',
      formControl: new FormControl({value: '', disabled: false}, [])
    },
    {
      label: 'Categorie', showLabel: true, property: 'category_id', type: 'select', selectItems: [],
      formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Categories multiples', showLabel: true, property: 'category_id', type: 'select', selectItems: [], multiple: true,
      compareFn: EditExampleComponent.compareFunction, formControl: new FormControl({value: '', disabled: false}, [Validators.required])
    },
    {
      label: 'Sous formulaire', showLabel: true, property: 'property1', type: 'form', selectItems: [], multiple: true,
      formControl: new FormArray([]), subFields: [
        {
          label: 'Sous-titre', showLabel: true, property: 'sub_title', type: 'text',
          formControl: new FormControl({value: '', disabled: false}, [])
        },
      ]
    },
  ];

  requestInProgress = new Subject();
  requestCount = 0;
  loader: boolean;

  modal: ConfirmModalComponent;

  static compareFunction(c1: any, c2: any) {
    if (!c1 || !c2) {
      return false;
    }
    return c1.id === c2.id;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersRequestService,
    private notifier: CustomNotifierService,
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(response => {
      if (response.data && response.data.example) {
        this.example = response.data.example;

        // DISABLE FIELD
        const emailField = this.fields.find(elem => {
          return elem.property === 'email';
        });
        emailField.formControl.disable();
        this.fields.splice(1, 2);
      }


      // ADD DATA IN SELECT
      this.subExamples = response.data.subExamples;
      const categoryField = this.fields.find(elem => {
        return elem.property === 'category_id';
      });
      categoryField.selectItems = [];
      this.subExamples.forEach(subExample => {
        categoryField.selectItems.push({
          value: subExample.id, label: subExample.name
        });
      });

      const categoriesField = this.fields.find(elem => {
        return elem.property === 'category_id';
      });
      categoriesField.selectItems = [];
      this.subExamples.forEach(subExample => {
        categoriesField.selectItems.push({
          // value: subExample.id, label: subExample.name
          value: { id: subExample.id }, label: subExample.name
        });
      });
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });

    this.exampleId = this.activatedRoute.snapshot.params.exampleId;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.isIntact) {
      return true;
    } else {
      const observable = new Observable<boolean>(observer => {
        this.modal.eventOnClose.subscribe(event => {
          if (event) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      });
      this.modal.open(1);
      return observable;
    }
  }

  validateForm($event) {
    let request;
    if (this.exampleId) {
      request = this.usersService.updateUser(this.exampleId, $event);
    } else {
      request = this.usersService.createUser($event);
    }
    this.requestInProgress.next(true);
    request.subscribe((response: UsersResponse) => {
      this.requestInProgress.next(false);
      if (response.success) {
        if (this.exampleId) {
          this.notifier.successUpdate();
        } else {
          this.notifier.successCreate();
        }
        this.isIntact = true;
        this.router.navigate(['/examples']).then();
      } else {
        this.errors = response.errors;
      }
    });
  }

  intactForm($event) {
    this.isIntact = $event;
  }

  bindModal(pendingModal: ConfirmModalComponent) {
    this.modal = pendingModal;
  }
}
