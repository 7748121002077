import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import * as _ from 'lodash';

import { NotificationService } from '../../core/services/notification/notification.service';
import { NotificationRequestService } from '../../core/notification-widget/notification.request.service';

import Notification from '../../core/interfaces/notification/notification';
import NotificationResponse from '../../core/interfaces/response/notification.response';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('notifications', [
      transition(':enter', [
        query('@notification', stagger(200, animateChild()), { optional: true }),
      ]),
    ]),
    trigger('notification', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))
      ])
    ])
  ]
})

export class NotificationsComponent implements OnInit {
  public url: any;
  public notifications: Notification[] = [];
  public changeIsViewed = false;
  public objectKeys = Object.keys;
  public throttle = 50;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public page = 1;
  public moment = moment;
  protected token = moment().format('x');

  /**
   * Class constructor
   * @param notificationService
   * @param notificationRequest
   */
  constructor(
    public notificationService: NotificationService,
    public notificationRequest: NotificationRequestService,
  ) {
    moment.locale('fr');
  }

  /**
   * No need to retrieve notifications on init,
   * they're retrieved with the notification widget
   */
  ngOnInit() {}

  /**
   * Mark notification as viewed or not viewed
   */
  viewNotification(notification) {
    this.changeIsViewed = true;

    if (!notification.isViewed) {
      notification.isViewed = true;
      this.notificationService.count = this.notificationService.count - 1;
    } else {
      notification.isViewed = false;
      this.notificationService.count = this.notificationService.count + 1;
    }

    this.notificationRequest.updateNotification(notification).subscribe(() => {
      this.changeIsViewed = false;
    });
  }

  /**
   * Infinite scroll event
   */
  onDown() {
    this.page = this.page + 1;
    if (this.page <= this.notificationService.pages) {
      this.notificationRequest.getAllNotifications('', 'created', 'desc', 10, this.page)
        .subscribe((response: NotificationResponse) => {
          this.notificationService.pages = response.pages;
          const groupResults = _.groupBy(response.datas, (result) => moment(result.created.date).startOf('month').format('MMMM YYYY'));
          this.notificationService.notifications = _.mergeWith(this.notificationService.notifications, groupResults, this.customizer);
        });
    }
  }

  /**
   * Use for merge notification service with new response from api
   */
  customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  }

  /**
   * Send for marking all notification as viewed
   */
  viewAll() {
    if (this.notificationService.count > 0) {
      this.notificationRequest.markAllNotificationsAsSeen()
        .subscribe((response: any) => {
          if (response.success) {
            this.notificationService.count = 0;
            this.notificationService.markAllNotificationsAsSeen();
          }
        });
    }
  }
}
