import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomNotifierService } from '../../../../core/services/custom.notifier.service';

import { PageList } from '../../../../core/list/page-list';

import Column from '../../../../core/list/column';
import { QuestionListComponent } from '../question-list/question-list.component';
import { TheonormeRequestService } from '../../../../core/services/theonorme/theonorme.request.service';
import {AuthService} from "../../../../core/auth/auth.service";
import User from "../../../../core/interfaces/user/user";
import { QuestionModalComponent } from '../../../../core/modals/question/question-modal.component';

@Component({
  selector: 'app-all-questions',
  templateUrl: './all-questions.component.html',
  styleUrls: ['./all-questions.component.scss'],
})
export class AllQuestionsComponent extends PageList implements OnInit {
  columns: Column[] = [];
  questions: any[];
  canCreate = false;
  public loader: boolean;
  private list: QuestionListComponent;
  hasPack = false;
  modal: any;
  uniqQuestion: any;
  uniqAnswer: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private notifier: CustomNotifierService,
    private activatedRoute: ActivatedRoute,
    private questionsService: TheonormeRequestService,
  ) {
    super();
  }

  get user(): User {
    return this.authService.user;
  }

  ngOnInit() {

    this.activatedRoute.data.subscribe(response => {

      if (response.data && response.data.success) {
        this.questions = response.data.questions;
        this.canCreate = response.data.canCreate;
        this.hasPack = response.data.hasPack;
        this.uniqQuestion = response.data.uniqQuestion;
        this.uniqAnswer = response.data.uniqAnswer;

        this.total = response.data.total;
        this.pages = response.data.pages;

        response.data.forms.forEach(item => {
          this.columns.push({ label: item.label, showLabel: true, sort: item.name, property: item.name, checked: true, type: item.type });
        });

      } else {
        this.notifier.errors(response.data.errors);
      }
    });

    this.requestInProgress.subscribe((value: boolean) => {
      if (value) {
        this.requestCount++;
      } else if (this.requestCount > 0) {
        this.requestCount--;
      }
      this.loader = this.requestCount > 0;
    });
  }

  getData() {
    this.requestInProgress.next(true);

    this.questionsService.getQuestions(this.search, this.sort, this.order, this.limit, this.page).subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        this.questions = response.datas;
        this.total = response.total;
        this.pages = response.pages;
      }
    });
  }

  deleteData(questionId) {
    this.requestInProgress.next(true);
    this.questionsService.deleteQuestion(questionId).subscribe((response: any) => {
      this.requestInProgress.next(false);
      if (response.success) {
        this.notifier.successDelete();
        this.changePage(1);
      } else {
        this.notifier.errors(response.errors);
      }
    });
  }

  updateList(data) {
    if (data.action === 'pageSize') {
      this.changePageSize(data.value);
    } else if (data.action === 'page') {
      this.changePage(data.value);
    } else if (data.action === 'sort') {
      this.sort = data.value;
      this.order = data.order;
      this.getData();
    } else if (data.action === 'search') {
      this.search = data.value;
      this.page = 1;
      this.getData();
    } else if (data.action === 'delete') {
      this.deleteData(data.value.id);
    } else if (data.action === 'select') {
      this.router.navigate(['/theonorme/question/' + data.value.id]).then(() => {});
    }
  }

  bindList(list: QuestionListComponent) {
    if (this.list === undefined) {
      this.list = list;
    }
  }

  askUniqueQuestion(event) {
    event.preventDefault();
    this.modal.open(this.uniqQuestion, this.uniqAnswer);
  }

  bindModal(questionModal: QuestionModalComponent) {
    if(this.modal === undefined) {
      this.modal = questionModal;

      this.modal.eventOnClose.subscribe(value => {
        if(value === null)
          return;

        this.questionsService.createQuestion(value).subscribe((response: any) => {
          this.requestInProgress.next(false);
          if (response.success === true) {
            this.notifier.successCustom('Question envoyée avec succès, vous recevrez une réponse dans les plus bref délais.');
            this.uniqQuestion = value['question'];
            this.canCreate = false;
          } else {
            this.notifier.errorRequest();
          }
        });
      });
    }
  }
}
