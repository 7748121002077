<div id="confirm-modal" class="modal modal-confirm fade" tabindex="-1" role="dialog" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center">

        <div class="row">
          <div class="col">
            <h1 class="header-title">Ajouter un fichier</h1>
          </div>
        </div>

        <button type="button" class="close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="mb-3">Choisissez un fichier depuis votre poste permettra de le partager avec Batisafe.</div>
        <div class="mb-3">
          <b class="d-block">Ajouter un fichier</b>
          <input type="file" [(ngModel)]="email" (change)="handleUpload($event)" />
        </div>
      </div>

      <div class="modal-footer">
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 mr-2" (click)="cancel()">
          Annuler
        </button>
        <button mat-raised-button class="mat-accent btn btn-primary col w-100 ml-2" [disabled]="file === undefined" (click)="validate()">
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
