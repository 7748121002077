import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { HttpCancelService } from '../http.cancel.service';

import * as moment from 'moment';

import { QuotationsRequestService } from './quotations.request.service';
import { QuotationAll } from '../../interfaces/quotation/response/all';

@Injectable()
export class QuotationsResolver implements Resolve<any> {
  routerEvent: Subscription;
  token = moment().format('x');

  requestInProgress = new Subject();
  requestCount = 0;

  constructor(
    private router: Router,
    private quotationsService: QuotationsRequestService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data: any = {};

    this.routerEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationCancel) {
        HttpCancelService.cancelPendingRequests(this.token);
        this.routerEvent.unsubscribe();
        this.requestCount = 0;
      }
    });

    return new Promise((resolve, reject) => {
      this.requestInProgress.subscribe((value: boolean) => {
        if (value) {
          this.requestCount++;
        } else if (this.requestCount > 0) {
          this.requestCount--;
        }
        if (this.requestCount === 0) {
          resolve(data);
        }
      });


      this.requestInProgress.next(true);
      this.quotationsService.getQuotations('', '', '', 12, 1, this.token)
        .subscribe((response: QuotationAll) => {
          data.datas = response.datas;
          data.forms = response.forms;
          data.total = response.total;
          data.pages = response.pages;
          this.requestInProgress.next(false);
        });


      if (route.routeConfig.path === 'quotations/:exampleId') {
        resolve({
          example: null,
          subExamples: [
            { id: 1, name: 'Cat 1' },
            { id: 2, name: 'Cat 2' },
            { id: 3, name: 'Cat 3' },
            { id: 4, name: 'Cat 4' },
            { id: 5, name: 'Cat 5' }
          ]
        });
      } else if (route.routeConfig.path === 'quotations/create') {
        resolve({
          subExamples: [
            { id: 1, name: 'Cat 1' },
            { id: 2, name: 'Cat 2' },
            { id: 3, name: 'Cat 3' },
            { id: 4, name: 'Cat 4' },
            { id: 5, name: 'Cat 5' }
          ]
        });
      }
    });
  }
}
