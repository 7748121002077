<div class="card">
  <div class="card-header d-flex">
    <mat-icon aria-hidden="false" aria-label="">dashboard</mat-icon>
    <h5 class="card-title">
      Tableau de bord
    </h5>
  </div>
  <div class="card-body">
    <div class="w-100 text-center p-5">
      Tableau de bord en construction
    </div>
  </div>
</div>
